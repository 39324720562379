/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-duplicate-props */
import _, { isEmpty } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import useSnackbar from 'src/hooks/useCustomSnackbar';
import { useForm, Controller } from 'react-hook-form';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useDebounce } from 'use-lodash-debounce';
import {
    Box,
    Button,
    Typography,
    ButtonGroup,
    Popper,
    Grow,
    Paper,
    MenuList,
    MenuItem,
    Autocomplete,
    TextField,
    Zoom,
    Tooltip,
    FormControl,
    InputLabel,
    Select,
    Radio,
    Avatar
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { TAB_HEAD } from 'src/constants/LeadDetailConst';
import { getIconAndColor } from 'src/utils/taskPriority';
import { grey } from 'src/theme/palette';
// components
import { Iconify, Label } from '..';
import { CustomAvatar, AssignUserAvatar } from '../custom-avatar';
import { NormalLoading } from '../loading';
import { SearchNotFound } from '../search-not-found';
import { AssignTaskPopup, AddNotePopup } from '../dialogPopUp';
import LeadTemplate from '../../pages/dashboard/LeadTemplate';
// store
import { useSelector, dispatch } from '../../redux/store';
import { getActivities } from '../../redux/slices/activity';
import {
    updateReminder,
    updateLeadTaskCounter,
    getLeadReminderData,
    clearTaskLead,
    followUpSlice,
    getReminderData,
    searchLead,
    addNotes
} from '../../redux/slices/lead';
import { clearPracticeUserData } from '../../redux/slices/practiceUser';
// hooks
import { FormProvider } from '../hook-form';
import { useResponsive, useAssignTaskToggle, useBoolean, useSwitchUserToggle, useAuth, useLeadTableHelper } from '../../hooks';
// utils
import capitalizeFirstLetter, { capitalizeFirstWord } from '../../utils/capitalizedFunc';
import { getAssignees } from '../../utils/multiAsignees';
// style
import {
    sidebarMainBox,
    plusButton,
    plusMenu,
    recordBtn,
    btnOuterBox,
    descriptionValueStyle,
    recordBtnArrow,
} from '../../styles/TaskSidebarStyle';
import {
    taskUserAvatar,
    openTask,
    assignTaskHandlerStyle,
    actionFormControlStyle,
    selectedReminderBoxStyle,
    selectColorControllerStyle,
    assignUserAvatarStyle,
    assignLengthStyle,
    readOnlyMainElementStyle,
    commentBoxStyle,
    commentBoxInnerStyle,
    pickerTextFieldStyle,
    dueDateBoxStyle,
    dueDateButtonStyle,
    dueDateButtonLabelStyle,
    dueDatePickerBoxStyle,
    callButtonStyle,
    mainFlowBoxStyle
} from '../../styles/leadTaskReminder';
import { avatarStyle } from '../../styles/AccountSocialLinks';
import {
    leadAutoComplete,
} from '../../styles/AddReminderStyle';

// ------------------------------------------------------------------

const TaskSidebar = ({ data, getReminderBtn, handleAttendClick, handleAttendFailedClick, reminderDataId, type, overViewbtns, setReloadCounters, onClose }) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { reminderPriority, reminderMethod } = useSelector((state) => state.schema);
    const [appointment, setAppointment] = useState([]);
    const [open, setOpen] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [anchorEl, setAnchorEl] = useState(null);
    const [priorityFilter, setPriorityFilter] = useState('');
    const isDesktop = useResponsive('down', 'sm');
    const [searchQuery, setSearchQuery] = useState(`${capitalizeFirstLetter(data?.lead?.first_name || '')} ${capitalizeFirstLetter(data?.lead?.last_name || '')}`);
    const {
        taskDetail,
        taskSearchLead,
        isLoading,
        isTasksCompleted,
        dashboardTaskPageFilter,
        taskPageFilter,
        leadNotes,
        taskTableData: { tasks: { limit, offset, filter, assignUser } }
    } = useSelector((state) => state.lead);
    const debouncedValue = useDebounce(searchQuery, 1000);
    const [leadTreatments, setLeadTreatments] = useState([]);
    const [dueDatetimeValue, setDueDatetimeValue] = useState(moment(data?.datetime, 'DD/MM/YYYY hh:mm:ss').format('ddd[,] D MMMM [at] hh:mm A'));
    const [detailValue, setDetailValue] = useState(data?.note || '');
    const [titleValue, setTitleValue] = useState(data?.title || '');
    const [descriptionValue] = useState(data?.description || '');
    const [comment, setComment] = useState('');
    const [markButton, setMarkButton] = useState(data?.is_completed || false);
    const [options, setOptions] = useState('');
    const [templateOpen, setTemplateOpen] = useState('');
    const theme = useTheme();
    const { user } = useAuth();
    const [anchorElAssign, setAnchorElAssign] = useState(null);
    const [selectedBox, setSelectedBox] = useState('');
    const [commentHide, setCommentHide] = useState(false);

    const { nearestPastData: nearestPastDataV2, nearestData: nearestDataV2 } = useLeadTableHelper('ConsultantBooked');

    useEffect(() => {
        if (!isEmpty(taskDetail?.priority)) {
            setPriorityFilter(taskDetail?.priority);
        }
    }, [taskDetail?.priority]);

    useEffect(() => {
        if (data) {
            const leadAppointments = nearestData(data?.lead_treatment?.appointments) || nearestPastData(data?.lead_treatment?.appointments);
            setAppointment(leadAppointments);
            setSearchQuery(`${capitalizeFirstLetter(data?.lead?.first_name || '')} ${capitalizeFirstLetter(data?.lead?.last_name || '')}`);
            if (data?.lead_treatment) {
                setLeadTreatments([{ treatment: data?.lead_treatment?.treatment, id: data?.lead_treatment?.id }]);
            }
            setValue('relation', data?.lead_treatment?.id);
            setValue('actionType', data?.method);
            setDetailValue(data?.note || '');
            setMarkButton(data?.is_completed || false);
            setSelectedBox(data.method);
            if (data?.datetime) {
                const formattedDate = moment(data?.datetime, 'DD/MM/YYYY hh:mm:ss').format('DD/MM/YYYY HH:mm');
                setValue('DueDatetime', formattedDate);
            }
            if (setReloadCounters) {
                setReloadCounters(false)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const openCalendar = useBoolean();

    const defaultValues = {
        relation: data?.lead_treatment?.id || '',
        actionType: data?.method || ''
    };

    const methods = useForm({
        mode: 'onChange',
        defaultValues
    });

    const {
        setValue,
        getValues,
        clearErrors,
        control,
        formState: { errors }
    } = methods;

    useEffect(() => {
        if (searchQuery?.trim()?.length > 0) {
            let search = searchQuery;
            if (Number(searchQuery) && !searchQuery.includes('+')) {
                search = `+44${searchQuery}`;
            }
            dispatch(searchLead(encodeURIComponent(search), practiceId, 'taskLead'));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [debouncedValue]);

    const nearestData = (data) => {
        const now = moment().format();
        const filterData = data?.filter(value => (value.status === 'BOOKED' || value.status === 'ATTENDED'));
        const result = _.filter(filterData, obj => moment(obj.datetime, 'DD-MM-YYYY HH:mm:ss').format() > now);
        result.sort((a, b) => moment(a.datetime, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').diff(moment(b.datetime, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ')));
        return result?.length > 0 && result;
    };

    const nearestPastData = (data) => {
        const now = moment().format();
        const filterData = data?.filter(value => (value.status === 'BOOKED' || value.status === 'ATTENDED'));
        const result = _.filter(filterData, obj => moment(obj.datetime, 'DD-MM-YYYY HH:mm:ss').format() < now);
        result.sort((a, b) => moment(b.datetime, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').diff(moment(a.datetime, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ')));
        return result?.length > 0 && result;
    };

    const handleLeadData = (e) => {
        if (reminderDataId) {
            reminderDataId.current = data?.id;
        }
        setAnchorEl(e.currentTarget);
        setOpen(!open);
    };

    const buttonHandleClose = (event) => {
        if (anchorEl.current && anchorEl.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const handleGroupBtns = (type) => {
        setOpen(false);
        if (type === 'Attended') {
            handleAttendClick(data?.lead_treatment?.lead, data);
        } else {
            handleAttendFailedClick('', data, data?.lead_treatment?.lead);
        }
    };

    const handleSearchLead = (value) => {
        const leadArr = [];
        value?.lead_treatments?.map((el) => leadArr.push({ treatment: el?.treatment, id: el?.id }));
        const reminderData = {
            lead: value?.id || '',
            lead_treatment: value?.lead_treatments[0]?.id || data?.lead_treatment?.id || '',
            assignees: data?.assignees?.map((el) => el?.id)
        };
        if (value) {
            setSearchQuery(`${value?.first_name} ${value?.last_name}`);
            dispatch(updateReminder(reminderData, data?.id, practiceId, handleReminder, 'markComplete', handleSuccess));
        }
    };

    const dueDateChange = (value) => {
        setDueDatetimeValue(value);
    };

    const dueDateBlur = (value) => {
        const reminderData = {
            datetime: value ? `${moment(value).format('DD-MM-YYYY HH:mm:ss')}.0Z` : null,
            assignees: data?.assignees?.map((el) => el?.id)
        };
        if (value) {
            openCalendar.onFalse();
            dispatch(updateReminder(reminderData, data?.id, practiceId, handleReminder, 'markComplete', handleSuccess));
        }
    };

    const handleActionTypeChange = (e) => {
        const reminderData = {
            method: e?.target?.value || '',
            assignees: data?.assignees?.map((el) => el?.id)
        };
        if (e?.target?.value) {
            dispatch(updateReminder(reminderData, data?.id, practiceId, handleReminder, 'markComplete', handleSuccess));
        }
    };

    const handleReminder = () => {
        setComment('');
        dispatch(updateLeadTaskCounter([]));
        dispatch(getReminderData(practiceId, data?.id));
        dispatch(followUpSlice(data?.lead?.id, practiceId, 'LeadDetail'));
        dispatch(getActivities(practiceId, data?.lead?.id, 10, 0));
        dispatch(getLeadReminderData(practiceId, 'Tasks', limit, offset, filter, '', assignUser, '', '', '', taskPageFilter, isTasksCompleted));
        dispatch(getLeadReminderData(practiceId, 'LeadDetail', 1, 0, '', data?.lead?.id, '', '', 'firstTime'));
        dispatch(getLeadReminderData(practiceId, 'dashboard', limit, offset, filter, '', assignUser, '', '', '', dashboardTaskPageFilter, isTasksCompleted, false));
        if (setReloadCounters){
            setReloadCounters(true);
            onClose();
        }
    };

    const searchLeadHandler = (value, reason) => {
        if (type === 'addTask' && reason === 'input') {
            setSearchQuery(value);
        }
        if (value.length === 0) {
            dispatch(clearTaskLead());
        }
    };

    const noOptionsHandler = () => {
        if (taskSearchLead && taskSearchLead.length === 0) {
            return <SearchNotFound searchQuery={searchQuery} />;
        }
        return '';
    };

    const handleSuccess = (data) => {
        enqueueSnackbar(data.message || '', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            }
        });
    };

    const handleTreatments = (e) => {
        setValue('relation', e?.target?.value);
        clearErrors('relation');
    };

    const {
        toggle: openAssignTask,
        onOpen: onOpenAssignTask,
        onClose: onCloseAssignTask
    } = useAssignTaskToggle();

    const assignTaskHandler = (event) => {
        setAnchorElAssign(anchorElAssign ? null : event.currentTarget);
        onOpenAssignTask();
    };

    const allPopupCloseHandler = (value) => {
        if (value === 'Assigned') {
            onCloseAssignTask();
            setAnchorElAssign(null);
        }
        dispatch(getReminderData(practiceId, data?.id));
        dispatch(getLeadReminderData(practiceId, 'Tasks', limit, offset, filter, '', assignUser, '', '', '', dashboardTaskPageFilter, isTasksCompleted));
    };

    const setDetail = (value) => {
        setDetailValue(value);
    };

    const handlePriorityFilterChange = (e) => {
        setPriorityFilter(e.target.value);
        const reminderData = {
            priority: e.target.value || '',
        };
        dispatch(updateReminder(reminderData, data?.id, practiceId, handleReminder, 'markComplete', handleSuccess));
    };

    const onBlurEvent = (value) => {
        const reminderData = {
            note: value || '',
            assignees: data?.assignees?.map((el) => el?.id)
        };
        dispatch(updateReminder(reminderData, data?.id, practiceId, handleReminder, 'markComplete', handleSuccess));
    };

    const onBlurCommentEvent = () => {
        const Details = {
            lead: data?.lead?.id,
            additional_notes: comment || ''
        };
        dispatch(addNotes(Details, practiceId, handleReminder, 'leadNotePopup'));
    };

    const setTitle = (value) => {
        setTitleValue(value);
    };

    const titleBlurEvent = (value) => {
        const reminderData = {
            title: value || '',
            assignees: data?.assignees?.map((el) => el?.id)
        };
        dispatch(updateReminder(reminderData, data?.id, practiceId, handleReminder, 'markComplete', handleSuccess));
    };

    const assigneeData = data?.assignees?.filter((el) => el?.user?.first_name && !el?.user?.is_superuser);

    const markButtonHandler = () => {
        const reminderData = {
            is_completed: !markButton
        };
        dispatch(updateReminder(reminderData, data?.id, practiceId, handleReminder, 'markComplete', handleSuccess));
    };

    const { toggle: openNotePopup, onOpen: onOpenNotePopup, onClose: onCloseNotePopup } = useSwitchUserToggle();

    const handleCloseNotePopup = () => {
        onCloseNotePopup();
        dispatch(getActivities(practiceId, data?.lead?.id || data?.lead?.id, 10, 0));
        dispatch(followUpSlice(data?.lead?.id || data?.lead?.id, practiceId, 'LeadDetail'));
    };

    const { iconColor } = getIconAndColor(priorityFilter);

    const leadAppointments = nearestDataV2(data?.lead_treatment?.appointments) || nearestPastDataV2(data?.lead_treatment?.appointments);

    const onClickAwayEvent = () => {
        const selectMenu = document.querySelector('.MuiModal-root');
        if (selectMenu) {
            return;
        }
        onCloseAssignTask();
        setAnchorElAssign(null);
        dispatch(clearPracticeUserData());
    };

    return (
        <FormProvider methods={methods}>
            <Box sx={{ position: 'relative', maxHeight: 'calc(100vh - 165px)', overflow: 'auto' }}>
                <NormalLoading pageCount={(Object.values(data)?.length <= 0  && 'No Found')} tableData={(Object.values(data)?.length <= 0  && 'No Found')} />
                <Box sx={{ display: 'flex', p: '16px 20px 24px' }} >
                    <Box sx={{ minWidth: '40px', mt: '0' }}>
                        <Tooltip title={markButton ? 'Mark as incomplete' : 'Mark as complete'} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                            <Radio
                                checked={markButton}
                                onClick={() => markButtonHandler()}
                                name='radioButtons'
                                sx={{ p: 1, '.MuiSvgIcon-root': { width: '24px', height: '24px' } }}
                            />
                        </Tooltip>
                    </Box>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', p: '4px 0 0' }}>
                        {data.title !== '' ? <Typography fontWeight={700} fontSize={'18px'} lineHeight={'28px'}>{capitalizeFirstLetter(data?.title)}</Typography> : <>
                            <Typography fontWeight={700} fontSize={'18px'} lineHeight={'28px'} marginRight={'8px'} > {capitalizeFirstLetter(data?.method === 'OTHER' ? 'Discuss with' : data?.method || '')} </Typography>
                            <Typography fontWeight={700} fontSize={'18px'} lineHeight={'28px'} marginRight={'8px'} > {`${data?.lead?.first_name || ''} ${data?.lead?.last_name || ''}`} </Typography>
                            <Typography fontWeight={700} fontSize={'18px'} lineHeight={'28px'} marginRight={'8px'} > about </Typography>
                            <Typography fontWeight={700} fontSize={'18px'} lineHeight={'28px'} marginRight={'8px'} > {`${data?.lead_treatment?.treatment || ''}`} </Typography>
                            {appointment?.length > 0 && <Typography fontWeight={700} fontSize={'18px'} lineHeight={'28px'} marginRight={'8px'} > at </Typography>}
                            <Typography fontWeight={700} fontSize={'18px'} lineHeight={'28px'} marginRight={'8px'} > {appointment[0]?.datetime ? moment(appointment[0]?.datetime, 'DD/MM/YYYY hh:mm:ss').format('ddd[,] D MMMM [at] hh:mm A') : ''} </Typography>
                        </>}
                    </Box>
                </Box>
                <Box sx={sidebarMainBox}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: '24px' }}>
                        <FormControl variant="standard" sx={actionFormControlStyle}>
                            <Box sx={selectedReminderBoxStyle}>
                                <Iconify icon={reminderMethod?.find(reminder => reminder.value === selectedBox)?.icon || 'mdi:target'} width={24} height={24} sx={{ color: selectedBox ? 'primary.main' : 'common.grey' }} />
                            </Box>
                            <InputLabel id="demo-simple-select-standard-label" sx={{ fontSize: '16px' }}>Trigger an action</InputLabel>
                            <Controller
                                name="actionType"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        label="Trigger an action"
                                        defaultValue={defaultValues?.actionType}
                                        sx={selectColorControllerStyle}
                                        error={!!errors.actionType}
                                        onChange={(e) => {
                                            field.onChange(e);
                                            handleActionTypeChange(e);
                                        }}
                                    >
                                        {reminderMethod?.map((reminder) => (
                                            <MenuItem key={reminder.value} value={reminder.value}>
                                                <Typography variant="inherit">{reminder.label}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                            {errors.actionType && (
                                <Typography variant="caption" sx={{ color: 'error.main', mt: 1 }}>
                                    {errors.actionType.message}
                                </Typography>
                            )}
                        </FormControl>
                    </Box>
                    <Box sx={readOnlyMainElementStyle}>
                        <FormControl variant="standard" sx={actionFormControlStyle}>
                            <Box sx={selectedReminderBoxStyle}>
                                <Iconify icon={'lets-icons:user'} width={24} height={24} sx={{ color: 'primary.main' }} />
                            </Box>
                            <Controller
                                name={'leadName'}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <Autocomplete
                                        {...field}
                                        name={'leadName'}
                                        inputValue={searchQuery}
                                        value={searchQuery}
                                        clearIcon={false}
                                        readOnly
                                        options={taskSearchLead && taskSearchLead?.length > 0 ? taskSearchLead : []}
                                        filterOptions={(option) => option}
                                        loading={isLoading}
                                        sx={{ ...leadAutoComplete, mb: 0, '.MuiInputBase-root': { color: 'primary.main', pb: '80px' }, '.MuiInputBase-input': { pt: '0 !important' } }}
                                        onInputChange={(event, value, reason) => searchLeadHandler(value, reason)}
                                        noOptionsText={noOptionsHandler()}
                                        onChange={(e, value) => handleSearchLead(value)}
                                        isOptionEqualToValue={(option, value) => option?.first_name === value}
                                        getOptionLabel={(option) => `${option?.first_name} ${option?.last_name}`}
                                        renderOption={(props, option) => {
                                            const { first_name: firstName, last_name: lastName, lead_contact: leadContact } = option;
                                            const { email, phone } = leadContact || {};
                                            return (
                                                <Box component="li" {...props} key={option.id} >
                                                    {firstName || ''} {lastName || ''} | {email || ''} | {phone || ''}
                                                </Box>
                                            );
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                error={!!error}
                                                labelId="demo-patient-select-standard-label"
                                                label="Patient"
                                                variant="standard"
                                                helperText={(searchQuery?.length === 0 && error) && 'This field is required'}
                                            />
                                        )}
                                    />
                                )}
                            />
                            <Box sx={{ position: 'absolute', bottom: 0, left: '40px', p: '0 0 16px' }}>
                                <Typography variant="subtitle2" sx={{ fontSize: '12px', color: 'common.grey', fontWeight: 600 }}>
                                    Patient current status
                                </Typography>
                                <Box sx={{ my: '4px' }}>
                                    <Label sx={{ bgcolor: 'common.labelGreyBackground' }}>{`${TAB_HEAD?.filter((el) => el?.value === data?.lead_treatment?.status)[0]?.label}`}</Label>
                                </Box>
                            </Box>
                        </FormControl>
                    </Box>
                    <Box sx={readOnlyMainElementStyle}>
                        <FormControl variant="standard" sx={actionFormControlStyle}>
                            <Box sx={selectedReminderBoxStyle}>
                                <Iconify icon={'lets-icons:tooth'} width={24} height={24} sx={{ color: 'primary.main' }} />
                            </Box>
                            <InputLabel id="demo-treatment-select-standard-label" sx={{ fontSize: '16px' }}>Treatment</InputLabel>
                            <Controller
                                name="relation"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        readOnly
                                        labelId="demo-treatment-select-standard-label"
                                        id="demo-simple-select-standard"
                                        name='relation'
                                        defaultValue={defaultValues?.relation}
                                        label='Treatment'
                                        sx={selectColorControllerStyle}
                                        error={!!errors.relation}
                                        onChange={(e, value) => handleTreatments(e, value)}
                                    >
                                        {leadTreatments.map((options, index) => (
                                            <MenuItem
                                                key={index}
                                                value={options?.id}
                                            >
                                                {options?.treatment}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                )}
                            />
                            {errors.relation && (
                                <Typography variant="caption" sx={{ color: 'error.main', mt: 1 }}>
                                    {errors.relation.message}
                                </Typography>
                            )}
                        </FormControl>
                    </Box>
                    <Box sx={{ display: 'none' }}>
                        {(data?.lead_treatment?.status === 'CONSULTATION_BOOKED' || data?.lead_treatment?.status === 'CONSULTATION_ATTENDED') &&
                            <>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: '24px' }} >
                                    <Box sx={{ width: '40%', fontSize: '13px', color: 'common.grey' }} >
                                        Appointment method
                                    </Box>
                                    <Box sx={{ textAlign: 'left', fontSize: '14px' }} >
                                        {capitalizeFirstWord(appointment[0]?.method || '')}
                                    </Box>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', mb: '24px' }} >
                                    <Box sx={{ width: '40%', fontSize: '13px', color: 'common.grey' }} >
                                        Appointment type
                                    </Box>
                                    <Box sx={{ textAlign: 'left', fontSize: '14px' }} >
                                        {capitalizeFirstWord(appointment[0]?.type || '')}
                                    </Box>
                                </Box>
                            </>
                        }
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: '24px' }}>
                        <FormControl variant="standard" sx={actionFormControlStyle}>
                            <Box sx={selectedReminderBoxStyle}>
                                <Iconify icon={'majesticons:calendar'} width={24} height={24} sx={{ color: 'primary.main' }} />
                            </Box>
                            <InputLabel id="demo-due-select-standard-label" sx={{ fontSize: '16px' }} shrink>Due date</InputLabel>
                            <Box sx={dueDateBoxStyle}>
                                <Button onClick={() => openCalendar.onTrue()} sx={dueDateButtonStyle} disableRipple >
                                    <Label sx={dueDateButtonLabelStyle}>
                                        {data?.datetime ? moment(data?.datetime, 'DD/MM/YYYY hh:mm:ss').format('ddd[,] D MMMM [at] hh:mm A') : ''}
                                    </Label>
                                </Button>
                                <Box sx={dueDatePickerBoxStyle}>
                                    <DateTimePicker
                                        orientation="landscape"
                                        ampm={false}
                                        label=''
                                        open={openCalendar.value}
                                        onClose={openCalendar.onFalse}
                                        name='DueDatetime'
                                        value={dueDatetimeValue}
                                        minDateTime={new Date()}
                                        inputFormat={'dd/MM/yyyy HH:mm'}
                                        onChange={(newValue) => dueDateChange(newValue)}
                                        onAccept={(newValue) => dueDateBlur(newValue)}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                margin: 'normal',
                                                sx: pickerTextFieldStyle,
                                                onKeyDown: (e) => e.preventDefault()
                                            },
                                        }}
                                    />
                                </Box>
                            </Box>
                        </FormControl>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: '24px' }}>
                        <FormControl variant="standard" sx={actionFormControlStyle}>
                            <Box sx={selectedReminderBoxStyle}>
                                <Iconify icon={'ic:outline-flag'} width={24} height={24} sx={{ color: iconColor }} />
                            </Box>
                            <InputLabel id="demo-simple-select-label" sx={{ fontSize: '16px' }} shrink>Priority</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={priorityFilter}
                                defaultValue={priorityFilter}
                                label='Priority'
                                sx={{ ...selectColorControllerStyle, color: iconColor }}
                                onChange={handlePriorityFilterChange}
                            >
                                {reminderPriority?.map((ele, index) => (
                                    <MenuItem key={index} value={ele?.value}>{ele?.label}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    {(data?.is_system_generated) && descriptionValue !== '' &&
                        <Box sx={{ display: 'none' }}>
                            <Box sx={{ display: 'none', alignItems: 'center', mb: '24px' }} >
                                <Box sx={{ width: '40%', fontSize: '13px', color: 'common.grey' }} >
                                    Title
                                </Box>
                                <Box sx={{ textAlign: 'left', fontSize: '14px' }} >
                                    <TextField
                                        value={titleValue || ''}
                                        onChange={(event) => setTitle(event.target.value)}
                                        onBlur={(event) => titleBlurEvent(event.target.value)}
                                        sx={{ '.MuiInputBase-input': { p: '15px 10px !important' } }}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: '24px' }} >
                                <Box sx={{ width: '40%', fontSize: '13px', color: 'common.grey' }} >
                                    Description
                                </Box>
                                <Box sx={{ textAlign: 'left', fontSize: '14px' }} >
                                    <TextField
                                        multiline
                                        maxRows={3}
                                        value={descriptionValue || ''}
                                        disabled
                                        sx={descriptionValueStyle}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    }
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: '24px' }} >
                        <TextField sx={{ width: '100%', bgcolor: '#919EAB14', borderRadius: 1 }} multiline maxRows={4} label='Reminder Notes' placeholder='Add notes here' value={detailValue || ''} onChange={(event) => setDetail(event.target.value)} onBlur={(event) => onBlurEvent(event.target.value)} />
                    </Box>
                    <Box sx={{ mb: '24px' }} >
                        <Typography sx={{ fontSize: '13px', color: 'common.grey', mb: 1 }}>Assignees</Typography>
                        <Box sx={{ cursor: 'pointer' }}>
                            {assigneeData?.length > 0 ?
                                <Box sx={taskUserAvatar} onClick={(e) => e.stopPropagation()}>
                                    <Tooltip title={getAssignees(data)} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                                        <Box sx={{ display: 'flex', gap: 1 }}>
                                            {assigneeData?.slice(0, 2)?.map((assignee, index) => (
                                                <AssignUserAvatar
                                                    key={index}
                                                    sx={{ ...assignUserAvatarStyle, border: 'none', '&:nth-child(2)': { ml: 0 } }}
                                                    src={assignee.user?.avatar || ''}
                                                    name={`${assignee.user?.first_name || ''} ${assignee.user?.last_name || ''}`}
                                                    role={assignee.role}
                                                    onClick={(e) => assignTaskHandler(e)}
                                                />
                                            ))}
                                            {assigneeData?.length > 2 && (
                                                <Box sx={{ ...assignLengthStyle, border: 'none', ml: 0 }} onClick={(e) => assignTaskHandler(e)}>
                                                    + {assigneeData?.length - 2}
                                                </Box>
                                            )}
                                        </Box>
                                    </Tooltip>
                                </Box> :
                                <Box sx={openTask} onClick={(e) => e.stopPropagation()}>
                                    <Box sx={{ ...assignTaskHandlerStyle, bgcolor: grey[200] }} onClick={(e) => assignTaskHandler(e)}>
                                        <Iconify sx={{ color: 'common.grey' }} width={20} height={20} icon={'eva:plus-fill'} />
                                    </Box>
                                </Box>
                            }
                        </Box>
                    </Box>
                    {data?.created_by && !(data.is_system_generated) && <Box>
                        <Typography sx={{ fontSize: '13px', color: 'common.grey', mb: 1 }}>Reporter</Typography>
                        <Box sx={{ cursor: 'pointer' }} >
                            <Tooltip title={`${capitalizeFirstLetter(data?.created_by?.first_name || '')} ${capitalizeFirstLetter(data?.created_by?.last_name || '')}`} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                                <CustomAvatar sx={{ height: '36px', width: '36px' }} src={data?.created_by?.avatar} name={data?.created_by?.first_name || ''} />
                            </Tooltip>
                        </Box>
                    </Box>}
                </Box>
                <Box sx={{ p: '16px', bgcolor: grey[200] }}>
                    <Box sx={commentBoxStyle}>
                        <Avatar sx={{ avatarStyle }} alt="J" src={user?.avatar || ''} />
                        <Box sx={commentBoxInnerStyle}>
                            <TextField
                                maxRows={1}
                                placeholder='Leave a comment'
                                value={comment || ''}
                                onChange={(event) => setComment(event.target.value)}
                                sx={{ width: '100%', '.MuiOutlinedInput-notchedOutline': { display: 'none' }, '.MuiInputBase-input': { p: '10px 0' } }}
                            />
                            <Box sx={{ display: 'flex' }}>
                                <Button variant='none' sx={{ color: theme.palette.common.blue, borderColor: theme.palette.common.blue, p: 0, minWidth: 'auto' }} onClick={() => onBlurCommentEvent()} disabled={comment === ''}><Iconify style={{ color: 'common.grey' }} width={24} height={24} icon={'lets-icons:send-hor'} /></Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ p: 0, m: '12px 0 24px', bgcolor: 'common.white' }}>
                    <Box sx={{ p: '10px 20px', bgcolor: grey[200], justifyContent: 'space-between' }} display={'flex'}>
                        <Typography sx={{ color: 'common.grey', fontWeight: 700, fontSize: '0.875rem' }}>Comments ({`${leadNotes?.length || 0}`})</Typography>
                        <Iconify icon={commentHide ? 'entypo:chevron-small-up' : 'entypo:chevron-small-down'} width={24} height={24} sx={{ color: 'common.grey', cursor: 'pointer' }} onClick={() => setCommentHide(!commentHide)} />
                    </Box>
                    {!commentHide &&
                        <>
                            {leadNotes && leadNotes?.length > 0 && leadNotes?.map((options, index) => (
                                <Box sx={{ p: '24px 20px', bgcolor: grey[200], mb: '6px' }} key={index}>
                                    <Box sx={{ gap: 2 }} display={'flex'}>
                                        <Avatar
                                            sx={{ height: '32px', width: '32px' }}
                                            src={options?.created_by && options?.created_by?.avatar || ''}
                                        />
                                        <Box display={'flex'} sx={{ gap: '2px', flexDirection: 'column' }}>
                                            <Box sx={{ gap: 1 }} display={'flex'}>
                                                <Typography sx={{ color: 'common.slateGray', fontSize: '0.875rem', fontWeight: '600' }}>{`${options?.created_by?.first_name} ${options?.created_by?.last_name || ''}`}</Typography>
                                                <Typography sx={{ color: grey[500], fontSize: '12px', fontWeight: '600' }}>{moment(options?.created_at, 'DD/MM/YYYY HH:mm:ss').format('DD MMM, YYYY')}</Typography>
                                            </Box>
                                            <Typography sx={{ color: 'common.slateGray', fontSize: '0.875rem', lineHeight: '16.45px' }}>{options?.additional_notes || options?.note || options?.lead_contact?.note || ''}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                        </>}
                </Box>
                <Box sx={mainFlowBoxStyle}>
                    {data?.lead_treatment?.status === 'CONSULTATION_BOOKED' && !data?.lead?.is_archived ?
                        <Box onClick={(e) => e.stopPropagation()} sx={{ p: 3, display: 'flex', gap: 1 }}>
                            {getValues('actionType') === 'CALL' ?
                                <Button variant="contained" onClick={() => { setTemplateOpen(false); setOptions('phoneNumber'); onOpenNotePopup(); }} sx={callButtonStyle(theme)}>Call Lead</Button>
                                : getValues('actionType') === 'EMAIL' ? <Button variant="contained" onClick={() => { setTemplateOpen(true); }} sx={callButtonStyle(theme)}>Email Lead</Button> : getValues('actionType') === 'WHATSAPP' ? <Button variant="contained" onClick={() => { setTemplateOpen(false); setOptions('whatsapp'); onOpenNotePopup(); }} sx={callButtonStyle(theme)}>WhatsApp Message</Button> : null
                            }
                            <ButtonGroup
                                variant="contained"
                                aria-label="split button"
                                sx={{
                                    boxShadow: '0px 8px 16px rgba(51, 102, 255, 0.24)',
                                    width: '100% !important',
                                    height: '48px'
                                }}
                                disabled={data?.lead_treatment?.status === 'CONSULTATION_BOOKED' && leadAppointments?.[0]?.status === 'CANCELLED'}
                            >
                                <Button sx={recordBtn(data)} onClick={handleLeadData}>
                                    Record Attendance
                                </Button>
                                <Button
                                    aria-controls={open ? 'split-button-menu' : undefined}
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-label="select merge strategy"
                                    aria-haspopup="menu"
                                    sx={recordBtnArrow(data)}
                                    onClick={handleLeadData}
                                >
                                    <Iconify
                                        style={{ color: 'common.white' }}
                                        width={10}
                                        height={10}
                                        icon={'bxs:right-arrow'}
                                    />
                                </Button>
                            </ButtonGroup>
                        </Box>
                        :
                        <Box sx={{ ...btnOuterBox, display: 'flex', gap: 2 }}>
                            {getValues('actionType') === 'CALL' ?
                                <Button variant="contained" onClick={() => { setTemplateOpen(false); setOptions('phoneNumber'); onOpenNotePopup(); }} sx={{ backgroundColor: `${theme.palette.common.blue} !important` }}>Call Lead</Button>
                                : getValues('actionType') === 'EMAIL' ? <Button variant="contained" onClick={() => { setTemplateOpen(true); }} sx={{ backgroundColor: `${theme.palette.common.blue} !important` }}>Email Lead</Button> : getValues('actionType') === 'WHATSAPP' ? <Button variant="contained" onClick={() => { setTemplateOpen(false); setOptions('whatsapp'); onOpenNotePopup(); }} sx={{ backgroundColor: `${theme.palette.common.blue} !important` }}>WhatsApp Message</Button> : null
                            }
                            {type === 'Task-overView' ? overViewbtns(data?.lead_treatment?.status, data?.lead_treatment, data?.lead?.is_archived)
                                : getReminderBtn(data?.lead_treatment?.status, data?.lead_treatment?.lead, data.id, data?.lead_treatment, data?.lead?.is_archived)}
                        </Box>}
                </Box>
                <Popper
                    sx={{
                        zIndex: 99999,
                        margin: '0px 20px !important',
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    role={undefined}
                    transition
                    placement={isDesktop ? 'top-start' : 'right-start'}
                >
                    {({ TransitionProps }) => (
                        <Grow
                            {...TransitionProps}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={buttonHandleClose}>
                                    <MenuList id="split-button-menu" autoFocusItem sx={plusButton}>
                                        <MenuItem
                                            key="Attended"
                                            onClick={() => handleGroupBtns('Attended')}
                                            sx={plusMenu}
                                        >
                                            <Iconify style={{ color: '#212B36', marginRight: 15 }} width={20} height={20} icon={'akar-icons:check'} /> Attended
                                        </MenuItem>
                                        <MenuItem
                                            key="Failed to attend"
                                            onClick={handleGroupBtns}
                                            sx={plusMenu}
                                        >
                                            <Iconify
                                                style={{ color: '#212B36', marginRight: 15 }}
                                                width={20} height={20} icon={'ci:close-big'} /> Failed to attend
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Box>
            {openAssignTask && (
                <ClickAwayListener onClickAway={onClickAwayEvent}>
                    <Popper open={openAssignTask} anchorEl={anchorElAssign} transition placement="left" sx={{ zIndex: '999999' }}>
                        {({ TransitionProps }) => (
                            <Grow {...TransitionProps}>
                                <Paper sx={{ borderRadius: 2 }}>
                                    <AssignTaskPopup
                                        open={openAssignTask}
                                        onClose={() => allPopupCloseHandler('Assigned')}
                                        leadDetail={data}
                                        reminder={data}
                                        assignees={assigneeData?.map((ele) => ele.id)}
                                    />
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </ClickAwayListener>
            )}
            {openNotePopup && (
                <AddNotePopup
                    open={openNotePopup}
                    onClose={handleCloseNotePopup}
                    Users={data}
                    type={'TaskSidebar'}
                    options={options}
                    setTemplateOpen={setTemplateOpen}
                    templateOpen={templateOpen}
                />
            )}
            {templateOpen && (
                <LeadTemplate
                    openEmailCompose={Boolean(templateOpen)}
                    onCloseEmailCompose={() => {
                        setTemplateOpen(false);
                    }}
                    practiceId={practiceId}
                    type={'EMAIL'}
                    leadDetail={data}
                />
            )}
        </FormProvider>
    );
};

TaskSidebar.propTypes = {
    data: PropTypes.any,
    type: PropTypes.string,
    reminderDataId: PropTypes.any,
    overViewbtns: PropTypes.func,
    getReminderBtn: PropTypes.func,
    handleAttendClick: PropTypes.func,
    handleAttendFailedClick: PropTypes.func,
    setReloadCounters: PropTypes.func,
    onClose: PropTypes.func
};

export default TaskSidebar;
