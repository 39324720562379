import PropTypes from 'prop-types';
import React, { useState } from 'react';
import moment from 'moment';
// use @mui
import {
    TableRow,
    TableCell,
    Box,
    Typography,
    Switch,
    FormControlLabel,
    MenuItem,
    MenuList,
    ListItemText,
    Divider,
    Button,
    Chip,
    useTheme
} from '@mui/material';
// components
import { useSelector } from 'react-redux';
import { isArray, uniqBy } from 'lodash';
import { useGetCategories } from 'src/hooks';
import AutocompleteCampaignTags from 'src/components/AutoComplete/AutocompleteCampaignTags';
import { getCsvLink, getPractitionerInArrayAudience } from '../../../utils/audiencemanagerUtils';
import { Iconify } from '../../../components';
import { TableMoreMenu } from '../../../components/table';
import { ConfirmDialog } from '../../../components/dialogPopUp';
// style
import {
    dividerStyle,
    messageTableCategory,
    messageTableCellStyle,
    messageTableMenuItem,
} from '../../../styles/MessageListStyle';
import { chipLabelStyle, statusTypoStyle } from '../../../styles/CampaignPageStyle';

const CampaignTableRow = ({ row, selected, onSelectRow, onDeleteRow, onChangeStatus, practiceMessageTable, isAdmin, messageType, handleCancelEvent, handleScheduleEvent, handleDuplicateEvent, handleReportEvent, handleCampaignPreview, handlePauseCampaign, handleResumeCampaign, handleAddTag, handleDeleteTag }) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const [openMenu, setOpenMenuActions] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);
    const { referral } = useSelector((state) => state.schema);
    const { practiceUser } = useSelector((state) => state.practiceUser);
    const getUserList = () => practiceUser.results.filter(el => el.user && el.user.first_name && !el.user.is_superuser);
    const tagInArray = row?.recepient?.filters?.tagged_items__tag__name__in || [];
    const tagInArrayAudience =
        row?.audience?.audience?.audience_internal_queryset?.filters?.tagged_items__tag__name__in || [];
    const statusInArray = row?.recepient?.filters?.lead_treatments__status__in || [];
    const statusInArrayAudience = row?.audience?.audience?.audience_internal_queryset?.filters?.lead_treatments__status__in || [];
    const treatmentInArray = row?.recepient?.filters?.lead_treatments__treatment__treatment_item__name__in || [];
    const treatmentInArrayAudience = row?.audience?.audience?.audience_internal_queryset?.filters?.lead_treatments__treatment__treatment_item__name__in || [];
    const archiveData = row?.recepient?.filters?.is_only_archived || '';
    const archiveDataAudience = row?.audience?.audience?.audience_internal_queryset?.filters?.is_only_archived || '';
    const communicationData = row?.recepient?.filters || '';
    const practitionerInArray = getUserList()?.filter((el) => {
        const assigneesIds = row?.recepient?.filters?.assignees__user__id__in;
        return Array.isArray(assigneesIds) && assigneesIds.includes(el?.user?.id);
    }).map((el) => `${el?.user?.salutation || ''} ${el?.user?.first_name} ${el?.user?.last_name}`);
    const referralInArray = [referral?.filter((el) => row?.recepient?.filters?.lead_source__referral__in?.includes(el.value))?.[0]?.label || ''];
    const referralInArrayAudience = [referral?.filter((el) => row?.audience?.audience?.audience_internal_queryset?.filters?.lead_source__referral__in?.includes(el.value))?.[0]?.label || ''];
    const dateRangeInArray = row?.recepient?.filters?.created_at__range || [];
    const dateRangeInArrayAudience = row?.audience?.audience?.audience_internal_queryset?.filters?.created_at__range || [];
    const formattedDateRange = dateRangeInArray.map(date => moment(date).format('DD-MM-YYYY'));
    const formattedDateRangeAudience = dateRangeInArrayAudience?.map(date => moment(date).format('DD-MM-YYYY'));
    const combinedLabel = formattedDateRange.join(' to ');
    const combinedLabelAudience = formattedDateRangeAudience?.join(' to ');
    const { dateFormat } = useSelector((state) => state.lead);
    const audienceData = row?.audience?.audience?.name || '';
    const audienceType = row?.audience?.audience?.type || '';
    const sourceTypeInArray = row?.recepient?.filters?.lead_source__type__in || [];
    const sourceTypeInArrayAudience = row?.audience?.audience?.audience_internal_queryset?.filters?.lead_source__type__in || [];
    const categoryInArray = row?.recepient?.filters?.lead_treatments__treatment__treatment_item__treatment_category__in || [];
    const categoryInArrayAudience = row?.audience?.audience?.audience_internal_queryset?.filters?.lead_treatments__treatment__treatment_item__treatment_category__in || [];
    const categories = useGetCategories();

    const getCategory = (categoryArray) => categories?.filter((el) => categoryArray?.includes(el?.id))?.map((el) => el.name);

    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    };

    const handleCloseConfirm = () => {
        setOpenConfirm(false);
        onDeleteRow();
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const handleChangeStatus = () => {
        onChangeStatus({ is_enabled: !row.is_enabled }, row.id);
    };

    const showDate = (data) => (data === 'date_format' ? moment(row?.created_at, 'DD-MM-YYYY HH:mm:ss').format('DD/MM/YY hh:mm A') : row.created_at_human);

    const handleDuplicateTemplate = () => {
        handleDuplicateEvent(row);
        handleCloseMenu();
    };

    const handleEditItemEvent = () => {
        onSelectRow(row);
        handleCloseMenu();
    };

    const handleScheduleItemEvent = () => {
        handleScheduleEvent(row.id);
        handleCloseMenu();
    };

    const handlePreviewItemEvent = () => {
        handleCampaignPreview(row);
        setOpenMenuActions(null);
    };

    const handleCancelItemEvent = () => {
        handleCancelEvent(row.id);
        handleCloseMenu();
    };

    const handleReportItemEvent = () => {
        handleReportEvent(row);
        handleCloseMenu();
    };

    const handleDeleteItemEvent = () => {
        handleOpenConfirm();
        handleCloseMenu();
    };

    const handlePauseCampaignRow = () => {
        handlePauseCampaign(row);
        handleCloseMenu();
    }
    const handleResumeCampaignRow = () => {
        handleResumeCampaign(row);
        handleCloseMenu();
    }

    return (
        <TableRow
            hover selected={selected}
            sx={{ borderTop: practiceMessageTable ? '2px solid rgba(145, 158, 171, 0.12)' : '' }}
        >
            <TableCell>
                <Box sx={{ whiteSpace: 'nowrap' }}>{showDate(dateFormat)}</Box>
            </TableCell>
            <TableCell sx={messageTableCellStyle}>
                <Box>
                    <Typography
                        variant='subtitle2'
                        noWrap
                        sx={{ width: '100%', textOverflow: 'ellipsis' }}
                    >
                        {row && row.name}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell sx={{ width: '220px' }}>
                <Box>
                    <Typography
                        variant='body'
                        noWrap
                    >
                        {row && row?.engagement_template?.subject}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell sx={messageTableCellStyle}>
                <Box>
                    <Typography
                        variant='body'
                        noWrap
                        sx={statusTypoStyle(row?.status)}
                    >
                        {row?.status && row?.status?.toLowerCase?.()}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell sx={messageTableCellStyle}>
                <Box sx={{ whiteSpace: 'nowrap' }}>
                    {moment(row?.scheduled_at, 'DD-MM-YYYY HH:mm:ss').format('ddd, D MMM hh:mm A')}
                </Box>
            </TableCell>
            <TableCell sx={messageTableCellStyle}>
                <Box sx={{ display: 'flex', overflow: 'auto' }}>
                    {isArray(tagInArray) && tagInArray?.map((tag, index) => (
                        <Chip
                            key={index}
                            label={tag}
                            sx={chipLabelStyle(isLight)}
                        />
                    ))}
                    {isArray(statusInArray) && statusInArray?.map((status, index) => (
                        <Chip
                            key={index}
                            label={status}
                            sx={chipLabelStyle(isLight)}
                        />
                    ))}
                    {treatmentInArray && treatmentInArray?.map((treatment, index) => (
                        <Chip
                            key={index}
                            label={treatment}
                            sx={chipLabelStyle(isLight)}
                        />
                    ))}
                    {practitionerInArray && practitionerInArray?.map((status, index) => (
                        <Chip
                            key={index}
                            label={status}
                            sx={chipLabelStyle(isLight)}
                        />
                    ))}
                    {referralInArray && referralInArray[0] !== '' && referralInArray?.map((status, index) => (
                        <Chip
                            key={index}
                            label={status}
                            sx={chipLabelStyle(isLight)}
                        />
                    ))}
                    {combinedLabel &&
                        <Chip
                            label={combinedLabel}
                            sx={chipLabelStyle(isLight)}
                        />
                    }
                    {archiveData &&
                        <Chip
                            label={'Archive'}
                            sx={chipLabelStyle(isLight)}
                        />
                    }
                    {communicationData && (communicationData?.is_open_for_communication || communicationData?.is_open_for_communication === false) &&
                        <Chip
                            label={`Communication: ${(communicationData?.is_open_for_communication === 'false' || communicationData?.is_open_for_communication === false) ? 'No' : 'Yes'}`}
                            sx={chipLabelStyle(isLight)}
                        />
                    }
                    {categoryInArray && isArray(getCategory(categoryInArray)) && getCategory(categoryInArray).length > 0 &&
                        uniqBy(getCategory(categoryInArray))?.map((status, index) => <Chip key={index} label={status} sx={chipLabelStyle} />)}
                    {sourceTypeInArray &&
                        sourceTypeInArray?.map((status, index) => <Chip key={index} label={status} sx={chipLabelStyle} />)}
                    {audienceData && audienceType === 'INTERNAL_QUERYSET' ? (
                        <>
                            {tagInArrayAudience && tagInArrayAudience?.map((tag, index) => <Chip key={index} label={tag} sx={chipLabelStyle} />)}
                            {statusInArrayAudience &&
                                statusInArrayAudience?.map((status, index) => <Chip key={index} label={status} sx={chipLabelStyle} />)}
                            {treatmentInArrayAudience &&
                                treatmentInArrayAudience?.map((treatment, index) => <Chip key={index} label={treatment} sx={chipLabelStyle} />)}
                            {getPractitionerInArrayAudience(getUserList(), row) &&
                                getPractitionerInArrayAudience(getUserList(), row)?.map((status, index) => <Chip key={index} label={status} sx={chipLabelStyle} />)}
                            {referralInArrayAudience &&
                                referralInArrayAudience?.[0] !== '' &&
                                referralInArrayAudience?.map((status, index) => <Chip key={index} label={status} sx={chipLabelStyle} />)}
                            {combinedLabelAudience && <Chip label={combinedLabelAudience} sx={chipLabelStyle} />}
                            {archiveDataAudience && <Chip label={'Archive'} sx={chipLabelStyle} />}
                            {categoryInArrayAudience && isArray(getCategory(categoryInArrayAudience)) && getCategory(categoryInArrayAudience).length > 0 &&
                                uniqBy(getCategory(categoryInArrayAudience))?.map((status, index) => <Chip key={index} label={status} sx={chipLabelStyle} />)}
                            {sourceTypeInArrayAudience &&
                                sourceTypeInArrayAudience?.map((status, index) => <Chip key={index} label={status} sx={chipLabelStyle} />)}
                        </>
                    ) : (
                        getCsvLink(row) && <Chip label={'csv file'} component='a' href={getCsvLink(row)} clickable sx={chipLabelStyle} />
                    )
                    }
                </Box>
            </TableCell>
            <TableCell sx={{ ...messageTableCellStyle, '&:hover': { border: '1px solid #DCDCDC !important' } }}>
                <Box sx={{ whiteSpace: 'nowrap' }}>
                    <AutocompleteCampaignTags row={row} tagType='campaigns' handleAddTag={handleAddTag} handleDeleteTag={handleDeleteTag} />
                </Box>
            </TableCell>
            {(messageType === 'Practice_Message' || messageType === 'OnBoarding') ?
                <TableCell align='left'>
                    <FormControlLabel
                        control={
                            <Switch
                                onChange={(event) => handleChangeStatus(event)}
                                checked={row.is_enabled}
                                disabled={!isAdmin}
                                color='primary'
                            />
                        }
                        label={`${row.is_enabled ? 'Published' : 'Unpublished'}`}
                    />
                </TableCell> :
                <TableCell>
                    <Box>
                        <Typography
                            variant='body'
                            noWrap
                            sx={messageTableCategory}
                        >
                            {row?.engagement_template?.category.toLowerCase()}
                        </Typography>
                    </Box>
                </TableCell>
            }
            <TableCell align='right' >
                <TableMoreMenu
                    open={openMenu}
                    sx={{ width: '200px' }}
                    onOpen={(event) => setOpenMenuActions(event.currentTarget)}
                    onClose={handleCloseMenu}
                    actions={
                        <MenuList>
                            {row.status === 'SCHEDULED' && <MenuItem onClick={handlePauseCampaignRow}>
                                <Iconify icon={'mdi:pause-circle'} />
                                <ListItemText
                                    primary='Pause'
                                    primaryTypographyProps={messageTableMenuItem}
                                />
                            </MenuItem>}
                            {row.status === 'PAUSED' && <MenuItem onClick={handleResumeCampaignRow}>
                                <Iconify icon={'mdi:play-circle'} />
                                <ListItemText
                                    primary='Resume'
                                    primaryTypographyProps={messageTableMenuItem}
                                />
                            </MenuItem>}
                            {(row.status !== 'CANCELLED' && row.status !== 'SENT' && row.status !== 'SCHEDULED' && row.status !== 'SENDING' && row.status !== 'PAUSED') && <>
                                <MenuItem onClick={handleEditItemEvent}>
                                    <Iconify icon={'solar:pen-bold'} />
                                    <ListItemText
                                        primary='Edit'
                                        primaryTypographyProps={messageTableMenuItem}
                                    />
                                </MenuItem>
                                <Divider sx={dividerStyle} />
                            </>}
                            <MenuItem onClick={handleDuplicateTemplate}>
                                <Iconify icon={'bxs:copy'} />
                                <ListItemText
                                    primary='Duplicate'
                                    primaryTypographyProps={messageTableMenuItem}
                                />
                            </MenuItem>
                            <Divider sx={dividerStyle} />
                            {(row.status !== 'CANCELLED' && row.status !== 'SENT' && row.status !== 'SCHEDULED' && row.status !== 'PAUSED') && <>
                                <MenuItem onClick={handleScheduleItemEvent}>
                                    <Iconify icon={'material-symbols:schedule'} />
                                    <ListItemText
                                        primary='Schedule'
                                        primaryTypographyProps={messageTableMenuItem}
                                    />
                                </MenuItem>
                                <Divider sx={dividerStyle} />
                            </>}
                            <MenuItem onClick={handlePreviewItemEvent}>
                                <Iconify icon={'icon-park-outline:preview-close'} />
                                <ListItemText
                                    primary='Preview'
                                    primaryTypographyProps={messageTableMenuItem}
                                />
                            </MenuItem>
                            <Divider sx={dividerStyle} />
                            {(row.status !== 'DRAFT' && row.status !== 'CANCELLED' && row.status !== 'SENT' && row.status !== 'PAUSED') && <>
                                <MenuItem onClick={handleCancelItemEvent}>
                                    <Iconify icon={'mdi:cancel'} />
                                    <ListItemText
                                        primary='Cancel'
                                        primaryTypographyProps={messageTableMenuItem}
                                    />
                                </MenuItem>
                                <Divider sx={dividerStyle} />
                            </>}
                            {row.status !== 'DRAFT' && <>
                                <MenuItem onClick={handleReportItemEvent}>
                                    <Iconify icon={'carbon:analytics'} />
                                    <ListItemText
                                        primary='Report'
                                        primaryTypographyProps={messageTableMenuItem}
                                    />
                                </MenuItem>
                                <Divider sx={dividerStyle} />
                            </>}
                            <MenuItem
                                sx={{ color: 'rgba(255, 72, 66, 1)' }}
                                onClick={handleDeleteItemEvent}
                            >
                                <Iconify icon={'solar:trash-bin-trash-bold'} />
                                <ListItemText
                                    primary='Delete'
                                    primaryTypographyProps={messageTableMenuItem}
                                />
                            </MenuItem>
                        </MenuList>
                    }
                />
            </TableCell>
            <ConfirmDialog
                open={openConfirm}
                onClose={() => setOpenConfirm(false)}
                title='Delete'
                content='Are you sure you want to delete this campaign?'
                action={
                    <Button variant='contained' color='error' onClick={handleCloseConfirm}>
                        Delete
                    </Button>
                }
            />
        </TableRow>
    );
};

CampaignTableRow.propTypes = {
    row: PropTypes.object,
    selected: PropTypes.bool,
    onSelectRow: PropTypes.func,
    onChangeStatus: PropTypes.func,
    practiceMessageTable: PropTypes.bool,
    isAdmin: PropTypes.bool,
    onDeleteRow: PropTypes.func,
    messageType: PropTypes.string,
    handleCancelEvent: PropTypes.func,
    handleScheduleEvent: PropTypes.func,
    handleDuplicateEvent: PropTypes.func,
    handleReportEvent: PropTypes.func,
    handleCampaignPreview: PropTypes.func,
    handlePauseCampaign: PropTypes.func,
    handleResumeCampaign: PropTypes.func,
    handleAddTag: PropTypes.func,
    handleDeleteTag: PropTypes.func
};

export default CampaignTableRow;