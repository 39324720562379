/* eslint-disable no-unneeded-ternary */
import PropTypes from 'prop-types';
import CurrencyInput from 'react-currency-input-field';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState
} from 'react';
import { useTheme } from '@mui/material/styles';
// @mui
import {
    Box,
    Grid,
    TextField,
    Typography
} from '@mui/material';
//
import { setCurrentTreatmentDataGlobal } from 'src/redux/slices/multiTreatment';
import { Scrollbar } from '.';
import { FormProvider } from './hook-form';
import { TreatmentDropdown } from './Dropdowns';
// redux
import { getActivities } from '../redux/slices/activity';
import {
    addTreatment,
    UpdateTreatment,
    followUpSlice,
    getLeadReminderData,
    clearLead
} from '../redux/slices/lead';
import { dispatch, useSelector } from '../redux/store';
// style
import { addTreatmentGridStyle, treatmentInputStyle } from '../styles/AddTreatment';
import {
    ErrorTextStyle,
    AmountInputStyle
} from '../styles/PaymentRefund';
// validation
import { AddTreatmentSchema } from '../validations/validations';

// ----------------------------------------------------------------------------

const AddTreatment = forwardRef(({ onClose, leadDetail, leadId, type, selectTreatment, selectTreatmentPrice, selectTreatmentId, updateLeadPage }, ref) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { tableRowLead } = useSelector((state) => state.lead);
    const [amountError, setAmountError] = useState(false);
    const [decimalSeparator] = useState('.');
    const [thousandSeparator] = useState(',');
    const [inputType] = useState('text');

    const [treatmentData, setTreatment] = useState('');

    const defaultValues = {
        treatment: selectTreatment || leadDetail && leadDetail?.lead_treatments.length > 0 && leadDetail.lead_treatments[0]?.treatment || '',
        treatmentValue: selectTreatmentPrice || leadDetail && leadDetail?.lead_treatments.length > 0 && leadDetail?.lead_treatments[0]?.price || '',
    };

    useEffect(() => {
        if (defaultValues.treatment) {
            setTreatment(defaultValues.treatment);
        }
    }, [defaultValues.treatment]);

    const [amount, setAmount] = useState(selectTreatmentPrice || leadDetail && leadDetail?.lead_treatments.length > 0 && leadDetail?.lead_treatments[0]?.price || 0.00);

    const methods = useForm({
        resolver: yupResolver(AddTreatmentSchema),
        mode: 'onSubmit',
        defaultValues,
    });

    const {
        handleSubmit,
        setValue,
        control,
        getValues,
        formState: { errors },
    } = methods;

    useImperativeHandle(
        ref,
        () => ({
            async submit() {
                await handleSubmit(onSubmit)();
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [amount]
    );

    const onSubmit = async (data) => {
        document.body.classList.add('points-event');
        if ((amount === 0 || amount === '0.00' || amount === undefined)) {
            setAmountError(true);
            return;
        }
        if (leadDetail && leadDetail.lead_treatments.length > 0) {
            const leadData = leadDetail && leadDetail.lead_treatments[0];
            if (data.treatment !== leadData.treatment || amount !== leadData.price) {
                const updateTreatment = {
                    treatment: data.treatment,
                    custom_price: amount,
                    lead: leadDetail.id,
                    id: selectTreatmentId || leadDetail.lead_treatments[0].id
                };
                dispatch(UpdateTreatment(updateTreatment, practiceId, handleClose, 'editTreatment'));
                return;
            }
            dispatch(clearLead());
            dispatch(setCurrentTreatmentDataGlobal(null));
            onClose(true);
            return;
        }
        const treatmentDetail = {
            treatment: data.treatment,
            custom_price: amount,
            is_practice_notification_required: false
        };
        const id = leadDetail && leadDetail.id ? leadDetail.id : tableRowLead.lead_id;
        dispatch(addTreatment(treatmentDetail, practiceId, type === 'leadDetailAddTreatment' ? leadId : id, handleClose));
    };

    const handleClose = (status) => {
        document.body.classList.remove('points-event');
        if (status?.toLowerCase?.() === 'finish') {
            onClose(true);
            dispatch(clearLead());
            dispatch(setCurrentTreatmentDataGlobal(null));
            if (type === 'tableData') {
                updateLeadPage();
                return;
            }
            if (leadDetail && leadDetail.id) {
                dispatch(getLeadReminderData(practiceId, 'LeadDetail', 10, 0, '', leadDetail.id, ''));
                dispatch(getLeadReminderData(practiceId, 'LeadDetail', 1, 0, '', leadDetail?.id, '', '', 'Graterthan'));
                dispatch(followUpSlice(leadDetail.id, practiceId, 'LeadDetail'));
                dispatch(getActivities(practiceId, leadDetail.id, 10, 0));
            }
        }
    };

    const handleTreatment = (value) => {
        if (value) {
            setAmount(value.price.toString());
            setAmountError(false);
            setTreatment(value.name);
            setValue('treatment', value.name);
        }
        else {
            setAmount(0.00);
            setValue('treatment', '');
            setTreatment('');
        }
    };

    const handleChange = (value) => {
        setAmount(value);
        if (value > 0) {
            setAmountError(false);
            document.body.classList.remove('points-event');
            return;
        }
        setAmountError(true);
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ margin: '0 -8px' }}>
                <Scrollbar sx={{ maxHeight: 64 * 6 }}>
                    <Grid container spacing={1} sx={addTreatmentGridStyle}>
                        <TreatmentDropdown
                            name={'treatment'}
                            value={treatmentData}
                            control={control}
                            onChange={(e, newValue) => handleTreatment(newValue)}
                            sx={treatmentInputStyle}
                            renderInput={(params) => <TextField {...params} label="Treatment (Required)" error={getValues('treatment').length <= 0 && errors?.treatment?.message} helperText={getValues('treatment').length <= 0 && errors?.treatment?.message && 'This field is required'} />}
                        />
                        <Box sx={{ width: '40%' }}>
                            <CurrencyInput
                                name="treatmentValue"
                                value={amount}
                                decimalSeparator={decimalSeparator}
                                thousandSeparator={thousandSeparator}
                                onValueChange={(value, name) => handleChange(value, name)}
                                className="currency-input"
                                decimalsLimit={2}
                                decimalScale={2}
                                inputType={inputType}
                                prefix={'£ '}
                                style={AmountInputStyle(amountError, 'appointment', isLight)}
                                step={1}
                            />
                            {amountError && <Typography variant='p' sx={ErrorTextStyle}>This field is required</Typography>}
                        </Box>
                    </Grid>
                </Scrollbar>
            </Box>
        </FormProvider>
    );
});

AddTreatment.propTypes = {
    onClose: PropTypes.func,
    leadDetail: PropTypes.any,
    leadId: PropTypes.any,
    type: PropTypes.any,
    selectTreatment: PropTypes.any,
    selectTreatmentPrice: PropTypes.any,
    selectTreatmentId: PropTypes.any,
    updateLeadPage: PropTypes.func,
};

export default AddTreatment;
