/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useImperativeHandle, forwardRef, useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import {
    Box,
    MenuItem,
    Grid,
    Button,
    ButtonGroup,
    Tooltip,
    Typography,
    Zoom,
    ClickAwayListener,
    Popper,
    Grow,
    Paper
} from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { grey } from 'src/theme/palette';
import { updateError } from '../../redux/slices/user';
import { useResponsive, useAssignTaskToggle } from '../../hooks';
import Iconify from '../Iconify';
import { AssignUserAvatar } from '../custom-avatar';
import { getAssignees } from '../../utils/multiAsignees';
import { btnHandler } from '../../utils/reminderSelectedTimeFunc';
import { AssignTaskPopup } from '../dialogPopUp';
import { FormProvider, RHFSelect, RHFTextField } from '../hook-form';
import { useSelector, dispatch } from '../../redux/store';
import { saveNewLeadDetail, updateCompleteStatus, addNotes } from '../../redux/slices/lead';
import { clearPracticeUserData } from '../../redux/slices/practiceUser';
import { FollowUpSchema } from '../../validations/validations';
import {
    FollowUpTitle,
    followUpTabStyle,
    followUpButtonGroupStyle,
    followUpHourButtonStyle,
    followUpTomorrowButtonStyle,
    followUpWeekButtonStyle,
    followUpCustomButtonStyle,
    followUpNoReminderButtonStyle,
    selectMenuItemStyle,
    followUpTwoWeekButtonStyle
} from '../../styles/DialogueTabs';
import {
    taskUserAvatar,
    openTask,
    openTaskPop,
    assignUserAvatarStyle,
    assignLengthStyle,
    assignPopperStyle,
} from '../../styles/leadTaskReminder';

const FollowUp = forwardRef(({ handleClose, type }, ref) => {
    const { createLead, followLead } = useSelector((state) => state.lead);
    const { reminderMethod } = useSelector((state) => state.schema);
    const [activeTime, setActiveTime] = useState('custom reminder');
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const [isOpen, setIsOpen] = useState(false);
    const [assignToState, setAssignToState] = useState([]);
    const [anchorElAssign, setAnchorElAssign] = useState(null);

    const { currentTreatmentDataGlobal } = useSelector((state) => state.multiTreatment);

    useImperativeHandle(
        ref,
        () => ({
            async submit(status) {
                await handleSubmit((data) => onSubmit(data, status))();
            },
        }),
        [activeTime, assignToState]
    );

    const defaultValues = {
        reminder: 'CALL',
        reminderDateTime: null,
        ReminderNotes: '',
        otherNotes: '',
        customReminder: '',
    };

    const methods = useForm({
        resolver: yupResolver(FollowUpSchema),
        defaultValues
    });

    const onSubmit = async (data, status) => {
        if (getValues('reminder') === 'no-reminder') {
            if (status?.toLowerCase?.() === 'finish' && type === 'ConsultationAttended') {
                const leadNoteData = {
                    lead: followLead && followLead.id || createLead && createLead.id || '',
                    note: 'FOLLOW_UP',
                    treatment: currentTreatmentDataGlobal?.treatmentData?.id || followLead && followLead?.lead_treatments[0]?.id || ''
                };
                dispatch(addNotes(leadNoteData, practiceId, handleClose, 'attended'));
                dispatch(updateCompleteStatus(true));
                return;
            }
            if (status?.toLowerCase?.() === 'finish') {
                dispatch(updateCompleteStatus(true));
                return;
            }
        }
        const leadId = followLead && followLead.id || createLead && createLead.id || '';
        if (activeTime !== '') {
            const addReminderObj = {
                note: data.ReminderNotes,
                is_completed: false,
                group: 'FOLLOW_UP',
                lead: leadId,
                assignees: assignToState?.map((el) => el?.id)
            };
            if (data.reminder) {
                addReminderObj.method = data.reminder;
            }
            if (data.reminderDateTime) {
                addReminderObj.datetime = `${moment(data.reminderDateTime).format('DD-MM-YYYY HH:mm:ss')}.0Z`;
            }
            dispatch(saveNewLeadDetail({ addReminder: addReminderObj }));
            if (status?.toLowerCase?.() === 'finish') {
                dispatch(updateCompleteStatus(true));
                return;
            }
            handleClose();
        } else {
            dispatch(updateError({
                message: 'Please select tab',
                status: false
            }));
        }
    };

    const {
        control,
        setValue,
        clearErrors,
        getValues,
        handleSubmit,
    } = methods;

    useEffect(() => {
        setValue('customReminder', activeTime);
    }, [activeTime]);

    const handleButtonClick = (value) => {
        btnHandler({ value, btnValues, reminderMethod });
        setActiveTime(value);
    };

    const isMobile = useResponsive('up', 'sm');

    const btnValues = (reminder, reminderDateTime) => {
        setValue('reminder', reminder);
        setValue('reminderDateTime', reminderDateTime);
        clearErrors('reminder');
        clearErrors('reminderDate');
        clearErrors('reminderDateTime');
    };

    const handleToggleOpen = () => {
        setIsOpen(!isOpen);
    };

    const {
        toggle: openAssignTask,
        onOpen: onOpenAssignTask,
        onClose: onCloseAssignTask
    } = useAssignTaskToggle();

    const assignTaskHandler = (event) => {
        setAnchorElAssign(anchorElAssign ? null : event.currentTarget);
        onOpenAssignTask();
    };

    const onClickAwayEvent = () => {
        const selectMenu = document.querySelector('.MuiModal-root');
        if (selectMenu) {
            return;
        }
        onCloseAssignTask();
        setAnchorElAssign(null);
        dispatch(clearPracticeUserData());
    };

    return (
        <Box sx={{ width: '100%', pt: '30px' }}>
            <FollowUpTitle>Add a follow up reminder</FollowUpTitle>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <Box sx={followUpTabStyle(isMobile)}>
                    <ButtonGroup
                        orientation={!isMobile ? 'vertical' : 'horizontal'}
                        sx={followUpButtonGroupStyle}
                    >
                        <Button
                            variant={activeTime === 'custom reminder' ? 'contained' : 'text'}
                            onClick={() => handleButtonClick('custom reminder')}
                            sx={followUpCustomButtonStyle(activeTime)}
                        >
                            <Iconify sx={{ marginRight: '10px' }} icon={'solar:clock-circle-bold'} width={20} height={20} /> Custom reminder
                        </Button>
                        <Button
                            variant={activeTime === '2 hours-call' ? 'contained' : 'text'}
                            onClick={() => handleButtonClick('2 hours-call')}
                            sx={followUpHourButtonStyle(activeTime)}
                        >
                            <Iconify sx={{ marginRight: '10px' }} icon={'fluent:call-32-filled'} width={16} height={16} /> Call in 2 hours
                        </Button>
                        <Button
                            variant={activeTime === 'tomorrow' ? 'contained' : 'text'}
                            onClick={() => handleButtonClick('tomorrow')}
                            sx={followUpTomorrowButtonStyle(activeTime)}
                        >
                            <Iconify sx={{ marginRight: '10px' }} icon={'fluent:call-32-filled'} width={16} height={16} /> Call 9am tomorrow
                        </Button>
                        <Button
                            variant={activeTime === 'week' ? 'contained' : 'text'}
                            onClick={() => handleButtonClick('week')}
                            sx={followUpWeekButtonStyle(activeTime)}
                        >
                            <Iconify sx={{ marginRight: '10px' }} icon={'fluent:call-32-filled'} width={16} height={16} /> Call in 1 week
                        </Button>
                        <Button
                            variant={activeTime === 'two-week' ? 'contained' : 'text'}
                            onClick={() => handleButtonClick('two-week')}
                            sx={followUpTwoWeekButtonStyle(activeTime)}
                        >
                            <Iconify sx={{ marginRight: '10px' }} icon={'fluent:call-32-filled'} width={16} height={16} /> Call in 2 week
                        </Button>
                        <Button
                            variant={activeTime === 'no-reminder' ? 'contained' : 'text'}
                            onClick={() => handleButtonClick('no-reminder')}
                            sx={followUpNoReminderButtonStyle(activeTime)}
                        >
                            <Iconify sx={{ marginRight: '8px' }} icon={'bxs:phone-off'} width={25} height={25} /> No reminder
                        </Button>
                    </ButtonGroup>
                </Box>
                {activeTime === 'custom reminder' ?
                    <Grid item xs={12} md={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Box sx={{ width: '50%', mb: 3 }}>
                                <Box sx={{ display: activeTime === 'custom reminder' ? 'block' : 'none' }}>
                                    <Controller
                                        name='reminderDateTime'
                                        control={control}
                                        render={({ field, fieldState: { error } }) => (
                                            <DateTimePicker
                                                open={isOpen}
                                                onOpen={handleToggleOpen}
                                                onClose={() => setIsOpen(false)}
                                                label='Custom date and time (Required)'
                                                value={field.value || null}
                                                format={'dd/MM/yyyy HH:mm'}
                                                ampm={false}
                                                minDateTime={new Date()}
                                                onChange={(newValue) => {
                                                    field.onChange(newValue);
                                                }}
                                                slotProps={{
                                                    textField: {
                                                        fullWidth: true,
                                                        error: !!error,
                                                        helperText: error?.message,
                                                        onKeyDown: (e) => e.preventDefault()
                                                    },
                                                    toolbarButton: {
                                                        onClick: handleToggleOpen
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{ width: '50%', mb: 3 }}>
                                <RHFSelect
                                    name='reminder'
                                    label='Reminder method'
                                    SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                                    sx={{ mb: 2 }}
                                >
                                    {reminderMethod?.map((options) => (
                                        <MenuItem
                                            key={options.label}
                                            value={options.value}
                                            sx={selectMenuItemStyle}
                                        >
                                            {options.label}
                                        </MenuItem>
                                    ))}
                                </RHFSelect>
                                <Box>
                                    <Typography sx={{ fontSize: '13px', color: 'common.grey', mb: '4px' }}>Assignees</Typography>
                                    <Box sx={{ cursor: 'pointer' }}>
                                        {assignToState?.length > 0 ? (
                                            <Box sx={taskUserAvatar}>
                                                <Tooltip title={getAssignees({ assignees: assignToState })} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                                                    <Box sx={{ display: 'flex' }}>
                                                        {assignToState?.slice(0, 2)?.map((assignee, index) => (
                                                            <AssignUserAvatar
                                                                key={index}
                                                                sx={assignUserAvatarStyle}
                                                                src={assignee.user?.avatar || ''}
                                                                name={`${assignee.user?.first_name || ''} ${assignee.user?.last_name || ''}`}
                                                                role={assignee.role}
                                                                onClick={(e) => assignTaskHandler(e)}
                                                            />
                                                        ))}
                                                        {assignToState?.length > 2 && (
                                                            <Box sx={assignLengthStyle} onClick={(e) => assignTaskHandler(e)}>
                                                                + {assignToState?.length - 2}
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </Tooltip>
                                            </Box>
                                        ) : (
                                            <Box sx={openTask} onClick={(e) => e.stopPropagation()}>
                                                <Box sx={{ ...openTaskPop, width: '36px', height: '36px', bgcolor: grey[200] }} onClick={(e) => assignTaskHandler(e)}>
                                                    <Iconify sx={{ color: 'common.grey' }} width={20} height={20} icon={'eva:plus-fill'} />
                                                </Box>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                    : (
                        <Box sx={{ mb: 4, display: (activeTime === 'no-reminder' || activeTime === '') ? 'none' : 'block' }}>
                            <Typography sx={{ fontSize: '13px', color: 'common.grey', mb: '4px' }}>Assignees</Typography>
                            <Box sx={{ cursor: 'pointer' }}>
                                {assignToState?.length > 0 ? (
                                    <Box sx={taskUserAvatar}>
                                        <Tooltip title={getAssignees({ assignees: assignToState })} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                                            <Box sx={{ display: 'flex' }}>
                                                {assignToState?.slice(0, 2)?.map((assignee, index) => (
                                                    <AssignUserAvatar
                                                        key={index}
                                                        sx={assignUserAvatarStyle}
                                                        src={assignee.user?.avatar || ''}
                                                        name={`${assignee.user?.first_name || ''} ${assignee.user?.last_name || ''}`}
                                                        role={assignee.role}
                                                        onClick={(e) => assignTaskHandler(e)}
                                                    />
                                                ))}
                                                {assignToState?.length > 2 && (
                                                    <Box sx={assignLengthStyle} onClick={(e) => assignTaskHandler(e)}>
                                                        + {assignToState?.length - 2}
                                                    </Box>
                                                )}
                                            </Box>
                                        </Tooltip>
                                    </Box>
                                ) : (
                                    <Box sx={openTask} onClick={(e) => e.stopPropagation()}>
                                        <Box sx={{ ...openTaskPop, width: '36px', height: '36px', bgcolor: grey[200] }} onClick={(e) => assignTaskHandler(e)}>
                                            <Iconify sx={{ color: 'common.grey' }} width={20} height={20} icon={'eva:plus-fill'} />
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        </Box>)
                }
                {activeTime !== 'no-reminder' &&
                    <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
                        <RHFTextField
                            sx={{ width: '50%', mt: 0 }}
                            name="notes"
                            multiline
                            rows={4}
                            label="Reminder notes"
                            placeholder="Add notes here"
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>
                }
                {openAssignTask && (
                    <ClickAwayListener onClickAway={onClickAwayEvent}>
                        <Popper open={openAssignTask} anchorEl={anchorElAssign} transition placement="bottom" sx={assignPopperStyle}>
                            {({ TransitionProps }) => (
                                <Grow {...TransitionProps}>
                                    <Paper sx={{ borderRadius: 2 }}>
                                        <AssignTaskPopup
                                            open={openAssignTask}
                                            onClose={() => { onCloseAssignTask(); setAnchorElAssign(null); dispatch(clearPracticeUserData()); }}
                                            type={'AddReminder'}
                                            userSpecific={'TCOUsers'}
                                            setAssignTo={setAssignToState}
                                            assignees={assignToState?.map((ele) => ele.id)}
                                        />
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </ClickAwayListener>
                )}
            </FormProvider>
        </Box>
    );
});

FollowUp.propTypes = {
    handleClose: PropTypes.func,
    type: PropTypes.string,
};

export default FollowUp;