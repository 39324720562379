export const getTagInArrayAudience = (row) =>
    row?.audience?.audience?.audience_internal_queryset?.filters?.tagged_items__tag__name__in || [];

export const getStatusInArrayAudience = (row) =>
    row?.audience?.audience?.audience_internal_queryset?.filters?.lead_treatments__status__in || [];

export const getTreatmentInArrayAudience = (row) =>
    row?.audience?.audience?.audience_internal_queryset?.filters?.lead_treatments__treatment__treatment_item__name__in ||
    [];

export const getArchiveDataAudience = (row) =>
    row?.audience?.audience?.audience_internal_queryset?.filters?.is_only_archived || '';

export const getReferralInArrayAudience = (row, referral) => [
    referral?.filter((el) =>
        row?.audience?.audience?.audience_internal_queryset?.filters?.lead_source__referral__in?.includes(el.value)
    )?.[0]?.label || '',
];

export const getAudienceType = (row) => row?.audience?.audience?.type || '';

export const getCsvLink = (row) => row?.audience?.audience?.audience_csv?.csv;

export const getPractitionerInArrayAudience = (userList, row) => userList
    ?.filter((el) =>
        row?.audience?.audience?.audience_internal_queryset?.filters?.assignees__user__id__in?.includes(el?.user?.id)
    )
    ?.map((el) => `${el?.user?.salutation || ''} ${el?.user?.first_name} ${el?.user?.last_name}`);

export const isTreatment = (word) => {
    switch (word) {
        case 'TREATMENT':
            return 'treatmentName';
        case 'DATE_RANGE':
            return 'dateRange';
        case 'SOURCE_REFERRAL':
            return 'referralName';
        case 'PRACTITIONER':
            return 'practitionerName';
        default:
            break;
    }
    return '';
};

export const tagsConditionData = { name: 'Tags', value: 'TAGS', title: 'contact is tagged' };
export const statusConditionData = { name: 'Status', value: 'STATUS', title: 'contact status is' };
export const treatmentConditionData = { name: 'Treatment', value: 'TREATMENT', title: 'contact treatment is' };
export const dateRangeConditionData = { name: 'Date range', value: 'DATE_RANGE', title: 'contact date range is' };
export const sourceReferralConditionData = { name: 'Source Referral', value: 'SOURCE_REFERRAL', title: 'contact source referral is' };
export const practitionerConditionData = { name: 'Practitioner', value: 'PRACTITIONER', title: 'contact practitioner is' };
export const sourceTypeConditionData = { name: 'Source Type', value: 'SOURCE_TYPE', title: 'contact source type is' };
export const categoryConditionData = { name: 'Category', value: 'CATEGORY', title: 'contact category is' };