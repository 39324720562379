/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import { useDebounce } from 'use-lodash-debounce';
import { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import _, { isArray, isEmpty, isPlainObject } from 'lodash';
import useSnackbar from 'src/hooks/useCustomSnackbar';
// use @mui
import {
    Box,
    Card,
    Tab,
    Tabs,
    InputAdornment,
    TextField,
    Button,
    Stack,
    Dialog,
    DialogTitle,
    DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Popper,
    Grow,
    Paper,
    ClickAwayListener,
    useTheme
} from '@mui/material';
// sections
import SelectArchiveReasons from 'src/components/Dropdowns/SelectArchiveReasons';
import useWindowSize from 'src/hooks/windowSizeListner';
import { initializeFacebookAuthToken, updateLeadDataCount } from 'src/redux/slices/whatsapp';
import { PracticeEditDialog } from '../../sections/@dashboard/practiceTreatment';
import { AppointmentPage, FollowUp } from '../../sections/@dashboard/lead';
import { Activities } from '../../sections/@dashboard/leadDetail';
import {
    TreatmentAndAppointment,
    PaymentOption,
    PersonalDetails,
    MessageAndReminder,
} from '../../sections/@dashboard/general/app';
// component
import DashBoardReportsWidget from '../../components/reportsWidget/DashBoardReportsWidget';
import AttendedTableReportsWidget from '../../components/reportsWidget/AttendedTableReportsWidget';
import StartedTableReportsWidget from '../../components/reportsWidget/StartedTableReportsWidget';
import FTATableReportsWidget from '../../components/reportsWidget/FTATableReportsWidget';
import ClinCheckStatusPopup from '../../components/dialogPopUp/ClinCheckStatusPopup';
import BookedTableReportsWidget from '../../components/reportsWidget/BookedTableReportsWidget';
import NewLeadTableReportsWidget from '../../components/reportsWidget/NewLeadTableReportsWidget';
import { Iconify, Label, MultiDatePicker } from '../../components';
import { ReferralDropdown, MultiTreatmentDropdown } from '../../components/Dropdowns';
import MultiAssigneesDropdown from '../../components/Dropdowns/SelectMultiAssignees';
import { AttendedAppointment } from '../../components/Attended';
import { SearchAutocompleteTags } from '../../components/AutoComplete';
import {
    ArchivedLeadPopUp,
    AssignTaskPopup,
    EditPersonalDetailsPopup,
    AppointmentAssignTaskPopup,
    ArchivedOrRestorePopup,
    AttendedNotePopup,
} from '../../components/dialogPopUp';
import { UnableToContact } from '../../components/failedToAttendTabs';
import { TableFilterResult } from '../../components/table';
import {
    OpenDialogPopup,
    OpenConsultationPopup,
    OpenAttendedPopup,
    OpenUnAttendedPopup,
    OpenRestoreArchived,
    OpenEditAppointmentPopup,
    OpenContactLeadPopup,
    OpenAppointmentPopup
} from '../../components/fullScreenPopUps';
import { useDateRangePicker } from '../../components/date-range-picker';
import TreatmentPlanDatePicker from '../../components/dialogPopUp/TreatmentPlanDatePicker';
import { NewLeadDataGrid } from './tables/AllLeadTable/NewLeadDataGrid';
import { AlignerApproveLeadDataGrid } from './tables/AllLeadTable/AlignerApproveLeadDataGrid';
import TreatmentEditPopover from './tables/AllLeadTable/TreatmentEditPopover';
import { FormProvider } from '../../components/hook-form';
import DepositPaymentPopover from './tables/AllLeadTable/DepositPaymentPopover';
import StartTreatmentPopup from '../../components/dialogPopUp/StartTreatmentPopup';
import CustomConfirmationDialog from './tables/AllLeadTable/CustomConfirmationDialog';
import CustomCompleteTreatmentDialog from './tables/AllLeadTable/CustomCompleteTreatmentDialog';

// redux
import { completedToStart, markAsDuplicate, unMarkAsDuplicate } from '../../redux/slices/duplicateLeads';
import { setCurrentTreatmentDataGlobal } from '../../redux/slices/multiTreatment';
import { getActivities } from '../../redux/slices/activity';
import { dispatch, useSelector } from '../../redux/store';
import {
    clearLead,
    updateSteps,
    updateFollowUpLead,
    updateLeadDetail,
    ConsultationAttended,
    RecordAttended,
    recordUnAttended,
    getLeadReminderData,
    ArchivedLeadDashBoard,
    restoreArchived,
    CompleteTreatment,
    startLoadingData,
    updateCounter,
    StartingTreatment,
    updateDashNewLead,
    dashNewTableCounter,
    selectLeads,
    updateTableType,
    startArchivedLoading,
    startLoadingDepositPending,
    UpdateDepositPendingStatus,
    setAction,
    followUpSlice,
} from '../../redux/slices/lead';
import { clearPracticeUserData } from '../../redux/slices/practiceUser';
import { getPracticeItem } from '../../redux/slices/practice';
import { setCallScriptSection } from '../../redux/slices/callScript';
import { clearMessage, getPracticeTreatmentList } from '../../redux/slices/practiceTreatment';
import { setLoader } from '../../redux/slices/loader';
// hooks/constants/util
import {
    useTabs,
    useToggle,
    useConsultationAttendToggle,
    useRecordAttendToggle,
    useUnRecordAttendToggle,
    useArchivedToggle,
    useAddTreatment,
    useTable,
    useRestoreArchived,
    useAssignTaskToggle,
    useBoolean,
    useLeadTableHelper,
    useAppointmentToggle
} from '../../hooks';
import {
    TAB_HEAD_FOLLOW_UP,
    DASH_NEW_HEADER,
    CONSULTANT_BOOKED_HEAD,
    CONSULTANT_NEW_ATTENDED_HEAD,
    CONSULTANT_CLINCHECK_ATTENDED_HEAD,
} from '../../constants/newLead';
import { CreateLeadHeaderData } from '../../constants/LeadDetailConst';
import {
    SelectTableTab,
    TabCount,
    TablePageNumber,
    TablePerPage,
    TableSearch,
    TableTreatment,
    TableFilterDate,
    TableStatus,
    TableFilterTags,
    TableOrder,
    TableOrderBy,
    TableReferral,
    TableCategory,
    TableCategoryName,
    TableBookedWith,
    TableTags,
    UpdateTableData,
    TableDepositPending,
    TableConsultationBookedWith,
    TableAssignees,
    TableArchiveReason,
} from '../../utils/allTableTab';
import { handleDeletePaymentMethods } from '../../utils/depositUtils';
import { handleLatestData } from '../../utils/getLeadLatestData';
import { localStorageRemoveItem } from '../../utils/storage-available';
// style
import { common, grey } from '../../theme/palette';
import {
    stackStyle,
    textFieldStyle,
    tabsStyle,
    tabStyle,
    cancelBtnStyle,
    yesBtnStyle,
    treatmentBoxStyle,
    referralBoxStyle,
    dateRangeBoxStyle,
    startDateBoxStyle,
    treatmentTagFilterStyle,
    filterFormBoxStyle,
    referralOutBoxStyle,
    searchFilterContent,
    depositPendingBoxStyle,
    zIndexStyle,
    borderRadiosStyle,
    dialogTitleStyle,
    dialogActionStyle,
} from '../../styles/NewLead';


const NewLead = ({
    leadData,
    updateLeadPage,
    pageCount,
    type,
    setUpdateList,
    tableTitle,
    counter,
    reloadCounter,
    reloadTaskCounter,
    onClose,
    rowStatus,
    defaultHideColumn,
    columnVisibilityModel,
    handleColumnVisibilityChange,
    handleColumnOrderChange,
    shouldRunEffect,
    dashBoardDateProps,
    isNoTaskLeadsTable,
    selectedTab,
    setColumnVisibilityModel,
    pinProps,
    displayForCategorySummary,
    leadCategoryType,
    forceReload,
    archiveReasonData
}) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { handleTableData } = useLeadTableHelper(type);
    const { page, order, orderBy, setPage, rowsPerPage, onChangePage, onChangeRowsPerPage, setRowsPerPage } = useTable({
        defaultOrder: 'desc',
    });
    const [paginationModel, setPaginationModel] = useState({
        pageSize: 10,
        page: 0,
    });
    const location = useLocation();
    const currentPagePath = location.pathname;
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const rowData = [];

    const inputRef = useRef();
    const personalDetailPopupRef = useRef();
    const rowLead = useRef();

    const { enqueueSnackbar } = useSnackbar();
    const [currentWidget, setCurrentWidget] = useState('1');

    const picker = useDateRangePicker(null, null);
    const { message, error } = useSelector((state) => state.practiceTreatment);
    const { activityList } = useSelector((state) => state.activity);
    const { results } = useSelector((state) => state.practiceTreatment?.treatmentsList);
    const { categoryList } = useSelector((state) => state.practiceTreatment);
    const { practiceUser } = useSelector((state) => state.practiceUser);
    const { practice } = useSelector((state) => state.practice);
    const { loadingForLead } = useSelector((state) => state.lead);
    const { referral: referralData } = useSelector((state) => state.schema);
    const [currentTreatmentData, setCurrentTreatmentData] = useState({});
    const isPaymentMandatory = practice?.preferences?.is_payment_enforced_before_treatment;
    const [depositStatus, setDepositStatus] = useState(false);
    const {
        isAppointmentBooked,
        attendance,
        followUpLead,
        markAsComplete,
        contactLead,
        BookingAttendance,
        multiLead,
        taskTableData: { limit, offset, filter, assignUser },
        action,
    } = useSelector((state) => state.lead);
    const { isLoader } = useSelector((state) => state.loaderReducer);
    const { practiceTreatment } = useSelector((state) => state.practiceTreatmentList);
    const getUserList = () => practiceUser.results.filter((el) => el.user && el.user.first_name && !el.user.is_superuser);

    const orderByValue = orderBy === 'name' ? 'created_at' : orderBy;
    const allType = type === 'Dashboard' || type === 'NoTaskTable';
    const notAllType = type !== 'Dashboard' || type !== 'NoTaskTable';
    const newType = type === 'NewLeadTable';
    const dashType = type === 'DashNewLeadTable';
    const noDashType = type !== 'DashNewLeadTable';
    const archType = type === 'ArchiveTable';
    const notArchType = type !== 'ArchiveTable';
    const consultantAttendedType = type === 'ConsultantAttend';
    const consultantClinCheckAttendedType = type === 'ConsultantClinCheckAttend';
    const notClinCheckType = type !== 'ConsultantClinCheckAttend';
    const consultantBookedType = type === 'ConsultantBooked';
    const noConsultantBookedType = type !== 'ConsultantBooked';
    const treatmentStartedType = type === 'treatmentStarted' || type === 'treatmentStartedWithInvisalign';
    const noTreatmentStartedType = type !== 'treatmentStarted';
    const failedToAttendType = type === 'FailedToAttend';
    const noFailedToAttendType = type !== 'FailedToAttend';
    const closedType = type === 'Closed';
    const noClosedType = type !== 'Closed';
    const isReportData = type !== 'reportTableData';
    const reportData = type === 'reportTableData';
    const commonType = notArchType && noFailedToAttendType && noClosedType;
    const bookedWithFilter = consultantAttendedType || consultantClinCheckAttendedType || consultantBookedType || treatmentStartedType;

    const contactCount = TabCount(type);
    const pageNumber = TablePageNumber(type);
    const perPage = TablePerPage(type);
    const searchValue = TableSearch(type);
    const treatmentValue = TableTreatment(type);
    const assigneeSelectedForFilter = TableAssignees(type);
    const searchFilterDate = TableFilterDate(type);
    const tableStatus = TableStatus(type);
    const searchTags = TableFilterTags(type)?.toString();
    const tableOrder = TableOrder(type);
    const tableOrderBy = TableOrderBy(type);
    const referral = TableReferral(type);
    const depositPendingStatus = TableDepositPending(type);
    const category = TableCategory(type);
    const categoryName = TableCategoryName(type);
    const bookedWith = TableBookedWith(type);
    const searchFilterTags = TableTags(type);
    const { consultationBookedWith, apptType } = TableConsultationBookedWith(type);
    const archiveReason = TableArchiveReason(type);

    const startDate = searchFilterDate ? searchFilterDate[0]?.startDate : null;
    const endDate = searchFilterDate ? searchFilterDate[0]?.endDate : null;

    const open = useBoolean();

    const paymentDeleteToggle = useToggle();

    const [tableData, setTableData] = useState(leadData);
    const [validStep, setValidStep] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const shouldCallReminder = useRef(true);
    const [active, setActive] = useState(0);
    const [searchQuery, setSearchQuery] = useState(decodeURIComponent(searchValue) || null);
    const [search, setSearch] = useState(decodeURIComponent(searchValue) || '');
    const [startConfirmation, setStartConfirmation] = useState(false);
    const [startTreatment, setStartTreatment] = useState('');
    const [selectedRowId, setSelectedRowId] = useState(null);
    const [checkOptions, setCheckOptions] = useState(false);
    const [headings, setHeading] = useState([]);
    const [date, setDate] = useState([startDate, endDate]);
    const [userList, setUserList] = useState([]);
    const [filters, setFilters] = useState([]);
    const [, setDepositPendingFilter] = useState('');
    const [assignees, setAssignees] = useState([]);
    const [anchorEditTreatment, setAnchorEditTreatment] = useState(null);

    const treatmentData = [];
    treatmentValue?.map((value) => treatmentData.push(value.id));

    const assigneeData = [];
    assigneeSelectedForFilter?.map((value) => assigneeData.push(value.id));

    const bookedWithData = [];
    if (Array.isArray(bookedWith)) {
        bookedWith.map((value) => bookedWithData.push(value?.id));
    }

    const consultationBookedWithData = [];
    if (Array.isArray(consultationBookedWith)) {
        consultationBookedWith.map((value) => consultationBookedWithData.push(value?.id));
    }

    const noTreatmentCounter = counter?.filter((value) => value.filterStatus === 'No Treatment');
    const noAwatingCount = counter?.filter((value) => value.filterStatus === 'AWAITING_RESPONSE');
    const nhsCount = counter?.filter((value) => value.filterStatus === 'NHS');
    const [appoinmentSelectData, setAppoinmentSelectData] = useState(null);
    const [forceUpdate, setForceUpdate] = useState(false);
    const [anchorElAssign, setAnchorElAssign] = useState(null);
    const [appoinmentSelectedId, setAppoinmentSelectedId] = useState(null);
    const [anchorClinCheck, setAnchorClinCheck] = useState(null);
    const [anchorDepositPayment, setAnchorDepositPayment] = useState(null);
    const { tableRowLead } = useSelector((state) => state.lead);
    const [width, height] = useWindowSize();
    const [openContactWhatsapp, setOpenContactWhatsapp] = useState({ open: false });
    const [isNoTreatment, setIsNoTreatment] = useState(false);

    const {
        toggle: openTreatmentEditPopup,
        onOpen: onopenTreatmentEditPopup,
        onClose: onCloseTreatmentEditPopup,
    } = useToggle();

    const {
        toggle: openDepositPaymentPopup,
        onOpen: onOpenDepositPaymentPopup,
        onClose: onCloseDepositPaymentPopup,
    } = useToggle();

    useEffect(() => {

        if (type) {
            switch (type) {
                case 'treatmentStarted':
                    setHeading(categoryList?.results);
                    break;
                case 'treatmentStartedWithInvisalign':
                    setHeading(categoryList?.results);
                    break;
                case 'NewLeadTable':
                case 'DashNewLeadTable':
                    setHeading(
                        practice?.preferences?.is_accepting_nhs_patients
                            ? DASH_NEW_HEADER
                            : DASH_NEW_HEADER.filter((e) => e.value !== 'NHS')
                    );
                    break;
                case 'ConsultantAttend':
                    setHeading(CONSULTANT_NEW_ATTENDED_HEAD);
                    break;
                case 'ConsultantClinCheckAttend':
                    setHeading(CONSULTANT_CLINCHECK_ATTENDED_HEAD);
                    break;
                case 'ConsultantBooked':
                    setHeading(CONSULTANT_BOOKED_HEAD);
                    break;
                case 'NoTaskTable':
                    setHeading(TAB_HEAD_FOLLOW_UP.filter((ele) => ele.value !== 'CLOSED'));
                    break;
                default:
                    setHeading(TAB_HEAD_FOLLOW_UP);
                    break;
            }
        }
    }, [type, categoryList?.results, practice]);

    const { currentTreatmentDataGlobal } = useSelector((state) => state.multiTreatment);

    useEffect(() => {
        if (!isEmpty(currentTreatmentDataGlobal)) {
            dispatch(setCurrentTreatmentDataGlobal(null));
        }
        if (action) {
            dispatch(setAction(null));
        }
        // dispatch(initializeFacebookAuthToken());
        return () => (dispatch(updateLeadDataCount(false)));
    }, []);

    useEffect(() => {
        if (type) {
            dispatch(updateTableType(type));
        }
    }, [type]);

    const debouncedValue = useDebounce(searchValue, 1000);

    const onSubmit = (data) => setValidStep(!!data);

    const handleReloadLeads = () => {
        const reloadPayLoad = {
            limit: perPage,
            offset: pageNumber,
            filterStatus: tableStatus,
            order: tableOrder,
            orderId: tableOrderBy,
            searchQuery,
            treatment: isNoTreatment ? '' : treatmentData?.toString(),
            startDate,
            endDate,
            contactedCount: contactCount,
            tags: searchTags,
            referral,
            depositPendingStatus,
            bookedWith: bookedWithData?.toString(),
            assigneeIds: assigneeData?.toString(),
            archiveReason,
            noTreatment: isNoTreatment
        };
        updateLeadPage(reloadPayLoad);
    };

    const handleClose = (status, data) => {
        dispatch(setLoader(false));
        const reloadPayLoad = {
            limit: perPage,
            offset: pageNumber,
            filterStatus: tableStatus,
            order: tableOrder,
            orderId: tableOrderBy,
            searchQuery,
            treatment: isNoTreatment ? '' : treatmentData?.toString(),
            startDate,
            endDate,
            contactedCount: contactCount,
            tags: searchTags,
            referral,
            depositPendingStatus,
            bookedWith: bookedWithData?.toString(),
            assigneeIds: assigneeData?.toString(),
            archiveReason,
            noTreatment: isNoTreatment
        };

        if (status?.toLowerCase?.() === 'finish' && followUpLead !== 'restoreArchived') {
            dispatch(updateCounter([]));
            updateLeadPage(!isEmpty(data) && isPlainObject(data) ? data : reloadPayLoad);
            dispatch(getLeadReminderData(practiceId, 'dashboard', limit, offset, filter, '', assignUser, '', '', '', '', '', '', '', ''));
            if (reloadTaskCounter) {

                reloadTaskCounter(assignUser);
            }
            if (dashType || newType) {
                dispatch(dashNewTableCounter(practiceId));
            }
            if (reloadCounter) {
                reloadCounter(typeof data === 'object' ? data : reloadPayLoad);
            }
        }
        open.onFalse();
        dispatch(clearLead());
        dispatch(setCurrentTreatmentDataGlobal(null));
        dispatch(updateSteps(null));
        dispatch(updateFollowUpLead(null));
        onCloseFollowLead();
        onCloseConsultationAttend();
        onCloseRecordAttend();
        onCloseUnRecordAttend();
        onCloseArchiveLead();
        onCloseRestoreArchived();
        closeDuplicatePopup();
        closeUnMarkAsDuplicate();
        setStartTreatment('');
        onCloseContactLead();
        setStartConfirmation(false);
        dispatch(setCallScriptSection(null));
        setAnchorClinCheck(null);
        setForceUpdate((prev) => !prev);
        handleReloadLeads();
        setOpenContactWhatsapp({ open: false });
    };

    useEffect(() => {
        if (forceReload) {
            handleReloadLeads();
        }
    }, [forceReload]);

    const STEPS = [
        {
            label: 'Contact',
            component: <FollowUp ref={inputRef} handleNext={onSubmit} handleClose={handleClose} />,
        },
    ];

    const [leadStep, setLeadStep] = useState(STEPS);

    const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus, setCurrentTab } = useTabs(SelectTableTab(type));

    const dataFiltered = applySortFilter({
        tableData,
        filterStatus,
    });

    const isNotFound =
        (!dataFiltered.length && (!!filterStatus || filterStatus === 0 ? true : filterStatus)) || !dataFiltered;
    const bookedWithLabel = getUserList()
        .filter((el) => bookedWithData.includes(el.user.id))
        ?.map((ele) => `${ele?.user?.salutation || ''} ${ele?.user?.first_name || ''} ${ele?.user?.last_name || ''}`);
    const consultationBookedWithLabel = getUserList()
        .filter((el) => consultationBookedWithData.includes(el.user.id))
        ?.map((ele) => `${ele?.user?.salutation || ''} ${ele?.user?.first_name || ''} ${ele?.user?.last_name || ''}`);

    const defaultValues = {
        multiSelect: treatmentData || [],
        referral: referral || '',
        category,
        bookedWith: bookedWithData ? bookedWithLabel : [] || [],
        consultationBookedWith: consultationBookedWithData ? consultationBookedWithLabel : [] || [],
        multiSelectAssignee: assigneeData || [],
        archiveReason: archiveReason || '',
    };

    const methods = useForm({
        defaultValues,
    });

    const { setValue, handleSubmit } = methods;

    const { toggle: openContacts, onClose: onCloseContacts } = useToggle();

    const { toggle: leadDetailModal, onOpen: onLeadDetail, onClose: onCloseLeadDetail } = useToggle();

    const { toggle: openFollowLead, onOpen: onOpenFollowLead, onClose: onCloseFollowLead } = useToggle();

    const {
        toggle: openConsultationAttend,
        onOpen: onOpenConsultationAttend,
        onClose: onCloseConsultationAttend,
    } = useConsultationAttendToggle();

    const {
        toggle: openRecordAttend,
        onOpen: onOpenRecordAttend,
        onClose: onCloseRecordAttend,
    } = useRecordAttendToggle();

    const { toggle: openAttendedNote, onOpen: onOpenAttendedNote, onClose: onCloseAttendedNote } = useToggle();

    const {
        toggle: openUnRecordAttend,
        onOpen: onOpenUnRecordAttend,
        onClose: onCloseUnRecordAttend,
    } = useUnRecordAttendToggle();

    const {
        toggle: openRestoreArchived,
        onOpen: onOpenRestoreArchived,
        onClose: onCloseRestoreArchived,
    } = useRestoreArchived();

    const { toggle: openArchiveLead, onOpen: onOpenArchiveLead, onClose: onCloseArchiveLead } = useArchivedToggle();

    const { toggle: openAddTreatment, onOpen: onOpenAddTreatment, onClose: onCloseAddTreatment } = useAddTreatment();

    const { toggle: OpenReSchedule, onOpen: onOpenReSchedule, onClose: onCloseReSchedule } = useToggle();

    const { toggle: toggleDuplicatePopup, onOpen: openDuplicatePopup, onClose: closeDuplicatePopup } = useToggle();
    const {
        toggle: toggleUnMarkAsDuplicate,
        onOpen: openUnMarkAsDuplicate,
        onClose: closeUnMarkAsDuplicate,
    } = useToggle();

    const {
        toggle: openContactLead,
        onOpen: onOpenContactLead,
        onClose: onCloseContactLead,
    } = useToggle();

    const {
        toggle: openAppointment,
        onOpen: onOpenAppointment,
        onClose: onCloseAppointment
    } = useAppointmentToggle();

    const handleReScheduleClose = () => {
        onCloseReSchedule();
        handleClose('finish');
    };

    const handleAppointmentClose = () => {
        onCloseAppointment();
        handleClose('finish');
    };

    const editDetailHandler = () => onLeadDetail();

    const closeEditDetails = () => {
        handleReloadAPI('finish');
        onCloseLeadDetail();
    };

    useEffect(() => {
        if (practiceUser) {
            const getUserList = () =>
                practiceUser?.results?.filter((el) => el.user && el.user.first_name && !el.user.is_superuser);
            const data = _.orderBy(
                getUserList(),
                [(o) => (o.user?.first_name).toLowerCase(), (o) => (o.user?.last_name).toLowerCase()],
                ['asc', 'asc']
            );
            setUserList(data);
        }
    }, [practiceUser]);

    useEffect(() => {
        if (filterStatus && headings) {
            const filteredData = commonType
                ? treatmentStartedType
                    ? headings.filter((el) => Number(el.id) === Number(filterStatus))[0]?.name
                    : headings.filter((el) => el.value === filterStatus)[0]?.label
                : '';
            setFilters({
                status: filteredData,
                treatment: isNoTreatment ? '' : treatmentValue,
                tags: searchFilterTags,
                referral: referralData?.filter((e) => e.value === referral)[0]?.label || '',
                startDate,
                endDate,
                assignTo: bookedWith,
                consultationBookedWith,
                statusLabel:
                    (consultantBookedType && 'Appointment method') || (treatmentStartedType && 'Treatment category') || '',
                depositPaidFilter: depositPendingStatus,
                assigneeSelectedForFilter,
                archiveReason: archiveReasonData?.filter((e) => e.value === archiveReason)[0]?.label || '',
                isNoTreatment
            });
        }
    }, [
        filterStatus,
        headings,
        treatmentValue,
        searchFilterTags,
        referral,
        startDate,
        endDate,
        bookedWith,
        consultationBookedWith,
        userList,
        depositPendingStatus,
        assigneeSelectedForFilter,
        archiveReason,
        isNoTreatment
    ]);

    const handleStartedTablePayload = (payloadData) => {
        if (!treatmentStartedType) return;
        payloadData.consultationBookedWith = consultationBookedWithData?.toString();
        payloadData.apptType = apptType;
    };

    useEffect(() => {
        setRowsPerPage(perPage);
        setPage(pageNumber / perPage);
        setPaginationModel({ pageSize: perPage, page: pageNumber / perPage });
        const payloadData = {
            limit: perPage,
            offset: pageNumber,
            filterStatus: tableStatus,
            order: tableOrder,
            orderId: tableOrderBy,
            searchQuery: searchValue,
            treatment: isNoTreatment ? '' : treatmentData?.toString(),
            startDate,
            endDate,
            contactedCount: contactCount,
            tags: searchTags,
            referral,
            depositPendingStatus,
            category,
            bookedWith: bookedWithData?.toString(),
            assigneeIds: assigneeData?.toString(),
            archiveReason,
            noTreatment: isNoTreatment
        };
        handleStartedTablePayload(payloadData);
        if (isReportData) {
            updateLeadPage(payloadData);
            if (practiceId) {
                dispatch(getPracticeItem(practiceId));
            }
        }
        dispatch(startLoadingData(true));
        dispatch(updateDashNewLead({ results: [] }));
        if (practiceId && practiceTreatment?.results?.length > 0) {
            const data = {
                limit: 50,
                offset: 0,
                category: categoryName !== 'All' && categoryName,
            };
            if (isReportData && type !== 'treatmentStartedWithInvisalign' && selectedTab !== 'invisalignAdditional') {
                dispatch(getPracticeTreatmentList(practiceId, data));
            }
        }
        if (noConsultantBookedType && notArchType && results) {
            dispatch(updateCounter([]));
        }

        if (tableData && practiceId && reloadCounter && practiceTreatment?.results?.length > 0) {
            reloadCounter(payloadData);
        }
    }, [practiceId, practiceTreatment]);

    useEffect(() => {
        if (searchQuery !== null) {
            if (debouncedValue) {
                dispatch(updateCounter([]));
                const payloadData = {
                    limit: perPage,
                    offset: pageNumber,
                    filterStatus: notAllType ? contactCount : filterStatus,
                    order: tableOrder,
                    orderId: tableOrderBy,
                    searchQuery: searchValue,
                    treatment: isNoTreatment ? '' : treatmentData?.toString(),
                    startDate,
                    endDate,
                    contactedCount: contactCount,
                    tags: searchTags,
                    referral,
                    depositPendingStatus,
                    category,
                    bookedWith: bookedWithData?.toString(),
                    assigneeIds: assigneeData?.toString(),
                    archiveReason,
                    noTreatment: isNoTreatment
                };
                handleStartedTablePayload(payloadData);
                updateLeadPage(payloadData);
                if (tableData && reloadCounter) {
                    reloadCounter(payloadData);
                }
                setCurrentTab(notAllType ? contactCount : filterStatus);
            } else {
                dispatch(updateCounter([]));
                const payloadData = {
                    limit: perPage,
                    offset: pageNumber,
                    filterStatus: tableStatus,
                    order: tableOrder,
                    orderId: tableOrderBy,
                    searchQuery: searchValue,
                    treatment: isNoTreatment ? '' : treatmentData?.toString(),
                    startDate,
                    endDate,
                    contactedCount: contactCount,
                    tags: searchTags,
                    referral,
                    depositPendingStatus,
                    category,
                    bookedWith: bookedWithData?.toString(),
                    assigneeIds: assigneeData?.toString(),
                    archiveReason,
                    noTreatment: isNoTreatment
                };
                handleStartedTablePayload(payloadData);
                updateLeadPage(payloadData);
                if (tableData && practiceId && reloadCounter) {
                    reloadCounter(payloadData);
                }
            }
        }
    }, [debouncedValue]);

    useEffect(() => {
        if (selectedRow && followUpLead === 'contactLead' && shouldCallReminder?.current) {
            dispatch(getActivities(practiceId, selectedRow?.lead_id, 10, 0));
            dispatch(getLeadReminderData(practiceId, 'LeadDetail', 50, 0, 'leadNotes', selectedRow?.lead_id, '', '', '', 'counter'));
        }
    }, [selectedRow, practiceId]);

    useEffect(() => {
        leadData.map((item) => {
            const latestData = handleLatestData(item, type, rowStatus);
            const latestItem = type === 'treatmentStartedWithInvisalign' ? {
                ...item,
                lead_treatments: item?.lead_treatments?.filter(treatment =>
                    treatment.treatment === 'Invisalign' && treatment.status === 'TREATMENT_STARTED'
                )
            } : item;
            rowData.push(handleTableData(latestItem, latestData));
            return item;
        });
        if (rowData) {
            setTableData(rowData);
        }
    }, [leadData, page]);

    const handleSetTableData = (item) => {
        setTableData((state) => state.map((ele) => {
            if (ele.lead_id === item.id) {
                const latestData = handleLatestData(item, type, rowStatus);
                const latestItem = type === 'treatmentStartedWithInvisalign' ? {
                    ...item,
                    lead_treatments: item?.lead_treatments?.filter(treatment =>
                        treatment.treatment === 'Invisalign' && treatment.status === 'TREATMENT_STARTED'
                    )
                } : item;
                return handleTableData(latestItem, latestData);
            }
            return ele;
        }));
    };

    const handleArchivedClose = () => {
        onCloseArchiveLead();
        if (type === 'NewLeadTable' && setUpdateList) {
            setUpdateList(true);
        }
        if (openFollowLead) {
            dispatch(updateFollowUpLead('contactLead'));
        } else {
            dispatch(getLeadReminderData(practiceId, 'dashboard', limit, offset, filter, '', assignUser));
        }
    };

    useEffect(() => {
        if (message) {
            enqueueSnackbar(message, { variant: 'success', anchorOrigin: { vertical: 'top', horizontal: 'center' } });
            setTimeout(() => {
                dispatch(clearMessage());
            }, 3000);
        }
        if (error) {
            enqueueSnackbar(error?.detail || 'something went wrong', {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
            });
            setTimeout(() => {
                dispatch(clearMessage());
            }, 3000);
        }
    }, [message, error]);

    useEffect(() => {
        if (markAsComplete) {
            switch (followUpLead) {
                case 'contactLead':
                    dispatch(updateLeadDetail(isAppointmentBooked, handleClose));
                    break;
                case 'consultationAttended':
                    dispatch(ConsultationAttended(handleClose));
                    break;
                case 'recordAttended':
                    dispatch(RecordAttended(handleClose));
                    break;
                case 'recordUnAttended':
                    dispatch(recordUnAttended(handleClose));
                    break;
                case 'archivedLead':
                    dispatch(ArchivedLeadDashBoard(handleClose));
                    break;
                case 'restoreArchived':
                    dispatch(restoreArchived(handleClose));
                    break;
                case 'StartTreatmentConfirmation':
                    dispatch(ConsultationAttended(handleClose));
                    break;
                default:
                    break;
            }
        }
    }, [markAsComplete, followUpLead]);

    const handlePaginationChange = (newPageData) => {

        const { page, pageSize } = newPageData;
        const payloadData = {
            limit: pageSize,
            offset: pageSize * page,
            filterStatus,
            order: tableOrder,
            orderId: tableOrderBy,
            searchQuery: searchValue,
            treatment: isNoTreatment ? '' : treatmentData?.toString(),
            startDate,
            endDate,
            contactedCount: contactCount,
            tags: searchTags,
            referral,
            depositPendingStatus,
            category,
            bookedWith: bookedWithData?.toString(),
            assigneeIds: assigneeData?.toString(),
            archiveReason,
            noTreatment: isNoTreatment
        };
        handleStartedTablePayload(payloadData);
        updateLeadPage(payloadData);
        onChangePage('', page);
        UpdateTableData(type, { perPage: pageSize, pageNumber: pageSize * page });
    };

    const handleChangePerPage = (event) => {

        const payloadData = {
            limit: event.target.value,
            offset: 0,
            filterStatus,
            order: tableOrder,
            orderId: tableOrderBy,
            searchQuery: searchValue,
            treatment: isNoTreatment ? '' : treatmentData?.toString(),
            startDate,
            endDate,
            contactedCount: contactCount,
            tags: searchTags,
            referral,
            depositPendingStatus,
            category,
            bookedWith: bookedWithData?.toString(),
            assigneeIds: assigneeData?.toString(),
            archiveReason,
            noTreatment: isNoTreatment
        };
        handleStartedTablePayload(payloadData);
        updateLeadPage(payloadData);
        onChangeRowsPerPage(event);
        UpdateTableData(type, { perPage: event.target.value });
    };

    const handleChangeStatus = (event, newValue) => {
        setPage(0);
        setRowsPerPage(10);
        setForceUpdate(prv => !prv);
        onChangeFilterStatus(event, newValue);
        setPaginationModel({ pageSize: 10, page: 0 });
        if (treatmentStartedType) {
            handleCategory(event, newValue);
            return;
        }
        const payloadData = {
            limit: 10,
            offset: 0,
            filterStatus: newValue,
            order: 'desc',
            orderId: 'created_at',
            searchQuery: searchValue,
            treatment: isNoTreatment ? '' : treatmentData?.toString(),
            startDate,
            endDate,
            contactedCount: newValue === 'AWAITING_RESPONSE' ? '' : newValue,
            tags: searchTags,
            referral,
            depositPendingStatus,
            category,
            bookedWith: bookedWithData?.toString(),
            assigneeIds: assigneeData?.toString(),
            archiveReason,
            noTreatment: isNoTreatment
        };
        updateLeadPage(payloadData);
        UpdateTableData(type, { count: newValue, perPage: 10, pageNumber: 0 });
    };

    const sortingApiCall = (id, defaultOrder) => {
        onChangePage(id, 0);
        const payloadData = {
            limit: rowsPerPage,
            offset: 0,
            filterStatus,
            order: defaultOrder,
            orderId: id,
            searchQuery: searchValue,
            treatment: isNoTreatment ? '' : treatmentData?.toString(),
            startDate,
            endDate,
            contactedCount: contactCount,
            tags: searchTags,
            referral,
            depositPendingStatus,
            category,
            assigneeIds: assigneeData?.toString(),
            archiveReason,
            noTreatment: isNoTreatment
        };

        handleStartedTablePayload(payloadData);
        updateLeadPage(payloadData);
    };

    useEffect(() => {
        if (contactLead) {
            const STEPS = [
                {
                    label: 'Contact',
                    component: <FollowUp ref={inputRef} handleNext={onSubmit} handleClose={handleClose} />,
                },
            ];
            setLeadStep(STEPS);
        } else {
            const STEPS = [
                {
                    label: 'Contact',
                    component: <Activities activityList={activityList?.results} notAllow />,
                },
            ];
            setLeadStep(STEPS);
        }
    }, [contactLead]);

    useEffect(() => {
        if (BookingAttendance) {
            const steps = [...STEPS];
            const row = rowLead.current;
            steps.push({
                label: isAppointmentBooked,
                component:
                    isAppointmentBooked === 'Booking Details' ? (
                        <TreatmentAndAppointment ref={inputRef} type={'followUp'} handleNext={onSubmit} isOverview />
                    ) : isAppointmentBooked === 'Unable to contact' ? (
                        <UnableToContact
                            ref={inputRef}
                            handleNext={onSubmit}
                            openArchivePopup={() => onOpenArchiveLead(row?.lead_id)}
                            isOverview
                            completeFlow={handleClose}
                        />
                    ) : (
                        <AppointmentPage ref={inputRef} handleNext={onSubmit} />
                    ),
            });
            setLeadStep(steps);
        } else {
            setLeadStep(STEPS);
        }
    }, [BookingAttendance]);

    useEffect(() => {
        if (attendance && isAppointmentBooked !== 'Appointment Not Booked') {
            const steps = [...STEPS];
            const row = rowLead.current;
            if (attendance === 'attendance') {
                steps.push({
                    label: isAppointmentBooked,
                    component:
                        isAppointmentBooked === 'Booking Details' ? (
                            <TreatmentAndAppointment ref={inputRef} type={'followUp'} handleNext={onSubmit} isOverview />
                        ) : isAppointmentBooked === 'Unable to contact' ? (
                            <UnableToContact
                                ref={inputRef}
                                handleNext={onSubmit}
                                openArchivePopup={() => onOpenArchiveLead(row?.lead_id)}
                                isOverview
                                completeFlow={handleClose}
                            />
                        ) : (
                            <AppointmentPage ref={inputRef} handleNext={onSubmit} />
                        ),
                });
                steps.push({
                    label: 'Appointment Outcome',
                    component: <AttendedAppointment ref={inputRef} handleNext={onSubmit} isOverview />,
                });
            } else {
                steps.push({
                    label: isAppointmentBooked,
                    component:
                        isAppointmentBooked === 'Booking Details' ? (
                            <TreatmentAndAppointment ref={inputRef} type={'followUp'} handleNext={onSubmit} isOverview />
                        ) : isAppointmentBooked === 'Unable to contact' ? (
                            <UnableToContact
                                ref={inputRef}
                                handleNext={onSubmit}
                                openArchivePopup={() => onOpenArchiveLead(row?.lead_id)}
                                isOverview
                                completeFlow={handleClose}
                            />
                        ) : (
                            <AppointmentPage ref={inputRef} handleNext={onSubmit} />
                        ),
                });
                steps.push({
                    label: 'Personal Details',
                    component: <PersonalDetails ref={inputRef} handleNext={onSubmit} />,
                });
                steps.push({
                    label: 'Payment options',
                    component: <PaymentOption ref={inputRef} handleNext={onSubmit} />,
                });
                steps.push({
                    label: 'Message review & reminders',
                    component: <MessageAndReminder ref={inputRef} handleNext={onSubmit} type={'FollowUp'} row={selectedRow} />,
                });
            }
            setLeadStep(steps);
        } else {
            setLeadStep(STEPS);
        }
    }, [attendance]);

    useEffect(() => {
        if (!followUpLead) {

            handleClose();
            dispatch(updateSteps(null));
        }
    }, [followUpLead]);

    const handleCompletedToStartAPI = () => {
        const row = rowLead.current;
        const data = {
            id: row.treatment_id,
            practiceId,
            leadId: row?.lead_id,
            handleClose
        };
        dispatch(completedToStart(data));
    };

    const onSelectedRow = (row, typed, isFlowButtonClicked, event) => {
        localStorageRemoveItem('localTreatmentData');
        setSelectedRow(row);
        if (isFlowButtonClicked) {
            shouldCallReminder.current = false;
        } else {
            shouldCallReminder.current = true;
        }
        const latestData = handleLatestData({ lead_treatments: row?.treatmentData || [] }, type, rowStatus);
        setDepositStatus(!latestData?.[0]?.is_treatment_fee_pending);
        dispatch(updateFollowUpLead(typed));
        switch (typed) {
            case 'consultationAttended':
                onOpenConsultationAttend();
                break;
            case 'deleteTreatmentPayment':
                paymentDeleteToggle.onOpen();
                onCloseDepositPaymentPopup();
                setAnchorDepositPayment(null);
                break;
            case 'recordAttended':
                onOpenRecordAttend();
                break;
            case 'recordUnAttended':
                onOpenUnRecordAttend();
                break;
            case 'restoreArchived':
                onOpenRestoreArchived(row.id);
                break;
            case 'archivedLead':
                onOpenArchiveLead(row.id);
                break;
            case 'backToAttended':
                onOpenAttendedNote(row.id);
                break;
            case 'addTreatment':
                onOpenAddTreatment('openAddTreatment');
                setAnchorEditTreatment(event.currentTarget);
                break;
            case 'editTreatment':
                onopenTreatmentEditPopup();
                setAnchorEditTreatment(event.currentTarget);
                break;
            case 'deposit_payment':
                onOpenDepositPaymentPopup();
                setAnchorDepositPayment(event.currentTarget);
                break;
            case 'markAsDuplicate':
                openDuplicatePopup();
                rowLead.current = row;
                break;
            case 'unMarkAsDuplicate':
                openUnMarkAsDuplicate();
                rowLead.current = row;
                break;
            case 'TreatmentConfirmation':
                setConfirmation(true);
                rowLead.current = row;
                break;
            case 'StartTreatmentConfirmation':
                setStartConfirmation(true);
                rowLead.current = row;
                break;
            case 'CompletedToStartTreatment':
                rowLead.current = row;
                handleCompletedToStartAPI();
                break;
            case 'EditAppointment':
                onOpenReSchedule(row);
                setAppoinmentSelectData(row);
                break;
            case 'AddAppointment':
                onOpenAppointment();
                break;
            case 'ClinCheckStatusOpen':
                openClinCheckStatusPopup(row, event);
                break;
            case 'contactLead': {
                const { openWhatsapp, treatmentCallBack } = event ?? {};
                if (openWhatsapp) {
                    dispatch(initializeFacebookAuthToken());
                    setOpenContactWhatsapp({ open: true, treatmentCallBack });
                    onOpenContactLead(row);
                } else {
                    onOpenContactLead(row);
                }
                break;
            }
            default:
                onOpenFollowLead();
                break;
        }
    };

    useEffect(() => {
        if (leadData.length > 0) {
            dispatch(startArchivedLoading(false));
        }
    }, [leadData]);

    useEffect(() => {
        if (displayForCategorySummary && leadCategoryType !== filterStatus) {
            handleChangeStatus("", leadCategoryType);
        }
    }, [filterStatus]);

    const completeTreatmentAPI = () => {
        const row = rowLead.current;
        const data = {
            treatment: row.treatment_name || '',
            custom_price: row.price,
        };
        dispatch(setLoader(true));
        dispatch(CompleteTreatment(data, row.lead_id, row.treatment_id, practiceId, handleReloadAPI));
    };

    const startTreatmentAPI = () => {
        const row = rowLead.current;
        const data = {
            treatment: row.treatment_name || '',
            custom_price: row.price,
            lead: row.lead_id,
            id: row.treatment_id,
        };
        dispatch(setLoader(true));
        dispatch(StartingTreatment(data, practiceId, handleClose, 'startingTreatmentFinish'));
    };

    const handleMarkAsDuplicateAPI = () => {
        const row = rowLead.current;
        dispatch(markAsDuplicate({ practiceId, leadId: row?.lead_id, handleClose }));
    };
    const handleUnMarkAsDuplicateAPI = () => {
        const row = rowLead.current;
        dispatch(unMarkAsDuplicate({ practiceId, leadId: row?.lead_id, handleClose }));
    };

    const handleReloadAPI = (value) => {
        if (value === 'finish') {
            dispatch(updateCounter([]));
            const reloadPayLoad = {
                limit: perPage,
                offset: pageNumber,
                filterStatus: notAllType ? contactCount : filterStatus,
                order: tableOrder,
                orderId: tableOrderBy,
                searchQuery: searchValue,
                treatment: isNoTreatment ? '' : treatmentData?.toString(),
                startDate,
                endDate,
                contactedCount: contactCount,
                tags: searchTags,
                referral,
                depositPendingStatus,
                category,
                bookedWith: bookedWithData?.toString(),
                assigneeIds: assigneeData?.toString(),
                archiveReason,
                noTreatment: isNoTreatment,
            };
            updateLeadPage(reloadPayLoad);
            if (tableData && practiceId && reloadCounter) {
                reloadCounter(reloadPayLoad);
            }
            dispatch(getLeadReminderData(practiceId, 'dashboard', limit, offset, filter, '', assignUser));
            setConfirmation(false);
            dispatch(setLoader(false));
        }
    };

    const handleSearch = (data) => {
        let search = data.trim();
        if (data.includes('+')) {
            search = `+44${parseInt(data.split('+44')[1], 10)}`;
        }
        if (Number(data) && !data.includes('+')) {
            search = `${parseInt(data, 10)}`;
        }
        setSearchQuery(data);
        setSearch(data);
        UpdateTableData(type, { search: encodeURIComponent(search), perPage: 10, pageNumber: 0 });
    };

    const onResetFilter = () => {
        const { setStartDate, setEndDate } = picker;
        if (dashBoardDateProps?.dateChange) {
            dashBoardDateProps.dateChange([null, null]);
        }
        if (shouldRunEffect) {
            shouldRunEffect.current = false;
        }
        dispatch(updateCounter([]));
        setSearch('');
        setSearchQuery(null);
        setValue('multiSelect', []);
        setValue('referral', '');
        setValue('archiveReason', '');
        setValue('category', '');
        setValue('bookedWith', []);
        setValue('consultationBookedWith', []);
        setValue('multiSelectAssignee', [])
        setDepositPendingFilter('');
        setStartDate(null);
        setEndDate(null);
        setDate([null, null]);
        const payloadData = {
            limit: 10,
            offset: 0,
            filterStatus,
            order,
            orderId: orderByValue,
            searchQuery: null,
            treatment: null,
            startDate: null,
            endDate: null,
            contactedCount: contactCount,
            tags: '',
            referral: '',
            depositPendingStatus: '',
            category,
            bookedWith: '',
            assigneeIds: '',
            archiveReason: ''
        };
        const updatedTableData = {
            search: '',
            date: [],
            treatment: [],
            perPage: 10,
            pageNumber: 0,
            tag: [],
            filterTags: [],
            referral: '',
            bookedWith: [],
            depositPendingStatus: '',
            assignee: [],
            archiveReason: ''
        };
        if (treatmentStartedType) {
            payloadData.apptType = '';
            payloadData.consultationBookedWith = '';
            updatedTableData.apptType = '';
            updatedTableData.consultationBookedWith = [];
        }
        updateLeadPage(payloadData);
        if (tableData && practiceId && reloadCounter) {
            if (dashType) {
                reloadCounter({ ...payloadData, isComeFromDateChange: true });
            } else {
                reloadCounter(payloadData);
            }
        }
        UpdateTableData(type, updatedTableData);
        setPaginationModel({ pageSize: 10, page: 0 });
    };

    const handleTreatment = (e, value) => {
        setIsNoTreatment(false);
        if (value) {
            const ids = [];
            value.map((data) => ids.push(data.id));
            dispatch(updateCounter([]));
            const payloadData = {
                limit: 10,
                offset: 0,
                filterStatus,
                order: tableOrder,
                orderId: tableOrderBy,
                searchQuery: searchValue,
                treatment: ids?.toString(),
                startDate,
                endDate,
                contactedCount: contactCount,
                tags: searchTags,
                referral,
                depositPendingStatus,
                category,
                bookedWith: bookedWithData?.toString(),
                assigneeIds: assigneeData?.toString(),
                archiveReason
            };
            handleStartedTablePayload(payloadData);
            updateLeadPage(payloadData);
            setPage(0);
            setRowsPerPage(10);
            setPaginationModel({ pageSize: 10, page: 0 });
            setValue('multiSelect', ids);
            if (tableData && practiceId && reloadCounter) {
                reloadCounter(payloadData);
            }
            UpdateTableData(type, { treatment: value, perPage: 10, pageNumber: 0 });
        } else {
            dispatch(updateCounter([]));
            const payloadData = {
                limit: 10,
                offset: 0,
                filterStatus,
                order: tableOrder,
                orderId: tableOrderBy,
                searchQuery: searchValue,
                treatment: '',
                startDate,
                endDate,
                contactedCount: contactCount,
                tags: searchTags,
                referral,
                depositPendingStatus,
                category,
                bookedWith: bookedWithData?.toString(),
                assigneeIds: assigneeData?.toString(),
                archiveReason
            };
            handleStartedTablePayload(payloadData);
            updateLeadPage(payloadData);
            if (tableData && practiceId && reloadCounter) {
                reloadCounter(payloadData);
            }
            setPage(0);
            setRowsPerPage(10);
            setPaginationModel({ pageSize: 10, page: 0 });
            setValue('multiSelect', []);
            UpdateTableData(type, { treatment: [], perPage: 10, pageNumber: 0 });
        }
    };

    const handleAssigneeFilter = (e, value) => {

        if (value) {
            const ids = [];
            value.map((data) => ids.push(data.id));
            dispatch(updateCounter([]));
            const payloadData = {
                limit: 10,
                offset: 0,
                filterStatus,
                order: tableOrder,
                orderId: tableOrderBy,
                searchQuery: searchValue,
                treatment: isNoTreatment ? '' : treatmentData?.toString(),
                startDate,
                endDate,
                contactedCount: contactCount,
                tags: searchTags,
                referral,
                depositPendingStatus,
                category,
                bookedWith: bookedWithData?.toString(),
                assigneeIds: ids?.toString(),
                archiveReason,
                noTreatment: isNoTreatment
            };
            updateLeadPage(payloadData);
            setPage(0);
            setRowsPerPage(10);
            setPaginationModel({ pageSize: 10, page: 0 });
            setValue('multiSelectAssignee', ids);

            if (tableData && practiceId && reloadCounter) {
                reloadCounter(payloadData);
            }
            UpdateTableData(type, { assignee: value, perPage: 10, pageNumber: 0 });
        } else {
            dispatch(updateCounter([]));
            const payloadData = {
                limit: 10,
                offset: 0,
                filterStatus,
                order: tableOrder,
                orderId: tableOrderBy,
                searchQuery: searchValue,
                treatment: isNoTreatment ? '' : treatmentData?.toString(),
                startDate,
                endDate,
                contactedCount: contactCount,
                tags: searchTags,
                referral,
                depositPendingStatus,
                category,
                bookedWith: bookedWithData?.toString(),
                assigneeIds: '',
                archiveReason,
                noTreatment: isNoTreatment
            };
            updateLeadPage(payloadData);
            if (tableData && practiceId && reloadCounter) {
                reloadCounter(payloadData);
            }
            setPage(0);
            setRowsPerPage(10);
            setPaginationModel({ pageSize: 10, page: 0 });
            setValue('multiSelectAssignee', []);
            UpdateTableData(type, { assignee: [], perPage: 10, pageNumber: 0 });
        }
    }

    const tabCounter = (data) => counter?.filter((value) => (value.filterStatus === data ? value.count : null));

    const labelCount = (data) => {
        const response = tabCounter(treatmentStartedType ? data?.id : data.value);
        return (
            <>
                <Box sx={{ fontWeight: '600' }}>
                    {noTreatmentStartedType && noConsultantBookedType && (
                        <Label
                            sx={{
                                m: '5px 8px',
                                color: isLight ? common.slateGray : common.white,
                                background: isLight ? grey[300] : common.stillBlue,
                            }}
                        >
                            {' '}
                            {(response && response[0]?.count) || 0}{' '}
                        </Label>
                    )}
                    {treatmentStartedType ? data?.name : data?.label}
                </Box>
            </>
        );
    };

    const { toggle: openAssignTask, onOpen: onOpenAssignTask, onClose: onCloseAssignTask } = useAssignTaskToggle();
    const {
        toggle: openTreatmentPlan,
        onOpen: onOpenOpenTreatmentPlan,
        onClose: onCloseTreatmentPlan,
    } = useAssignTaskToggle();

    const {
        toggle: openAppointmentAssignTask,
        onOpen: onOpenAppointmentAssignTask,
        onClose: onCloseAppointmentAssignTask,
    } = useAssignTaskToggle();
    const {
        toggle: openClinCheckStatus,
        onOpen: onOpenClinCheckStatus,
        onClose: onCloseClinCheckStatus,
    } = useAssignTaskToggle();

    const OpenAssignTask = (row, event) => {
        setAnchorElAssign(anchorElAssign ? null : event.currentTarget);
        onOpenAssignTask();
        onCloseTreatmentEditPopup();
        setSelectedRowId(row?.lead_id);
    };
    const openClinCheckStatusPopup = (row, event) => {
        setAnchorClinCheck(anchorClinCheck ? null : event.currentTarget);
        onOpenClinCheckStatus();
        setSelectedRowId(row?.lead_id);
    };

    const openAppointmentAssignTaskFuc = (row, assignees, event, id) => {
        setAnchorElAssign(anchorElAssign ? null : event.currentTarget || event);
        onOpenAppointmentAssignTask();
        setSelectedRowId(row?.lead_id || row?.id);
        setAssignees(assignees);
        setAppoinmentSelectedId(id);
    };

    const onCloseAssignTaskPopup = (isReload, data) => {
        if (isReload) {
            setTableData((state) => state.map((ele) => {
                if (ele.id === data?.id) {
                    return { ...ele, assignees: data?.assignees };
                }
                return ele;
            }));
        }
        onCloseAssignTask();
    };

    const onCloseTreatmentPlanDatePicker = () => {
        handleReloadAPI('finish');
        onCloseTreatmentPlan();
    };

    const onCloseAppointmentAssignTaskPopup = (isReload, data) => {
        if (isReload) {
            handleSetTableData(data);
        }
        onCloseAppointmentAssignTask();
    };

    const handleReferral = (e, data) => {
        dispatch(updateCounter([]));
        const payloadData = {
            limit: 10,
            offset: 0,
            filterStatus,
            order: tableOrder,
            orderId: tableOrderBy,
            searchQuery: searchValue,
            treatment: isNoTreatment ? '' : treatmentData?.toString(),
            startDate,
            endDate,
            contactedCount: contactCount,
            tags: searchTags,
            referral: data?.id ? "CLINICIAN" : data?.value,
            ...(data?.id ? { affiliate_user: data.affiliate_user, type: "CLINICIAN" } : {}),
            depositPendingStatus,
            category,
            bookedWith: bookedWithData?.toString(),
            assigneeIds: assigneeData?.toString(),
            archiveReason,
            noTreatment: isNoTreatment
        };
        handleStartedTablePayload(payloadData);
        if (tableData && practiceId && reloadCounter) {
            reloadCounter(payloadData);
        }
        updateLeadPage(payloadData);
        UpdateTableData(type, { referral: data?.value, perPage: 10, pageNumber: 0 });
        setValue('referral', data?.id ? "CLINICIAN" : data.value);
        if (data?.id) {
            setValue('affiliate_user', data?.id);
            setValue('type', "CLINICIAN");
        }
        setPage(0);
        setRowsPerPage(10);
        setPaginationModel({ pageSize: 10, page: 0 });
    };

    const handleArchiveFilter = (e, data) => {
        dispatch(updateCounter([]));
        const payloadData = {
            limit: 10,
            offset: 0,
            filterStatus,
            order: tableOrder,
            orderId: tableOrderBy,
            searchQuery: searchValue,
            treatment: isNoTreatment ? '' : treatmentData?.toString(),
            startDate,
            endDate,
            contactedCount: contactCount,
            tags: searchTags,
            referral,
            depositPendingStatus,
            category,
            bookedWith: bookedWithData?.toString(),
            assigneeIds: assigneeData?.toString(),
            archiveReason: data?.value,
            noTreatment: isNoTreatment
        };
        handleStartedTablePayload(payloadData);
        if (tableData && practiceId && reloadCounter) {
            reloadCounter(payloadData);
        }
        updateLeadPage(payloadData);
        UpdateTableData(type, { archiveReason: data?.value, perPage: 10, pageNumber: 0 });
        setValue('archiveReason', data?.value);
        setPage(0);
        setRowsPerPage(10);
        setPaginationModel({ pageSize: 10, page: 0 });
    }
    const handleDepositPendingFilter = (e) => {
        dispatch(updateCounter([]));
        const payloadData = {
            limit: 10,
            offset: 0,
            filterStatus,
            order: tableOrder,
            orderId: tableOrderBy,
            searchQuery: searchValue,
            treatment: isNoTreatment ? '' : treatmentData?.toString(),
            startDate,
            endDate,
            contactedCount: contactCount,
            tags: searchTags,
            referral,
            depositPendingStatus: e?.target?.value || '',
            category,
            bookedWith: bookedWithData?.toString(),
            assigneeIds: assigneeData?.toString(),
            archiveReason,
            noTreatment: isNoTreatment
        };
        if (tableData && practiceId && reloadCounter) {
            reloadCounter(payloadData);
        }
        updateLeadPage(payloadData);
        UpdateTableData(type, { depositPendingStatus: e?.target?.value || '', perPage: 10, pageNumber: 0 });
        setDepositPendingFilter(e?.target?.value || '');
        setPage(0);
        setRowsPerPage(10);
        setPaginationModel({ pageSize: 10, page: 0 });
    };

    const handleCloseTreatment = () => {
        dispatch(updateCounter([]));
        const payloadData = {
            limit: perPage,
            offset: pageNumber,
            filterStatus: notAllType ? contactCount : filterStatus,
            order: tableOrder,
            orderId: tableOrderBy,
            searchQuery: searchValue,
            treatment: isNoTreatment ? '' : treatmentData?.toString(),
            startDate,
            endDate,
            contactedCount: contactCount,
            tags: searchTags,
            referral,
            depositPendingStatus,
            category,
            bookedWith: bookedWithData?.toString(),
            assigneeIds: assigneeData?.toString(),
            archiveReason,
            noTreatment: isNoTreatment
        };
        updateLeadPage(payloadData);
        if (tableData && reloadCounter) {
            reloadCounter(payloadData);
        }
        document.body.classList.remove('points-event');
        dispatch(clearLead());
        dispatch(setCurrentTreatmentDataGlobal(null));
    };

    const referralValue = () => referralData?.filter((data) => data.value === referral);
    const archiveReasonValue = () => archiveReasonData?.filter((data) => data.value === archiveReason);

    const handleCategory = (e, newValue) => {
        const data = {
            limit: 30,
            offset: 0,
            category: e?.target?.innerText !== 'All' ? e?.target?.innerText : null,
            callBack: () => setCheckOptions(true),
        };
        dispatch(getPracticeTreatmentList(practiceId, data));
        const payloadData = {
            limit: perPage,
            offset: pageNumber,
            filterStatus: notAllType ? contactCount : filterStatus,
            order: tableOrder,
            orderId: tableOrderBy,
            searchQuery: searchValue,
            treatment: '',
            startDate,
            endDate,
            contactedCount: contactCount,
            tags: searchTags,
            referral,
            depositPendingStatus,
            category: newValue,
            bookedWith: bookedWithData?.toString(),
            assigneeIds: assigneeData?.toString(),
            archiveReason
        };
        updateLeadPage(payloadData);
        UpdateTableData(type, {
            treatment: [],
            category: newValue,
            categoryName: e?.target?.innerText !== 'All' ? e?.target?.innerText : '',
            perPage: 10,
            pageNumber: 0,
        });
    };

    useEffect(() => {
        if (results && checkOptions) {
            const data = treatmentValue.filter((el) => results.map((elem) => elem?.id).includes(el?.id) && el);
            UpdateTableData(type, { treatment: data, perPage: 10, pageNumber: 0 });
            setCheckOptions(false);
        }
    }, [checkOptions]);

    const dateChange = (newValue, shouldNotCall) => {
        setDate(newValue);
        if (!shouldNotCall && dashBoardDateProps?.dateChange) {
            dashBoardDateProps.dateChange(newValue);
        }
        if (shouldRunEffect) {
            shouldRunEffect.current = false;
        }
        const startDate = newValue[0] ? moment(newValue[0]?.$d, 'YYYY-MM-DD').format('YYYY-MM-DD') : '';
        const endDate = newValue[1] ? moment(newValue[1]?.$d, 'YYYY-MM-DD').format('YYYY-MM-DD') : '';
        if (startDate && endDate) {
            dispatch(updateCounter([]));
            const payloadData = {
                limit: 10,
                offset: 0,
                filterStatus: notAllType ? contactCount : filterStatus,
                order: tableOrder,
                orderId: tableOrderBy,
                searchQuery: searchValue,
                treatment: isNoTreatment ? '' : treatmentData?.toString(),
                startDate,
                endDate,
                contactedCount: contactCount,
                tags: searchTags,
                referral,
                depositPendingStatus,
                category,
                bookedWith: bookedWithData?.toString(),
                assigneeIds: assigneeData?.toString(),
                archiveReason,
                noTreatment: isNoTreatment
            };
            updateLeadPage(payloadData);
            if (tableData && practiceId && reloadCounter) {
                reloadCounter(payloadData);
            }
        }

        if (newValue[0] === null && newValue[1] === null) {
            dispatch(updateCounter([]));
            const payloadData = {
                limit: 10,
                offset: 0,
                filterStatus: notAllType ? contactCount : filterStatus,
                order: tableOrder,
                orderId: tableOrderBy,
                searchQuery: searchValue,
                treatment: isNoTreatment ? '' : treatmentData?.toString(),
                startDate: null,
                endDate: null,
                contactedCount: contactCount,
                tags: searchTags,
                referral,
                depositPendingStatus,
                category,
                bookedWith: bookedWithData?.toString(),
                assigneeIds: assigneeData?.toString(),
                archiveReason,
                noTreatment: isNoTreatment
            };
            updateLeadPage(payloadData);
            if (tableData && practiceId && reloadCounter) {
                if (!shouldNotCall && dashType) {
                    reloadCounter({ ...payloadData, isComeFromDateChange: true });
                } else {
                    reloadCounter(payloadData);
                }
            }
        }
        setPage(0);
        setRowsPerPage(10);
        setPaginationModel({ pageSize: 10, page: 0 });
        UpdateTableData(type, { date: [{ startDate, endDate }], pageNumber: 0, perPage: 10 });
    };

    useEffect(() => {
        if (shouldRunEffect && shouldRunEffect.current) {
            const today = moment();
            if (today.isSame(dashBoardDateProps?.startDateDashboard, 'day') && today.isSame(dashBoardDateProps?.endDateDashboard, 'day')) {
                dateChange([null, null], true);
                return;
            }
            dateChange(dashBoardDateProps.date, true);
        }
    }, [dashBoardDateProps?.date]);

    const handlePage = () => {
        setPage(0);
        setRowsPerPage(10);
        setPaginationModel({ pageSize: 10, page: 0 });
    };

    // const practiceUserId = (e) =>
    //     practiceUser.results.filter((el) =>
    //         e?.includes(`${el?.user?.salutation || ''} ${el?.user?.first_name || ''} ${el?.user?.last_name || ''}`)
    //     )[0]?.user.id;

    const handlePractitioner = (e, value) => {
        const ids = [];
        value.map((data) => ids.push(data.id));
        setValue('bookedWith', value);
        dispatch(updateCounter([]));
        const payloadData = {
            limit: perPage,
            offset: pageNumber,
            filterStatus: tableStatus,
            order: tableOrder,
            orderId: tableOrderBy,
            searchQuery: searchValue,
            treatment: isNoTreatment ? '' : treatmentData?.toString(),
            startDate,
            endDate,
            contactedCount: contactCount,
            tags: searchTags,
            referral,
            depositPendingStatus,
            category,
            bookedWith: ids?.toString(),
            assigneeIds: assigneeData?.toString(),
            archiveReason,
            noTreatment: isNoTreatment
        };
        const updatedTableData = {
            bookedWith: value, pageNumber: 0, perPage: 10,
        };
        if (treatmentStartedType) {
            setValue('consultationBookedWith', []);
            payloadData.apptType = ids.length ? 'TREATMENT' : '';
            payloadData.consultationBookedWith = '';
            updatedTableData.apptType = ids.length ? 'TREATMENT' : '';
            updatedTableData.consultationBookedWith = []
        }

        updateLeadPage(payloadData);
        if (tableData && practiceId && reloadCounter) {
            reloadCounter(payloadData);
        }
        setPage(0);
        setRowsPerPage(10);
        setPaginationModel({ pageSize: 10, page: 0 });
        UpdateTableData(type, updatedTableData);
    };
    const handleConsultationBookedWith = (e, value) => {
        const ids = [];
        value.map((data) => ids.push(data.id));
        setValue('consultationBookedWith', value);
        setValue('bookedWith', []);
        dispatch(updateCounter([]));
        const payloadData = {
            limit: perPage,
            offset: pageNumber,
            filterStatus: tableStatus,
            order: tableOrder,
            orderId: tableOrderBy,
            searchQuery: searchValue,
            treatment: isNoTreatment ? '' : treatmentData?.toString(),
            startDate,
            endDate,
            contactedCount: contactCount,
            tags: searchTags,
            referral,
            depositPendingStatus,
            category,
            bookedWith: '',
            consultationBookedWith: ids?.toString(),
            assigneeIds: assigneeData?.toString(),
            archiveReason,
            noTreatment: isNoTreatment
        };
        updateLeadPage({ ...payloadData, apptType: ids.length ? 'CONSULTATION' : '' });
        if (tableData && practiceId && reloadCounter) {
            reloadCounter({ ...payloadData, apptType: ids.length ? 'CONSULTATION' : '' });
        }
        setPage(0);
        setRowsPerPage(10);
        setPaginationModel({ pageSize: 10, page: 0 });
        UpdateTableData(type, { bookedWith: [], consultationBookedWith: value, apptType: ids.length ? 'CONSULTATION' : '', pageNumber: 0, perPage: 10 });
    };

    const handleTags = (data, tags) => {
        dispatch(updateCounter([]));
        const payloadData = {
            limit: 10,
            offset: 0,
            filterStatus,
            order: tableOrder,
            orderId: tableOrderBy,
            searchQuery: searchValue,
            treatment: '',
            startDate,
            endDate,
            contactedCount: contactCount,
            tags,
            referral,
            depositPendingStatus,
            category,
            bookedWith: bookedWithData?.toString(),
            assigneeIds: assigneeData?.toString(),
            archiveReason
        };
        handleStartedTablePayload(payloadData);
        updateLeadPage(payloadData);
        if (tableData && practiceId && reloadCounter) {
            reloadCounter(payloadData);
        }
        setPage(0);
        setRowsPerPage(10);
        setPaginationModel({ pageSize: 10, page: 0 });
        UpdateTableData(type, { tag: data, filterTags: tags, perPage: 10, pageNumber: 0 });
    };

    const onSelectionModelChange = (newSelectionModel) => {
        const updatedData = tableData.map((item) => {
            if (item.lead_id && !item.is_archive_pending) {
                const checked = newSelectionModel.includes(item.lead_id);
                return { ...item, checked };
            }
            return item;
        });
        setTableData(updatedData);
        dispatch(
            selectLeads({
                leadId: newSelectionModel,
                type: archType ? 'Restore' : 'Archive',
                loading: false,
            })
        );
    };

    const handleClearReportTable = () => {
        if (onClose) {
            onClose(true);
        }
        UpdateTableData(type, {
            search: '',
            date: [],
            treatment: [],
            perPage: 10,
            pageNumber: 0,
            tag: [],
            filterTags: [],
            referral: '',
            bookedWith: '',

        });
    };

    const isDisabled = () => {
        if (loadingForLead) {
            return { pointerEvents: 'none' };
        }
        return {};
    };

    const handleDepositPendingChange = (e) => {
        setDepositStatus(e.target.checked);
        let treatment = '';
        let id = '';
        const latestData = handleLatestData({ lead_treatments: selectedRow.treatmentData || [] }, type, rowStatus);
        if (isArray(latestData) && latestData.length > 0) {
            treatment = latestData[0]?.treatment;
            id = latestData[0]?.id;
        } else {
            return;
        }
        const data = {
            leadId: selectedRow.lead_id,
            treatmentId: id,
            payload: {
                treatment,
                is_treatment_fee_pending: !e.target.checked,
            },
        };
        dispatch(startLoadingDepositPending(true));
        dispatch(UpdateDepositPendingStatus(data, handleReloadLeads));
    };

    const handleCloseStartTreatment = () => {
        dispatch(clearLead());
        dispatch(setCurrentTreatmentDataGlobal(null));
        setStartConfirmation(false);
    };

    const onWidgetClick = (value) => {
        if (newType) {
            handleChangeStatus('', value);
            return;
        }
        if (consultantBookedType || failedToAttendType || treatmentStartedType || consultantAttendedType || consultantClinCheckAttendedType) {
            handleTreatment('', value);
        }
    };
    const handleClickAwayLister = (e, popup1Ref) => {
        if (popup1Ref && popup1Ref.current) {
            if (!popup1Ref.current?.contains(e.target)) {
                onCloseTreatmentEditPopup();
                setAnchorElAssign(null);
                return;
            }
            return;
        }
        onCloseTreatmentEditPopup();
        setAnchorElAssign(null);
    };

    const handleCloseAddTreatment = (e, popup1Ref) => {
        if (popup1Ref && popup1Ref.current) {
            if (!popup1Ref.current?.contains(e.target)) {
                onCloseAddTreatment();
                setAnchorElAssign(null);
                return;
            }
            return;
        }
        onCloseAddTreatment();
        setAnchorElAssign(null);
    };

    const handleCloseAssignPopup = (isReload, data) => {
        onCloseAssignTaskPopup(isReload, data);
        setAnchorElAssign(null);
    };
    const handleCloseAppointmentAssignPopup = (isReload, data) => {
        onCloseAppointmentAssignTaskPopup(isReload, data);
        setAnchorElAssign(null);
    };

    const onClickAwayEvent = () => {
        const selectMenu = document.querySelector('.MuiModal-root');
        if (selectMenu) {
            return;
        }
        onCloseAssignTask();
        setAnchorElAssign(null);
        dispatch(clearPracticeUserData());
    };

    const appointmentClickAwayEvent = () => {
        const selectMenu = document.querySelector('.MuiModal-root');
        if (selectMenu) {
            return;
        }
        onCloseAppointmentAssignTaskPopup();
        setAnchorElAssign(null);
        dispatch(clearPracticeUserData());
    };

    const handleDepositPaymentOnclose = (leadId) => {
        onCloseDepositPaymentPopup();
        paymentDeleteToggle.onClose();
        if (leadId) {
            dispatch(followUpSlice(leadId, practiceId, 'LeadDetail', (data) => handleSetTableData(data)));
        }
    };

    const onCloseClickCheckPopup = (data) => {
        onCloseClinCheckStatus(data);
        setAnchorClinCheck(null);
    };

    const handleNoTreatmentSwitch = (val) => {
        setIsNoTreatment(val);
        if (val) {
            dispatch(updateCounter([]));
            const payloadData = {
                limit: 10,
                offset: 0,
                filterStatus,
                order: tableOrder,
                orderId: tableOrderBy,
                searchQuery: searchValue,
                treatment: '',
                startDate,
                endDate,
                contactedCount: contactCount,
                tags: searchTags,
                referral,
                depositPendingStatus,
                category,
                bookedWith: bookedWithData?.toString(),
                assigneeIds: assigneeData?.toString(),
                archiveReason,
                noTreatment: true
            };
            handleStartedTablePayload(payloadData);
            updateLeadPage(payloadData);
            if (tableData && practiceId && reloadCounter) {
                reloadCounter(payloadData);
            }
            setPage(0);
            setRowsPerPage(10);
            setPaginationModel({ pageSize: 10, page: 0 });
            setValue('multiSelect', []);
            UpdateTableData(type, { treatment: [], perPage: 10, pageNumber: 0 });
        } else {
            dispatch(updateCounter([]));
            const payloadData = {
                limit: 10,
                offset: 0,
                filterStatus,
                order: tableOrder,
                orderId: tableOrderBy,
                searchQuery: searchValue,
                treatment: treatmentData?.toString(),
                startDate,
                endDate,
                contactedCount: contactCount,
                tags: searchTags,
                referral,
                depositPendingStatus,
                category,
                bookedWith: bookedWithData?.toString(),
                assigneeIds: assigneeData?.toString(),
                archiveReason,
                noTreatment: false
            };
            handleStartedTablePayload(payloadData);
            updateLeadPage(payloadData);
            if (tableData && practiceId && reloadCounter) {
                reloadCounter(payloadData);
            }
            setPage(0);
            setRowsPerPage(10);
            setPaginationModel({ pageSize: 10, page: 0 });
        }
    }


    return (
        <>
            <Helmet>
                <title> {`${noDashType ? tableTitle : displayForCategorySummary ? 'Category Summary' : 'Dashboard'}`} | Dental SEM CRM</title>
            </Helmet>
            {reportData && (
                <Box sx={{ textAlign: 'right' }}>
                    <Button variant='outlined' onClick={handleClearReportTable}>
                        Cancel
                    </Button>
                </Box>
            )}
            <Box sx={{ mb: reportData ? 0 : 5 }}>
                {allType && !isNoTaskLeadsTable && !displayForCategorySummary && < DashBoardReportsWidget
                    dateRangeProps={{ date, dateChange, setDate, startDate, endDate }}
                    activeWidgetProps={{ currentWidget, setCurrentWidget }}
                />}
                {newType && !displayForCategorySummary && <NewLeadTableReportsWidget
                    counter={counter}
                    onWidgetClick={onWidgetClick}
                    currentSelectedTab={notAllType ? contactCount : filterStatus}
                    dateRangeProps={{ date, dateChange, setDate, startDate, endDate }}
                />}
                {consultantBookedType && !displayForCategorySummary && <BookedTableReportsWidget
                    onWidgetClick={onWidgetClick}
                    dateRangeProps={{ date, dateChange, setDate, startDate, endDate }}
                />}
                {failedToAttendType && !displayForCategorySummary && <FTATableReportsWidget
                    onWidgetClick={onWidgetClick}
                    dateRangeProps={{ date, dateChange, setDate, startDate, endDate }}
                />}
                {treatmentStartedType && selectedTab !== 'invisalignAdditional' && !displayForCategorySummary &&
                    <StartedTableReportsWidget
                        onWidgetClick={onWidgetClick}
                        dateRangeProps={{ date, dateChange, setDate, startDate, endDate }}
                        tableTitle={tableTitle}
                    />}
                {consultantAttendedType && !displayForCategorySummary && <AttendedTableReportsWidget
                    onWidgetClick={onWidgetClick}
                    dateRangeProps={{ date, dateChange, setDate, startDate, endDate }}
                />}
                {(consultantClinCheckAttendedType) && !displayForCategorySummary && <Box sx={{ mb: 2 }}>
                    <MultiDatePicker
                        value={date}
                        endDate={new Date()}
                        dateChange={dateChange}
                        setValue={setDate}
                        startDateValue={startDate}
                        endDateValue={endDate}
                        inputFieldType='button'
                    />
                </Box>}
                <Card sx={{ bgcolor: grey[200], padding: '0', ...isDisabled() }}>
                    {isReportData && !displayForCategorySummary && commonType && notClinCheckType && tableTitle !== 'Aligner Approve Leads' && selectedTab !== 'invisalignAdditional' && (
                        <Tabs
                            allowScrollButtonsMobile
                            variant='scrollable'
                            scrollButtons='auto'
                            value={notAllType ? contactCount : filterStatus}
                            onChange={handleChangeStatus}
                            sx={tabsStyle}
                        >
                            {headings.map((item) => (
                                <Tab
                                    sx={{
                                        ...tabStyle(
                                            type,
                                            item,
                                            item?.value === 'AWAITING_RESPONSE'
                                                ? noAwatingCount && noAwatingCount[0]?.count
                                                : item?.value === 'NHS'
                                                    ? nhsCount && nhsCount[0]?.count
                                                    : noTreatmentCounter && noTreatmentCounter[0]?.count
                                        ),
                                    }}
                                    disableRipple
                                    key={treatmentStartedType ? item?.id : item.value}
                                    label={labelCount(item)}
                                    value={treatmentStartedType ? item?.id : item.value}
                                />
                            ))}
                        </Tabs>
                    )}
                    {isReportData && selectedTab !== 'invisalignAdditional' && !displayForCategorySummary && (
                        <Box sx={{ position: multiLead?.leadId?.length > 0 ? 'relative' : 'unset' }}>
                            <Stack
                                className='filter-toggle-stack'
                                sx={stackStyle}
                                alignItems='left'
                                direction={{
                                    xs: 'column',
                                    md: 'row',
                                }}
                            >
                                {
                                    <Box sx={treatmentTagFilterStyle}>
                                        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                            <Box sx={filterFormBoxStyle}>
                                                {tableTitle !== 'Aligner Approve Leads' && <MultiTreatmentDropdown
                                                    name={'multiSelect'}
                                                    label='Select treatments'
                                                    sx={treatmentBoxStyle}
                                                    onChange={handleTreatment}
                                                    value={treatmentValue}
                                                    type={type}
                                                />}
                                                <Box sx={{ flex: '1', minWidth: '200px' }}>
                                                    <TextField
                                                        fullWidth
                                                        onChange={(event) => handleSearch(event.target.value)}
                                                        value={search}
                                                        sx={textFieldStyle}
                                                        placeholder='Search lead name, email, number ...'
                                                        InputProps={{
                                                            startAdornment: (
                                                                <InputAdornment position='start'>
                                                                    <Iconify
                                                                        icon={'eva:search-fill'}
                                                                        sx={{ color: 'text.disabled', width: 20, height: 20 }}
                                                                    />
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                </Box>
                                                {(type === 'ConsultantAttend' || type === 'ConsultantClinCheckAttend' || type === 'ConsultantBooked') && (
                                                    <Box sx={depositPendingBoxStyle}>
                                                        <Box sx={{ minWidth: 150 }}>
                                                            <FormControl fullWidth>
                                                                <InputLabel id='demo-simple-select-label'>Deposit Paid Filter</InputLabel>
                                                                <Select
                                                                    labelId='demo-simple-select-label'
                                                                    id='demo-simple-select'
                                                                    value={depositPendingStatus}
                                                                    label='Deposit Paid Filter'
                                                                    onChange={handleDepositPendingFilter}
                                                                >
                                                                    <MenuItem value={'YES'}>Yes</MenuItem>
                                                                    <MenuItem value={'NO'}>No</MenuItem>
                                                                </Select>
                                                            </FormControl>
                                                        </Box>
                                                    </Box>
                                                )}
                                                <Box sx={{ flex: '1', ml: '16px', minWidth: '200px' }}>
                                                    <SearchAutocompleteTags
                                                        updateLeadPage={updateLeadPage}
                                                        tableType={type}
                                                        reloadCounter={reloadCounter}
                                                        handlePage={handlePage}
                                                        handleStartedTablePayload={handleStartedTablePayload}
                                                    />
                                                </Box>
                                                <Box sx={referralOutBoxStyle}>
                                                    <ReferralDropdown
                                                        name={'referral'}
                                                        value={referralValue()[0]?.label || ''}
                                                        onChange={handleReferral}
                                                        sx={{ ...referralBoxStyle }}
                                                        type={'search'}
                                                        affiliateUser={rowLead.current?.lead_source?.affiliate_user}
                                                    />
                                                </Box>
                                                <MultiAssigneesDropdown
                                                    name={'multiSelectAssignee'}
                                                    label='Assignees'
                                                    sx={treatmentBoxStyle}
                                                    onChange={handleAssigneeFilter}
                                                    value={assigneeSelectedForFilter}
                                                    type={type}
                                                />

                                                {type === "ArchiveTable" && (
                                                    <Box sx={referralOutBoxStyle}>
                                                        <SelectArchiveReasons
                                                            name={'archive'}
                                                            value={archiveReasonValue()[0]?.label || ''}
                                                            onChange={handleArchiveFilter}
                                                            sx={{ ...referralBoxStyle }}
                                                            type={'search'}
                                                            archiveReasonData={archiveReasonData}
                                                        />
                                                    </Box>
                                                )}

                                                {bookedWithFilter && (
                                                    <Box sx={referralOutBoxStyle}>
                                                        <MultiAssigneesDropdown
                                                            name={'bookedWith'}
                                                            label={treatmentStartedType && tableTitle !== 'Aligner Approve Leads' ? 'Treatment booked with' : 'Booked with'}
                                                            onChange={(e, value) => handlePractitioner(e, value)}
                                                            value={bookedWith}
                                                            type={type}
                                                        />
                                                    </Box>
                                                )}

                                                {treatmentStartedType && type !== "treatmentStartedWithInvisalign" && (
                                                    <Box sx={{ ...referralOutBoxStyle, minWidth: '220px' }}>
                                                        <MultiAssigneesDropdown
                                                            name={'consultationBookedWith'}
                                                            label='Consultation booked with'
                                                            onChange={(e, value) => handleConsultationBookedWith(e, value)}
                                                            value={consultationBookedWith}
                                                            type={type}
                                                        />
                                                    </Box>
                                                )}

                                                {(archType || closedType || isNoTaskLeadsTable) && <Box sx={dateRangeBoxStyle}>
                                                    <Box sx={startDateBoxStyle}>
                                                        <MultiDatePicker
                                                            value={date}
                                                            endDate={new Date()}
                                                            dateChange={dateChange}
                                                            setValue={setDate}
                                                            startDateValue={startDate}
                                                            endDateValue={endDate}
                                                        />
                                                    </Box>
                                                </Box>}
                                            </Box>
                                        </FormProvider>
                                    </Box>
                                }
                            </Stack>
                            {multiLead?.leadId?.length > 0 ? (
                                ''
                            ) : (
                                <Stack
                                    className='filter-toggle-stack'
                                    sx={searchFilterContent}
                                    alignItems='left'
                                    direction={{
                                        xs: 'column',
                                        md: 'row',
                                    }}
                                >
                                    <TableFilterResult
                                        filters={filters}
                                        onResetFilters={onResetFilter}
                                        onReset={
                                            searchValue ||
                                            treatmentValue?.length > 0 ||
                                            startDate ||
                                            endDate ||
                                            referral?.length > 0 ||
                                            bookedWith?.length > 0 ||
                                            depositPendingStatus ||
                                            consultationBookedWith?.length > 0 ||
                                            assigneeSelectedForFilter?.length > 0 ||
                                            archiveReason?.length > 0
                                        }
                                        isTreatmentStarted={treatmentStartedType && type !== "treatmentStartedWithInvisalign"}
                                        handleTreatment={handleTreatment}
                                        handleReferral={handleReferral}
                                        dateChange={dateChange}
                                        handleTags={handleTags}
                                        handlePractitioner={handlePractitioner}
                                        handleDepositPendingFilter={handleDepositPendingFilter}
                                        handleConsultationBookedWith={handleConsultationBookedWith}
                                        tableTitle={tableTitle}
                                        handleAssigneeFilter={handleAssigneeFilter}
                                        handleArchiveFilter={handleArchiveFilter}
                                        handleNoTreatmentSwitch={handleNoTreatmentSwitch}
                                        isNoTreatment={isNoTreatment}
                                    />
                                </Stack>
                            )}
                        </Box>
                    )}
                    <Box sx={{ borderTop: 'solid 1px rgba(145, 158, 171, 0.16)' }}>
                        {tableTitle === 'Aligner Approve Leads' ?
                            <AlignerApproveLeadDataGrid
                                tableTitle={tableTitle}
                                tableData={tableData}
                                typed={type}
                                isReportData={isReportData}
                                selectedRow={onSelectedRow}
                                paginationModel={paginationModel}
                                setPaginationModel={setPaginationModel}
                                handlePaginationChange={handlePaginationChange}
                                pageCount={pageCount}
                                handleChangePerSize={(event) => handleChangePerPage(event)}
                                OpenAssignTask={OpenAssignTask}
                                reloadCounter={reloadCounter}
                                updateLeadPage={updateLeadPage}
                                openBulkArchive={open}
                                onSelectionModelChange={onSelectionModelChange}
                                isNotFound={isNotFound}
                                onOpenOpenTreatmentPlan={onOpenOpenTreatmentPlan}
                                forceUpdate={forceUpdate}
                                tablePath={currentPagePath}
                                sortingApiCall={sortingApiCall}
                                defaultHideColumn={defaultHideColumn}
                                columnVisibilityModel={columnVisibilityModel}
                                handleColumnVisibilityChange={handleColumnVisibilityChange}
                                handleColumnOrderChange={handleColumnOrderChange}
                                openAppointmentAssignTaskFuc={openAppointmentAssignTaskFuc}
                                handleReloadAPI={handleReloadAPI}
                                setTableData={setTableData}
                                handleSetTableData={handleSetTableData}
                                setCurrentTreatmentData={setCurrentTreatmentData}
                                setColumnVisibilityModel={setColumnVisibilityModel}
                                selectedTab={selectedTab}
                            /> :
                            <NewLeadDataGrid
                                tableTitle={tableTitle}
                                tableData={(displayForCategorySummary && leadCategoryType !== filterStatus) ? [] : tableData}
                                typed={type}
                                forceLoading={displayForCategorySummary && leadCategoryType !== filterStatus}
                                isReportData={isReportData}
                                selectedRow={onSelectedRow}
                                paginationModel={paginationModel}
                                setPaginationModel={setPaginationModel}
                                handlePaginationChange={handlePaginationChange}
                                pageCount={pageCount}
                                handleChangePerSize={(event) => handleChangePerPage(event)}
                                OpenAssignTask={OpenAssignTask}
                                reloadCounter={reloadCounter}
                                updateLeadPage={updateLeadPage}
                                openBulkArchive={open}
                                onSelectionModelChange={onSelectionModelChange}
                                isNotFound={isNotFound}
                                onOpenOpenTreatmentPlan={onOpenOpenTreatmentPlan}
                                forceUpdate={forceUpdate}
                                tablePath={currentPagePath}
                                sortingApiCall={sortingApiCall}
                                defaultHideColumn={defaultHideColumn}
                                columnVisibilityModel={columnVisibilityModel}
                                handleColumnVisibilityChange={handleColumnVisibilityChange}
                                handleColumnOrderChange={handleColumnOrderChange}
                                openAppointmentAssignTaskFuc={openAppointmentAssignTaskFuc}
                                handleReloadAPI={handleReloadAPI}
                                setTableData={setTableData}
                                handleSetTableData={handleSetTableData}
                                setCurrentTreatmentData={setCurrentTreatmentData}
                                setColumnVisibilityModel={setColumnVisibilityModel}
                                pinProps={pinProps}
                            />
                        }

                    </Box>
                </Card>
                <CustomConfirmationDialog
                    toggle={paymentDeleteToggle.toggle}
                    handleClose={() => paymentDeleteToggle.onClose()}
                    handleSubmit={() => handleDeletePaymentMethods(selectedRow, handleDepositPaymentOnclose)}
                    message={'Are you sure you want to delete this payment?'}
                />
                {openContacts && <PracticeEditDialog open={openContacts} onClose={onCloseContacts} />}
                {openFollowLead && (
                    <OpenDialogPopup
                        open={openFollowLead}
                        headerButtons
                        onClose={handleClose}
                        openArchivePopup={onOpenArchiveLead}
                        user={selectedRow}
                        steps={leadStep}
                        type={'followup'}
                        validStep={validStep}
                        BookingAttendance={BookingAttendance}
                        updateStep={() => setValidStep(!validStep)}
                        inputRef={inputRef}
                        active={active}
                        CreateLeadHeaderData={CreateLeadHeaderData}
                        setActive={setActive}
                        ContactedCount={
                            selectedRow &&
                            selectedRow?.lead_treatments &&
                            selectedRow?.lead_treatments[0]?.lead_contacted_counts?.length > 0 &&
                            selectedRow?.lead_treatments[0]?.lead_contacted_counts[0]?.count
                        }
                        timeLineCount={activityList?.results?.length}
                    />
                )}
                {/* {New flow popup} */}
                {openContactLead && (
                    <OpenContactLeadPopup
                        open={openContactLead}
                        headerButtons
                        onClose={handleClose}
                        openArchivePopup={onOpenArchiveLead}
                        user={selectedRow}
                        steps={leadStep}
                        type={'followup'}
                        validStep={validStep}
                        BookingAttendance={BookingAttendance}
                        updateStep={() => setValidStep(!validStep)}
                        inputRef={inputRef}
                        active={active}
                        CreateLeadHeaderData={CreateLeadHeaderData}
                        setActive={setActive}
                        handleReloadLeads={handleReloadLeads}
                        ContactedCount={
                            selectedRow &&
                            selectedRow?.treatmentData &&
                            selectedRow?.treatmentData[0]?.lead_contacted_counts?.length > 0 &&
                            selectedRow?.treatmentData[0]?.lead_contacted_counts[0]?.count
                        }
                        timeLineCount={activityList?.results?.length}
                        showWhatsapp={openContactWhatsapp?.open ? {
                            windowDimension: { width, height },
                            chatScreenData: {
                                name: `${selectedRow?.first_name} ${selectedRow?.last_name}`,
                                phoneNumber: selectedRow?.phone ?? '',
                                leadId: selectedRow?.id
                            },
                            setChatScreenData: () => { },
                            triggeredFromGrid: true
                        } : null}
                    />
                )}
                {openConsultationAttend && (
                    <OpenConsultationPopup
                        open={openConsultationAttend}
                        headerButtons
                        onClose={() => onCloseConsultationAttend()}
                        handleCloseAndReload={handleClose}
                        handleReloadLeads={handleReloadLeads}
                        user={selectedRow}
                        type={'consultationAttended'}
                        inputRef={inputRef}
                        CreateLeadHeaderData={CreateLeadHeaderData}
                        startTreatment={startTreatment}
                        OpenAssignTask={openAppointmentAssignTaskFuc}
                        editDetailHandler={editDetailHandler}
                        selectedTreatmentData={currentTreatmentData}
                    />
                )}
                {openRecordAttend && (
                    <OpenAttendedPopup
                        open={openRecordAttend}
                        headerButtons
                        onClose={() => onCloseRecordAttend()}
                        handleCloseAndReload={handleClose}
                        user={selectedRow}
                        type={'recordAttended'}
                        inputRef={inputRef}
                        CreateLeadHeaderData={CreateLeadHeaderData}
                        OpenAssignTask={openAppointmentAssignTaskFuc}
                        editDetailHandler={editDetailHandler}
                        handleReloadLeads={handleReloadLeads}
                        isOverview
                    />
                )}
                {openAttendedNote && (
                    <AttendedNotePopup
                        open={openAttendedNote}
                        headerButtons
                        onClose={() => onCloseAttendedNote()}
                        handleCloseAndReload={handleClose}
                        user={selectedRow}
                        type={'backtoAttended'}
                        inputRef={inputRef}
                        CreateLeadHeaderData={CreateLeadHeaderData}
                    />
                )}
                {openUnRecordAttend && (
                    <OpenUnAttendedPopup
                        open={openUnRecordAttend}
                        headerButtons
                        onClose={() => onCloseUnRecordAttend()}
                        handleCloseAndReload={handleClose}
                        user={selectedRow}
                        type={'recordUnAttended'}
                        tableType={type}
                        inputRef={inputRef}
                        CreateLeadHeaderData={CreateLeadHeaderData}
                    />
                )}
                {openArchiveLead && (
                    <ArchivedLeadPopUp
                        open={openArchiveLead}
                        headerButtons
                        onClose={handleArchivedClose}
                        user={selectedRow}
                        type={'archiveLead'}
                        inputRef={inputRef}
                        CreateLeadHeaderData={CreateLeadHeaderData}
                        setForceUpdate={setForceUpdate}
                    />
                )}
                {openRestoreArchived && (
                    <OpenRestoreArchived
                        open={openRestoreArchived}
                        headerButtons
                        onClose={() => onCloseRestoreArchived()}
                        user={selectedRow}
                        type={'RestoreArchived'}
                        inputRef={inputRef}
                        CreateLeadHeaderData={CreateLeadHeaderData}
                    />
                )}
                {openAddTreatment && (
                    <TreatmentEditPopover
                        handleReloadAPI={handleCloseTreatment}
                        open={openAddTreatment}
                        anchorEl={anchorEditTreatment}
                        handleClickAwayLister={handleCloseAddTreatment}
                        leadId={tableRowLead?.lead_id}
                        isAddTreatment
                    />
                )}
                {openTreatmentEditPopup && (
                    <TreatmentEditPopover
                        handleReloadAPI={handleReloadAPI}
                        treatmentData={selectedRow?.treatmentData}
                        treatment={selectedRow?.treatment_name || ''}
                        leadTreatmentSpecification={selectedRow?.leadTreatmentSpecification || null}
                        open={openTreatmentEditPopup}
                        anchorEl={anchorEditTreatment}
                        handleClickAwayLister={handleClickAwayLister}
                        treatmentId={selectedRow?.treatment_id}
                        treatmentPrice={selectedRow?.price}
                        treatmentCategory={selectedRow?.treatmentCategory}
                        leadId={selectedRow?.lead_id}
                    />
                )}
                {openDepositPaymentPopup && (
                    <DepositPaymentPopover
                        rowData={selectedRow}
                        open={openDepositPaymentPopup}
                        anchorEl={anchorDepositPayment}
                        handleClickAwayLister={handleDepositPaymentOnclose}
                    />
                )}
                {openAssignTask && (
                    <ClickAwayListener onClickAway={onClickAwayEvent}>
                        <Popper open={openAssignTask} anchorEl={anchorElAssign} transition placement='bottom' sx={zIndexStyle}>
                            {({ TransitionProps }) => (
                                <Grow {...TransitionProps}>
                                    <Paper sx={borderRadiosStyle}>
                                        <AssignTaskPopup
                                            open={openAssignTask}
                                            onClose={handleCloseAssignPopup}
                                            user={selectedRow}
                                            leadId={selectedRowId}
                                            type={'leadAssign'}
                                            assignees={tableData?.find((ele) => ele.id === selectedRowId)?.assignees?.filter(
                                                (el) => el?.user?.first_name && !el?.user?.is_superuser)?.map((ele) => ele.id)}
                                        />
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </ClickAwayListener>
                )}
                {openClinCheckStatus && (
                    <ClickAwayListener onClickAway={() => { onCloseClinCheckStatus(); setAnchorClinCheck(null); }}>
                        <Popper open={openClinCheckStatus} anchorEl={anchorClinCheck} transition placement='bottom' sx={zIndexStyle}>
                            {({ TransitionProps }) => (
                                <Grow {...TransitionProps}>
                                    <Paper>
                                        <ClinCheckStatusPopup
                                            open={openClinCheckStatus}
                                            onClose={onCloseClickCheckPopup}
                                            handleClose={handleClose}
                                            leadDetail={selectedRow}
                                            leadId={selectedRowId}
                                            type={'leadAssign'}
                                        />
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </ClickAwayListener>
                )}
                {openTreatmentPlan && (
                    <TreatmentPlanDatePicker
                        open={openTreatmentPlan}
                        onClose={() => onCloseTreatmentPlanDatePicker()}
                        user={selectedRow}
                        leadId={selectedRowId}
                        type={'leadAssign'}
                    />
                )}
                {openAppointmentAssignTask && (
                    <ClickAwayListener onClickAway={() => appointmentClickAwayEvent()}>
                        <Popper open={openAppointmentAssignTask} anchorEl={anchorElAssign} transition placement='bottom' sx={zIndexStyle}>
                            {({ TransitionProps }) => (
                                <Grow {...TransitionProps}>
                                    <Paper sx={borderRadiosStyle}>
                                        <AppointmentAssignTaskPopup
                                            open={openAppointmentAssignTask}
                                            onClose={handleCloseAppointmentAssignPopup}
                                            user={selectedRow}
                                            leadId={selectedRowId}
                                            type={'leadAssign'}
                                            assignees={assignees}
                                            appoinmentSelectedId={appoinmentSelectedId}
                                            selectedTreatmentData={currentTreatmentData}
                                        />
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </ClickAwayListener>
                )}
                {OpenReSchedule && (
                    <OpenEditAppointmentPopup
                        open={OpenReSchedule}
                        headerButtons
                        onClose={handleReScheduleClose}
                        inputRef={inputRef}
                        appoinmentSelectData={appoinmentSelectData}
                    />
                )}
                {leadDetailModal && (
                    <EditPersonalDetailsPopup
                        open={leadDetailModal}
                        handleClose={closeEditDetails}
                        selectedRow={selectedRow}
                        type={'editLead'}
                        inputRef={personalDetailPopupRef}
                        title='Edit Details'
                        headerButtons
                    />
                )}
                {openAppointment && <OpenAppointmentPopup
                    open={openAppointment}
                    headerButtons
                    onClose={handleAppointmentClose}
                    treatmentData={selectedRow?.treatmentData[0]}
                    inputRef={inputRef}
                    type={'leadTable'}
                />}
                {open.value && <ArchivedOrRestorePopup open={open.value} onClose={open.onFalse} handleClose={handleClose} />}
                <CustomCompleteTreatmentDialog handleClose={() => setConfirmation(false)} handleYes={() => completeTreatmentAPI()} isLoader={isLoader} open={confirmation} />
                {startConfirmation && <StartTreatmentPopup
                    isOpen={startConfirmation}
                    handleCloseStartTreatment={handleCloseStartTreatment}
                    isPaymentMandatory={isPaymentMandatory}
                    depositStatus={depositStatus}
                    handleDepositPendingChange={handleDepositPendingChange}
                    startTreatmentAPI={startTreatmentAPI}
                />}
                <Dialog open={toggleDuplicatePopup} onClose={() => closeDuplicatePopup()}>
                    <DialogTitle sx={dialogTitleStyle}>
                        Are you sure you want to mark as duplicate?
                    </DialogTitle>
                    <DialogActions sx={dialogActionStyle}>
                        <Button sx={cancelBtnStyle} onClick={() => closeDuplicatePopup()}>
                            Cancel
                        </Button>
                        <Button type='submit' sx={yesBtnStyle} onClick={() => handleMarkAsDuplicateAPI()}>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={toggleUnMarkAsDuplicate} onClose={() => closeUnMarkAsDuplicate()}>
                    <DialogTitle sx={dialogTitleStyle}>
                        Are you sure you want to un mark as duplicate?
                    </DialogTitle>
                    <DialogActions sx={dialogActionStyle}>
                        <Button sx={cancelBtnStyle} onClick={() => closeUnMarkAsDuplicate()}>
                            Cancel
                        </Button>
                        <Button type='submit' sx={yesBtnStyle} onClick={() => handleUnMarkAsDuplicateAPI()}>
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </>
    );
};

function applySortFilter({ tableData }) {
    const stabilizedThis = tableData.map((el, index) => [el, index]);
    tableData = stabilizedThis.map((el) => el[0]);

    return tableData;
}

export default NewLead;

NewLead.propTypes = {
    leadData: PropTypes.array,
    updateLeadPage: PropTypes.func,
    pageCount: PropTypes.number,
    type: PropTypes.string,
    setUpdateList: PropTypes.func,
    tableTitle: PropTypes.string,
    counter: PropTypes.array,
    reloadCounter: PropTypes.func,
    reloadTaskCounter: PropTypes.func,
    onClose: PropTypes.func,
    rowStatus: PropTypes.string,
    clinCheck: PropTypes.string,
    defaultHideColumn: PropTypes.object,
    columnVisibilityModel: PropTypes.object,
    handleColumnVisibilityChange: PropTypes.func,
    handleColumnOrderChange: PropTypes.func,
    shouldRunEffect: PropTypes.object,
    dashBoardDateProps: PropTypes.object,
    isNoTaskLeadsTable: PropTypes.bool,
    selectedTab: PropTypes.string,
    setColumnVisibilityModel: PropTypes.func,
    pinProps: PropTypes.object,
    displayForCategorySummary: PropTypes.bool.isRequired,
    leadCategoryType: PropTypes.string.isRequired,
    forceReload: PropTypes.bool,
    archiveReasonData: PropTypes.array
};