export const formControlStyle = {
    px: 3,
    py: 1.5,
    top: 0,
    position: {
        md: 'absolute'
    }
};
export const tableContainerStyle = {
    minWidth: 1100,
    position: 'relative',
    overflowX: 'auto'
};
export const tabStyle = {
    minWidth: {
        xs: 'auto',
        sm: 'auto'
    }
};