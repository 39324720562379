import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
// libraries
import { Box, Card } from '@mui/material';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// components
import TreatmentPlan from '../../sections/@dashboard/leadDetail/TreatmentPlan';
import SummaryContent from './SummaryContent';
import { TaskReminder } from '../../sections/@dashboard/leadDetail';
import { NormalLoading } from '../loading';
import { OpenScheduleReminderPopup, OpenAppointmentPopup } from '../fullScreenPopUps';
import TreatmentEditPopover from '../../pages/dashboard/tables/AllLeadTable/TreatmentEditPopover';
import UpcomingAppointments from './UpcomingAppointments';
import PastAppointments from './PastAppointments';
import StartTreatmentPopup from '../dialogPopUp/StartTreatmentPopup';
import TreatmentDepositPayment from '../../sections/@dashboard/leadDetail/TreatmentDepositPayment';
import CustomCompleteTreatmentDialog from '../../pages/dashboard/tables/AllLeadTable/CustomCompleteTreatmentDialog';
import TreatmentMoreMenu from './TreatmentMoreMenu';
import TreatmentSteppers from './TreatmentSteppers';
import ConfirmationDialog from './CommonDialog';
import TreatmentTabs from './TreatmentTabs';
import TreatmentHeader from './TreatmentHeader';
// redux
import { useSelector, dispatch } from '../../redux/store';
import { getActivities } from '../../redux/slices/activity';
import { getLeadTreatmentsList, setCurrentTreatmentDataGlobal } from '../../redux/slices/multiTreatment';
import { deleteTreatment, tableLead, followUpSlice, getLeadReminderData, updateLeadTaskCounter, consultationAttended, updateTransitionTime, clearLead } from '../../redux/slices/lead';
import { getTreatmentPayment, setDepositLoading } from '../../redux/slices/deposit';
import { setLoader } from '../../redux/slices/loader';
// hooks/utils
import useIsSuperAdmin from '../../hooks/useIsSuperAdmin';
import { useTabs, useAddTreatment, useToggle, useAppointmentToggle, useBoolean } from '../../hooks';
import { attendedTreatmentAPI, completeTreatmentAPI, failedAttendTreatmentAPI, handleAction, handleDepositPendingChange, handleMessage, handleSuccessReload, slotProps, startTreatmentAPI } from '../../utils/treatmentAccordionUtils';
// style
import { treatmentTabCardStyle } from '../../styles/treatmentTabStyle';
import { datePickerBoxStyle } from '../../styles/NewLeadTableRowStyle';
import { treatmentCancel, treatmentConfirmCancel } from '../../styles/LeadDetailView';

const TreatmentAccordion = ({ data, leadDetail, index, leadAppointment, reminder, reloadTaskCounter, counter, handleLoading, loading, selectTreatmentData }) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const inputRef = useRef();
    const [confirmation, setConfirmation] = useState(false);
    const [startConfirmation, setStartConfirmation] = useState(false);
    const [backtoAttendConfirmation, setBacktoAttendConfirmation] = useState(false);
    const { practice } = useSelector((state) => state.practice);
    const isPaymentMandatory = practice?.preferences?.is_payment_enforced_before_treatment;
    const [depositPaidStatus, setDepositPaidStatus] = useState(false);
    const { currentTab, onChangeTab } = useTabs('Summary');
    const [treatmentData, setTreatmentData] = useState('');
    const [appointment, setAppointment] = useState(0);
    const [pastAppointment, setPastAppointment] = useState(0);
    const [open, toggleOpen] = useState(false);
    const [openMoreMenu, setOpenMoreMenu] = useState(false);
    const [selectTreatment, setSelectTreatment] = useState('');
    const [leadSpecification, setLeadSpecification] = useState(null);
    const [selectedTreatmentCategory, setSelectedTreatmentCategory] = useState('');
    const [selectTreatmentPrice, setSelectTreatmentPrice] = useState('');
    const [selectTreatmentId, setSelectTreatmentId] = useState('');
    const treatmentStatus = leadDetail?.lead_treatments?.[0]?.status;
    const [attendedConfirmation, setAttendedConfirmation] = useState(false);
    const [failedAttendConfirmation, setFailedAttendConfirmation] = useState(false);
    const [isAccordionExpanded, setAccordionExpanded] = useState(false);
    const [anchorEditTreatment, setAnchorEditTreatment] = useState(null);
    const [anchorMoreMenu, setAnchorMoreMenu] = useState(null);
    const accordionRef = useRef(null);
    const [selectedDate, setSelectedDate] = useState(moment(new Date(), 'DD/MM/YYYY hh:mm:ss').format('ddd[,] D MMMM [at] hh:mm A'));
    const openCalendar = useBoolean();
    const { tableRowLead } = useSelector((state) => state.lead);
    const { isLoader } = useSelector((state) => state.loaderReducer);
    const leadId = data?.lead;
    const treatmentId = data?.id;

    const { isSuperAdminUser } = useIsSuperAdmin();
    const shouldRenderTreatmentPlan = () => isSuperAdminUser() && treatmentStatus === 'CONSULTATION_ATTENDED';

    useEffect(() => {
        if (leadDetail) {
            setTreatmentData(data);
        }
    }, [leadDetail]);

    useEffect(() => {
        if (practiceId && leadId && treatmentId) {
            dispatch(setDepositLoading(true));
            dispatch(getTreatmentPayment({ leadId, treatmentId }));
        }
    }, [leadId, practiceId, treatmentId]);

    const { toggle: EditTreatment, onOpen: onEditTreatment, onClose: onCloseEditTreatment } = useAddTreatment();

    const { toggle: openScheduleReminder, onOpen: onOpenScheduleReminder, onClose: onCloseScheduleReminder } = useToggle();

    const handleUpdate = (data) => {
        if (data === 'finish') {
            setStartConfirmation(false);
            setConfirmation(false);
            setBacktoAttendConfirmation(false);
        }
        dispatch(setLoader(false));
        dispatch(updateLeadTaskCounter([]));
        dispatch(getLeadReminderData(practiceId, 'LeadDetail', 10, 0, '', leadDetail?.id, ''));
        dispatch(getLeadReminderData(practiceId, 'LeadDetail', 1, 0, '', leadDetail?.id, '', '', 'Graterthan'));
        dispatch(getActivities(practiceId, leadDetail.id, 10, 0));
        reloadTaskCounter({ userId: '', id: leadDetail.id, additionalFilter: '', CompletedTask: false });
        dispatch(followUpSlice(leadDetail.id, practiceId, 'LeadDetail'));
        dispatch(getLeadTreatmentsList({ leadId: leadDetail.id }));
    };

    const handleDateChange = (date) => {
        setSelectedDate(date);
        const detail = {
            timestamp: `${moment(date).format('DD-MM-YYYY HH:mm:ss')}.0Z`,
            lead: treatmentData?.lead,
            id: treatmentData?.id,
            state: treatmentData.status
        };
        dispatch(updateTransitionTime(detail, practiceId, handleSuccess));
    };

    const handleDate = () => openCalendar.onTrue();

    const TabsContent = [
        {
            value: 'Summary',
            component: <SummaryContent leadDetail={leadDetail} treatmentData={treatmentData} handleDate={handleDate} />,
        },
        {
            value: 'Appointments',
            component: <UpcomingAppointments leadDetail={leadDetail} treatmentData={treatmentData} leadAppointment={appointment} handleUpdate={handleUpdate} />,
        },
        {
            value: 'Attended appointments',
            component: <PastAppointments leadDetail={leadDetail} treatmentData={treatmentData} pastAppointment={pastAppointment} />,
        },
        {
            value: 'Tasks',
            component: <TaskReminder type='LeadDetail' reminder={reminder} leadDetail={leadDetail} leadDetailTab={currentTab} reloadTaskCounter={reloadTaskCounter} counter={counter} handleLoading={handleLoading} reset />,
        },
        {
            value: 'Treatment plan',
            component: <TreatmentPlan />,
        },
        {
            value: 'Payment',
            component: <TreatmentDepositPayment treatmentId={data?.id} leadId={data?.lead} />
        }
    ];

    const TABS = shouldRenderTreatmentPlan() ? TabsContent : TabsContent?.filter((ele) => ele.value !== 'Treatment plan');

    useEffect(() => {
        if (leadAppointment) {
            const treatmentFilter = leadAppointment.filter((el) => el.lead_treatment === data.id);
            const UpcomingAppointment = treatmentFilter.filter((el) => el.status !== 'ATTENDED');
            const UpcomingAppointmentSorting = UpcomingAppointment.sort((a, b) => new Date(moment(a.datetime, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD hh:mm:ss')) - new Date(moment(b.datetime, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD hh:mm:ss')));
            const PastAppointment = treatmentFilter.filter((el) => el.status === 'ATTENDED');
            const PastAppointmentSorting = PastAppointment?.sort((a, b) => new Date(moment(a.datetime, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD hh:mm:ss')) - new Date(moment(b.datetime, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD hh:mm:ss')));
            setAppointment(UpcomingAppointmentSorting);
            setPastAppointment(PastAppointmentSorting);
        }
    }, [leadAppointment]);

    const onChangeTabHandler = (event, newValue, data) => {
        onChangeTab(event, newValue);
        setTreatmentData(data);
    };

    const handleCancelPopup = () => toggleOpen(true);

    const handleClose = () => toggleOpen(false);

    const handleDelete = (data) => {
        dispatch(deleteTreatment(practiceId, data.lead, data.id, handleSuccess));
    };

    const handleSuccess = (data) => {
        handleMessage({ message: data.message, success: data.success });
        handleSuccessReload({ leadDetail });
        reloadTaskCounter({ userId: '', id: leadDetail.id, additionalFilter: '', CompletedTask: false });
        handleClose();
    };

    const handleScheduleReminderClose = () => {
        onCloseScheduleReminder();
        dispatch(getLeadReminderData(practiceId, 'LeadDetail', 10, 0, '', data.lead, ''));
    };

    const handlePopup = (id) => {
        dispatch(tableLead(data.id));
        dispatch(followUpSlice(id, practiceId));
        setTimeout(() => {
            onOpenScheduleReminder();
        }, 800);
    };

    const handleTreatment = ({ treatmentValue, priceValue, treatmentId, event, leadtreatmentSpecification, treatmentCategory }) => {
        event.stopPropagation();
        setSelectTreatment(treatmentValue);
        setLeadSpecification(leadtreatmentSpecification);
        setSelectTreatmentPrice(priceValue);
        setSelectTreatmentId(treatmentId);
        setSelectedTreatmentCategory(treatmentCategory);
        onEditTreatment();
        setAnchorEditTreatment(event.currentTarget);
    };

    const handleAppointmentClose = () => {
        onCloseAppointment();
        dispatch(getLeadTreatmentsList({ leadId: leadDetail.id }));
    };

    const { toggle: OpenAppointment, onOpen: onOpenAppointment, onClose: onCloseAppointment } = useAppointmentToggle();

    const handleAppointmentPopup = () => {
        setTimeout(() => { onOpenAppointment(); }, 800);
    };

    const handleCloseEditTreatmentPopup = () => {
        if (EditTreatment) handleClickAwayLister();
    };

    const handleStartTreatment = (row, event) => {
        event.stopPropagation();
        setDepositPaidStatus(!row?.is_treatment_fee_pending);
        setStartConfirmation(true);
        handleCloseEditTreatmentPopup();
    };

    const handleTreatmentAction = (actionType, row, event) => {
        event.stopPropagation();
        handleAction({ actionType, setConfirmation, setBacktoAttendConfirmation, setAttendedConfirmation, setFailedAttendConfirmation });
        handleCloseEditTreatmentPopup();
    };

    const handleStatusUpdate = (data) => {
        handleMessage({ message: 'Lead treatment status updated successfully', success: data.success });
        setAttendedConfirmation(false);
        setFailedAttendConfirmation(false);
        setBacktoAttendConfirmation(false);
        handleUpdate();
    };

    useEffect(() => {
        setAccordionExpanded(selectTreatmentData.id === data.id || (index === 0 && !selectTreatmentData.id));
        if (isAccordionExpanded && accordionRef.current) {
            accordionRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }
    }, [selectTreatmentData.id, data.id, index]);

    const handleClickAwayLister = (e, popup1Ref) => {
        if (popup1Ref && popup1Ref.current) {
            if (!popup1Ref.current?.contains(e.target)) {
                onCloseEditTreatment();
                dispatch(getLeadTreatmentsList({ leadId: leadDetail.id }));
                return;
            }
            return;
        }
        onCloseEditTreatment();
        dispatch(getLeadTreatmentsList({ leadId: leadDetail.id }));
    };

    const handleReloadAPI = (status) => {
        document.body.classList.remove('points-event');
        if (status?.toLowerCase?.() === 'finish') {
            handleClickAwayLister();
            dispatch(clearLead());
            dispatch(setCurrentTreatmentDataGlobal(null));
            if (leadDetail && leadDetail.id) {
                dispatch(getLeadReminderData(practiceId, 'LeadDetail', 10, 0, '', leadDetail.id, ''));
                dispatch(getLeadReminderData(practiceId, 'LeadDetail', 1, 0, '', leadDetail?.id, '', '', 'Graterthan'));
                dispatch(followUpSlice(leadDetail.id, practiceId, 'LeadDetail'));
                dispatch(getActivities(practiceId, leadDetail.id, 10, 0));
            }
        }
    };

    const handleMoreMenuClick = (e) => {
        setOpenMoreMenu(true);
        setAnchorMoreMenu(e.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenMoreMenu(false);
        setAnchorMoreMenu(null);
    };

    return (
        <Card sx={treatmentTabCardStyle}>
            <NormalLoading type={'treatment'} tableData={loading && 'No Found'} pageCount={loading && 'No Found'} />
            <TreatmentHeader data={data} handleAppointmentPopup={handleAppointmentPopup} handleMoreMenuClick={handleMoreMenuClick} />
            <TreatmentMoreMenu
                data={data}
                openMoreMenu={openMoreMenu}
                anchorMoreMenu={anchorMoreMenu}
                handleClosePopover={handleClosePopover}
                handleTreatment={handleTreatment}
                handleStartTreatment={handleStartTreatment}
                handleTreatmentAction={handleTreatmentAction}
                handlePopup={handlePopup}
                handleCancelPopup={handleCancelPopup} />
            <TreatmentSteppers data={data} />
            <TreatmentTabs
                TABS={TABS}
                appointment={appointment}
                currentTab={currentTab}
                data={data}
                onChangeTabHandler={onChangeTabHandler}
                pastAppointment={pastAppointment} />
            <ConfirmationDialog
                open={open}
                onClose={handleClose}
                title='Are you sure you want to cancel the treatment?'
                onConfirm={() => handleDelete(data)}
                cancelBtnStyle={treatmentCancel}
                confirmBtnStyle={treatmentConfirmCancel} />
            <ConfirmationDialog
                open={failedAttendConfirmation}
                onClose={() => setFailedAttendConfirmation(false)}
                title='Are you sure you want to failed to attend the treatment?'
                onConfirm={() => { failedAttendTreatmentAPI(data, handleStatusUpdate); }} />
            <ConfirmationDialog
                open={attendedConfirmation}
                onClose={() => setAttendedConfirmation(false)}
                title='Are you sure you want to attended the treatment?'
                onConfirm={() => { attendedTreatmentAPI(data, handleStatusUpdate); }} />
            <CustomCompleteTreatmentDialog
                handleClose={() => setConfirmation(false)}
                handleYes={() => completeTreatmentAPI(data, handleUpdate)}
                isLoader={isLoader}
                open={confirmation} />
            <ConfirmationDialog
                open={backtoAttendConfirmation}
                onClose={() => setBacktoAttendConfirmation(false)}
                title='Are you sure you want to back to attended the treatment?'
                onConfirm={() => dispatch(consultationAttended(practiceId, data.lead, data.id, handleStatusUpdate, 'TreatmentAccordion'))} />
            <StartTreatmentPopup
                isOpen={startConfirmation}
                handleCloseStartTreatment={() => setStartConfirmation(false)}
                isPaymentMandatory={isPaymentMandatory}
                depositStatus={depositPaidStatus}
                handleDepositPendingChange={(e) => handleDepositPendingChange(e, { data, setDepositPaidStatus, handleUpdate })}
                startTreatmentAPI={() => startTreatmentAPI(data, handleUpdate)} />
            {openScheduleReminder && <OpenScheduleReminderPopup
                open={openScheduleReminder}
                headerButtons
                onClose={handleScheduleReminderClose}
                inputRef={inputRef} />}
            {EditTreatment && <TreatmentEditPopover
                handleReloadAPI={handleReloadAPI}
                treatmentData={leadDetail.lead_treatments}
                treatment={selectTreatment || leadDetail && leadDetail?.lead_treatments.length > 0 && leadDetail.lead_treatments[0]?.treatment || ''}
                leadTreatmentSpecification={leadSpecification || leadDetail?.lead_treatments[0]?.leadTreatmentSpecification || null}
                open={EditTreatment}
                anchorEl={anchorEditTreatment}
                handleClickAwayLister={handleClickAwayLister}
                treatmentId={selectTreatmentId || leadDetail.lead_treatments[0].id}
                treatmentPrice={selectTreatmentPrice || leadDetail && leadDetail?.lead_treatments.length > 0 && leadDetail?.lead_treatments[0]?.price || ''}
                treatmentCategory={selectedTreatmentCategory || leadDetail?.lead_treatments[0]?.treatment_data?.treatment_item?.treatment_category?.name || ''}
                leadId={leadDetail && leadDetail.id ? leadDetail.id : tableRowLead.lead_id} />}
            {OpenAppointment && <OpenAppointmentPopup
                open={OpenAppointment}
                headerButtons
                onClose={handleAppointmentClose}
                treatmentData={treatmentData}
                inputRef={inputRef} />}
            {openCalendar.value && <Box sx={datePickerBoxStyle}>
                <DateTimePicker
                    orientation='landscape'
                    label=''
                    open={openCalendar.value}
                    onClose={openCalendar.onFalse}
                    name='DueDatetime'
                    value={selectedDate}
                    inputFormat={'dd/MM/yyyy HH:mm'}
                    onAccept={(newValue) => handleDateChange(newValue)}
                    ampm={false}
                    slotProps={slotProps}
                    disableFuture />
            </Box>}
        </Card >
    );
};

export default TreatmentAccordion;

TreatmentAccordion.propTypes = {
    data: PropTypes.object,
    leadDetail: PropTypes.object,
    leadAppointment: PropTypes.string,
    index: PropTypes.number,
    reminder: PropTypes.object,
    reloadTaskCounter: PropTypes.func,
    counter: PropTypes.number,
    handleLoading: PropTypes.func,
    loading: PropTypes.bool,
    selectTreatmentData: PropTypes.string,
};