import moment from 'moment';
import { filter, isArray } from 'lodash';
import { store } from 'src/redux/store';
import { noteUpdated, noteUpdatedAvatar, noteUpdatedBy, noteUpdatedHuman } from '../utils/formatTime';

const useLeadTableHelper = (tableType) => {

    const consultantBookedType = tableType === 'ConsultantBooked';
    const allType = tableType === 'Dashboard' || tableType === 'NoTaskTable';
    const consultantAttendedType = tableType === 'ConsultantAttend';
    const consultantClinCheckAttendedType = tableType === 'ConsultantClinCheckAttend';
    const failedToAttendType = tableType === 'FailedToAttend';
    const treatmentStartedType = tableType === 'treatmentStarted' || tableType === 'treatmentStartedWithInvisalign';
    const closedType = tableType === 'Closed';

    function getMatchedCount(data) {
        const rootStatus = data?.status;
        const leadContactedCounts = data?.lead_contacted_counts || [];
        const matchedCountObject = leadContactedCounts?.find((item) => item.status === rootStatus);
        return matchedCountObject ? matchedCountObject.count : 0;
    }

    const Type =
        (consultantBookedType && 'BOOKED') ||
        (failedToAttendType && 'FAILED_TO_ATTEND') ||
        (closedType && 'ATTENDED') ||
        (consultantAttendedType && 'ATTENDED') ||
        (consultantClinCheckAttendedType && 'ATTENDED') ||
        (treatmentStartedType && 'BOOKED');

    const getFilter = (status, treatmentStatus, type) => {
        if (consultantBookedType || (allType && treatmentStatus === 'CONSULTATION_BOOKED')) return status === 'BOOKED' || status === 'CANCELLED';
        if (consultantAttendedType || consultantClinCheckAttendedType || closedType) return status === 'ATTENDED';
        if (failedToAttendType) return status === 'FAILED_TO_ATTEND';
        if (treatmentStartedType) return (status === 'BOOKED' && type === 'TREATMENT');
        return status === Type;
    };

    const getFilteredData = (data, treatmentStatus) => {
        const tempData = data?.filter((ele) => ele.status === 'BOOKED');
        if ((consultantBookedType || (allType && treatmentStatus === 'CONSULTATION_BOOKED')) && tempData?.length > 0) {
            return data?.filter((ele) => ele.status === 'BOOKED');
        }
        return data;
    };

    const nearestData = (data, treatmentStatus) => {
        const now = moment().format();
        const filterData = data?.filter((value) => getFilter(value.status, treatmentStatus, value.type));
        const filteredData = getFilteredData(filterData, treatmentStatus);
        const result = filter(filteredData, (obj) => moment(obj.datetime, 'DD-MM-YYYY HH:mm:ss').format() > now);
        result.sort((a, b) =>
            moment(a.datetime, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').diff(moment(b.datetime, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ'))
        );
        return result?.length > 0 && result;
    };

    const getLatestLeadNotes = (notes) => {
        if (!isArray(notes)) return null;
        if (isArray(notes) && notes.length === 0) return null;
        const copiedNotes = [...notes];
        const latestNote = copiedNotes.sort((a, b) =>
            moment(b.updated_at, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').diff(moment(a.updated_at, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ'))
        );
        return latestNote?.length > 0 && latestNote[0];
    };

    const nearestPastData = (data) => {
        const now = moment().format();
        const filterData = data?.filter((value) => getFilter(value.status, '', value.type));
        const filteredData = getFilteredData(filterData);
        const result = filter(filteredData, (obj) => moment(obj.datetime, 'DD-MM-YYYY HH:mm:ss').format() < now);
        result.sort((a, b) =>
            moment(b.datetime, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').diff(moment(a.datetime, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ'))
        );
        return result?.length > 0 && result;
    };

    const consultationData = (data) => {
        const now = moment().format();
        const filterData = data?.filter((value) => value.status === 'ATTENDED' && value.type === 'CONSULTATION');
        const result = filter(filterData, (obj) => moment(obj.datetime, 'DD-MM-YYYY HH:mm:ss').format() > now);
        result.sort((a, b) =>
            moment(b.datetime, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').diff(moment(a.datetime, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ'))
        );
        return result?.length > 0 && result;
    };

    const consultationPastData = (data) => {
        const now = moment().format();
        const filterData = data?.filter((value) => value.status === 'ATTENDED' && value.type === 'CONSULTATION');
        const result = filter(filterData, (obj) => moment(obj.datetime, 'DD-MM-YYYY HH:mm:ss').format() < now);
        result.sort((a, b) =>
            moment(b.datetime, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').diff(moment(a.datetime, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ'))
        );
        return result?.length > 0 && result;
    };

    const getSortedDepositPaymentData = (data) => {
        if (!isArray(data)) return null;
        const dataToSort = [...data];
        return dataToSort.sort((a, b) => moment(a.updated_at, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ').diff(moment(b.updated_at, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ'))
        );
    };

    const handleTableData = (item, latestData) => {
        const { multiLead } = store.getState().lead;
        return {
            id: item.id,
            lead_id: Number(item?.id?.toString()?.split("-")?.[0] || '0'),
            assignees: item?.assignees || null,
            email: item?.lead_contact?.email || '',
            phone: item?.lead_contact?.phone || '',
            treatment_id: latestData && latestData[0] ? latestData[0].id : '',
            price: latestData && latestData[0] ? latestData[0].price : '',
            first_name: item?.first_name || '',
            last_name: item?.last_name || '',
            status: latestData[0] ? latestData[0].status : '',
            note: item.lead_contact && item.lead_contact.note ? item.lead_contact.note : '',
            preferred_contact_method: (item.lead_contact && item.lead_contact.preferred_contact_method) || '',
            treatment_name: latestData[0] ? latestData[0].treatment : '',
            created_at_human: item?.created_at_human || '',
            created_at: item?.created_at || '',
            updated_at_human: item?.updated_at_human || '',
            archive_restore_requests: item?.archive_restore_requests ? item.archive_restore_requests : [],
            lead_treatments__lead_contacted_counts__count:
                latestData[0]?.lead_contacted_counts?.length > 0 ? getMatchedCount(latestData[0]) : 0,
            is_archive_pending: (item?.is_archive_pending && item.is_archive_pending) || false,
            archived_at_human: (item?.archived_at_human && item.archived_at_human) || '',
            archived_at: item?.archived_at || '',
            tags: item.tags || [],
            last_time_contacted: item?.lead_notes?.length > 0 ? noteUpdated(item?.lead_notes) : 'No contact',
            last_time_contacted_human: item?.lead_notes?.length > 0 ? noteUpdatedHuman(item?.lead_notes) : 'No contact',
            last_time_created_by_avatar: item?.lead_notes?.length > 0 ? noteUpdatedAvatar(item?.lead_notes) : '',
            last_time_updated_by: item?.lead_notes?.length > 0 ? noteUpdatedBy(item?.lead_notes) : '',
            lead_notes: getLatestLeadNotes(item?.lead_notes),
            treatment_updated_date: latestData[0] ? latestData[0].updated_at : '',
            appointments: nearestData(latestData[0]?.appointments, latestData[0] ? latestData[0].status : '') || nearestPastData(latestData[0]?.appointments, latestData[0] ? latestData[0].status : '') || [],
            consultationAppointment:
                consultationData(latestData[0]?.appointments) || consultationPastData(latestData[0]?.appointments) || [],
            archivedBy: item?.archived_by,
            reason: item?.reason_for_archiving || '',
            openCommunication: item?.is_open_for_communication ? 'Yes' : 'No',
            lead_contacted_counts: latestData[0]?.lead_contacted_counts || [],
            referral: item?.lead_source?.referral,
            affiliate_user: item?.lead_source?.affiliate_user,
            metadata: item?.lead_source?.metadata,
            lead_address: item?.lead_address,
            checked: multiLead?.leadId?.includes?.(item.id) || false,
            is_duplicate: item?.is_duplicate || false,
            clinCheckData: item?.lead_treatments?.[0]?.leadtreatment_clincheck || null,
            treatmentData: item?.lead_treatments || null,
            treatmentBgColor: latestData[0]?.treatment_data?.colour || '',
            treatmentIcon: latestData[0]?.treatment_data?.icon || '',
            treatmentCategory: latestData[0]?.treatment_data?.treatment_item?.treatment_category?.name || '',
            leadTreatmentSpecification: latestData[0]?.leadtreatment_specification || null,
            leadTreatmentAttributes: latestData[0]?.lead_treatment_attributes || null,
            deposit_amount: latestData && latestData[0] ? latestData[0].lead_treatment_payments?.[0]?.amount || '' : '',
            deposit_payment_data: latestData && latestData[0] ? getSortedDepositPaymentData(latestData[0].lead_treatment_payments || []) || null : null,
        };
    };
    return { handleTableData, nearestPastData, nearestData, getSortedDepositPaymentData };
};

export default useLeadTableHelper;