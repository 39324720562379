import PropTypes from 'prop-types';

// @mui
import { Box, TableRow, TableCell, TableHead, TableSortLabel, Checkbox, MenuItem, Menu, Switch, Divider, Button, Typography, Tooltip } from '@mui/material';
import { useForm } from 'react-hook-form';
//
import { validationTableHead } from '../../styles/AudienceManager';
import { RHFSelect, FormProvider } from '../hook-form';
import { Iconify } from '..';
// constant
import { DATE_TITLES } from '../../constants/newLead';
// style
import { tableHeading, mainDateFilter, selectFilter, selectMenuStyle, menuPropsStyle, columnMenuStyle } from '../../styles/NewLead';
// redux
import { dispatch, useSelector } from '../../redux/store';
import { updateDateFormat, updateAppointmentDateFormat, updateAttendedDateFormat, updateLastContactDateFormat, updateArchiveDateFormat } from '../../redux/slices/lead';

// ----------------------------------------------------------------------

const visuallyHidden = {
    border: 0,
    margin: -1,
    padding: 0,
    width: '1px',
    height: '1px',
    overflow: 'hidden',
    position: 'absolute',
    whiteSpace: 'nowrap',
    clip: 'rect(0 0 0 0)',
};

// ----------------------------------------------------------------------
export default function TableHeadCustom({
    order,
    orderBy,
    headLabel,
    onSort,
    sx,
    practiceMessageTable,
    type,
    onChange,
    leadTable,
    handleSelectAll,
    showSelected,
    disabledCheckbox,
    handleClick,
    handleCloseMenu,
    handleSwitchChange,
    handleSelectShowAndHide,
    handleAllChecked,
    anchorEl,
    isReportData
}) {

    const { dateFormat, dateAppointmentFormat, dateAttendedFormat, dateLastContactFormat, dateArchiveFormat } = useSelector((state) => state.lead);

    const defaultValues = {
        title: dateFormat || '',
        attendedTitle: dateAttendedFormat || '',
        appointmentsTitle: dateAppointmentFormat || '',
        lastContactTitle: dateLastContactFormat || '',
        archiveTitle: dateArchiveFormat || ''
    };

    const methods = useForm({
        defaultValues,
    });

    const { setValue } = methods;

    const handleChangeDateFormat = (e, data) => {
        if (data === 'archived_at') {
            localStorage.setItem('dateArchiveFormat', e.target.value);
            dispatch(updateArchiveDateFormat(e.target.value));
            setValue('archiveTitle', e.target.value);
            return;
        }
        if (data === 'created_at' || data === 'create_date') {
            localStorage.setItem('dateFormat', e.target.value);
            dispatch(updateDateFormat(e.target.value));
            setValue('title', e.target.value);
            return;
        }
        if (data === 'date_attended') {
            localStorage.setItem('attendedFormat', e.target.value);
            dispatch(updateAttendedDateFormat(e.target.value));
            setValue('attendedTitle', e.target.value);
            return;
        }
        if (data === 'lead_treatments__appointments__datetime') {
            localStorage.setItem('appointmentFormat', e.target.value);
            dispatch(updateAppointmentDateFormat(e.target.value));
            setValue('appointmentsTitle', e.target.value);
        }
        if (data === 'lastContact') {
            localStorage.setItem('lastContactFormat', e.target.value);
            dispatch(updateLastContactDateFormat(e.target.value));
            setValue('lastContactTitle', e.target.value);
        }
    };

    const getName = (id) => {
        if (id === 'date_attended') return 'attendedTitle';
        if (id === 'lead_treatments__appointments__datetime') return 'appointmentsTitle';
        if (id === 'lastContact') return 'lastContactTitle';
        if (id === 'archived_at') return 'archiveTitle';
        return 'title';
    };

    const getLabel = (headCell) => {
        if (headCell.id === 'validation_status' || headCell.id === 'validation_result')
            return (
                <Typography
                    sx={validationTableHead} >
                    {headCell.label}
                    <Tooltip title="For CSV Only" sx={{ ml: 1 }}>
                        <Iconify icon={'material-symbols:info'} width={16} height={16} />
                    </Tooltip>
                </Typography>
            );
        return headCell.label;
    };

    return (
        <TableHead sx={sx}>
            <TableRow sx={{ border: practiceMessageTable ? '1px solid rgba(145, 158, 171, 0.12)' : '', borderRadius: '0' }}>
                {headLabel.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.align || 'left'}
                        sortDirection={orderBy === headCell.id ? order : false}
                        sx={tableHeading(headCell, type, practiceMessageTable)}
                    >
                        {type === 'notification' && headCell.checkbox && (
                            <Checkbox onChange={(e) => onChange(e, headCell.id)} checked={headCell.count === 6} />
                        )}
                        {leadTable && headCell.checkbox && (
                            <Checkbox
                                onChange={(e) => handleSelectAll(e, headCell.id)}
                                checked={showSelected}
                                disabled={disabledCheckbox}
                            />
                        )}
                        {headCell?.id === "allOffer" && headCell.checkbox && (
                            <Checkbox
                                onChange={(e) => handleSelectAll(e.target.checked)}
                                checked={showSelected}
                               // disabled={disabledCheckbox}
                            />
                        )}
                        {onSort ? (
                            <TableSortLabel
                                hideSortIcon
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={() => onSort(headCell.id, headCell)}
                                sx={{ svg: { display: headCell.sort === '' && 'none' } }}
                            >
                                {headCell.label}

                                {orderBy === headCell.id ? (
                                    <Box sx={{ ...visuallyHidden }}>{order === 'desc' ? 'sorted descending' : 'sorted ascending'}</Box>
                                ) : null}
                            </TableSortLabel>
                        ) : getLabel(headCell)}

                        {headCell.dateFormat ? (
                            <Box sx={mainDateFilter}>
                                <Iconify sx={{ mr: '8px', ml: '8px' }} icon={'mdi:calendar-filter-outline'} width={15} height={15} />
                                <Box sx={selectFilter}>
                                    <FormProvider methods={methods}>
                                        <RHFSelect
                                            name={getName(headCell.id)}
                                            label="Title"
                                            SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                                            onChange={(e) => handleChangeDateFormat(e, headCell.id)}
                                        >
                                            {DATE_TITLES.map((option, index) => (
                                                <MenuItem key={index} value={option.value} sx={selectMenuStyle}>
                                                    <Iconify sx={{ mr: '8px' }} icon={option.icon} width={18} height={18} />
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </RHFSelect>
                                    </FormProvider>
                                </Box>
                            </Box>
                        ) : (
                            ''
                        )}
                        {headCell.columns && (
                            <Box sx={{ display: 'flex', mt: '-25px' }}>
                                {isReportData && (
                                    <Button variant="outlined" sx={{ minWidth: '40px', p: '8px 0' }} onClick={handleClick}>
                                        <Iconify icon={'heroicons:view-columns-20-solid'} />
                                    </Button>
                                )}
                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={handleCloseMenu}
                                    PaperProps={menuPropsStyle}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                    sx={{ ul: { minWidth: '200px', p: '8px 0 !important' } }}
                                >
                                    <MenuItem sx={{ m: '0 !important', p: '0px 8px', display: 'none' }}>
                                        <Switch
                                            checked={handleAllChecked()}
                                            onChange={(e, value) => handleSelectShowAndHide(e, value, 'Select All')}
                                            size="small"
                                        />
                                        Select All
                                    </MenuItem>
                                    <Divider sx={{ display: 'none' }} />
                                    {headLabel?.map(
                                        (data, index) =>
                                            data.id !== '' && (
                                                <MenuItem
                                                    key={index}
                                                    sx={{
                                                        ...columnMenuStyle,
                                                        pointerEvents:
                                                            data.id === 'first_name' || data.id === 'email' || data.id === 'contact' ? 'none' : '',
                                                    }}
                                                    onClick={() => handleSwitchChange(!data?.show, data)}
                                                >
                                                    <Switch
                                                        checked={data.show}
                                                        onChange={(e, value) => handleSwitchChange(value, data)}
                                                        size="small"
                                                        disabled={data.id === 'first_name' || data.id === 'email' || data.id === 'contact'}
                                                    />
                                                    {data.label}
                                                </MenuItem>
                                            )
                                    )}
                                </Menu>
                            </Box>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

TableHeadCustom.propTypes = {
    onSort: PropTypes.func,
    orderBy: PropTypes.string,
    headLabel: PropTypes.array,
    order: PropTypes.oneOf(['asc', 'desc']),
    sx: PropTypes.object,
    practiceMessageTable: PropTypes.bool,
    type: PropTypes.any,
    onChange: PropTypes.func,
    leadTable: PropTypes.bool,
    handleSelectAll: PropTypes.any,
    showSelected: PropTypes.any,
    disabledCheckbox: PropTypes.any,
    handleClick: PropTypes.any,
    handleCloseMenu: PropTypes.any,
    handleSwitchChange: PropTypes.any,
    handleSelectShowAndHide: PropTypes.any,
    handleAllChecked: PropTypes.any,
    anchorEl: PropTypes.any,
    isReportData: PropTypes.any,
};