/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
// libraries
import {
    Box,
    Typography,
    Grid,
    Card,
    FormControl,
    FormHelperText,
    ToggleButtonGroup,
    ToggleButton,
    useTheme
} from '@mui/material';
import moment from 'moment';
import { isArray, isEmpty } from 'lodash';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// components
import Iconify from '../Iconify';
import { UserSearchDropdown } from '..';
import { FormProvider, RHFTextField } from '../hook-form';
import TreatmentEditPopover from '../../pages/dashboard/tables/AllLeadTable/TreatmentEditPopover';
import TreatmentFlowComponent from '../treatmentComponents/TreatmentFlowComponent';
// store
import { useSelector, dispatch } from '../../redux/store';
import { getPracticeTreatmentList } from '../../redux/slices/practiceTreatment';
import { saveNewLeadDetail, updateCompleteStatus } from '../../redux/slices/lead';
// hooks/validation
import { useAddTreatment } from '../../hooks';
import { FailedAppointmentSchema } from '../../validations/validations';
// style
import { grey } from '../../theme/palette';
import {
    appointmentCard,
    appointmentGridContainer,
    appointmentGridStyle,
} from '../../styles/DialogueTabs';
import { appointmentTypoStyle, assignToUserBoxStyle, toggleButtonStyle } from '../../styles/Common';

const FailedAppointment = forwardRef(({ handleNext }, ref) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { tableRowLead, followLead } = useSelector((state) => state.lead);
    const { contactMethod, appointmentType, appointmentMethod } = useSelector((state) => state.schema);
    const [treatmentData, setTreatment] = useState('');
    const [assignToState, setAssignToState] = useState([]);
    const [anchorEditTreatment, setAnchorEditTreatment] = useState(null);
    const { currentTreatmentDataGlobal } = useSelector((state) => state.multiTreatment);
    const [priceAmountError, setPriceAmountError] = useState(false);
    const [teethAmountError, setTeethAmountError] = useState(false);

    const {
        toggle: EditTreatment,
        onOpen: onEditTreatment,
        onClose: onCloseEditTreatment
    } = useAddTreatment();

    const getCurrentTreatmentDetail = (key, value) => {
        if (!isEmpty(currentTreatmentDataGlobal?.treatmentData)) {
            switch (key) {
                case 'treatment':
                    return currentTreatmentDataGlobal?.treatmentData?.treatment;
                case 'price':
                    return currentTreatmentDataGlobal?.treatmentData?.price;
                case 'id':
                    return currentTreatmentDataGlobal?.treatmentData?.id;
                case 'teeth':
                    return currentTreatmentDataGlobal?.treatmentData?.leadtreatment_specification;
                default:
                    return '';
            }
        }
        return value;
    };

    const defaultValues = {
        treatment: getCurrentTreatmentDetail('treatment', tableRowLead?.treatment_name || ''),
        treatmentValue: getCurrentTreatmentDetail('price', (tableRowLead?.price === 0 ? '0' : tableRowLead?.price) || ''),
        teethAmount: (() => {
            const treatmentData = tableRowLead?.treatmentData;
            const treatment = isArray(treatmentData) ? treatmentData?.find((ele) => ele?.id === tableRowLead?.treatment_id) : '';
            return getCurrentTreatmentDetail('teeth', treatment)?.teeth_count || null;
        })(),
        appoinmentDate: null,
        bookedWith: [],
        practiceContactedVia: '',
        preferredContactMethod: '',
        appoimentId: '',
        appointmentType: ''
    };

    const [amount, setAmount] = useState(getCurrentTreatmentDetail('price', (tableRowLead.price === 0 ? '0' : tableRowLead.price) || 0.00));

    useEffect(() => {
        if (defaultValues.treatment) {
            setTreatment(defaultValues.treatment);
        }
    }, [defaultValues.treatment]);

    const methods = useForm({
        resolver: yupResolver(FailedAppointmentSchema(assignToState)),
        defaultValues,
    });

    const onSubmit = async (data, status) => {
        const updatedTreatment = {
            treatment: data.treatment,
            custom_price: amount,
            teethAmount: data.teethAmount
        };
        const appointmentDetail = {
            datetime: `${moment(data.appoinmentDate).format('DD-MM-YYYY HH:mm:ss')}.0Z`,
            method: data.preferredContactMethod || '',
            contacted_via: data.practiceContactedVia || '',
            assignees: assignToState?.map((el) => el?.id),
            lead: currentTreatmentDataGlobal?.lead_id || tableRowLead?.lead_id || '',
            id: data.appoimentId,
            type: data?.appointmentType,
            lead_treatment: getCurrentTreatmentDetail('id', tableRowLead?.treatment_id)
        };
        dispatch(saveNewLeadDetail({
            startTreatment: {
                appointmentDetail,
                updatedTreatment
            }
        }));
        if (status?.toLowerCase?.() === 'finish') {
            dispatch(updateCompleteStatus(true));
            return;
        }
        handleNext(true);
    };

    useImperativeHandle(
        ref,
        () => ({
            async submit(status) {
                await handleSubmit((data) => onSubmit(data, status))();
            },
        }),
        [amount, assignToState]
    );

    const {
        control,
        setValue,
        handleSubmit,
        formState: { errors },
        trigger,
        watch
    } = methods;

    const values = watch();

    useEffect(() => {
        if (assignToState?.length > 0) {
            trigger('bookedWith');
        }
    }, [assignToState, trigger]);

    useEffect(() => {
        if (practiceId) {
            dispatch(getPracticeTreatmentList(practiceId));
        }
    }, [practiceId]);

    useEffect(() => {
        if (followLead && followLead.lead_appointment) {
            const data = followLead.lead_appointment.filter((el) => el.lead_treatment === getCurrentTreatmentDetail('id', tableRowLead?.treatment_id));
            if (data.length > 0) {
                setValue('appoimentId', data[0].id);
            }
        }
    }, [followLead]);

    const handleClickAwayLister = () => {
        onCloseEditTreatment();
    };

    const handleSaveTreatment = (data) => {
        setTreatment(data.selectedTreatment);
        setAmount(data.treatmentPrice);
        setValue('treatment', data.selectedTreatment);
        setValue('teethAmount', data.teethAmount);
        onCloseEditTreatment();
    };

    const handleTreatment = (event) => {
        onEditTreatment();
        setAnchorEditTreatment(event.currentTarget);
    };

    const buttonChangeEvent = (e, value, field) => {
        if (value === null) {
            field.onChange('');
            return;
        }
        field.onChange(value);
    };

    const amountHandleChange = (value) => {
        setAmount(value);
        if (value > 0) {
            setPriceAmountError(false);
            document.body.classList.remove('points-event');
            return;
        }
        setPriceAmountError(true);
    };

    const handleChangeTeeth = (e) => {
        const value = e.target.value > 0 ? e.target.value : '';
        setValue('teethAmount', value);
        setTeethAmountError(value === 0);
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Card sx={appointmentCard}>
                <Grid container spacing={1} sx={appointmentGridContainer}  >
                    {EditTreatment && (
                        <TreatmentEditPopover
                            treatment={treatmentData || ''}
                            leadTreatmentSpecification={getCurrentTreatmentDetail('teeth', isArray(tableRowLead?.treatmentData) ? tableRowLead?.treatmentData?.find((ele) => ele.id === tableRowLead?.treatment_id) : '') || null}
                            open={EditTreatment}
                            anchorEl={anchorEditTreatment}
                            handleClickAwayLister={handleClickAwayLister}
                            treatmentPrice={amount}
                            handleSaveTreatment={handleSaveTreatment}
                            isFlow
                        />
                    )}
                    <TreatmentFlowComponent treatmentData={treatmentData} teethAmount={values.teethAmount} handleTreatment={handleTreatment} amount={amount} amountError={priceAmountError} amountHandleChange={amountHandleChange} handleChangeTeeth={handleChangeTeeth} teethAmountError={teethAmountError} />
                </Grid>
                <Box sx={{ mt: 0 }}>
                    <Grid sx={appointmentGridStyle}>
                        <Box>
                            <Typography variant='h6' sx={appointmentTypoStyle}>
                                Schedule a new appointment
                            </Typography>
                            <Controller
                                name='appoinmentDate'
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <DateTimePicker
                                        label='Appointment date (Required)'
                                        format={'dd/MM/yyyy HH:mm'}
                                        value={field.value || null}
                                        minDateTime={new Date()}
                                        ampm={false}
                                        onChange={(newValue) => field.onChange(newValue)}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                error: !!error,
                                                helperText: error?.message,
                                                onKeyDown: (e) => e.preventDefault()
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Box>
                        <Box>
                            <Typography variant='h6' sx={appointmentTypoStyle}>
                                Booked with (Required)
                            </Typography>
                            <Box sx={assignToUserBoxStyle}>
                                <UserSearchDropdown
                                    name={'bookedWith'}
                                    control={control}
                                    assignToState={assignToState}
                                    setAssignTo={setAssignToState}
                                    error={errors?.bookedWith}
                                />
                            </Box>
                        </Box>
                    </Grid>
                    <Box sx={{ ...appointmentGridStyle, gridTemplateColumns: 'repeat(1, 1fr)' }}>
                        <Controller
                            name='preferredContactMethod'
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <FormControl>
                                    <Typography
                                        variant='subtitle2'
                                        sx={{ m: '0 0 10px' }}
                                    >
                                        Appointment method (Required)
                                    </Typography>
                                    <ToggleButtonGroup
                                        color='primary'
                                        exclusive
                                        value={field.value}
                                        onChange={(e, value) => buttonChangeEvent(e, value, field)}
                                        aria-label='Platform'
                                        sx={{ borderRadius: 0, border: 'none', flexWrap: 'wrap' }}
                                        error={error && !field.value?.length}
                                    >
                                        {appointmentMethod?.map((options, index) => (
                                            <ToggleButton
                                                key={index}
                                                value={options.value}
                                                sx={toggleButtonStyle(isLight)}
                                            >
                                                <Iconify icon={field.value === options.value ? 'bi:check-circle-fill' : 'material-symbols:circle'} width={20} height={20} sx={{ mr: 1, color: field.value === options.value ? 'primary.main' : grey[400] }} />{options.label}
                                            </ToggleButton>
                                        ))}
                                    </ToggleButtonGroup>
                                    {error && !field.value?.length &&
                                        <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
                                            {error?.message}
                                        </FormHelperText>}
                                </FormControl>
                            )}
                        />
                        <Controller
                            name='appointmentType'
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <FormControl>
                                    <Typography
                                        variant='subtitle2'
                                        sx={{ m: '0 0 10px' }}
                                    >
                                        Appointment type (Required)
                                    </Typography>
                                    <ToggleButtonGroup
                                        color='primary'
                                        exclusive
                                        value={field.value}
                                        onChange={(e, value) => buttonChangeEvent(e, value, field)}
                                        aria-label='Platform'
                                        sx={{ borderRadius: 0, border: 'none', flexWrap: 'wrap' }}
                                        error={error && !field.value?.length}
                                    >
                                        {appointmentType?.map((options, index) => (
                                            <ToggleButton
                                                key={index}
                                                value={options.value}
                                                sx={toggleButtonStyle(isLight)}
                                            >
                                                <Iconify icon={field.value === options.value ? 'bi:check-circle-fill' : 'material-symbols:circle'} width={20} height={20} sx={{ mr: 1, color: field.value === options.value ? 'primary.main' : grey[400] }} />{options.label}
                                            </ToggleButton>
                                        ))}
                                    </ToggleButtonGroup>
                                    {error && !field.value?.length &&
                                        <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
                                            {error?.message}
                                        </FormHelperText>}
                                </FormControl>
                            )}
                        />
                        <Controller
                            name='practiceContactedVia'
                            control={control}
                            render={({ field, fieldState: { error } }) => (
                                <FormControl>
                                    <Typography
                                        variant='subtitle2'
                                        sx={{ m: '0 0 10px' }}
                                    >
                                        Contact method (Required)
                                    </Typography>
                                    <ToggleButtonGroup
                                        color='primary'
                                        exclusive
                                        value={field.value}
                                        onChange={(e, value) => buttonChangeEvent(e, value, field)}
                                        aria-label='Platform'
                                        sx={{ borderRadius: 0, border: 'none', flexWrap: 'wrap' }}
                                        error={error && !field.value?.length}
                                    >
                                        {contactMethod?.map((options, index) => (
                                            <ToggleButton
                                                key={index}
                                                value={options.value}
                                                sx={toggleButtonStyle(isLight)}
                                            >
                                                <Iconify icon={field.value === options.value ? 'bi:check-circle-fill' : 'material-symbols:circle'} width={20} height={20} sx={{ mr: 1, color: field.value === options.value ? 'primary.main' : grey[400] }} />{options.label}
                                            </ToggleButton>
                                        ))}
                                    </ToggleButtonGroup>
                                    {error && !field.value?.length &&
                                        <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
                                            {error?.message}
                                        </FormHelperText>}
                                </FormControl>
                            )}
                        />
                    </Box>
                    <Box>
                        <RHFTextField
                            sx={{ width: { xs: '100%', md: '100%' } }}
                            name='notes' multiline rows={4}
                            label='Appointment notes'
                            placeholder='Add notes here'
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>
                </Box>
            </Card>
        </FormProvider>
    );
});

FailedAppointment.propTypes = {
    handleNext: PropTypes.func,
};

export default FailedAppointment;