/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import {
    Badge,
    Box,
    Button,
    ButtonGroup,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    useTheme
} from '@mui/material';
import { Iconify } from 'src/components';
import {
    arcBtnStyle,
    archivingButtonStyle,
    badgeStyle,
    flwBtnStyle,
    menuListStyle,
    recordAttendedButtonStyle,
    viewBtnStyle,
    arrowButtonStyle,
    buttonGroupStyle
} from 'src/styles/NewLeadTableRowStyle';

const ContactCell = ({
    row,
    anchorRef,
    open,
    setOpen,
    isDesktop,
    buttonHandleClose,
    handlePopup,
    viewDetailBtn,
    handleAttendedClick,
    handleAttendedFailedClick,
    handleAttendedPopup,
}) => {
    const theme = useTheme();
    const handleTogglePopper = (id) => {
        anchorRef.current = document.getElementById(`button-${row.lead_id}`);
        setOpen((prevStates) => ({
            ...prevStates,
            [id]: !prevStates[id],
        }));
    };

    const isPopperOpen = (id) => Boolean(open[id]);

    if (row.is_archive_pending) {
        return (
            <Badge
                badgeContent={[row?.lead_treatments__lead_contacted_counts__count]}
                color="primary"
                sx={arcBtnStyle(row?.lead_treatments__lead_contacted_counts__count)}
            >
                <Button
                    variant="contained"
                    color="primary"
                    disabled={row.is_archive_pending}
                    sx={{ backgroundColor: 'rgba(145, 158, 171, 0.24)', color: 'rgba(145, 158, 171, 0.8)', minWidth: '150px' }}
                >
                    Archiving
                </Button>
            </Badge>
        );
    }

    if (row.status === 'CONSULTATION_BOOKED' && !row.is_archive_pending) {
        return (
            <Box>
                <Box>
                    <ButtonGroup
                        variant="contained"
                        ref={anchorRef}
                        aria-label="split button"
                        sx={buttonGroupStyle(theme)}
                        disabled={row?.appointments?.[0]?.status === 'CANCELLED'}
                    >
                        <Button onClick={() => handleTogglePopper(row.lead_id)} id={`button-${row.lead_id}`} sx={{ ...recordAttendedButtonStyle, p: '9px 16px' }}>
                            Record Attendance
                        </Button>
                        <Button
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            onClick={() => handleTogglePopper(row.lead_id)}
                            id={`button-${row.lead_id}`}
                            sx={arrowButtonStyle(theme)}
                        >
                            <Badge
                                badgeContent={[row?.lead_treatments__lead_contacted_counts__count]}
                                color="primary"
                                sx={badgeStyle(row.status, row?.lead_treatments__lead_contacted_counts__count)}
                            >
                                <Iconify style={{ color: '#fff' }} width={10} height={10} icon={'bxs:right-arrow'} />
                            </Badge>
                        </Button>
                    </ButtonGroup>
                </Box>
                <Popper
                    sx={{
                        zIndex: 1,
                        marginLeft: '10px !important',
                    }}
                    open={isPopperOpen(row.lead_id)}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    placement={isDesktop ? 'left-start' : 'right-start'}
                >
                    {({ TransitionProps }) => (
                        <Grow {...TransitionProps}>
                            <Paper>
                                <ClickAwayListener onClickAway={buttonHandleClose}>
                                    <MenuList id="split-button-menu" autoFocusItem sx={menuListStyle}>
                                        <MenuItem
                                            key="Attended"
                                            onClick={() => handleAttendedClick(row.lead_id, row)}
                                            sx={{ borderRadius: '8px', minHeight: '40px !important', color: '#212B36' }}
                                        >
                                            <Iconify
                                                style={{ color: '#212B36', marginRight: 15 }}
                                                width={20}
                                                height={20}
                                                icon={'akar-icons:check'}
                                            />{' '}
                                            Attended
                                        </MenuItem>
                                        <MenuItem
                                            key="Failed to attend"
                                            onClick={() => handleAttendedFailedClick(row.lead_id, row)}
                                            sx={{ borderRadius: '8px', minHeight: '40px !important', color: '#212B36' }}
                                        >
                                            <Iconify
                                                style={{ color: '#212B36', marginRight: 15 }}
                                                width={20}
                                                height={20}
                                                icon={'ci:close-big'}
                                            />{' '}
                                            Failed to attend
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Box>
        );
    }
    if (row.status === 'CONSULTATION_ATTENDED' && !row.is_archive_pending) {
        return (
            <Button
                variant="contained"
                color="primary"
                startIcon={<Iconify icon={'fluent:call-32-filled'} />}
                onClick={() => {
                    handleAttendedPopup(row.lead_id, row);
                }}
                disabled={row.is_archive_pending}
                sx={archivingButtonStyle}
            >
                {
                    <Badge
                        badgeContent={[row?.lead_treatments__lead_contacted_counts__count]}
                        color="primary"
                        sx={badgeStyle(row.status, row?.lead_treatments__lead_contacted_counts__count)}
                    >
                        {row.is_archive_pending ? 'Archiving' : 'Follow up'}
                    </Badge>
                }
            </Button>
        );
    }

    if (
        (row.status === 'CLOSED' && !row.is_archive_pending) ||
        (row.status === 'TREATMENT_STARTED' && !row.is_archive_pending)
    ) {
        return (
            <Button
                variant="contained"
                color="primary"
                onClick={() => viewDetailBtn(row.lead_id, row.status, row)}
                sx={viewBtnStyle(row?.status)}
            >
                <Badge
                    badgeContent={[row?.lead_treatments__lead_contacted_counts__count]}
                    color="primary"
                    sx={badgeStyle(row.status, row?.lead_treatments__lead_contacted_counts__count)}
                >
                    {row.status === 'CLOSED' ? 'View details' : 'Complete treatment'}
                </Badge>
            </Button>
        );
    }

    if (row.treatment_name === '' || null) {
        return '';
    }
    return (
        <Button
            variant="contained"
            color="primary"
            startIcon={!row.is_archive_pending && <Iconify icon={'fluent:call-32-filled'} />}
            onClick={() => handlePopup(row.lead_id, row.status, row, row?.referral === "WHATSAPP" && row.status !== 'FAILED_TO_ATTEND_CONSULTATION' && row?.lead_treatments__lead_contacted_counts__count === 0)}
            disabled={row.is_archive_pending}
            sx={flwBtnStyle(row.is_archive_pending)}
        >
            <Badge
                badgeContent={[row?.lead_treatments__lead_contacted_counts__count]}
                color="primary"
                sx={badgeStyle(row.status, row?.lead_treatments__lead_contacted_counts__count)}
            >
                {row.status === 'FAILED_TO_ATTEND_CONSULTATION'
                    ? 'Follow up'
                    : row?.lead_treatments__lead_contacted_counts__count === 0
                        ? 'contact lead'
                        : 'Follow up'}
            </Badge>
        </Button>
    );
};

export default ContactCell;

ContactCell.propTypes = {
    row: PropTypes.any,
    anchorRef: PropTypes.any,
    open: PropTypes.bool,
    setOpen: PropTypes.func,
    isDesktop: PropTypes.bool,
    buttonHandleClose: PropTypes.func,
    handlePopup: PropTypes.func,
    viewDetailBtn: PropTypes.func,
    handleAttendedClick: PropTypes.func,
    handleAttendedFailedClick: PropTypes.func,
    handleAttendedPopup: PropTypes.func,
};
