import { PATH_DASHBOARD } from '../../../routes/paths';
import { SvgIconStyle } from '../../../components';

const getIcon = (name) => <SvgIconStyle src={`/assets/icons/navbar/${name}.svg`} sx={{ width: '20px', height: '20px' }} />;

const ICONS = {
    blog: getIcon('ic_blog'),
    cart: getIcon('ic_cart'),
    chat: getIcon('ic_chat'),
    mail: getIcon('ic_mail'),
    user: getIcon('ic_user'),
    kanban: getIcon('ic_kanban'),
    banking: ('ic_banking'),
    job: getIcon('ic_job'),
    invoice: getIcon('ic_file'),
    tables: getIcon('ic_filter'),
    calendar: getIcon('ic_calendar2'),
    ecommerce: getIcon('ic_ecommerce'),
    analytics: getIcon('ic_analytics'),
    dashboard: getIcon('ic_dashboard'),
    menuItem: getIcon('ic_menu_item'),
    campaigns: getIcon('ic_campaign'),
    notification: getIcon('ic_notification'),
    task: getIcon('ic_task'),
    campaign: getIcon('ic_campaign'),
    financial: getIcon('ic_financial'),
    qrcode: getIcon('ic_qrcode'),
    facebook: getIcon('ic_facebook'),
    whatsapp: getIcon('ic_whatsapp'),
    sms: getIcon('ic_sms'),
    patientReview: getIcon('ic_label'),
    websiteSupport: getIcon('ic_support')
};

const navConfig = [
    {
        subheader: 'dashboard',
        items: [
            { title: 'Dashboard', path: PATH_DASHBOARD.general.app, icon: ICONS.dashboard },
            {
                title: 'Leads',
                path: PATH_DASHBOARD.tables.root,
                icon: ICONS.tables,
                children: [
                    { title: 'All', path: PATH_DASHBOARD.tables.AllLeadTable },
                    { title: 'New', path: PATH_DASHBOARD.tables.newLeadTable },
                    { title: 'NHS', path: PATH_DASHBOARD.tables.nhsLeadTable },
                    { title: 'Consultation Booked', path: PATH_DASHBOARD.tables.ConsultantBookedTable },
                    { title: 'Consultation attended', path: PATH_DASHBOARD.tables.ConsultantAttendedTable },
                    { title: 'Treatment started', path: PATH_DASHBOARD.tables.treatmentStartedTable },
                    { title: 'Aligner Approve', path: PATH_DASHBOARD.tables.alignerApproveTable },
                    { title: 'Failed to attend', path: PATH_DASHBOARD.tables.failedToAttendTable },
                    { title: 'Completed', path: PATH_DASHBOARD.tables.closedTable },
                    { title: 'Archived', path: PATH_DASHBOARD.tables.archiveLeadTable },
                    { title: 'Duplicate leads', path: PATH_DASHBOARD.tables.duplicateLeadTable },
                    { title: 'No task assigned', path: PATH_DASHBOARD.tables.NoTaskLeadsTable },
                ],
            },
            {
                title: 'Tasks',
                path: PATH_DASHBOARD.task.root,
                icon: ICONS.task
            },
            { title: 'Whatsapp', path: PATH_DASHBOARD.whatsapp.root, icon: ICONS.whatsapp },
            { title: 'Emails', path: PATH_DASHBOARD.emails.root, icon: ICONS.mail },
            {
                title: 'Patient feedback',
                path: PATH_DASHBOARD.patientReview,
                icon: ICONS.patientReview
            },
            {
                title: 'Analytics',
                path: PATH_DASHBOARD.analytics.root,
                icon: ICONS.analytics,
                children: [
                    { title: 'reports', path: PATH_DASHBOARD.analytics.reports },
                    { title: 'category summary', path: PATH_DASHBOARD.analytics.categorySummary },
                    { title: 'contact summary', path: PATH_DASHBOARD.analytics.contactSummary },
                    { title: 'contact logs', path: PATH_DASHBOARD.analytics.contactLogs },
                    { title: 'treatment summary', path: PATH_DASHBOARD.analytics.treatmentSummary },
                    { title: 'source summary', path: PATH_DASHBOARD.analytics.sourceSummary },
                    { title: 'Practitioner summary', path: PATH_DASHBOARD.analytics.practitionerSummary },
                    { title: 'Archived summary', path: PATH_DASHBOARD.analytics.archiveSummary },
                    { title: 'Reports settings', path: PATH_DASHBOARD.analytics.reportsSetting },
                ]
            },
            {
                title: 'Campaigns Manager',
                path: PATH_DASHBOARD.campaign.root,
                icon: ICONS.campaigns,
                children: [
                    { title: 'Campaigns', path: PATH_DASHBOARD.campaign.campaign },
                    { title: 'Audience Manager', path: PATH_DASHBOARD.campaign.audienceManager },
                    { title: 'Templates', path: PATH_DASHBOARD.campaign.templates },
                ]
            },
            { title: 'Facebook', path: PATH_DASHBOARD.user.facebookComment, icon: ICONS.facebook },
            {
                title: 'Financial tracker',
                path: PATH_DASHBOARD.financialTracker,
                icon: ICONS.financial
            },
            {
                title: 'QR Codes',
                path: PATH_DASHBOARD.qrCodeGenerator,
                icon: ICONS.qrcode
            },
            {
                title: 'Messages',
                path: PATH_DASHBOARD.analytics.root,
                icon: ICONS.analytics,
                children: [
                    { title: 'reports', path: PATH_DASHBOARD.analytics.reports },
                    { title: 'category summary', path: PATH_DASHBOARD.analytics.categorySummary },
                    { title: 'treatment summary', path: PATH_DASHBOARD.analytics.treatmentSummary },
                    { title: 'source summary', path: PATH_DASHBOARD.analytics.sourceSummary },
                    { title: 'Practitioner summary', path: PATH_DASHBOARD.analytics.practitionerSummary },
                    { title: 'Archived summary', path: PATH_DASHBOARD.analytics.archiveSummary },
                    { title: 'Reports settings', path: PATH_DASHBOARD.analytics.reportsSetting },
                ]
            },
            { title: 'Notifications', path: PATH_DASHBOARD.user.notificationPage, icon: ICONS.notification },
            // { title: 'Messaging' },


            // { title: 'SMS', path: PATH_DASHBOARD.sms.root, icon: ICONS.sms },

            {
                title: 'Super admin',
                path: PATH_DASHBOARD.superAdmin.engagementTemplates,
                icon: ICONS.job,
                children: [
                    { title: 'Engagement template', path: PATH_DASHBOARD.superAdmin.engagementTemplates },
                    { title: 'Auto reminder', path: PATH_DASHBOARD.superAdmin.BulkAutoReminders },
                    { title: 'Import Leads', path: PATH_DASHBOARD.superAdmin.ImportLeads },
                    { title: 'Consent', path: PATH_DASHBOARD.user.consentBuilder },
                ]
            },
            {
                title: 'Website support',
                path: PATH_DASHBOARD.websiteSupport,
                icon: ICONS.websiteSupport
            }
        ],
    },
];

export default navConfig;
