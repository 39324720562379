import moment from 'moment';
import { enqueueSnackbar } from 'notistack';
import { inputKeys } from 'src/constants/LeadDetailConst';
import { createSubmission } from 'src/redux/slices/callScript';
import { getPracticeId } from 'src/redux/slices/clincheck';
import { clearTaskLead, searchLead } from 'src/redux/slices/lead';
import { dispatch } from 'src/redux/store';
import capitalizeFirstLetter from './capitalizedFunc';

export default function onFieldNameChange(event, sections, sectionId, fieldId, setSections) {
    const updatedData = sections.map(parent => {
        if (parent.id === sectionId) {
            const updatedItems = parent.fields.map(item => {
                if (item.id === fieldId) {
                    return { ...item, name: event.target.value };
                }
                return item;
            });
            return { ...parent, fields: updatedItems };
        }
        return parent;
    });
    setSections(updatedData);
};

export const setAddressData = (addresses, fulladdress, setValue) => {
    ClearAddressData(setValue);
    if (addresses && addresses.length > 0) {
        const address1 = fulladdress.split(',')[0];
        setValue('address_line_1', address1);
        addresses.forEach(address => {
            if (address.types.indexOf('postal_code') > -1) {
                setValue('postcode', address.long_name);
            }
            if (address.types.indexOf('country') > -1) {
                if (address1 === address.long_name) {
                    setValue('address_line_1', '');
                }
                setValue('county', address.long_name);
            }
            if (address.types.indexOf('locality') > -1 || address.types.indexOf('postal_town') > -1) {
                if (address1.includes(address.long_name)) {
                    setValue('address_line_1', '');
                }
                setValue('city', address.long_name);
            }
            if (address.types.indexOf('route') > -1) {
                if (!address1.includes(address.long_name)) {
                    setValue('address_line_2', address.long_name);
                    return;
                }
                setValue('address_line_2', '');
            }
        });
    } else {
        ClearAddressData(setValue);
    }
};

export const ClearAddressData = (setValue) => {
    setValue('postcode', '');
    setValue('address_line_2', '');
    setValue('county', '');
    setValue('city', '');
    setValue('address_line_1', '');
};

export const submissionFunc = ({ data, leadId, type, fieldData, handleSuccess, scriptData, setLeadId }) => {
    const practiceId = getPracticeId();
    const textKeys = Object.entries(data).filter(key => key);
    const fieldDataArray = fieldData.flatMap(data => data.fields.map(field => field));
    const fieldDataWithValue = textKeys?.filter((el) => fieldDataArray.some((sx) => sx?.id?.toString().includes(el[0]?.split('_')[1]) ? { fields: sx.id, value: el[1] } : ''));
    const submissionData = {
        practice: Number(practiceId),
        lead: leadId,
        formId: scriptData?.id,
        fields: fieldDataWithValue?.map((el) => ({ field: Number(el[0]?.split('_')[1]), value: Array.isArray(el[1]) ? el[1].join(' | ') : el[1] }))
    };
    const dataValid = submissionData.fields;
    const valueValid = dataValid.map((el) => el.value !== undefined);
    setLeadId(leadId);
    if (data && valueValid.includes(true)) {
        dispatch(createSubmission(submissionData, handleSuccess, type));
    }
};

export const handleAddressData = ({ data, leadData, leadUpdateData }) => {
    if (data.preferredContactMethod) {
        leadData.lead_contact = {
            ...leadData.lead_contact,
            preferred_contact_method: data.preferredContactMethod?.toUpperCase()
        };
        leadUpdateData.lead_contact = {
            ...leadUpdateData.lead_contact,
            preferred_contact_method: data.preferredContactMethod?.toUpperCase()
        };
    }
    if (data.address_line_1) {
        leadData.lead_address = {
            ...leadData.lead_address,
            address_line_1: data.address_line_1
        };
        leadUpdateData.lead_address = {
            ...leadUpdateData.lead_address,
            address_line_1: data.address_line_1
        };
    }
    if (data.postcode) {
        leadData.lead_address = {
            ...leadData.lead_address,
            postcode: data.postcode
        };
        leadUpdateData.lead_address = {
            ...leadUpdateData.lead_address,
            postcode: data.postcode
        };
    }
    if (data.address_line_2) {
        leadData.lead_address = {
            ...leadData.lead_address,
            address_line_2: data.address_line_2
        };
        leadUpdateData.lead_address = {
            ...leadUpdateData.lead_address,
            address_line_2: data.address_line_2
        };
    }
    if (data.city) {
        leadData.lead_address = {
            ...leadData.lead_address,
            city: data.city,
        };
        leadUpdateData.lead_address = {
            ...leadUpdateData.lead_address,
            city: data.city
        };
    }
    if (data.county) {
        leadData.lead_address = {
            ...leadData.lead_address,
            county: data.county
        };
        leadUpdateData.lead_address = {
            ...leadUpdateData.lead_address,
            county: data.county
        };
    }
};

export const handleSuccessMessage = ({ data }) => {
    enqueueSnackbar(data.message || '', {
        variant: data.success ? 'success' : 'error',
        anchorOrigin: { vertical: 'top', horizontal: 'center' }
    });
};

export const getAddressField = (values) => values.address_line_1 || values.address_line_2 || values.city || values.county || values.postcode;

export const handleSearch = (searchQuery) => {
    const practiceId = getPracticeId();
    if (searchQuery.length > 0) {
        let search = searchQuery;
        if (Number(searchQuery) && !searchQuery.includes('+')) {
            search = `+44${searchQuery}`;
        }
        if (search) {
            dispatch(searchLead(encodeURIComponent(search), practiceId, 'callScriptPreview'));
        }
    }
};

export const searchLeadHandler = (value, reason, setSearchQuery) => {
    switch (reason) {
        case 'input':
            setSearchQuery(value);
            break;
        default:
            break;
    }
    if (value.length === 0) dispatch(clearTaskLead());
};

export const inboundSubmissionFunc = ({ data, fieldData, handleSuccess, selectedFormId, leadId }) => {
    const practiceId = getPracticeId();
    const textKeys = Object.entries(data).filter(key => key);
    const fieldDataArray = fieldData.flatMap(data => data.fields.map(field => field));
    const fieldDataWithValue = textKeys?.filter((el) => fieldDataArray.some((sx) => sx?.id?.toString().includes(el[0]?.split('_')[1]) ? { fields: sx?.field_type?.id, value: el[1] } : ''));
    const submissionData = {
        practice: Number(practiceId),
        lead: data?.lead_id || leadId,
        formId: selectedFormId,
        fields: fieldDataWithValue?.map((el) => ({ field: Number(el[0]?.split('_')[1]), value: Array.isArray(el[1]) ? el[1].join(' | ') : el[1] }))
    };
    const dataValid = submissionData.fields;
    const valueValid = dataValid.map((el) => el.value !== undefined && el.value !== '');
    if (data && valueValid.includes(true)) {
        dispatch(createSubmission(submissionData, handleSuccess));
    }
};

export const templateChangeFunc = ({ menuItems, setSections, setOpenIndexes }) => {
    const selectedSections = [...(menuItems?.sections || [])].sort((a, b) => a.order - b.order);
    if (selectedSections) {
        setSections((prevSections) => {
            const filter = prevSections?.filter((el) => (el.name || el.fields.length > 0))?.map(e => ({ ...e, isPreviousTemplate: e?.created_at != null }));
            const selectedSectionsModify = selectedSections.map((section, index) => ({
                ...section,
                id: index,
                fields: section.fields.map((field) => ({
                    ...field,
                    id: field.field_type?.content_type_id || field.content_type_id,
                })),
            }));
            return [...selectedSectionsModify, ...filter];
        });
        setOpenIndexes((prevOpenIndexes) => {
            const newOpenIndexes = [...prevOpenIndexes];
            newOpenIndexes[0] = false;
            return newOpenIndexes;
        });
    }
};

export const numberKeyDownFunc = (evt) => {
    const isControlC = (evt.ctrlKey && evt.key === 'c') || (evt.metaKey && evt.key === 'c');
    const isControlV = (evt.ctrlKey && evt.key === 'v') || (evt.metaKey && evt.key === 'v');
    const isAllowedKey = inputKeys.includes(evt.key) || (/^[0-9]*$/).test(evt.key);
    if (!(isControlC || isControlV || isAllowedKey)) {
        evt.preventDefault();
    }
};

export const templateRenderFunc = ({ value, setValue, InboundScripts, setSelectedFormId, setTempFieldData, fieldData }) => {
    if (value) {
        setValue('template', value);
        const selectedSections = InboundScripts?.find((el) => el.id === value);
        const selectedScript = selectedSections?.sections || [];
        setSelectedFormId(selectedSections?.id);
        if (selectedScript?.length > 0) {
            setTempFieldData(() => {
                if (selectedScript.length > 0) {
                    const fieldNameToMatch = fieldData?.[0]?.name;
                    const filteredScript = selectedScript.filter(
                        (section) => section.name !== fieldNameToMatch
                    );
                    return [...filteredScript];
                }
                return [...selectedScript];
            });
        }
    }
};

export const searchLeadFunc = ({ value, setValue, setSearchQuery, clearErrors, getCallScriptSection, setLeadTreatmentValue, setLeadTreatmentTeethValue, setNewTreatment, setLeadTreatment, setAssignToState }) => {
    if (value) {
        setValue('leadName', `${value?.first_name} ${value?.last_name}`);
        setSearchQuery(`${value?.first_name} ${value?.last_name}`);
        setValue('lead_id', value?.id);
        setValue('treatment_id', value?.lead_treatments[0]?.id);
    }
    clearErrors('leadName');
    const leadArr = value;
    const practiceId = getPracticeId();
    if (leadArr) {
        dispatch(getCallScriptSection({ practiceId, treatment: leadArr?.lead_treatments?.length === 0 ? '' : leadArr?.lead_treatments[0]?.treatment, isTemplate: false, category: 'INBOUND' }));
        setValue('treatmentValue', leadArr?.lead_treatments[0]?.price.toString());
        setValue('teethAmount', leadArr?.lead_treatments[0]?.leadtreatment_specification?.teeth_count);
        setLeadTreatmentValue(leadArr?.lead_treatments[0]?.price.toString());
        setLeadTreatmentTeethValue(leadArr?.lead_treatments[0]?.leadtreatment_specification?.teeth_count);
        clearErrors('treatmentValue');
        setNewTreatment(leadArr?.lead_treatments?.length === 0 ? '' : leadArr?.lead_treatments[0]?.treatment);
        setValue('treatment', leadArr?.lead_treatments?.length === 0 ? '' : leadArr?.lead_treatments[0]?.treatment);
        setLeadTreatment(leadArr?.lead_treatments?.length === 0 ? '' : leadArr?.lead_treatments[0]?.treatment);
        setValue('type', leadArr?.healthcare_provider);
        setValue('leadType', leadArr?.lead_source?.type);
        setValue('preferredContactMethod', leadArr?.lead_contact?.preferred_contact_method);
        setValue('bookedWith', leadArr?.assignees?.length > 0 ? leadArr?.assignees : []);
        setAssignToState(leadArr?.assignees?.length > 0 ? leadArr?.assignees : []);
        setValue('referral', leadArr?.lead_source?.referral || '');
        setValue('affiliate_user', leadArr?.lead_source?.affiliate_user || '');
        setValue('firstName', leadArr?.first_name);
        setValue('lastName', leadArr?.last_name);
        setValue('email', leadArr?.lead_contact?.email);
        setValue('phone_number', leadArr?.lead_contact?.phone);
        setValue('title', leadArr?.salutation || 'MR');
        setValue('dob', leadArr?.dob ? new Date(moment(leadArr?.dob, 'DD/MM/YYYY')) : null);
        setValue('address_line_1', leadArr?.lead_address?.address_line_1 || '');
        setValue('address_line_2', leadArr?.lead_address?.address_line_2 || '');
        setValue('county', leadArr?.lead_address?.county || '');
        setValue('city', leadArr?.lead_address?.city || '');
        setValue('postcode', leadArr?.lead_address?.postcode || '');
        setValue('isRegister', leadArr?.is_registered_patient || false);
        clearErrors();
    } else {
        setValue('type', '');
        setValue('referral', '');
        setValue('affiliate_user', null);
        setValue('leadType', '');
    }
};

export const formatLeadData = (data, assignToState = [], isUpdate = false) => {
    const formattedData = {
        first_name: data.firstName,
        last_name: data.lastName,
        dob: data.dob ? moment(data.dob).format('DD-MM-YYYY') : null,
        lead_contact: { email: data.email, phone: data.phone_number },
        healthcare_provider: data.type,
        salutation: data?.title?.toUpperCase(),
        is_registered_patient: data.isRegister,
    };
    if (!isUpdate) {
        return {
            ...formattedData,
            assignees: assignToState?.map((el) => el?.id) || [],
        };
    }
    return formattedData;
};
export const formatLeadDetails = (data) => {
    const formattedData = {
        first_name: data.firstName,
        last_name: data.lastName || '',
        dob: data.dob ? moment(data.dob).format('DD-MM-YYYY') : null,
        lead_contact: {
            email: data.email,
            phone: data.phone_number
        },
        lead_source: {},
        lead_address: {},
        healthcare_provider: data.type || 'PRIVATE',
        is_registered_patient: data.isRegister,
    };
    return formattedData;
};
export const formatLeadTreatment = (data) => {
    const formattedData = {
        treatment: data.treatment,
        custom_price: data.treatmentValue,
        is_practice_notification_required: false,
        teethAmount: data.teethAmount
    };
    return formattedData;
};
export const handleLeadTypeData = ({ data, leadData, leadUpdateData }) => {
    if (data.leadType) {
        leadData.lead_source = {
            ...leadData.lead_source,
            type: data.leadType
        };
        leadUpdateData.lead_source = {
            ...leadUpdateData.lead_source,
            type: data.leadType
        };
    }
    if (data.referral) {
        leadData.lead_source = {
            ...leadData.lead_source,
            referral: data.referral
        };
        leadUpdateData.lead_source = {
            ...leadUpdateData.lead_source,
            referral: data.referral
        };
    }
    if (data?.affiliate_user) {
        leadUpdateData.lead_source = {
            ...leadUpdateData.lead_source,
            affiliate_user: data.affiliate_user,
            type: "CLINICIAN"
        };
    }
};
export const createDefaultValues = (user = {}) => ({
    type: '',
    inquired: '',
    speaking_to: `${user?.salutation ? `${capitalizeFirstLetter(user?.salutation)}.` : ''} ${user?.first_name || ''} ${user?.last_name || ''}`,
    leadType: '',
    firstName: '',
    phone_number: '',
    email: '',
    treatment: '',
    teethAmount: null,
    treatmentValue: '',
    referral: '',
    bookedWith: [],
    title: 'MR',
    isRegister: false,
    preferredContactMethod: ''
});
export const saveTreatmentFunc = ({ setNewTreatment, setValue, data }) => {
    setNewTreatment(data.selectedTreatment);
    setValue('treatmentValue', data.treatmentPrice);
    setValue('treatment', data.selectedTreatment);
    setValue('teethAmount', data.teethAmount);
};

export const handleTreatmentFunc = ({ onEditTreatment, setAnchorEditTreatment, event }) => {
    onEditTreatment();
    setAnchorEditTreatment(event.currentTarget);
};