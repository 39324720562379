import PropTypes from 'prop-types';
// @mui
import { Box, Chip, Paper, Button, Stack, Switch } from '@mui/material';
// components
import { Iconify } from '..';
// style
import { tableFilterBox } from '../../styles/NewLead';

const TableFilterResult = ({
    filters,
    onResetFilters,
    onReset,
    handleTreatment,
    handleReferral,
    dateChange,
    handleTags,
    handlePractitioner,
    handleDepositPendingFilter,
    handleConsultationBookedWith,
    tableTitle,
    handleAssigneeFilter,
    hideNewFilters,
    isTreatmentStarted,
    handleArchiveFilter,
    handleNoTreatmentSwitch,
    isNoTreatment,
    ...other
}) => {

    const handleRemoveTreatment = (inputValue) => {
        const newValue = filters.treatment.filter((item) => item.id !== inputValue);
        handleTreatment('', newValue);
    };

    const handleRemoveTags = (id) => {
        const newValue = filters.tags.filter((item) => item?.id !== id);
        const tagName = newValue.map((item) => item?.name);
        handleTags(newValue, tagName);
    };

    const handleRemoveAssignee = (id) => {
        const newValue = filters.assigneeSelectedForFilter?.filter((res) => res.id !== id)
        handleAssigneeFilter('', newValue);
    };

    const handleRemoveBookedWith = (id) => {
        const newValue = filters.assignTo?.filter((res) => res.id !== id)
        handlePractitioner('', newValue);
    };
    const handleRemoveConsultationBookedWith = (id) => {
        const newValue = filters.consultationBookedWith?.filter((res) => res.id !== id)
        handleConsultationBookedWith('', newValue);
    };

    return (
        <Stack spacing={1.5} {...other} sx={{width:"100%", marginTop:"10px"}}>
            <Stack flexGrow={1} spacing={1} direction='row' flexWrap='wrap' alignItems='center' justifyContent='space-between'>
                <Stack direction="row" spacing={1} alignItems="center">
                    {!!filters?.status?.length && tableTitle !== 'Aligner Approve Leads' && !hideNewFilters && (
                        <Block label={(filters.statusLabel?.length > 0 && `${filters.statusLabel}:`) || 'Status:'}>
                            <Chip label={filters?.status} size='small' />
                        </Block>
                    )}

                    {!!filters?.treatment?.length && tableTitle !== 'Aligner Approve Leads' && (
                        <Block label='Treatments:'>
                            {filters?.treatment?.map((item) => (
                                <Chip key={item.id} label={item.name} size='small' onDelete={() => handleRemoveTreatment(item.id)} />
                            ))}
                        </Block>
                    )}

                    {!!filters?.tags?.length && (
                        <Block label='Tags:'>
                            {filters?.tags?.map((item) => (
                                <Chip key={item?.id} label={item?.name} size='small' onDelete={() => handleRemoveTags(item?.id)} />
                            ))}
                        </Block>
                    )}

                    {!!filters?.referral?.length && (
                        <Block label='Referral:'>
                            <Chip label={filters?.referral} size='small' onDelete={(e) => handleReferral(e, { value: '' })} />
                        </Block>
                    )}

                    {!!filters?.assignTo?.length && (
                        <Block label={`${isTreatmentStarted ? "Treatment booked with:" : "Booked with:"} `}>
                            {filters?.assignTo?.map((item) => (
                                <Chip key={item.id} label={item.name} size='small' onDelete={() => handleRemoveBookedWith(item.id)} />
                            ))}
                        </Block>
                    )}

                    {!!filters?.consultationBookedWith?.length && (
                        <Block label='Consultation Booked with:'>
                            {filters?.consultationBookedWith?.map((item) => (
                                <Chip key={item.id} label={item.name} size='small' onDelete={() => handleRemoveConsultationBookedWith(item.id)} />
                            ))}
                        </Block>
                    )}

                    {(!!filters?.startDate?.length && !!filters?.endDate?.length) && (
                        <Block label='Date filter:'>
                            <Chip label={`${filters?.startDate} to ${filters?.endDate}`} size='small' onDelete={() => dateChange([null, null])} />
                        </Block>
                    )}

                    {(!!filters?.depositPaidFilter?.length) && (
                        <Block label='Deposit paid filter:'>
                            <Chip label={`${filters?.depositPaidFilter}` || ''} size='small' onDelete={() => handleDepositPendingFilter('')} />
                        </Block>
                    )}

                    {!!filters?.assigneeSelectedForFilter?.length && (
                        <Block label='Assignee:'>
                            {filters?.assigneeSelectedForFilter?.map((item) => (
                                <Chip key={item.id} label={item.name} size='small' onDelete={() => handleRemoveAssignee(item.id)} />
                            ))}
                        </Block>
                    )}

                    {!!filters?.archiveReason?.length && (
                        <Block label='Archive reason:'>
                            <Chip label={filters?.archiveReason} size='small' onDelete={(e) => handleArchiveFilter(e, { value: '' })} />
                        </Block>
                    )}

                    {onReset && <Button
                        color='error'
                        onClick={onResetFilters}
                        startIcon={<Iconify icon='solar:trash-bin-trash-bold' />}
                    >
                        Clear
                    </Button>}
                </Stack>
                <Box>
                    No Treatment
                    <Switch
                       checked={Boolean(isNoTreatment)}
                       onClick={(e) => handleNoTreatmentSwitch(e.target.checked)}
                        color="primary"
                    />
                </Box>
            </Stack>
        </Stack>
    );
};

TableFilterResult.propTypes = {
    filters: PropTypes.object,
    onResetFilters: PropTypes.func,
    results: PropTypes.number,
    onReset: PropTypes.bool,
    handleTreatment: PropTypes.func,
    handleReferral: PropTypes.func,
    dateChange: PropTypes.func,
    handleTags: PropTypes.func,
    handlePractitioner: PropTypes.func,
    handleDepositPendingFilter: PropTypes.func,
    handleConsultationBookedWith: PropTypes.func,
    tableTitle: PropTypes.string,
    handleAssigneeFilter: PropTypes.func,
    hideNewFilters: PropTypes.bool,
    isTreatmentStarted: PropTypes.bool,
    handleArchiveFilter: PropTypes.func,
    handleNoTreatmentSwitch: PropTypes.func,
    isNoTreatment: PropTypes.bool
};

const Block = ({ label, children, sx, ...other }) => (
    <Stack
        component={Paper}
        variant='outlined'
        spacing={1}
        direction='row'
        sx={tableFilterBox(sx)}
        {...other}
    >
        <Box component='span' sx={{ typography: 'subtitle2' }}>
            {label}
        </Box>

        <Stack spacing={1} direction='row' flexWrap='wrap'>
            {children}
        </Stack>
    </Stack>
);

Block.propTypes = {
    children: PropTypes.node,
    label: PropTypes.string,
    sx: PropTypes.object,
    hideNewFilters: PropTypes.bool
};

export default TableFilterResult;