import PropTypes from 'prop-types';
import React, { useState } from 'react';
import moment from 'moment';
// use @mui
import {
    TableRow,
    TableCell,
    Box,
    Typography,
    MenuItem,
    MenuList,
    ListItemText,
    Button,
    Checkbox,
    Chip,
} from '@mui/material';
// components
import { isArray, isEmpty, uniqBy } from 'lodash';
import { useGetCategories, useToggle } from '../../../hooks';
import ValidationResultPopup from '../../../components/AudienceManager/validationResultPopup';
import { useSelector } from '../../../redux/store';
import useUserList from '../../../hooks/useUserList';
import { chipLabelStyle } from '../../../styles/CampaignPageStyle';
import { Iconify } from '../../../components';
import { TableMoreMenu } from '../../../components/table';
import { ConfirmDialog } from '../../../components/dialogPopUp';
// style
import { messageTableMenuItem } from '../../../styles/MessageListStyle';

// ----------------------------------------------------------------
export default function AudienceTableRow({
    row,
    onSelectRow,
    handleCreateCampaign,
    type,
    selectedRow,
    handleRowSelection,
    onDeleteRow,
}) {
    const [openMenu, setOpenMenuActions] = useState(null);
    const [openConfirm, setOpenConfirm] = useState(false);
    const { referral } = useSelector((state) => state.schema);
    const tagInArray = row?.audience_internal_queryset?.filters?.tagged_items__tag__name__in || [];
    const statusInArray = row?.audience_internal_queryset?.filters?.lead_treatments__status__in || [];
    const sourceTypeInArray = row?.audience_internal_queryset?.filters?.lead_source__type__in || [];
    const categoryInArray = row?.audience_internal_queryset?.filters?.lead_treatments__treatment__treatment_item__treatment_category__in || [];
    const treatmentInArray =
        row?.audience_internal_queryset?.filters?.lead_treatments__treatment__treatment_item__name__in || [];
    const archiveData = row?.audience_internal_queryset?.filters?.is_only_archived || '';
    const practitionerInArray = useUserList()
        ?.filter((el) => row?.audience_internal_queryset?.filters?.assignees__user__id__in?.includes(el?.user?.id))
        ?.map((el) => `${el?.user?.salutation || ''} ${el?.user?.first_name} ${el?.user?.last_name}`);
    const referralInArray = [
        referral?.filter((el) =>
            row?.audience_internal_queryset?.filters?.lead_source__referral__in?.includes(el.value)
        )?.[0]?.label || '',
    ];
    const dateRangeInArray = row?.audience_internal_queryset?.filters?.created_at__range || [];
    const formattedDateRange = dateRangeInArray.map(date => moment(date).format('DD-MM-YYYY'));
    const combinedLabel = formattedDateRange.join(' to ');
    const csvLink = row?.audience_csv?.csv;

    const { toggle: validationResult, onOpen: validationResultOpen, onClose: validationResultClose } = useToggle();

    const handleOpenConfirm = () => {
        setOpenConfirm(true);
    };

    const categories = useGetCategories();

    const getCategory = () => categories?.filter((el) => categoryInArray?.includes(el?.id))?.map((el) => el.name);

    const handleCloseConfirm = (e) => {
        e.stopPropagation();
        setOpenConfirm(false);
        onDeleteRow();
    };

    const handleOpenMenu = (event) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const handleAudienceSelect = () => {
        if (type === 'ExistingAudience' && (getValidationStatus(row) === 'Success' || getValidationStatus(row) === '-')) {
            handleRowSelection(row.id, row);
        }
    };

    const handleMenuClick = (event) => {
        event.stopPropagation();
    };

    const getStatus = (status) => {
        if (status === 'IN_PROGRESS') return 'In Progress';
        if (status === 'SUCCESS') return 'Success';
        if (status === 'FAILED') return 'Failed';
        return '-';
    };

    const getValidationStatus = (data) => {
        if (row.type === 'INTERNAL_QUERYSET') return '-';
        if (!isEmpty(data) && !isEmpty(data.audience_csv)) {
            return getStatus(data.audience_csv.validation_status);
        }
        return '-';
    };

    return (
        <TableRow sx={{ borderTop: 'solid 1px rgba(241, 243, 244, 1)' }} onClick={handleAudienceSelect}>
            {type === 'ExistingAudience' && (
                <TableCell>
                    <Checkbox checked={selectedRow === row.id} value={row.id?.toString()} name="radio-button" disabled={!(getValidationStatus(row) === 'Success' || getValidationStatus(row) === '-')} />
                </TableCell>
            )}
            <TableCell>
                <Box sx={{ ml: 0 }}>
                    <Typography noWrap variant="subtitle2" sx={{ width: '100%', fontWeight: 600 }}>
                        {row && row.name}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell>
                <Box sx={{ ml: 0 }}>
                    <Typography noWrap variant="subtitle2" sx={{ width: '100%', fontWeight: 600 }}>
                        {getValidationStatus(row)}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell sx={{ maxWidth: '200px' }}>
                <Box sx={{ ml: 0 }}>
                    {row.type === 'CSV' && row?.audience_csv?.validation_result?.length > 0 ? (
                        <Button onClick={() => validationResultOpen()}>View results</Button>
                    ) : (
                        <Typography noWrap variant="subtitle2" sx={{ width: '100%', fontWeight: 600 }}>
                            {'-'}
                        </Typography>
                    )}
                </Box>
            </TableCell>
            <TableCell sx={{ maxWidth: '320px' }}>
                <Box sx={{ display: 'flex', overflow: 'auto' }}>
                    {row && row.type === 'INTERNAL_QUERYSET' ? (
                        <>
                            {isArray(tagInArray) && tagInArray?.map((tag, index) => <Chip key={index} label={tag} sx={chipLabelStyle} />)}
                            {statusInArray &&
                                statusInArray?.map((status, index) => <Chip key={index} label={status} sx={chipLabelStyle} />)}
                            {treatmentInArray &&
                                treatmentInArray?.map((treatment, index) => <Chip key={index} label={treatment} sx={chipLabelStyle} />)}
                            {practitionerInArray &&
                                practitionerInArray?.map((status, index) => <Chip key={index} label={status} sx={chipLabelStyle} />)}
                            {categoryInArray && isArray(getCategory()) && getCategory().length > 0 &&
                                uniqBy(getCategory())?.map((status, index) => <Chip key={index} label={status} sx={chipLabelStyle} />)}
                            {sourceTypeInArray &&
                                sourceTypeInArray?.map((status, index) => <Chip key={index} label={status} sx={chipLabelStyle} />)}
                            {referralInArray &&
                                referralInArray[0] !== '' &&
                                referralInArray?.map((status, index) => <Chip key={index} label={status} sx={chipLabelStyle} />)}
                            {combinedLabel && <Chip label={combinedLabel} sx={chipLabelStyle} />}
                            {archiveData && <Chip label={'Archive'} sx={chipLabelStyle} />}
                        </>
                    ) : (
                        csvLink && <Chip label={'csv file'} component="a" href={csvLink} clickable sx={chipLabelStyle} />
                    )}
                </Box>
            </TableCell>
            <TableCell>
                <Box sx={{ ml: 0 }}>
                    <Typography noWrap variant="subtitle2" sx={{ width: '100%', fontWeight: 600 }}>
                        {row?.recepient_count || row?.recepient_count === 0 ? row?.recepient_count : '-'}
                    </Typography>
                </Box>
            </TableCell>
            <TableCell align="right" onClick={handleMenuClick}>
                <TableMoreMenu
                    open={openMenu}
                    sx={{ width: '200px' }}
                    onOpen={handleOpenMenu}
                    onClose={handleCloseMenu}
                    actions={
                        <MenuList>
                            <MenuItem
                                sx={{ color: '#00000' }}
                                onClick={() => {
                                    onSelectRow(row);
                                    handleCloseMenu();
                                }}
                            >
                                <Iconify icon={'solar:pen-bold'} sx={{ mr: 0, width: '24px !important', height: '24px !important' }} />
                                <ListItemText primary="Edit audience" primaryTypographyProps={messageTableMenuItem} />
                            </MenuItem>
                            {type !== 'ExistingAudience' && (getValidationStatus(row) === 'Success' || getValidationStatus(row) === '-') && (
                                <MenuItem
                                    sx={{ color: '#00000' }}
                                    onClick={() => {
                                        handleCreateCampaign(row);
                                        handleCloseMenu();
                                    }}
                                >
                                    <img
                                        src="/assets/icons/navbar/ic_campaign_black.svg"
                                        alt="campaign"
                                        style={{ marginRight: '16px', height: '24px' }}
                                    />
                                    <ListItemText primary="Create campaign" primaryTypographyProps={messageTableMenuItem} />
                                </MenuItem>
                            )}
                            {/* {type !== 'ExistingAudience' && <MenuItem onClick={() => { handleReportEvent(row); handleCloseMenu(); }}>
                                <Iconify
                                    icon={'uis:chart'}
                                    sx={{ mr: 0, width: '24px !important', height: '24px !important' }}
                                />
                                <ListItemText
                                    primary="View report"
                                    primaryTypographyProps={messageTableMenuItem}
                                />
                            </MenuItem>} */}
                            <MenuItem
                                sx={{ color: 'rgba(255, 72, 66, 1)' }}
                                onClick={() => {
                                    handleOpenConfirm();
                                    handleCloseMenu();
                                }}
                            >
                                <Iconify
                                    icon={'eva:archive-fill'}
                                    sx={{ mr: 0, width: '24px !important', height: '24px !important' }}
                                />
                                <ListItemText primary="Archive" primaryTypographyProps={messageTableMenuItem} />
                            </MenuItem>
                        </MenuList>
                    }
                />
            </TableCell>
            <ConfirmDialog
                open={openConfirm}
                onClose={() => setOpenConfirm(false)}
                title="Delete"
                content="Are you sure you want to delete?"
                action={
                    <Button variant="contained" color="error" onClick={handleCloseConfirm}>
                        Delete
                    </Button>
                }
            />
            <ValidationResultPopup
                open={validationResult}
                onClose={validationResultClose}
                results={row?.audience_csv?.validation_result}
            />
        </TableRow>
    );
}

AudienceTableRow.propTypes = {
    row: PropTypes.object,
    onSelectRow: PropTypes.any,
    handleCreateCampaign: PropTypes.any,
    type: PropTypes.string,
    selectedRow: PropTypes.any,
    handleRowSelection: PropTypes.any,
    onDeleteRow: PropTypes.any,
};
