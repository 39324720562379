import React from 'react';
import PropTypes from 'prop-types';
// libraries
import {
    Box,
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    Typography,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// components
import Iconify from '../Iconify';
import { FormProvider, RHFTextField } from '../hook-form';
import ReportsAudienceForm from '../ReportsAudienceForm';
import { Transition } from '.';
// redux
import { dispatch, useSelector } from '../../redux/store';
import { updateError } from '../../redux/slices/user';
import { handleCreateAudience, setHeaders, updateSelectedAudience } from '../../redux/slices/audienceManager';
import { updateConditionList } from '../../redux/slices/campaign';
// validations
import { AudienceCampaignSchema } from '../../validations/validations';
// styles
import { grey } from '../../theme/palette';
import {
    createScriptButtonStyle,
    saveButtonStyle,
    cancelButtonStyle,
    reportsAudienceStack,
    reportsAudienceTitleWrapper,
} from '../../styles/AudienceManager';
import { leadListingBox, reminderStackStyle } from '../../styles/AddReminderPopup';

const ReportsAudienceDialog = ({
    generateAudience,
    currentTab,
    handleCreateAudienceSuccess,
    handleCloseAudienceDialog,
}) => {
    const defaultValues = {
        audienceName: '',
    };

    const { newAudience, isAudienceIncludeArchive, selectedAudienceData } = useSelector(
        (state) => state.audienceManager
    );

    const methods = useForm({
        resolver: yupResolver(AudienceCampaignSchema),
        defaultValues,
    });

    const { reset, handleSubmit } = methods;

    const AUDIENCE_TABS = [
        {
            value: 'By Conditions',
            component: <ReportsAudienceForm />,
        },
    ];

    const getFilters = () => ({
        filters: {
            ...(newAudience.tagged_items__tag__name__in ||
                selectedAudienceData?.recepient?.filters?.tagged_items__tag__name__in
                ? newAudience.tagged_items__tag__name__in || {
                    tagged_items__tag__name__in: selectedAudienceData?.recepient?.filters.tagged_items__tag__name__in,
                }
                : {}),
            ...(newAudience.lead_treatments__status__in ||
                selectedAudienceData?.recepient?.filters?.lead_treatments__status__in
                ? newAudience.lead_treatments__status__in || {
                    lead_treatments__status__in: selectedAudienceData?.recepient?.filters?.lead_treatments__status__in,
                }
                : {}),
            ...(newAudience.lead_treatments__treatment__treatment_item__name__in ||
                selectedAudienceData?.recepient?.filters?.lead_treatments__treatment__treatment_item__name__in
                ? newAudience.lead_treatments__treatment__treatment_item__name__in || {
                    lead_treatments__treatment__treatment_item__name__in:
                        selectedAudienceData?.recepient?.filters?.lead_treatments__treatment__treatment_item__name__in,
                }
                : {}),
            ...(newAudience.assignees__user__id__in || selectedAudienceData?.recepient?.filters?.assignees__user__id__in
                ? newAudience.assignees__user__id__in || {
                    assignees__user__id__in: selectedAudienceData?.recepient?.filters?.assignees__user__id__in,
                }
                : {}),
            ...(newAudience.lead_source__referral__in || selectedAudienceData?.recepient?.filters?.lead_source__referral__in
                ? newAudience.lead_source__referral__in || {
                    lead_source__referral__in: selectedAudienceData?.recepient?.filters?.lead_source__referral__in,
                }
                : {}),
            ...(newAudience.created_at__range || selectedAudienceData?.recepient?.filters?.created_at__range
                ? newAudience.created_at__range || {
                    created_at__range: selectedAudienceData?.recepient?.filters?.created_at__range,
                }
                : {}),
            ...(newAudience.lead_source__type__in || selectedAudienceData?.recepient?.filters?.lead_source__type__in
                ? newAudience.lead_source__type__in || {
                    lead_source__type__in: selectedAudienceData?.recepient?.filters?.lead_source__type__in,
                }
                : {}),
            ...(newAudience.lead_treatments__treatment__treatment_item__treatment_category__in || selectedAudienceData?.recepient?.filters?.lead_treatments__treatment__treatment_item__treatment_category__in
                ? newAudience.lead_treatments__treatment__treatment_item__treatment_category__in || {
                    lead_treatments__treatment__treatment_item__treatment_category__in: selectedAudienceData?.recepient?.filters?.lead_treatments__treatment__treatment_item__treatment_category__in,
                }
                : {}),
        },
        is_archived_included: isAudienceIncludeArchive,
    });

    const isNext = () =>
        newAudience?.tagsNames?.length > 0 ||
        newAudience?.treatmentName?.length > 0 ||
        newAudience?.statusNames?.length > 0 ||
        newAudience?.archivedValue ||
        newAudience?.practitionerName?.length > 0 ||
        newAudience?.referralName?.length > 0 ||
        newAudience?.dateRange?.length > 0 ||
        newAudience?.source__type?.length > 0 ||
        newAudience?.category__in?.length > 0 ||
        selectedAudienceData?.recepient?.filters?.tagged_items__tag__name__in?.length > 0 ||
        selectedAudienceData?.recepient?.filters?.lead_treatments__treatment__treatment_item__name__in?.length > 0 ||
        selectedAudienceData?.recepient?.filters?.lead_treatments__status__in?.length > 0 ||
        selectedAudienceData?.recepient?.filters?.is_only_archived ||
        selectedAudienceData?.recepient?.filters?.assignees__user__id__in?.length > 0 ||
        selectedAudienceData?.recepient?.filters?.lead_source__referral__in?.length > 0 ||
        selectedAudienceData?.recepient?.filters?.created_at__range?.length > 0 ||
        selectedAudienceData?.recepient?.filters?.lead_treatments__treatment__treatment_item__treatment_category__in?.length > 0 ||
        selectedAudienceData?.recepient?.filters?.lead_source__type__in?.length > 0;

    const handleClose = () => {
        handleCloseAudienceDialog();
    };

    const onSubmit = (data) => {
        if (!isNext()) {
            dispatch(
                updateError({
                    message: 'Please select any one tag',
                    status: false,
                })
            );
            return;
        }
        dispatch(setHeaders(null));
        const payloadData = {
            name: data?.audienceName || '',
            type: 'INTERNAL_QUERYSET',
            audience_internal_queryset: getFilters(),
        };
        if (newAudience.is_only_archived || selectedAudienceData?.recepient?.filters?.is_only_archived) {
            if (
                (newAudience.is_only_archived && newAudience?.is_only_archived?.is_open_for_communication === 'false') ||
                (selectedAudienceData?.recepient?.filters?.is_only_archived &&
                    (selectedAudienceData?.recepient?.filters?.is_open_for_communication === 'false' ||
                        selectedAudienceData?.recepient?.filters?.is_open_for_communication === false))
            ) {
                payloadData.audience_internal_queryset.filters = {
                    ...payloadData.audience_internal_queryset.filters,
                    is_only_archived: true,
                    is_open_for_communication: false,
                };
            } else if (newAudience.is_only_archived || selectedAudienceData?.recepient?.filters?.is_only_archived) {
                payloadData.audience_internal_queryset.filters = {
                    ...payloadData.audience_internal_queryset.filters,
                    is_only_archived: true,
                    is_open_for_communication: true,
                };
            }
        }
        const filteredFilters = Object.entries(payloadData?.audience_internal_queryset?.filters)
            .filter(([, value]) => !Array.isArray(value) || value.length > 0)
            .reduce((obj, [key, value]) => {
                obj[key] = value;
                return obj;
            }, {});
        const finalCampaignData = {
            ...payloadData,
            audience_internal_queryset: { ...payloadData?.audience_internal_queryset, filters: filteredFilters },
        };
        dispatch(handleCreateAudience({ payload: finalCampaignData, type: 'AudiencePopup', shouldShowAudienceSuccessMsg: false }, handleCreateAudienceSuccess));
        reset(defaultValues);
        dispatch(updateSelectedAudience({}));
        dispatch(updateConditionList([]));
    };

    return (
        <div>
            <Dialog TransitionComponent={Transition} open={generateAudience.value} onClose={handleClose} sx={leadListingBox}>
                <Stack sx={{ ...reminderStackStyle, ...reportsAudienceStack }}>
                    <DialogTitle sx={{ padding: '0' }}>
                        {
                            <Box sx={reportsAudienceTitleWrapper}>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <IconButton onClick={handleClose} sx={{ p: 0, mr: 1 }}>
                                        <Iconify height={24} width={24} icon='fa:angle-left' sx={{ color: grey[600] }} />
                                    </IconButton>
                                    <Typography variant='body' noWrap sx={{ fontSize: '20px' }}>
                                        Generate an audience
                                    </Typography>
                                </Box>
                            </Box>
                        }
                    </DialogTitle>
                    <DialogContent sx={{ p: 0 }}>
                        <Box sx={{ marginTop: '24px' }}>
                            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                <Box sx={{ mb: '40px' }}>
                                    <Box sx={{ mb: 1 }}>
                                        <RHFTextField name='audienceName' label='Audience Name' sx={{ mb: 1 }} />
                                        <Typography sx={{ color: grey[600], fontSize: '12px' }}>
                                            Give your campaign a unique, identifiable name.
                                        </Typography>
                                    </Box>
                                    {AUDIENCE_TABS.map((tab) => {
                                        const isMatched = tab.value === currentTab;
                                        return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                                    })}
                                </Box>
                                <Box sx={createScriptButtonStyle}>
                                    <Button variant='outlined' sx={cancelButtonStyle} onClick={handleClose}>
                                        Cancel
                                    </Button>
                                    <Button type='submit' variant='contained' sx={saveButtonStyle}>
                                        Save Audience
                                    </Button>
                                </Box>
                            </FormProvider>
                        </Box>
                    </DialogContent>
                </Stack>
            </Dialog>
        </div>
    );
};

export default ReportsAudienceDialog;
ReportsAudienceDialog.propTypes = {
    generateAudience: PropTypes.bool,
    currentTab: PropTypes.string,
    handleCreateAudienceSuccess: PropTypes.func,
    handleCloseAudienceDialog: PropTypes.func,
};
