import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import { Iconify } from 'src/components';
import { tableCellInnerBoxStyle } from '../../../../styles/NewLeadTableRowStyle';

const CustomSelectTreatmentCell = ({ params, handleNext }) => {

    const handleClickEvent = (e) => {
        if (params?.row?.referral === 'WHATSAPP') {
            handleNext(params?.row, e, true);
        } else
            handleNext(params?.row, e);
    };

    return (
        <Box onClick={(e) => handleClickEvent(e)} sx={tableCellInnerBoxStyle} disabled={params?.row?.is_archive_pending}>
            {params?.row?.referral === 'WHATSAPP' ?
                <Button
                    color='primary'
                    variant='contained'
                    disabled={params?.row?.is_archive_pending}
                    sx={{ p: '9px 16px' }}
                >
                    <Iconify icon={'ri:whatsapp-fill'} width={20} height={20} sx={{ mr: 1 }} /> Whatsapp
                </Button>
                : <Button
                    color='primary'
                    variant='outlined'
                    disabled={params?.row?.is_archive_pending}
                >
                    Select Treatment
                </Button>
            }
        </Box>
    );
};

export default CustomSelectTreatmentCell;

CustomSelectTreatmentCell.propTypes = {
    params: PropTypes.any,
    handleNext: PropTypes.func,
};