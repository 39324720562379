/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unused-state */
import React from 'react';
import { Box, Typography, Button, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';

class AppErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidUpdate(prevProps) {
    // eslint-disable-next-line react/prop-types
    if (this.state.hasError && prevProps.children !== this.props.children && window.reset_error) {
      console.warn("Resetting ErrorBoundary state after component reload...");
      this.setState({ hasError: false, error: null, errorInfo: null });
      window.reset_error = false;
    }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  render() {
    return (
      <Box sx={{ padding: '20px', textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Something went wrong.
        </Typography>
        <Typography variant="body1" color="text.secondary" gutterBottom>
          {this.state.error?.toString()}
        </Typography>

        {/* <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
          <Accordion >
            <AccordionSummary expandIcon={<iconify-icon icon="akar-icons:plus" />}>
              <Typography> Check for more details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap' }}>
                {this.state.errorInfo?.componentStack}
              </Typography>
            </AccordionDetails>
          </Accordion>
        </Box> */}

        <Button
          variant="contained"
          color="primary"
          onClick={() => window.location.reload()}
          sx={{ marginTop: '20px' }}
        >
          Reload
        </Button>
      </Box>
    );
  }
}


export default AppErrorBoundary;
