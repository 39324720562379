import React, { useState } from 'react';
import PropTypes from 'prop-types';
// utils
import { useGetTreatment } from 'src/hooks/useGetTreatment';
// components
import WidgetButtons from './WidgetButtons';
import CustomWidgetCard from './CustomWidgetCard';
// hooks
import { useToggle } from '../../hooks';

const TaskReportWidget = ({ onWidgetClick, dateRangeProps }) => {
    const { toggle, onOpen, onClose } = useToggle(true);

    const [currentWidget, setCurrentWidget] = useState('0');

    const { getTreatmentId } = useGetTreatment();

    const handleOpen = () => onOpen();

    const handleClose = () => onClose();

    const widgetArray = [
        { key: 'Total', icon: '', value: '0', text: 'Total Task' },
        { key: 'Invisalign', icon: '', value: 'invisalign', text: 'Task for Invisalign', isTreatment: true },
        { key: 'Bonding', icon: '', value: 'bonding', text: 'Task for Bonding', isTreatment: true },
        { key: 'Implants', icon: '', value: 'implants', text: 'Task for Implants', isTreatment: true },
        { key: 'Whitening', icon: '', value: 'whitening', text: 'Task for Whitening', isTreatment: true },
        // { key: '6', icon: '', value: '6', text: 'Estimated value of tasks', isPrice: true },
    ];

    const handleWidgetClick = (value) => {
        setCurrentWidget(value);
        const treatmentId = getTreatmentId(value)    
        onWidgetClick(treatmentId);
    };

    return (
        <>
            <WidgetButtons
                toggle={toggle}
                dateRangeProps={dateRangeProps}
                handleOpen={handleOpen}
                handleClose={handleClose}
            />
            {toggle && (
                <CustomWidgetCard handleWidgetClick={handleWidgetClick} widgetArray={widgetArray} currentWidget={currentWidget} isTaskWidget />
            )}
        </>
    );
};

export default TaskReportWidget;

TaskReportWidget.propTypes = {
    onWidgetClick: PropTypes.func,
    dateRangeProps: PropTypes.any,
};
