import React, { useEffect, useState } from 'react';
// libraries
import { useForm } from 'react-hook-form';
// components
import { ReferralDropdown } from '../../Dropdowns';
// redux
import { saveNewAudience, updateSelectedAudience } from '../../../redux/slices/audienceManager';
import { dispatch, useSelector } from '../../../redux/store';

const AudienceLeadReferelDropdown = () => {
    const { referral } = useSelector((state) => state.schema);
    const [getStatusValue, setStatusValue] = useState('');
    const { newAudience, selectedAudienceData } = useSelector((state) => state.audienceManager);

    const defaultValues = {
        referral: newAudience.referralName || getStatusValue || []
    };

    const methods = useForm({
        defaultValues,
    });

    const {
        setValue,
    } = methods;

    useEffect(() => {
        if (selectedAudienceData) {
            const statusValue = referral?.find((el) => selectedAudienceData?.recepient?.filters?.lead_source__referral__in?.includes(el.value))?.label;
            setStatusValue(statusValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAudienceData]);

    const handleReferral = (event, data) => {
        if (data) {
            const value = { lead_source__referral__in: [data?.value] };
            setValue('referral', referral?.find((el) => data?.value?.includes(el.value))?.label);
            if (Object.values(selectedAudienceData).length > 0) {
                const updatedCampaign = { ...selectedAudienceData, recepient: { ...selectedAudienceData.recepient, filters: { ...selectedAudienceData?.recepient?.filters, ...value } } };
                dispatch(updateSelectedAudience(updatedCampaign));
                return;
            }
            dispatch(saveNewAudience({ lead_source__referral__in: value, referralName: referral?.find((el) => data.value?.includes(el.value))?.label }));
        }
    };

    return <ReferralDropdown
        name={'referral'}
        value={newAudience.referralName || getStatusValue || []}
        onChange={handleReferral}
        preserveClinician
    />;
};

export default AudienceLeadReferelDropdown;
