/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
// libraries
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography, Button, Tabs, Tab, Divider } from '@mui/material';
import { has, isEmpty } from 'lodash';
// components
import UploadFileV2 from 'src/components/AudienceManager/UploadFileV2';
import AudienceByConditions from '../../../components/AudienceManager/AudienceByConditions';
import UploadFile from '../../../components/AudienceManager/UploadFile';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
// redux
import { updateError } from '../../../redux/slices/user';
import { getIndividualAudience, handleCreateAudience, handleUpdateAudience, setFileName, setHeaders, setImportFiles, setIsEditAudience, setMappingValues, setOriginalDataFile, updateSelectedAudience } from '../../../redux/slices/audienceManager';
import { dispatch, useSelector } from '../../../redux/store';
import { clearNewCampaignData, updateConditionList, setUsedIn } from '../../../redux/slices/campaign';
// hooks/validations/routes
import { useTabs } from '../../../hooks';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { AudienceCampaignSchema } from '../../../validations/validations';
// styles
import { createScriptButtonStyle, saveButtonStyle, cancelButtonStyle } from '../../../styles/AudienceManager';

const AudienceCampaign = ({ type, onClose }) => {
    const navigate = useNavigate();
    const { selectedRowData, isEditAudience, originalDataFile, newAudience, isAudienceIncludeArchive, selectedAudienceData, mappingValues, headers } =
        useSelector((state) => state.audienceManager);
    const { currentTab, onChangeTab } = useTabs(selectedRowData?.type === 'CSV' ? 'Imported' : 'By Conditions');
    const { pathname } = useLocation();
    const AUDIENCE_TABS = [
        {
            value: 'By Conditions',
            component: <AudienceByConditions />,
        },
        // {
        //     value: 'Imported',
        //     component: <UploadFile />,
        // },
        {
            value: 'Imported',
            component: <UploadFileV2 />,
        },
    ];

    const extractNumberFromPath = (path) => {
        const match = path.match(/\/(\d+)\/edit_audience/);
        return match ? match[1] : null;
    };

    const audienceId = extractNumberFromPath(pathname);

    useEffect(() => {
        if (!isEmpty(audienceId)) {
            dispatch(getIndividualAudience({ audienceId }));
        }
        dispatch(setUsedIn('AUDIENCE_MANAGER'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const defaultValues = {
        audienceName: selectedRowData?.name || '',
    };

    const methods = useForm({
        resolver: yupResolver(AudienceCampaignSchema),
        defaultValues,
    });

    const { handleSubmit, reset, setValue } = methods;

    useEffect(() => {
        if (!isEmpty(selectedRowData)) {
            onChangeTab('', selectedRowData?.type === 'INTERNAL_QUERYSET' ? 'By Conditions' : 'Imported');
            setValue('audienceName', selectedRowData?.name);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRowData]);

    const handleClose = () => {
        if (type === 'AudiencePopup') {
            onClose();
        }
        dispatch(setFileName(''));
        dispatch(setImportFiles(''));
        dispatch(setOriginalDataFile(''));
        dispatch(setMappingValues({}));
    };

    const isNext = () => newAudience?.tagsNames?.length > 0 ||
        newAudience?.treatmentName?.length > 0 ||
        newAudience?.statusNames?.length > 0 ||
        newAudience?.archivedValue ||
        newAudience?.practitionerName?.length > 0 ||
        newAudience?.referralName?.length > 0 ||
        newAudience?.dateRange?.length > 0 ||
        newAudience?.source__type?.length > 0 ||
        newAudience?.category__in?.length > 0 ||
        selectedAudienceData?.recepient?.filters?.tagged_items__tag__name__in?.length > 0 ||
        selectedAudienceData?.recepient?.filters?.lead_treatments__treatment__treatment_item__name__in?.length > 0 ||
        selectedAudienceData?.recepient?.filters?.lead_treatments__status__in?.length > 0 ||
        selectedAudienceData?.recepient?.filters?.is_only_archived ||
        selectedAudienceData?.recepient?.filters?.assignees__user__id__in?.length > 0 ||
        selectedAudienceData?.recepient?.filters?.lead_source__referral__in?.length > 0 ||
        selectedAudienceData?.recepient?.filters?.created_at__range?.length > 0 ||
        selectedAudienceData?.recepient?.filters?.lead_treatments__treatment__treatment_item__treatment_category__in?.length > 0 ||
        selectedAudienceData?.recepient?.filters?.lead_source__type__in?.length > 0;

    const getFilters = () => {
        return {
            filters: {
                ...(newAudience.tagged_items__tag__name__in ||
                    selectedAudienceData?.recepient?.filters?.tagged_items__tag__name__in
                    ? newAudience.tagged_items__tag__name__in || {
                        tagged_items__tag__name__in: selectedAudienceData?.recepient?.filters.tagged_items__tag__name__in,
                    }
                    : {}),
                ...(newAudience.lead_treatments__status__in ||
                    selectedAudienceData?.recepient?.filters?.lead_treatments__status__in
                    ? newAudience.lead_treatments__status__in || {
                        lead_treatments__status__in: selectedAudienceData?.recepient?.filters?.lead_treatments__status__in,
                    }
                    : {}),
                ...(newAudience.lead_treatments__treatment__treatment_item__name__in ||
                    selectedAudienceData?.recepient?.filters?.lead_treatments__treatment__treatment_item__name__in
                    ? newAudience.lead_treatments__treatment__treatment_item__name__in || {
                        lead_treatments__treatment__treatment_item__name__in:
                            selectedAudienceData?.recepient?.filters?.lead_treatments__treatment__treatment_item__name__in,
                    }
                    : {}),
                ...(newAudience.assignees__user__id__in || selectedAudienceData?.recepient?.filters?.assignees__user__id__in
                    ? newAudience.assignees__user__id__in || {
                        assignees__user__id__in: selectedAudienceData?.recepient?.filters?.assignees__user__id__in,
                    }
                    : {}),
                ...(newAudience.lead_source__referral__in ||
                    selectedAudienceData?.recepient?.filters?.lead_source__referral__in
                    ? newAudience.lead_source__referral__in || {
                        lead_source__referral__in: selectedAudienceData?.recepient?.filters?.lead_source__referral__in,
                    }
                    : {}),
                ...(newAudience.created_at__range || selectedAudienceData?.recepient?.filters?.created_at__range
                    ? newAudience.created_at__range || {
                        created_at__range: selectedAudienceData?.recepient?.filters?.created_at__range,
                    }
                    : {}),
                ...(newAudience.lead_source__type__in || selectedAudienceData?.recepient?.filters?.lead_source__type__in
                    ? newAudience.lead_source__type__in || {
                        lead_source__type__in: selectedAudienceData?.recepient?.filters?.lead_source__type__in,
                    }
                    : {}),
                ...(newAudience.lead_treatments__treatment__treatment_item__treatment_category__in || selectedAudienceData?.recepient?.filters?.lead_treatments__treatment__treatment_item__treatment_category__in
                    ? newAudience.lead_treatments__treatment__treatment_item__treatment_category__in || {
                        lead_treatments__treatment__treatment_item__treatment_category__in: selectedAudienceData?.recepient?.filters?.lead_treatments__treatment__treatment_item__treatment_category__in,
                    }
                    : {}),
            },
            is_archived_included: isAudienceIncludeArchive
        };
    };

    const isAllMappingFilled = () => !isEmpty(mappingValues) && Object.values(mappingValues)?.includes('lead_email') && Object.values(mappingValues)?.includes('lead_first_name');

    const handleNavigate = () => {
        if (type === 'AudiencePopup') return;
        navigate(PATH_DASHBOARD.campaign.audienceManager);
    };

    const onSubmit = (data) => {
        if (currentTab === 'By Conditions' && !isNext()) {
            dispatch(updateError({
                message: 'Please select any one tag',
                status: false
            }));
            return;
        }
        if (currentTab === 'Imported' && isEmpty(originalDataFile)) {
            dispatch(updateError({
                message: 'Please select file',
                status: false
            }));
            return;
        }
        if (currentTab === 'Imported' && !isAllMappingFilled()) {
            dispatch(updateError({
                message: 'Email and Firstname mapping are required',
                status: false
            }));
            return;
        }
        if (currentTab === 'Imported' && !isAllMappingFilled()) {
            dispatch(updateError({
                message: 'Email and Firstname mapping are required',
                status: false
            }));
            return;
        }
        dispatch(setHeaders(null));
        const payloadData = {
            name: data?.audienceName || '',
            type: currentTab === 'By Conditions' ? 'INTERNAL_QUERYSET' : 'CSV',
        };
        if (currentTab === 'Imported' || currentTab === 'Imported') {
            payloadData.audience_csv = {
                csv: originalDataFile,
            };
            if (!isEmpty(mappingValues)) {
                payloadData.audience_csv = {
                    ...payloadData.audience_csv,
                    mapping: mappingValues
                };
            }
        }
        if (currentTab === 'By Conditions') {
            payloadData.audience_internal_queryset = getFilters();
        }
        if (
            currentTab === 'By Conditions' && (newAudience.is_only_archived ||
                selectedAudienceData?.recepient?.filters?.is_only_archived)
        ) {
            if (
                (newAudience.is_only_archived && newAudience?.is_only_archived?.is_open_for_communication === 'false') ||
                (selectedAudienceData?.recepient?.filters?.is_only_archived &&
                    (selectedAudienceData?.recepient?.filters?.is_open_for_communication === 'false' ||
                        selectedAudienceData?.recepient?.filters?.is_open_for_communication === false))
            ) {
                payloadData.audience_internal_queryset.filters = {
                    ...payloadData.audience_internal_queryset.filters,
                    is_only_archived: true,
                    is_open_for_communication: false,
                };
            } else if (newAudience.is_only_archived || selectedAudienceData?.recepient?.filters?.is_only_archived) {
                payloadData.audience_internal_queryset.filters = {
                    ...payloadData.audience_internal_queryset.filters,
                    is_only_archived: true,
                    is_open_for_communication: true,
                };
            }
        }
        let finalCampaignData = {};
        if (currentTab === 'By Conditions') {
            const filteredFilters = Object.entries(payloadData?.audience_internal_queryset?.filters).filter(([key, value]) => !Array.isArray(value) || value.length > 0).reduce((obj, [key, value]) => {
                obj[key] = value;
                return obj;
            }, {});
            finalCampaignData = {
                ...payloadData,
                audience_internal_queryset:
                    { ...payloadData?.audience_internal_queryset, filters: filteredFilters },
            };
        } else {
            finalCampaignData = {
                ...payloadData,
            };
        }
        if (isEditAudience) {
            dispatch(handleUpdateAudience({ payload: finalCampaignData, type, audienceId: selectedRowData?.id }, handleClose, handleNavigate));
        } else {
            dispatch(handleCreateAudience({ payload: finalCampaignData, type, shouldShowAudienceSuccessMsg: true }, handleClose, handleNavigate));
        }
        reset(defaultValues);
        if (type === 'AudiencePopup') {
            dispatch(updateSelectedAudience({}));
            dispatch(updateConditionList([]));
            return;
        }
        dispatch(clearNewCampaignData());
    };

    const handleCancel = () => {
        dispatch(clearNewCampaignData());
        navigate(PATH_DASHBOARD.campaign.audienceManager);
        dispatch(setIsEditAudience(false));
    };

    const onError = (error) => {
        if (error?.audienceName) {
            dispatch(updateError({
                message: 'Audience name is required field',
                status: false
            }));
        }
    };

    return (
        <>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit, onError)}>
                <Box sx={{ mb: '40px' }}>
                    <Box sx={{ mb: '40px' }}>
                        <RHFTextField name="audienceName" label="Audience Name" sx={{ mb: 1 }} />
                        <Typography sx={{ color: '#637381', fontSize: '14px' }}>
                            Give your campaign a unique, identifiable name.
                        </Typography>
                    </Box>
                    <Typography sx={{ fontSize: '16px', fontWeight: '700', mb: 1 }}>Target Audience</Typography>
                    <Typography sx={{ color: '#777777', fontSize: '14px', mb: '16px' }}>Choose your audience for this campaign by selecting certain conditions.</Typography>
                    <Tabs
                        variant="scrollable"
                        scrollButtons
                        allowScrollButtonsMobile
                        value={currentTab}
                        onChange={onChangeTab}
                        sx={{ mb: '12px' }}
                    >
                        {AUDIENCE_TABS.map((tab, index) => (
                            <Tab disableRipple key={index} label={tab.value} value={tab.value} />
                        ))}
                    </Tabs>
                    {currentTab !== 'Imported' && <Divider />}
                    {AUDIENCE_TABS.map((tab) => {
                        const isMatched = tab.value === currentTab;
                        return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                    })}
                </Box>
                <Box sx={createScriptButtonStyle}>
                    {type !== 'AudiencePopup' && (
                        <Button
                            variant="outlined"
                            sx={cancelButtonStyle}
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    )}
                    <Button type="submit" variant="contained" sx={saveButtonStyle}>
                        {isEditAudience ? 'Update Audience' : 'Save Audience'}
                    </Button>
                </Box>
            </FormProvider>
        </>
    );
};

AudienceCampaign.propTypes = {
    type: PropTypes.string,
    onClose: PropTypes.func,
};

export default AudienceCampaign;
