export const widgetArray = [
    { key: '1', icon: '', value: '0', text: 'Total income for this go aheads', isPrice: true },
    { key: '2', icon: '', value: 'invisalign', text: 'Invisalign go aheads', isTreatment: true },
    { key: '3', icon: '', value: 'bonding', text: 'Bonding go aheads', isTreatment: true },
    { key: '4', icon: '', value: 'implants', text: 'Implants go aheads', isTreatment: true },
    { key: '5', icon: '', value: 'whitening', text: 'Whitening go aheads', isTreatment: true },
    { key: '6', icon: '', value: '6', text: 'Overdue treatments to be completed as passed' },
];

export const widgetArrayTask = [
    { key: 'TotalToday' , dateFilters: 'Today' },
    { key: 'TotalUpcoming' , dateFilters: 'Upcoming' },
    { key: 'InvisalignToday', treatment: 'invisalign',  dateFilters: 'Today'},
    { key: 'InvisalignUpcoming', treatment: 'invisalign', dateFilters: 'Upcoming'},
    { key: 'BondingToday', treatment: 'bonding', dateFilters: 'Today'},
    { key: 'BondingUpcoming', treatment: 'bonding', dateFilters: 'Upcoming' },
    { key: 'ImplantsToday', treatment: 'implants', dateFilters: 'Today'},
    { key: 'ImplantsUpcoming', treatment: 'implants', dateFilters: 'Upcoming'},
    { key: 'WhiteningToday', treatment: 'whitening', dateFilters: 'Today'},
    { key: 'WhiteningUpcoming', treatment: 'whitening', dateFilters: 'Upcoming'},
    // { key: '6', shouldGetPrice: true },
]