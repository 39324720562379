/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
// use @mui
import {
    Dialog,
    Box,
    Button,
    Typography,
    Tooltip,
    Divider,
    FormControlLabel,
    Checkbox
} from '@mui/material';
// use form
import { isEqual } from 'lodash'
import { useForm, Controller } from 'react-hook-form';
// use components
import { openTaskPop } from 'src/styles/leadTaskReminder';
import { grey, common } from 'src/theme/palette';
import { Iconify } from 'src/components';
import ReactHtmlParser from 'react-html-parser';
import { formats } from 'src/components/editor/EditorToolbar';
import ReactQuill from 'react-quill';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField } from '../../../components/hook-form';


export default function MultipleInfoEditDialog({
    openEditInfo,
    handleCloseEditInfoDialog,
    inputValue,
    setInputValue
}) {

    const [showFormFields, setShowFormFields] = useState(false);
    const [isAddingInfo, setIsAddingInfo] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isInfoChanged, setIsInfoChanged] = useState(false);

    const originalInputValue = useRef(null);

    const EditorMessage = useRef();
    const commonModules = {
        toolbar: [
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline'],
            [
                {
                    color: [common.red, common.green, common.blue, common.orange, common.violet, common.skyBlue],
                },
            ],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link'],
            ['clean']
        ],
        history: {
            delay: 500,
            maxStack: 100,
            userOnly: true,
        },
        syntax: true,
        clipboard: {
            matchVisual: false,
        },
    };

    useEffect(() => {
        if (openEditInfo) {
            const input = inputValue.additional_infos.map((info) => {
                return {
                    ...info,
                    text: info.text.replace(/<\/?[^>]+(>|$)/g, '\n')
                }
            });
            originalInputValue.current = JSON.parse(JSON.stringify(input));
            setIsInfoChanged(false);

        }
    }, [openEditInfo]);

    const checkForChanges = () => {
        const isChanged = !isEqual(originalInputValue.current, inputValue.additional_infos);
        setIsInfoChanged(isChanged);
    };

    useEffect(() => {
        if (openEditInfo) {
            checkForChanges();
        }
    }, [inputValue]);

    useEffect(() => {
        setShowFormFields(false);
        setIsAddingInfo(false);
        setActiveIndex(0);
        setIsInfoChanged(false);
    }, [openEditInfo]);

    const validationSchema = Yup.object().shape({
        text: Yup.string().required('Text is required'),
        categories: Yup.string().required('Category is required'),
        custom_identifier: Yup.string().required('Custom_Identifier is required')
    });

    const methodsEditInfo = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            text: '',
            categories: '',
            custom_identifier: '',
            is_enabled: true
        },
    });
    const {
        control: controlEditInfo,
        handleSubmit: handleSaveEditInfo,
        reset
    } = methodsEditInfo;

    useEffect(() => {
        if (openEditInfo) {
            if (isAddingInfo) {
                reset({
                    text: '',
                    categories: '',
                    custom_identifier: '',
                    is_enabled: true
                });
            } else {
                const currentInfo = inputValue?.additional_infos?.[activeIndex];
                reset({
                    text: currentInfo?.text || '',
                    categories: currentInfo?.categories?.toString() || '',
                    custom_identifier: currentInfo?.custom_identifier || '',
                    is_enabled: currentInfo?.is_enabled ?? true
                });
            }
        }
    }, [openEditInfo, activeIndex, inputValue, isAddingInfo, reset, showFormFields]);
    const onSubmitEditOffer = (data) => {
        const formattedData = {
            ...data,
            text: data.text?.replace(/<p><br><\/p>/g, "<br/>"),
            categories: data.categories.split(',').map((category) => category.trim()),
        };

        if (isAddingInfo) {
            setInputValue((prevState) => ({
                ...prevState,
                additional_infos: [
                    ...prevState.additional_infos,
                    {
                        text: formattedData.text,
                        categories: formattedData.categories,
                        custom_identifier: formattedData.custom_identifier,
                        is_enabled: Boolean(formattedData.is_enabled),
                    },
                ],
            }));
        } else {
            setInputValue((prevState) => {
                const updatedInfo = [...prevState.additional_infos];
                updatedInfo[activeIndex] = {
                    ...updatedInfo[activeIndex],
                    text: formattedData.text,
                    categories: formattedData.categories,
                    custom_identifier: formattedData.custom_identifier,
                    is_enabled: Boolean(formattedData.is_enabled),
                };
                return { ...prevState, additional_infos: updatedInfo };
            });
        }

        setIsAddingInfo(false);
        setShowFormFields(false);
    };

    const handleAddInfo = () => {
        setIsAddingInfo(true);
        setShowFormFields(true);
    }

    const handleEditInfo = (index) => {
        setIsAddingInfo(false);
        setActiveIndex(index);
        setShowFormFields(true)
    }

    const handleDeleteInfo = (index) => {
        setInputValue((state) => {
            const updatedInfo = [...state.additional_infos];
            updatedInfo.splice(index, 1);
            return { ...state, additional_infos: updatedInfo };
        });
    }

    const handleSaveInfo = () => {
        setShowFormFields(false);
        setIsAddingInfo(false);
        setActiveIndex(0);
        setIsInfoChanged(false);
        handleCloseEditInfoDialog(isInfoChanged);
    }

    const handleCloseForm = () => {
        setShowFormFields(false);
        setIsAddingInfo(false);
        setActiveIndex(0);
    }

    const editorOnChange = (value, field) => {
        field.onChange(value);
    };

    return (
        <Dialog fullWidth maxWidth={`${(isAddingInfo || showFormFields) ? "lg" : !inputValue?.additional_infos?.length ? "sm" : "md"}`} open={openEditInfo} onClose={handleCloseEditInfoDialog} >
            <Box sx={{ width: '100%', p: 2, mt: 2 , background: "#F4F6F8", }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, alignItems: 'center' }}>
                    <Typography variant='h5' sx={{ color: '#212B36', fontSize: '16px', display: "inline-block" }}>Existing Additional Info</Typography>
                    <Tooltip title="Add Info">
                        <Box sx={{ ...openTaskPop, width: '36px', height: '36px', bgcolor: grey[200], mr: 3 }} onClick={handleAddInfo} >
                            <Iconify sx={{ color: 'common.grey' }} width={20} height={20} icon={'eva:plus-fill'} />
                        </Box>
                    </Tooltip>
                </Box>
                <Divider sx={{ mb: 2 }} />
                <FormProvider methods={methodsEditInfo} onSubmit={handleSaveEditInfo(onSubmitEditOffer)}>
                    <Box>
                        {

                            !inputValue?.additional_infos?.length ? <Typography sx={{ color: 'grey.500', fontSize: '14px' }}>No common Info available for selected treatment</Typography> : (
                                <>
                                    {
                                        inputValue?.additional_infos?.map((info, index) => {
                                            return <Box key={index} sx={index !== 0 ? { mt: "20px" } : {}}>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, gap: 2 }}>
                                                    <Box>
                                                        <Typography>
                                                            {index + 1}. Category -
                                                            <Typography sx={{ fontWeight: "600", display: "inline-block", mx: "5px" }}>{info?.categories?.join(', ')}</Typography>
                                                        </Typography>
                                                    </Box>


                                                    {showFormFields && activeIndex === index && !isAddingInfo ? <></> : <Box>
                                                        <Tooltip title="Edit">
                                                            <Iconify width={18} height={18} icon={'solar:pen-bold'} sx={{ mr: 1 }} onClick={() => handleEditInfo(index)} /></Tooltip>

                                                        <Tooltip title="Delete"><Iconify width={18} height={18} icon={'solar:trash-bin-trash-bold'} sx={{ mr: 1, color: "rgb(255, 86, 48)" }} onClick={() => handleDeleteInfo(index)} /></Tooltip>

                                                    </Box>}
                                                </Box>
                                                {(showFormFields && activeIndex === index && !isAddingInfo) && (
                                                    <Box sx={{ p: 2, mb: 1, display: "flex", flexDirection: "column", justifyContent: "center", mt: 1 }} >
                                                        <Box sx={{ mt: 0, '.ql-container': { borderRadius: "0 0 10px 10px !important", borderColor: "rgba(145, 158, 171, 0.2) !important" }, '.ql-toolbar.ql-snow': { borderRadius: "10px 10px 0 0 !important", borderColor: "rgba(145, 158, 171, 0.2) !important" }, '.ql-container.ql-snow': { minHeight: "250px !important" } }}>
                                                            <Controller
                                                                name={"text"}
                                                                control={controlEditInfo}
                                                                render={({ field }) => <ReactQuill
                                                                    ref={EditorMessage}
                                                                    modules={commonModules}
                                                                    formats={formats}
                                                                    placeholder='Write info here...'
                                                                    value={field.value}
                                                                    onChange={(newValue) => editorOnChange(newValue, field)}
                                                                />
                                                                }
                                                            />
                                                        </Box>
                                                        <RHFTextField
                                                            sx={{ width: '100%', mt: 2 }}
                                                            name="categories"
                                                            label="Categories"
                                                            control={controlEditInfo}
                                                        />
                                                        <RHFTextField
                                                            sx={{ width: '100%', mt: 2 }}
                                                            name="custom_identifier"
                                                            label="Custom Identifier"
                                                            control={controlEditInfo}
                                                        />
                                                        <Box sx={{ mt: 2, mx: 2 }}>
                                                            <Controller
                                                                name="is_enabled"
                                                                control={controlEditInfo}
                                                                render={({ field }) => (
                                                                    <FormControlLabel
                                                                        control={<Checkbox {...field} checked={field.value} />}
                                                                        label="Is Enabled"
                                                                    />
                                                                )}
                                                            />
                                                        </Box>
                                                    </Box>)}
                                                {!(showFormFields && activeIndex === index && !isAddingInfo) && <Box sx={{ '& p, & h1, & h2, & h3, & h4, & h5, & h6, & blockquote': { margin: 0, padding: 0 }, mx:4, p:1,mt:1, boxShadow: "rgba(145, 158, 171, 0.16) 0px 1px 1px 1px", border: "2px solid #F4F6F8", borderRadius:"6px" }}>{ReactHtmlParser(info?.text)}</Box>}
                                            </Box>
                                        })
                                    }
                                </>
                            )
                        }
                    </Box>


                    {isAddingInfo && <Box sx={{ p: 2, mt: 2, display: "flex", flexDirection: "column", justifyContent: "center" }} >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                            <Typography sx={{ fontWeight: "500", fontSize: "18px" }}>New Info</Typography>
                        </Box>

                        <Box sx={{ mt: 0, '.ql-container': { borderRadius: "0 0 10px 10px !important", borderColor: "rgba(145, 158, 171, 0.2) !important" }, '.ql-toolbar.ql-snow': { borderRadius: "10px 10px 0 0 !important", borderColor: "rgba(145, 158, 171, 0.2) !important" }, '.ql-container.ql-snow': { minHeight: "250px !important" } }}>
                            <Controller
                                name={"text"}
                                control={controlEditInfo}
                                render={({ field }) => <ReactQuill
                                    ref={EditorMessage}
                                    modules={commonModules}
                                    formats={formats}
                                    placeholder='Write info here...'
                                    value={field.value}
                                    onChange={(newValue) => editorOnChange(newValue, field)}
                                />
                                }
                            />
                        </Box>
                        <RHFTextField
                            sx={{ width: '100%', mt: 2 }}
                            name="categories"
                            label="Categories"
                            control={controlEditInfo}
                        />
                        <RHFTextField
                            sx={{ width: '100%', mt: 2 }}
                            name="custom_identifier"
                            label="Custom Identifier"
                            control={controlEditInfo}
                        />
                        <Box sx={{ mt: 2, mx: 2 }}>
                            <Controller
                                name="is_enabled"
                                control={controlEditInfo}
                                render={({ field }) => (
                                    <FormControlLabel
                                        control={<Checkbox {...field} checked={field.value} />}
                                        label="Is Enabled"
                                    />
                                )}
                            />
                        </Box>

                    </Box>}



                    {(!showFormFields && !isAddingInfo) ? <Box sx={{ display: 'flex', p: 2, width: '100%', justifyContent: "center", mt: 2 }}>
                        <Button
                            variant="outlined"
                            color="inherit"
                            onClick={handleCloseEditInfoDialog}
                        >
                            cancel
                        </Button>
                        <Button
                            variant="contained"
                            sx={{ ml: 2 }}
                            // disabled={!isInfoChanged}
                            onClick={handleSaveInfo}
                        >
                            Save
                        </Button>
                    </Box> : (
                        <Box sx={{ display: 'flex', p: 2, width: '100%', justifyContent: "center", mt: 2 }}>
                            <Button
                                variant="outlined"
                                color="inherit"
                                onClick={handleCloseForm}
                            >
                                cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ ml: 2 }}
                            // disabled={!isInfoChanged}
                            // onClick={handleSaveInfo}
                            >
                                Confirm
                            </Button>
                        </Box>
                    )}
                </FormProvider>

            </Box>

        </Dialog>
    );
}

MultipleInfoEditDialog.propTypes = {
    openEditInfo: PropTypes.bool,
    handleCloseEditInfoDialog: PropTypes.func,
    inputValue: PropTypes.object,
    setInputValue: PropTypes.func,
};