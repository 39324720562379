import { useEffect, useRef, useState } from 'react';
import { differenceWith, isEmpty } from 'lodash';
// libraries
import { useForm } from 'react-hook-form';
import { Box, MenuItem, Divider, Typography, Select, Button, List, ListItem, IconButton, FormControlLabel, Switch } from '@mui/material';
// components
import AudienceLeadReferelDropdown from './audienceFilters/AudienceLeadReferelDropdown';
import AudiencePractitionerDropdown from './audienceFilters/AudiencePractitionerDropdown';
import AudienceLeadDateRangePicker from './audienceFilters/AudienceLeadDateRangePicker';
import AudienceMultiTreatmentDropdown from './audienceFilters/AudienceMultiTreatmentDropdown';
import AudienceAutocompleteTags from './audienceFilters/AudienceAutocompleteTags';
import AudienceStatusDropDown from './audienceFilters/AudienceStatusDropDown';
import AudienceLeadSourceDropdown from './audienceFilters/AudienceLeadSourceDropdown';
import AudienceLeadCategoryDropdown from './audienceFilters/AudienceLeadCategoryDropdown';
import CustomRHFSelect from '../CampaignManager/CustomRHFSelect';
import CustomConditionSelect from '../CampaignManager/CustomConditionSelect';
import Iconify from '../Iconify';
import { FormProvider } from '../hook-form';
// redux
import { saveNewAudience, setAudienceConditionListing, setAudienceIncludeArchive, updateSelectedAudience } from '../../redux/slices/audienceManager';
import { dispatch, useSelector } from '../../redux/store';
import { getPracticeTreatmentList } from '../../redux/slices/practiceTreatment';
// utils
import { getWord } from '../../utils/getWord';
import { setBlankValueForKeyContainingWord } from '../../utils/CampaignUtils';
import { categoryConditionData, dateRangeConditionData, isTreatment, practitionerConditionData, sourceReferralConditionData, sourceTypeConditionData, statusConditionData, tagsConditionData, treatmentConditionData } from '../../utils/audiencemanagerUtils';

const AudienceByConditions = () => {
    const socialLinks = [
        {
            name: 'TAGS',
            value: 'contact is tagged',
            tagSelect: '',
            id: 1,
            component: <AudienceAutocompleteTags />
        },
    ];

    const [conditionListing, setConditionListing] = useState(socialLinks);
    const [any, setAny] = useState('All');
    const [manual, setManual] = useState(false);
    const [orAndValue, setOrAndValue] = useState('AND');
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { newAudience, selectedAudienceData, isAudienceIncludeArchive, audienceConditionListing } = useSelector((state) => state.audienceManager);
    const shouldCall = useRef(true);

    const handleAudienceListing = (data) => {
        dispatch(setAudienceConditionListing(data));
    };

    useEffect(() => {
        if (audienceConditionListing?.length > 0) {
            setConditionListing(audienceConditionListing);
            return;
        }
        if (socialLinks.length) {
            const data = [];
            socialLinks.forEach((el) => {
                data.push({
                    name: el.name,
                    value: el.value,
                    id: el.id,
                    component: el.component
                });
            });
            setConditionListing(data);
            return;
        }
        setConditionListing([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (practiceId) {
            const data = {
                limit: 30,
                offset: 0
            };
            dispatch(getPracticeTreatmentList(practiceId, data));
        }
    }, [practiceId]);

    useEffect(() => {
        if (Object.values(selectedAudienceData).length === 0 && !manual) {
            const data = [];
            if (newAudience?.tags !== undefined) {
                data.push({
                    name: 'TAGS',
                    value: 'contact is tagged',
                    component: <AudienceAutocompleteTags />
                });
            }
            if (newAudience?.status !== undefined) {
                data.push({
                    name: 'STATUS',
                    value: 'contact status is',
                    component: <AudienceStatusDropDown />,
                });
            }
            if (newAudience?.treatment !== undefined) {
                data.push({
                    name: 'TREATMENT',
                    value: 'contact treatment is',
                    component: <AudienceMultiTreatmentDropdown name={'treatment'} value={[]} onChange={(e, newValue, selectedVal) => handleTreatment(newValue, selectedVal)} type={'compaign'} />,
                });
            }
            if (newAudience?.practitioner !== undefined) {
                data.push({
                    name: 'PRACTITIONER',
                    value: 'contact practitioner is',
                    component: <AudiencePractitionerDropdown />,
                });
            }
            if (newAudience?.source_referral !== undefined) {
                data.push({
                    name: 'SOURCE_REFERRAL',
                    value: 'contact source referral is',
                    component: <AudienceLeadReferelDropdown />,
                });
            }
            if (data.length > 0) {
                setConditionListing([...data]);
                handleAudienceListing([...data]);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newAudience, manual]);

    useEffect(() => {
        if (Object.values(selectedAudienceData).length > 0) {
            const data = [];
            if (selectedAudienceData?.recepient?.filters?.tagged_items__tag__name__in?.length > 0) {
                data.push({
                    name: 'TAGS',
                    value: 'contact is tagged',
                    component: <AudienceAutocompleteTags />
                });
            }
            if (selectedAudienceData?.recepient?.filters?.lead_treatments__status__in?.length > 0) {
                data.push({
                    name: 'STATUS',
                    value: 'contact status is',
                    component: <AudienceStatusDropDown />,
                });
            }
            if (selectedAudienceData?.recepient?.filters?.lead_treatments__treatment__treatment_item__name__in?.length > 0) {
                data.push({
                    name: 'TREATMENT',
                    value: 'contact treatment is',
                    component: <AudienceMultiTreatmentDropdown name={'treatment'} value={[]} onChange={(e, newValue, selectedVal) => handleTreatment(newValue, selectedVal)} type={'compaign'} />,
                });
            }
            if (selectedAudienceData?.recepient?.filters?.created_at__range?.length > 0) {
                data.push({
                    name: 'DATE_RANGE',
                    value: 'contact date range is',
                    component: <AudienceLeadDateRangePicker />,
                });
            }
            if (selectedAudienceData?.recepient?.filters?.lead_source__referral__in?.length > 0) {
                data.push({
                    name: 'SOURCE_REFERRAL',
                    value: 'contact source referral is',
                    component: <AudienceLeadReferelDropdown />,
                });
            }
            if (selectedAudienceData?.recepient?.filters?.assignees__user__id__in?.length > 0) {
                data.push({
                    name: 'PRACTITIONER',
                    value: 'contact practitioner is',
                    component: <AudiencePractitionerDropdown />,
                });
            }
            if (selectedAudienceData?.recepient?.filters?.lead_source__type__in) {
                data.push({
                    name: 'SOURCE_TYPE',
                    value: 'contact source type is',
                    component: <AudienceLeadSourceDropdown />,
                });
            }
            if (selectedAudienceData?.recepient?.filters?.lead_treatments__treatment__treatment_item__treatment_category__in) {
                data.push({
                    name: 'CATEGORY',
                    value: 'contact category is',
                    component: <AudienceLeadCategoryDropdown />,
                });
            }
            if (data.length > 0 && shouldCall.current) {
                shouldCall.current = false;
                setConditionListing([...data]);
                handleAudienceListing([...data]);
                setManual(false);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAudienceData]);

    const methods = useForm();

    const CONDITIONS = [
        { ...tagsConditionData, component: <AudienceAutocompleteTags /> },
        { ...statusConditionData, component: <AudienceStatusDropDown /> },
        { ...treatmentConditionData, component: <AudienceMultiTreatmentDropdown name={'treatment'} value={[]} onChange={(e, newValue, selectedVal) => handleTreatment(newValue, selectedVal)} type={'compaign'} /> },
        { ...dateRangeConditionData, component: <AudienceLeadDateRangePicker /> },
        { ...sourceReferralConditionData, component: <AudienceLeadReferelDropdown /> },
        { ...practitionerConditionData, component: <AudiencePractitionerDropdown /> },
        { ...sourceTypeConditionData, component: <AudienceLeadSourceDropdown /> },
        { ...categoryConditionData, component: <AudienceLeadCategoryDropdown />, },
    ];

    const addNewLink = () => {
        const difference = differenceWith(CONDITIONS, conditionListing, (a, b) => a.value.toLowerCase() === b.name.toLowerCase());
        setConditionListing([
            ...conditionListing,
            {
                name: difference[0]?.value,
                value: difference[0]?.title,
                component: difference[0]?.component,
            }
        ]);
        handleAudienceListing([
            ...conditionListing,
            {
                name: difference[0]?.value,
                value: difference[0]?.title,
                component: difference[0]?.component,
            }
        ]);
    };

    const hendleEvent = (event, index) => {
        let selected = conditionListing[index];
        const data = setBlankValueForKeyContainingWord(!isEmpty(selectedAudienceData) ? selectedAudienceData : newAudience, getWord(selected.name));
        setManual(true);
        selected = { ...selected, name: event.target.value };
        switch (selected?.name) {
            case 'TAGS':
                selected = { ...selected, value: 'contact is tagged', component: <AudienceAutocompleteTags /> };
                break;
            case 'STATUS':
                selected = { ...selected, value: 'contact status is', component: <AudienceStatusDropDown /> };
                break;
            case 'TREATMENT':
                selected = { ...selected, value: 'contact treatment is', component: <AudienceMultiTreatmentDropdown name={'treatment'} value={[]} onChange={(e, newValue, selectedVal) => handleTreatment(newValue, selectedVal)} type={'compaign'} /> };
                break;
            case 'DATE_RANGE':
                selected = { ...selected, value: 'contact date range is', component: <AudienceLeadDateRangePicker /> };
                break;
            case 'SOURCE_REFERRAL':
                selected = { ...selected, value: 'contact source referral is', component: <AudienceLeadReferelDropdown /> };
                break;
            case 'PRACTITIONER':
                selected = { ...selected, value: 'contact practitioner is', component: <AudiencePractitionerDropdown /> };
                break;
            case 'SOURCE_TYPE':
                selected = { ...selected, value: 'contact source type is', component: <AudienceLeadSourceDropdown /> };
                break;
            case 'CATEGORY':
                selected = { ...selected, value: 'contact category is', component: <AudienceLeadCategoryDropdown /> };
                break;
            default:
                break;
        }
        const temp = [...conditionListing];
        temp[index] = selected;
        setConditionListing([
            ...temp,
        ]);
        handleAudienceListing([
            ...temp,
        ]);
        if (Object.values(selectedAudienceData).length > 0) {
            dispatch(updateSelectedAudience(data));
            return;
        }
        dispatch(saveNewAudience({ ...data }));
    };

    const deleteRow = (index) => {
        const selected = conditionListing[index];
        const results = setBlankValueForKeyContainingWord(!isEmpty(selectedAudienceData) ? selectedAudienceData : newAudience, getWord(selected.name), isTreatment(selected.name));
        const data = conditionListing.filter((el, ind) => ind !== index);
        setConditionListing([...data]);
        handleAudienceListing([...data]);
        if (Object.values(selectedAudienceData).length > 0) {
            dispatch(updateSelectedAudience(results));
            return;
        }
        dispatch(saveNewAudience({ ...results }));
    };

    const handleChange = (event) => {
        setAny(event.target.value);
        if (event.target.value === 'All') {
            setOrAndValue('AND');
        }
        if (event.target.value === 'Any') {
            setOrAndValue('OR');
        }
    };

    const handleTreatment = (value, selectedVal) => {
        if (value) {
            const data = { lead_treatments__treatment__treatment_item__name__in: value?.map((el) => el.name) };
            if (selectedVal && Object.values(selectedVal).length > 0) {
                const updatedCampaign = { ...selectedVal, recepient: { ...selectedVal.recepient, filters: { ...selectedVal.recepient?.filters, ...data } } };
                dispatch(updateSelectedAudience(updatedCampaign));
                return;
            }
            dispatch(saveNewAudience({ lead_treatments__treatment__treatment_item__name__in: data, treatmentName: value }));
        }
    };

    const handleArchiveFilter = (e) => {
        dispatch(setAudienceIncludeArchive(e.target.checked));
    };

    const getArchiveChecked = () => isAudienceIncludeArchive;

    return (
        <Box>
            <FormProvider methods={methods}>
                <Box sx={{ py: '24px', display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontSize: '16px' }}>Contacts match
                        <Select name='contactMatchCondition' value={any} sx={{ mx: '12px', '.MuiSelect-select': { p: '8.5px 14px' } }} onChange={handleChange}>
                            <MenuItem
                                value='All'
                            >
                                all
                            </MenuItem>
                        </Select>
                        of the following conditions:</Typography>
                    <FormControlLabel control={
                        <Switch checked={getArchiveChecked()}
                            onChange={handleArchiveFilter}
                            inputProps={{ 'aria-label': 'controlled' }} />
                    } labelPlacement="start" label="Include Archived Data" />
                </Box>
                <Divider />
                <Box>
                    <Box>
                        <List sx={{ p: '0', ' .items:first-of-type p': { display: 'none' } }}>
                            {conditionListing.map((link, index) => (
                                <Box key={index} sx={{ borderBottom: 'solid 1px rgba(145, 158, 171, 0.24)' }} className='items'>
                                    {conditionListing?.length > 1 &&
                                        <Typography sx={{ fontSize: '16px', fontWeight: '700', p: '24px 0 0' }}>{orAndValue}</Typography>
                                    }
                                    <ListItem sx={{ p: '24px 0' }}>
                                        <IconButton
                                            edge="end"
                                            aria-label="delete"
                                            title="Delete"
                                            onClick={() => deleteRow(index)}
                                            sx={{ p: '0', mr: '0' }}
                                        >
                                            <Box>
                                                <Iconify icon={'eva:minus-fill'} sx={{ border: '1px solid #919EAB', borderRadius: '8px' }} />
                                            </Box>
                                        </IconButton>
                                        <CustomConditionSelect
                                            value={link.name}
                                            name='preferredContactMethod'
                                            label=''
                                            list={CONDITIONS}
                                            conditionListing={conditionListing}
                                            handleChange={hendleEvent}
                                            index={index}
                                        />
                                        <CustomRHFSelect value={link.value} />
                                        <Box sx={{ minWidth: '41%' }}>
                                            {link?.component}
                                        </Box>
                                    </ListItem>
                                </Box>
                            ))}
                        </List>
                    </Box>
                </Box>
            </FormProvider>
            {conditionListing?.length <= 7 && <Box
                style={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px' }}
            >
                <Button
                    variant="outlined"
                    onClick={addNewLink}
                    sx={{ fontSize: '14px', minWidth: '155px', minHeight: '36px' }}
                >
                    <Iconify sx={{ marginRight: '8px' }} icon={'eva:plus-fill'} width={20} height={20} /> Add Condition
                </Button>
            </Box>}
        </Box>
    );
};

export default AudienceByConditions;