import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import useSnackbar from 'src/hooks/useCustomSnackbar';
import { Box, Tooltip, MenuItem, ListItemIcon, ListItemText, Zoom, useTheme, TextField, InputAdornment, Popover, ClickAwayListener } from '@mui/material';
import { Iconify } from 'src/components';
import { dispatch, useSelector } from '../../../../redux/store';
import { updatePersonalDetail } from '../../../../redux/slices/lead';
import { TableTreatment } from '../../../../utils/allTableTab';
import { setLeadSourceIcon } from '../../../../utils/timelineIcon';
import { tableCellInnerBoxStyle } from '../../../../styles/NewLeadTableRowStyle';

const ReferralField = ({ params, tableType, setTableData, handleArchivedNavigate }) => {
    const { enqueueSnackbar } = useSnackbar();
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedValue, setSelectedValue] = useState(params.value);
    const [tooltipTitle, setTooltipTitle] = useState('');
    const { practiceId, referrals, practiceUsers } = useSelector((state) => ({
        practiceId: state.practiceTreatmentList?.practiceId,
        referrals: state.schema?.referral || [],
        practiceUsers: state.mergeTags?.practiceUsers || []
    }));
    const treatmentValue = TableTreatment(tableType);
    const treatmentData = [];
    treatmentValue?.map((value) => treatmentData.push(value.id));
    const [searchQuery, setSearchQuery] = useState('');

    const referral = React.useMemo(() => [
        ...referrals.map((item) => ({ ...item, group: "Default" })),
        ...practiceUsers.map((user) => ({ ...user, group: "Clinicians" }))
    ], [referrals, practiceUsers]);

    React.useEffect(() => {
        if (params.value === "CLINICIAN" && params?.row?.affiliate_user?.id) {
            const currentClinician = practiceUsers?.filter(obj => obj?.id === params?.row?.affiliate_user?.id)?.[0]?.value;
            setSelectedValue(currentClinician);
        }
    }, [params, practiceUsers])

    // const toolTipText = (data) => (
    //     <div style={{ lineHeight: '15px', whiteSpace: true }}>
    //         {`Resource referral : ${setLeadSourceIcon(data?.referral, referral)?.label || 'not found'}`} <br />
    //         {data?.metadata &&
    //             Object.values(data?.metadata)?.length > 0 &&
    //             data?.metadata?.campaign_name &&
    //             `Campaign name : ${data.metadata.campaign_name}`}
    //     </div>
    // );
    const toolTipText = useCallback((data) => (
        <div style={{ lineHeight: '15px', whiteSpace: true }}>
            {`Resource referral : ${setLeadSourceIcon(data?.referral, referral)?.label || 'not found'}`} <br />
            {data?.metadata &&
                Object.values(data?.metadata)?.length > 0 &&
                data?.metadata?.campaign_name &&
                `Campaign name : ${data.metadata.campaign_name}`}
        </div>
    ), [referral]);

    useEffect(() => {
        const newTooltipTitle = toolTipText({ ...params.row, referral: selectedValue });
        setTooltipTitle(newTooltipTitle);
    }, [params.row, selectedValue, toolTipText]);

    const handleReferralChange = (option) => {
        const newValue = option?.value;
        setAnchorEl(null);
        const detail = option?.id ? {
            id: params?.row?.id,
            lead_source: { referral: "CLINICIAN", affiliate_user: option?.id, type: "CLINICIAN" }
        } : {
            id: params?.row?.id,
            lead_source: { referral: newValue }
        };
        dispatch(updatePersonalDetail(detail, practiceId, handleSuccess, 'leadTableEdit'));
        const newTooltipTitle = toolTipText({ ...params.row, referral: newValue });
        setTooltipTitle(newTooltipTitle);
        setSelectedValue(newValue);
    };

    const handleSuccess = (data) => {
        setAnchorEl(null);
        setSearchQuery('');
        enqueueSnackbar(data.message || '', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            }
        });
        setTableData((state) => state.map((ele) => {
            if (ele.id === data?.data?.id) {
                return {
                    ...ele,
                    referral: data?.data?.lead_source?.referral,
                    metadata: data?.data?.lead_source?.metadata,
                };
            }
            return ele;
        }));
    };

    const handleReferralClick = (e) => {
        if (tableType === 'archiveLeads' && handleArchivedNavigate) {
            handleArchivedNavigate(params.row);
            return;
        };
        setAnchorEl(e.currentTarget);
    };

    return (
        <Box sx={tableCellInnerBoxStyle} onClick={handleReferralClick}>
            <Tooltip
                title={tooltipTitle}
                placement="bottom"
                TransitionComponent={Zoom}
                TransitionProps={{ timeout: 300 }}
            >
                <Box sx={{ ...tableCellInnerBoxStyle, justifyContent: 'center' }}>
                    <Iconify
                        icon={setLeadSourceIcon(selectedValue, referral)?.icon}
                        sx={{ color: theme.palette.common.grey, p: '0' }}
                        width={24}
                        height={24}
                    />
                </Box>
            </Tooltip>
            <Popover
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                sx={{ zIndex: '99999999' }}
            >
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <Box sx={{ maxHeight: 220, overflowY: 'auto' }}>
                        <TextField
                            variant="outlined"
                            size="small"
                            fullWidth
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            sx={{ m: '8px 0 8px' }}
                            placeholder="Search referral"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Iconify
                                            icon={'eva:search-fill'}
                                            sx={{ color: 'text.disabled', width: 20, height: 20 }}
                                        />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {referral
                            ?.filter(option => (option?.label?.toLowerCase()?.includes(searchQuery?.toLowerCase()) && option?.value !== "CLINICIAN"))
                            ?.map((option) => (
                                <MenuItem key={option.value} value={option.value} onClick={() => handleReferralChange(option)} sx={{ backgroundColor: selectedValue === option.value ? theme.palette.action.selected : 'transparent' }}>
                                    <ListItemIcon sx={{ mr: 1 }}>
                                        <Iconify
                                            icon={setLeadSourceIcon(option.value, referral)?.icon}
                                            sx={{ color: theme.palette.common.grey, p: '3px' }}
                                            width={24}
                                            height={24}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary={option.label} />
                                </MenuItem>
                            ))}
                    </Box>
                </ClickAwayListener>
            </Popover>
        </Box>
    );
};

ReferralField.propTypes = {
    params: PropTypes.object,
    tableType: PropTypes.string,
    setTableData: PropTypes.func,
    handleArchivedNavigate: PropTypes.func,
};

export default ReferralField;
