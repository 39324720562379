import axios from 'axios';
import { updateError } from '../redux/slices/user';
import { dispatch } from '../redux/store';
// config
import { HOST_API, ADMIN_HOST_API } from '../config';
import { setSession } from './jwt';

// ----------------------------------------------------------------------

axios.create({
    baseURL: HOST_API,
});

axios.interceptors.response.use(
    (response) => response,
    (error) => Promise.resolve((error.response && error.response) || 'Something went wrong')
);

let isRefreshing = false;
let refreshQueue = [];

const refreshToken = () => {
    if (isRefreshing) {
        return new Promise((resolve, reject) => {
            refreshQueue.push({ resolve, reject });
        });
    }

    isRefreshing = true;

    return axios
        .post(`${HOST_API}/api/v1/auth/token/refresh/`, {
            refresh: localStorage.getItem('refreshToken'),
        })
        .then((response) => {
            if (response.status === 200) {
                setSession(response.data.access, localStorage.getItem('refreshToken'));
                axios.defaults.headers.common.Authorization = `Bearer ${response.data.access}`;
                refreshQueue.forEach((req) => req.resolve(response));
                refreshQueue = [];
            } else {
                throw new Error('Refresh failed');
            }

            return response;
        })
        .catch((error) => {
            setSession(null, null);

            refreshQueue.forEach((req) => req.reject(error));
            refreshQueue = [];

            throw error; // Ensures rejection propagates properly
        })
        .finally(() => {
            setTimeout(() => {
                isRefreshing = false;
            }, 200);
        });
};

const accessToken = () => localStorage.getItem('accessToken');

export async function callApi(uri, method = 'GET', data = null, isUnAuthorized = false) {
    try {
        const config = {
            method,
            url: `${HOST_API}${uri}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data
        };
        if (!isUnAuthorized) {
            axios.defaults.headers.common.Authorization = `Bearer ${accessToken()}`;
        }
        let response = await axios(config).then((res) => res).catch((error) => error.response);
        const { status } = response;

        if (status === 500 || status === 503) {
            dispatch(updateError({
                message: 'Something went wrong!',
                success: false
            }));
            return false;
        }

        if (status === 401) {
            const success = await refreshToken();
            if (success.status === 200) {
                axios.defaults.headers.common.Authorization = `Bearer ${accessToken()}`;
                response = await axios(config).then((res) => res).catch((error) => error.response);
            } else if (success.status === 401) {
                window.location.href = '/';
                dispatch(updateError({
                    message: 'Session Timed Out! Please Login Again',
                    success: false
                }));
                return success;
            } else {
                return success;
            }
        }
        return response;
    } catch (error) {
        return error.response;
    }
}

export async function callAdminApi(uri, method = 'GET', data = null) {
    try {
        const config = {
            method,
            url: `${ADMIN_HOST_API}${uri}`,
            headers: {
                'Content-Type': 'application/json'
            },
            data
        };
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken()}`;
        let response = await axios(config).then((res) => res).catch((error) => error.response);
        const { status } = response;

        if (status === 500 || status === 503) {
            dispatch(updateError({
                message: 'Something went wrong!',
                success: false
            }));
            return false;
        }

        if (status === 401) {
            const success = await refreshToken();
            if (success.status === 200) {
                axios.defaults.headers.common.Authorization = `Bearer ${accessToken()}`;
                response = await axios(config).then((res) => res).catch((error) => error.response);
            } else if (success.status === 401) {
                window.location.href = '/';
                return success;
            } else {
                return success;
            }
        }
        return response;
    } catch (error) {
        return error.response;
    }
}
