/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// libraries
import { Box, Divider, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
// components
import MailCompose from '../../sections/@dashboard/leadDetail/mail/mailCompose';
import Iconify from '../Iconify';
import { FormProvider } from '../hook-form';
import FailedToReminder from './FailedToReminder';
import SendWhatsappContent from './SendWhatsappContent';
import UnableToContactConfirmationDialog from '../UnableToContactConfirmationDialog';
import EmailForm from '../../sections/@dashboard/leadDetail/EmailForm';
import CustomCheckBox from './CustomCheckBox';
import NotesComponent from './NotesComponent';
// redux
import { dispatch, useSelector } from '../../redux/store';
import { updateCompleteStatus, saveNewLeadDetail, addNotes, updatePersonalDetail } from '../../redux/slices/lead';
import { getActivities } from '../../redux/slices/activity';
import { getWhatsappAccount } from '../../redux/slices/whatsappAccount';
import { setLoader } from '../../redux/slices/loader';
// hooks/utils/constants/validations
import { useToggle } from '../../hooks';
import { getTreatment } from '../../utils/multiTreatmentFlow';
// styles
import {
    unableToContactBoxFirstStyle,
    sendEmailBoxStyle,
    sendEmailTitleStyle,
    emailSuccessBoxStyle,
    emailSuccessIconBoxStyle,
} from '../../styles/FailedToAttendTabs';

const UnableToContact = forwardRef(({ handleNext, type, openArchivePopup, isOverview, completeFlow }, ref) => {
    const { followLead } = useSelector((state) => state.lead);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { currentTreatmentDataGlobal } = useSelector((state) => state.multiTreatment);
    const { sendEmailStatus } = useSelector((state) => state.mail);
    const [note, setNote] = useState(true);
    const [email, setEmail] = useState(false);
    const [whatsapp, setWhatsapp] = useState(false);
    const [reminder, setReminder] = useState(false);
    const [contact, setContact] = useState(false);
    const inputRef = useRef();
    const [noteChecked, setNoteChecked] = useState(false);
    const [emailChecked, setEmailChecked] = useState(false);
    const [reminderChecked, setReminderChecked] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isNoteSaved, setIsNoteSaved] = useState(true);
    const [notesValue, setNotesValue] = useState('');
    const [emailNotification, setEmailNotification] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState('');
    const dialogRef = useRef();
    const [whatsappChecked, setWhatsappChecked] = useState(false);
    const [whatsappNotification, setWhatsappNotification] = useState(false);

    useEffect(() => {
        if (practiceId) { dispatch(getWhatsappAccount(practiceId)); }
    }, [practiceId]);

    const { toggle, onOpen, onClose } = useToggle();

    const defaultValues = {
        notes: '',
        openNotes: true,
        Reminder: '',
        appoimentId: '',
    };

    const methods = useForm({ defaultValues });

    const { handleSubmit, setValue, getValues } = methods;

    useImperativeHandle(
        ref,
        () => ({
            async submit(status) {
                await handleSubmit((data) => onSubmit(data, status))();
            },
        }),
        [isNoteSaved, noteChecked, reminderChecked]
    );

    const onSubmit = async (data, status) => {
        if (data?.notes === '' && (data?.Reminder === '' || data?.Reminder === false)) {
            setConfirmMessage('No selections have been made. The system will not submit any data. Are you sure you want to proceed without making any selections?');
            onOpen();
        } else if (data?.Reminder && !reminderChecked) {
            await inputRef.current.submit(status);
        } else if (!isNoteSaved) {
            setConfirmMessage('Do you want to submit "Note"?');
            onOpen();
        } else {
            let unableToContact = {};
            const Details = {
                lead: followLead?.id,
                additional_notes: data?.notes ? data.notes : '',
                note: 'UNABLE_TO_CONTACT',
                treatment: getTreatment(isOverview, currentTreatmentDataGlobal, followLead),
            };
            if (status !== 'Archive') {
                unableToContact = {
                    ...unableToContact,
                    noteDetail: Details
                };
                dispatch(saveNewLeadDetail({ unableToContact }));
            }
            if (status?.toLowerCase?.() === 'finish') dispatch(updateCompleteStatus(true));
            if (status === 'Archive') openArchivePopup();
            flowUpdateFunc();
        }
    };

    const saveNoteSubmit = () => {
        const details = {
            lead: followLead?.id,
            additional_notes: getValues('notes'),
            contacted_via: 'PHONE',
            note: 'UNABLE_TO_CONTACT',
            treatment: getTreatment(isOverview, currentTreatmentDataGlobal, followLead),
        };
        if (details?.lead)
            dispatch(addNotes(details, practiceId, handleNoteUpdate, 'UNABLE_TO_CONTACT'));
    };

    const handleNoteUpdate = () => {
        setNoteChecked(true);
        setIsNoteSaved(true);
        dispatch(getActivities(practiceId, followLead?.id, 10, 0));
        setNote(!note);
        handleAccordian('email', true);
    };

    const handleAccordian = (data, forceOpen = false) => {
        switch (data) {
            case 'notes':
                setNote(!note);
                setValue('openNotes', !note);
                break;
            case 'email':
                setEmail(forceOpen || !email);
                break;
            case 'whatsapp':
                setWhatsapp(forceOpen || !whatsapp);
                break;
            case 'reminder':
                setReminder(!reminder);
                setValue('Reminder', !reminder);
                break;
            case 'contact':
                setContact(!contact);
                break;
            default:
                break;
        }
    };

    const emailSendSuccess = () => {
        setEmailChecked(true);
        setEmailNotification(true);
        setEmail(!email);
        setWhatsapp(true);
    };

    const whatsappMessageSuccess = () => {
        setWhatsappChecked(true);
        setWhatsappNotification(true);
        setWhatsapp(!whatsapp);
        setReminder(true);
        setValue('Reminder', true);
    };

    const handleReminderUpdate = () => {
        setReminderChecked(true);
        setReminder(!reminder);
        dispatch(setLoader(false));
    };

    const handleOptionClick = (value) => {
        const newSelectedOptions = [value];
        setSelectedOptions(newSelectedOptions);
        const newNotesValue = newSelectedOptions.join('');
        setValue('notes', newNotesValue);
        setNotesValue(newNotesValue);
        setIsNoteSaved(false);
        if (newSelectedOptions.length === 0) setIsNoteSaved(true);
        const textFieldElement = document.querySelector('.notes-main-box .MuiInputBase-root textarea');
        if (textFieldElement) textFieldElement.focus();
    };

    const handleNotesInputChange = (e) => {
        const enteredValue = e.target.value;
        setValue('notes', enteredValue);
        setNotesValue(enteredValue);
        if (enteredValue !== '' && getValues('notes') !== notesValue) {
            setIsNoteSaved(false);
        }
        if (getValues('notes') === '') setIsNoteSaved(true);
    };

    useEffect(() => {
        if (getValues('notes') !== '' && getValues('notes') !== notesValue) {
            setIsNoteSaved(false);
        }
        if (getValues('notes') === '') {
            setIsNoteSaved(true);
        }
    }, [getValues('notes')]);

    const confirmHandleSubmit = async () => {
        dispatch(setLoader(true));
        if (getValues('Reminder') && !reminderChecked) {
            await inputRef.current.submit('finish');
        }
        if (!isNoteSaved) {
            const details = {
                lead: followLead?.id,
                additional_notes: getValues('notes'),
                contacted_via: 'PHONE',
                note: 'UNABLE_TO_CONTACT',
                treatment: getTreatment(isOverview, currentTreatmentDataGlobal, followLead),
            };
            if (details?.lead)
                dispatch(addNotes(details, practiceId, flowUpdateFunc, 'UNABLE_TO_CONTACT'));
        }
        setTimeout(() => {
            flowUpdateFunc();
        }, 1500);
    };

    const confirmHandleClose = (e) => {
        e.stopPropagation();
        onClose();
    };

    const flowUpdateFunc = () => {
        completeFlow('finish');
        const htmlElement = document.querySelector('body');
        htmlElement.classList.remove('overflow-hidden');
    };

    const handleEmailSubmit = (data) => {
        const detail = { lead_contact: { email: data.email } };
        if (followLead && followLead?.id) {
            detail.id = followLead?.id;
            dispatch(updatePersonalDetail(detail, practiceId, () => { }, 'leadDetail'));
        }
    };

    const getEmailComponent = () => {
        if (email) {
            if (followLead?.lead_contact?.email) {
                return <Box>
                    <MailCompose
                        title={'dfadfadf'}
                        onCloseCompose={() => { }}
                        practiceId={practiceId}
                        leadDetail={followLead}
                        isFromUnableToContact={'true'}
                        isFromSideBar={'true'}
                        successDone={() => { }}
                        sendUnableToContact={emailSendSuccess}
                        setEmailNotification={setEmailNotification}
                    />
                </Box>;
            }
            return <Box sx={{ mt: 2, mb: 3 }}>
                <EmailForm handleEmailSubmit={handleEmailSubmit}
                    formWrapper={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                    fieldWrapperSx={{ width: 'calc(100% - 100px)' }}
                    btnSx={{ p: '8px 24px', minHeight: '53.13px' }}
                />
            </Box>;
        }
        return null;
    };

    const downIcon = <Iconify width={20} height={20} icon={'akar-icons:chevron-down'} />;
    const minusIcon = <Iconify width={20} height={20} icon={'akar-icons:minus'} />;

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)} >
            <Box sx={unableToContactBoxFirstStyle}>
                <NotesComponent data={{
                    note, downIcon, minusIcon, handleAccordian, noteChecked, notesValue,
                    handleNotesInputChange, handleOptionClick, selectedOptions, isNoteSaved, saveNoteSubmit
                }} />
                <Divider sx={{ m: '16px 0' }} />
                <Box>
                    <Box onClick={() => handleAccordian('email')} sx={sendEmailBoxStyle}>
                        <Typography sx={sendEmailTitleStyle}>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <CustomCheckBox checked={emailChecked} label='Send a email' />
                                {emailNotification &&
                                    <Box sx={emailSuccessBoxStyle}>
                                        <Box sx={emailSuccessIconBoxStyle}><Iconify icon={'fa:send'} color='common.lightGreen' width={14} height={14} /></Box> {sendEmailStatus === 'Save as drafts' ? 'Email saved as draft successfully!' : 'Email sent successfully!'}
                                    </Box>
                                }
                            </Box>
                            {!email ? downIcon : minusIcon}
                        </Typography>
                    </Box>
                    {getEmailComponent()}
                </Box>
                <Divider sx={{ m: '16px 0' }} />
                <SendWhatsappContent
                    handleAccordian={handleAccordian}
                    whatsappChecked={whatsappChecked}
                    whatsappNotification={whatsappNotification}
                    whatsapp={whatsapp}
                    whatsappMessageSuccess={whatsappMessageSuccess}
                    flowUpdateFunc={flowUpdateFunc}
                    setWhatsappNotification={setWhatsappNotification}
                />
                <Box>
                    <Box onClick={() => handleAccordian('reminder')} sx={sendEmailBoxStyle}>
                        <Typography sx={sendEmailTitleStyle}>
                            <CustomCheckBox checked={reminderChecked} label='Add a follow up reminder' />
                            {!reminder ? downIcon : minusIcon}
                        </Typography>
                    </Box>
                    {reminder ? <Box>
                        <FailedToReminder isOverview={isOverview} value={reminder} type={type} ref={inputRef} onNextSubmit={onSubmit} handleNext={handleNext} handleReminderUpdate={handleReminderUpdate} onOpen={onOpen} flowUpdateFunc={flowUpdateFunc} setConfirmMessage={setConfirmMessage} />
                    </Box> : null}
                </Box>
            </Box>
            <UnableToContactConfirmationDialog
                toggle={toggle}
                handleClose={(e) => confirmHandleClose(e)}
                handleSubmit={() => confirmHandleSubmit()}
                message={confirmMessage}
                style={{ zIndex: '9999999' }}
                dialogRef={dialogRef}
                note={getValues('notes')}
            />
        </FormProvider>
    );
});

UnableToContact.propTypes = {
    handleNext: PropTypes.func,
    type: PropTypes.string,
    openArchivePopup: PropTypes.func,
    isOverview: PropTypes.bool,
    completeFlow: PropTypes.func
};

export default UnableToContact;
