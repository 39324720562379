/* eslint-disable no-unused-vars */
import { Box, Button, Card, Checkbox, FormControl, InputLabel, MenuItem, Select, Step, StepLabel, Stepper, Tooltip, Typography, useTheme } from '@mui/material';
import getStyles from 'src/styles/uploadFileV2Styles';
import React from 'react';
import { sampleCsv } from 'src/constants/commonConstants';
import Papa from 'papaparse';
import { useSelector, useDispatch } from 'react-redux';
import { grey } from 'src/theme/palette';
import { setFileName, setImportFiles, setMappingValues, setOriginalDataFile } from 'src/redux/slices/audienceManager';
import { emailRegex } from 'src/utils/regex';
import moment from 'moment';
import { isArray, isEmpty } from 'lodash';
import { Iconify } from '..';
import { VisuallyHiddenInput } from '../styledComponents';
import TransferList from './TransferList';

const STEPS = ['Upload a CSV File', 'Select Columns', 'Duplicate Validation', 'Preview & Save'];
const fieldName = [
    { label: 'First name', value: 'firstname' },
    { label: 'Last name', value: 'lastname' },
    { label: 'Title', value: 'title' },
    { label: 'Email', value: 'email' },
    { label: 'Phone', value: 'phone' },
    { label: 'Date of Birth', value: 'dob' },
];

const UploadFileV2 = () => {
    const theme = useTheme();
    const styles = getStyles(theme);
    const fileInputRef = React.useRef(null);
    const { fileName, originalDataFile, importFiles } = useSelector((state) => state.audienceManager);
    const csvLink = fileName;
    const dispatch = useDispatch();


    const [activeStep, setActiveStep] = React.useState(0);
    const [isFileUploaded, setIsFileUploaded] = React.useState(false);
    const [isFileValue, setIsFileValue] = React.useState('');
    const [csvHeaders, setCsvHeaders] = React.useState([]);
    const [originals, setOriginals] = React.useState([]);
    const [duplicates, setDuplicates] = React.useState([]);
    const [all, setAll] = React.useState([]);
    const [headers, setHeaders] = React.useState([]);
    const [finalHeaders, setFinalHeaders] = React.useState({});
    const [uploadedFile, setUploadedFile] = React.useState('');
    const [newHeaders, setNewHeaders] = React.useState('');

    const handleHeaders = (file, newHeaders) => {
        Papa.parse(file, {
            download: true,
            header: false,
            complete(results) {
                if (newHeaders) {
                    setNewHeaders(results.data);
                } else {
                    setHeaders(results.data);
                }
            },
        });
    };


    const convertToBase64 = (result, isOriginal) => {
        const reader = new FileReader();
        reader.readAsDataURL(result);
        reader.onload = () => {
            if (isOriginal) {
                dispatch(setOriginalDataFile(reader.result));
                handleHeaders(result, true);
                return;
            }
            dispatch(setImportFiles(reader.result));
        };
    };

    const convertToCSV = (fileData) => {
        if (isEmpty(fileData)) {
            dispatch(setImportFiles(''));
            return;
        }
        const newHeaders = Object.keys(finalHeaders)?.filter(el => finalHeaders?.[el]?.visible)?.map(el => finalHeaders[el]?.mappedToKey);
        const data = fileData.map((el) => {
            let payload = {};
            if (isArray(newHeaders) && newHeaders.length > 0) {
                payload = newHeaders.reduce((total, current) => {
                    total = { ...total, [current]: el[current] };
                    return total;
                }, {});
            } else {
                payload = {
                    firstname: el.firstname || '',
                    lastname: el.lastname || '',
                    email: el?.email || '',
                    phone: el?.phone || '',
                    salutation: el?.salutation || '',
                    dob: el.dob || '',
                };
            }
            return payload;
        });
        const csvRows = [];
        const keys = Object.keys(data[0]);
        csvRows.push(keys.join(','));
        // eslint-disable-next-line no-restricted-syntax
        for (const row of data) {
            const values = keys.map((key) => `"${row[key]}"`);
            csvRows.push(values.join(','));
        }
        const newCsvData = csvRows.join('\n');

        const blob = new Blob([newCsvData], { type: 'text/csv;charset=utf-8;' });
        convertToBase64(blob, true);
    };

    const dobCheck = (item) => {
        if (!item?.dob) return true;
        const dob = moment(item?.dob, 'DD/MM/YYYY', true);
        if (!dob.isValid()) return true;
        return moment().diff(dob, 'years') > 17;
    };

    const handleFilter = (group, key) => group?.find((item) => item?.salutation && item?.salutation?.toLowerCase() === key && dobCheck(item));

    const handleNext = () => {
        if (activeStep === 1) {
            const newMappings = {};
            Object.keys(finalHeaders)?.forEach(headerName => {
                if (finalHeaders?.[headerName]?.visible)
                    newMappings[headerName] = finalHeaders?.[headerName]?.mappedToKey;
            })

            dispatch(setMappingValues({ firstname: "lead_first_name", lastname: "lead_last_name", title: "lead_salutation", email: "lead_email", phone: "lead_phone" }));

            // filter new data
            const newData = uploadedFile?.map(eachData => {
                const newObj = {};
                Object.keys(eachData)?.forEach(eachKey => {
                    if (finalHeaders[eachKey]?.visible) {
                        newObj[finalHeaders[eachKey]?.mappedToKey || eachKey] = eachData?.[eachKey];
                    }
                })
                return newObj;
            });

            if (newData?.length) {
                const filteredData = newData.filter((entry) => {
                    if (!entry?.email) return false;
                    let emailList = entry?.email?.split(/\s+/);
                    emailList = emailList?.filter(em => emailRegex.test(em));
                    return emailList?.[0];
                })?.map(entry => {
                    let emailList = entry?.email?.split(/\s+/);
                    emailList = emailList?.filter(em => emailRegex.test(em));
                    return { ...entry, email: emailList?.[0] }
                });
                const groupByEmail = filteredData.reduce((groups, item) => {
                    const group = groups[item?.email] || [];
                    group.push(item);
                    groups[item?.email] = group;
                    return groups;
                }, {});
                const originals = [];
                const duplicates = [];
                const isInArray = (obj, array) => array.some((item) => JSON.stringify(item) === JSON.stringify(obj));
                const isMatchesObj = (a, b) => JSON.stringify(a) === JSON.stringify(b);
                Object.values(groupByEmail).forEach((group) => {
                    if (group.length > 1) {
                        let original = handleFilter(group, 'mrs');
                        if (!original) {
                            original = handleFilter(group, 'mr');;
                        }
                        if (!original) {
                            const filteredData = group?.find((item) => item?.salutation && dobCheck(item));
                            if (!isEmpty(filteredData)) {
                                original = filteredData;
                            }
                        }
                        if (isEmpty(original)) {
                            const filteredData = group?.find((item) => dobCheck(item));
                            if (!isEmpty(filteredData)) {
                                original = filteredData;
                            }
                        }
                        if (isArray(original)) {
                            originals.push(...original);
                            const duplicateItems = group.filter((item) => !isInArray(item, original));
                            duplicates.push(...duplicateItems);
                        } else {
                            const duplicateItems = group.filter((item) => !isMatchesObj(item, original));
                            originals.push(original);
                            duplicates.push(...duplicateItems);
                        }
                    } else {
                        const item = group[0];
                        if (dobCheck(item)) {
                            originals.push(item);
                        } else {
                            duplicates.push(item);
                        }
                    }
                });
                const filteredOriginals = originals?.filter((ele) => ele?.email) || [];
                const filteredDuplicates = filteredData.filter((item) => !isInArray(item, filteredOriginals)) || [];
                setOriginals(filteredOriginals?.map((ele, index) => ({ ...ele, id: index })));
                convertToCSV(filteredOriginals);
                setDuplicates(filteredDuplicates?.map((ele, index) => ({ ...ele, id: originals?.length + index })));
                setAll([...filteredOriginals, ...filteredDuplicates]?.map((ele, index) => ({ ...ele, id: index })));
            }
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const isNextDisabled = currStep => {
        switch (currStep) {
            case 0: return false;
            default: return false;
        }
    }

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleFileUpload = (event) => {
        if (event.target.value) {
            setCsvHeaders([]);
            setFinalHeaders({});
            dispatch(setMappingValues({}));
            dispatch(setImportFiles(''));
            dispatch(setOriginalDataFile(''));
            setIsFileUploaded(true);
            setIsFileValue(event.target.files[0].name);
            dispatch(setFileName(event.target.files[0].name));
            convertToBase64(event.target.files[0]);
            Papa.parse(event.target.files[0], {
                complete(results) {
                    const filteredData = results.data?.filter((item) => !Object.values(item).every(value => value === '')) || [];
                    setUploadedFile(filteredData);
                    fileInputRef.current.value = null;
                    handleNext();
                },
                header: true,
            });
            handleHeaders(event.target.files[0]);
        }
    };

    React.useEffect(() => {
        if (importFiles) {
            setIsFileValue(fileName);
            setIsFileUploaded(true);
            handleHeaders(importFiles);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [importFiles]);

    return (
        <>
            <Box>
                <Card sx={styles.root}>
                    {/** Stepper Component */}
                    <Box sx={styles.stepperBox}>
                        <Stepper activeStep={activeStep}>
                            {STEPS.map((label) => {
                                const stepProps = {};
                                const labelProps = {};
                                return (
                                    <Step key={label} {...stepProps}>
                                        <StepLabel {...labelProps}>{label}</StepLabel>
                                    </Step>
                                );
                            })}
                        </Stepper>
                    </Box>
                    {/** Stepper Step Component */}
                    <Box sx={styles.stepperStepComponent}>
                        {activeStep === 0 && (
                            <Box sx={styles.dropBox}>
                                <Box sx={{ mb: 1 }}>
                                    <Button component='label' variant='contained'
                                        sx={{ borderRadius: "0.5rem" }}>
                                        Upload File
                                        <VisuallyHiddenInput
                                            type='file'
                                            onChange={handleFileUpload}
                                            accept='.csv'
                                            ref={fileInputRef} />
                                    </Button>
                                </Box>
                                {isFileValue && (
                                    <Typography
                                        sx={{
                                            fontWeight: '700', display: 'block',
                                            color: grey[600]
                                        }}
                                        component={isFileValue?.split('csv/')[1] ? 'a' : 'p'}
                                        href={csvLink}
                                        clickable
                                    >
                                        {isFileValue.split('csv/')[1] || isFileValue || ''}
                                    </Typography>
                                )}
                                <Box>
                                    <Typography sx={{ fontSize: '14px', color: grey[600] }}>You can import customer data here with a .csv file</Typography>
                                </Box>
                                <Tooltip title="DownloadSample CSV File">
                                    <Button href={sampleCsv} sx={{ ml: 1 }} endIcon={<Iconify
                                        sx={{ cursor: 'pointer' }}
                                        icon={'material-symbols:download-rounded'}
                                        width={14}
                                        height={14}
                                    />}>
                                        <Typography sx={{ fontSize: '10px', color: grey[600] }}>Click here to download sample file</Typography>
                                    </Button>
                                </Tooltip>
                            </Box>)}
                        {/** Active step for Selecting Headers */}
                        {activeStep === 1 && (<Box sx={styles.selectHeaders}>
                            {headers?.[0]?.sort()?.map((headerName, index) => (
                                <Box key={index} sx={styles.selectHeaderRow}>
                                    <Box sx={styles.alignCenter}>
                                        <Checkbox
                                            checked={finalHeaders[headerName]?.visible}
                                            onChange={(event) => {
                                                setFinalHeaders(prvObj => ({
                                                    ...prvObj, [headerName]: {
                                                        ...prvObj?.[headerName],
                                                        visible: event.target.checked
                                                    }
                                                }))
                                            }}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                        />
                                        <Typography sx={styles.headerName}>{headerName}</Typography>
                                    </Box>
                                    {finalHeaders[headerName]?.visible ? (
                                        <>
                                            <Iconify
                                                icon={'heroicons:arrow-long-right-20-solid'}
                                                width={14}
                                                height={14}
                                            />
                                            <Box sx={{ height: "2rem", width: "10rem" }}>
                                                <FormControl fullWidth size="small" sx={{ height: "2rem" }}>
                                                    <InputLabel id="demo-simple-select-label12"><Typography sx={{ fontSize: "0.8rem" }}>Mapped to</Typography></InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label12"
                                                        id="demo-simple-select12"
                                                        value={finalHeaders?.[headerName]?.mappedToKey || ''}
                                                        label="Mapped to"
                                                        size="small"
                                                        sx={{ height: "2rem" }}
                                                        onChange={(e) => {
                                                            setFinalHeaders(prvObj => ({
                                                                ...prvObj, [headerName]: {
                                                                    ...prvObj?.[headerName],
                                                                    mappedToKey: e.target.value
                                                                }
                                                            }))
                                                        }}
                                                    >
                                                        {fieldName?.map((fn, index) => (
                                                            <MenuItem key={index} value={fn?.value}>{fn?.label}</MenuItem>))}
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </>
                                    ) : null}
                                </Box>))}
                        </Box>)}
                        {/** Active step for selecting duplicates */}
                        {activeStep === 2 && (<Box sx={styles.transferList}>
                            <TransferList
                                originalProps={{ originals, setOriginals }}
                                duplicateProps={{ duplicates, setDuplicates }}
                                allProps={{ all, setAll }}
                                convertToCSV={convertToCSV}
                                headers={newHeaders}
                            />
                        </Box>)}
                        {/** Activty Step preview */}
                        {activeStep === 3 && (<Box sx={styles.dropBox}>
                            <Tooltip title="Preview CSV File">
                                <Button href={originalDataFile} sx={{ ml: 1 }} endIcon={<Iconify
                                    sx={{ cursor: 'pointer' }}
                                    icon={'material-symbols:download-rounded'}
                                    width={14}
                                    height={14}
                                />}>
                                    <Typography sx={{ fontSize: '14px', color: grey[600] }}>Click here to preview final csv file</Typography>
                                </Button>
                            </Tooltip>
                        </Box>)}
                    </Box>
                    <Box sx={styles.footer}>
                        <Button
                            sx={{ height: "2rem" }}
                            variant="outlined"
                            disabled={activeStep === 0}
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                        <Button sx={{ height: "2rem" }}
                            variant="outlined"
                            disabled={activeStep === (STEPS.length - 1) || isNextDisabled(activeStep)}
                            onClick={handleNext}
                        >Next</Button>
                    </Box>
                </Card>
            </Box>
        </>
    )
};

export default UploadFileV2;
