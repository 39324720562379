const getStyles = (theme) => ({
    root: {
        border: '1px dashed rgba(145, 158, 171, 0.32)',
        minHeight: '500px',
        display: 'flex',
        alignItems: 'space-between',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    stepperBox: {
        width: "100%",
        margin: "1.5rem 1rem 0rem 1rem"
    },
    dropBox: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        border: `2px solid ${theme.palette.text.disabled}`,
        borderStyle: 'dashed',
        borderRadius: "1rem",
        width: "90%",
        height: "300px"
    },
    footer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: "3rem",
        width: "90%",
    },
    stepperStepComponent: {
        minHeight: "300px",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    selectHeaders: {
        display: "flex",
        flexWrap: "wrap",
        width: "90%",
        height: "300px",
        overflow: "auto"
    },
    transferList: {
        width: "90%",
        height: "500px",
        overflow: "auto",
        display: "flex",
    },
    selectHeaderRow: {
        width: "48%",
        height: "2.5rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    headerName: {
        fontSize: "0.8rem",
        ml: "0.2rem",
        mr: "0.2rem"
    },
    alignCenter: {
        display: "flex",
        alignItems: 'center'
    },
});

export default getStyles;