/* eslint-disable no-nested-ternary */
import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { callApi } from '../../utils/apiCall';

import { updateError } from './user';

import { dispatch, store } from '../store';
import { getPracticeId } from './clincheck';


const initialState = {
    isLoading: false,
    widgetCounter: [],
    isLoadingWidgetCounter: true
};

const slice = createSlice({
    name: 'widgetCounter',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        setWidgetCounter(state, action) {
            state.widgetCounter = action.payload;
        },

    }
});

export default slice.reducer;

export const { setWidgetCounter, reset } = slice.actions;

export const getDateRange = (date) => {
    const filteredDate = date.split(',');
    const customStartDate = `${filteredDate[0]} 00:00:00`;
    const customEndDate = `${filteredDate[1]} 23:59:00`;
    return `${customStartDate},${customEndDate}`;
};

export const getQueryTask = (data) => {
    const { isTasksCompleted, assigneeUser, unassignedUser, filter } = data.taskFilter
    let query = `?fields={}&lead__is_archived=false&ordering=datetime&is_completed=${isTasksCompleted}`;

    if (filter === 'TODO') {
        query += `&group__in=TODO`
    } else if (filter === "CALLBACK") {
        query += `&group__in=CALLBACK_REQUEST`
    } else {
        query += `&group__in=TODO,FOLLOW_UP,CALLBACK_REQUEST`
    }
    if (assigneeUser) {
        query += `&assignees__user__id__in=${assigneeUser}`
    }
    if (unassignedUser) {
        query += '&assignees__isnull=true'
    }
    if (filter !== 'TODO' && filter !== 'leadNotes' && filter !== '' && filter !== "CALLBACK") {
        query += `&lead_treatment__status=${filter}`
    }
    if (data.dateFilters === 'Upcoming') {
        query += `&datetime__date__gte=${moment(new Date().setDate(new Date().getDate() + 1)).format('YYYY-MM-DD')}`
    } else if (data.dateFilters === 'Today') {
        query += `&datetime__date__lte=${moment(new Date().setDate(new Date().getDate())).format('YYYY-MM-DD')}`
    }

    if (data.treatment) {
        query += `&lead_treatment__treatment__in=${data.treatment}`
    }

    return query;

}

export const getQuery = (data) => {
    let query = '?fields={}&is_archived=false';

    if (data?.dateRange) {
        query += `&created_at__range=${getDateRange(data?.dateRange)}`;
    }
    if (data.treatmentStatusForPrice === 'TREATMENT_STARTED' || data.status === 'TREATMENT_STARTED') {
        if (data.startDate && data.endDate) {
            query += `&state_transition_params={"state":"TREATMENT_STARTED","timestamp_gte":"${data.startDate} 00:00:00","timestamp_lte":"${data.endDate} 23:59:00"}`;
        }
        // else if (!data.appointmentDateRange) {
        //     query += '&state_transition_params={"state":"TREATMENT_STARTED"}';
        // }
    }
    if (data?.status) {
        if (data?.status === "NEW" && data?.healthCareProvider && data?.hasNewOrNullTreatment) {
            query += `&has_new_or_null_treatment=true`
        } else
            query += `&lead_treatments__status=${data?.status}`;
    }
    if (data?.treatmentStatus) {
        query += `&lead_treatment__status=${data?.treatmentStatus}`;
    }
    if (data?.treatment) {
        query += `&lead_treatments__treatment__in=${data?.treatment}`;
    }
    if (data?.reminderContactedCount) {
        query += '&contacted_count=0';
    }
    if (data?.contactedCount) {
        query += `&lead_treatments__lead_contacted_counts__count__gte=${data?.contactedCount}`;
    }
    if (data?.clinCheckStatus) {
        query += `&lead_treatments__leadtreatment_clincheck__status__in=${data?.clinCheckStatus}`;
    }
    if (data?.isLeadNotes) {
        query += '&lead_notes__note__in=AWAITING_RESPONSE';
    }
    if (data.appointmentDateRange) {
        query += `&lead_treatments__appointments__datetime__range=${getDateRange(data.appointmentDateRange)}`;
    }
    if (data.treatmentPrice) {
        query += '&aggregate[Sum]=lead_treatments__custom_price';
    }
    if (data.isContactedCountNull) {
        query += '&lead_treatments__lead_contacted_counts__isnull=true';
    }
    if (data.isClinCheckTable) {
        query += '&lead_treatments__leadtreatment_clincheck__isnull=false';
    }
    if (data.group__in) {
        query += '&group__in=FOLLOW_UP,TODO';
        query += '&lead__is_archived=false';
    }
    if (data.completedFilter) {
        query += `&is_completed=${data.is_completed}`;
    }
    if (data.isTaskDateTimeRange) {
        query += `&datetime__date__range=${data?.taskDateRange}`;
    }
    if (data.treatmentStatusForPrice) {
        query += `&lead_treatments__status__in=${data?.treatmentStatusForPrice}`;
    }
    if (data.appStatus) {
        query += `&lead_treatments__appointments__status=${data?.appStatus}`;
    }
    if (data.appStatusForPrice) {
        query += `&lead_treatments__appointments__status__in=${data?.appStatusForPrice}`;
    }
    if (data?.healthCareProvider) {
        query += `&healthcare_provider=${data?.healthCareProvider}`;
    }
    if (data?.hasNewOrNullTreatment) {
        query += `&has_new_or_null_treatment=${data?.hasNewOrNullTreatment}`;
    }

    return query;
};

export const setCounter = (data, counter, teethCount) => {
    const { widgetCounter } = store.getState().widgetCounter;
    const existingItemIndex = widgetCounter.findIndex(item => item.key === data.key);
    if (existingItemIndex !== -1) {
        const updatedWidgetCounter = [...widgetCounter];
        updatedWidgetCounter[existingItemIndex] = {
            ...updatedWidgetCounter[existingItemIndex],
            count: counter,
            teethCount
        };
        dispatch(setWidgetCounter(updatedWidgetCounter));
    } else {
        dispatch(setWidgetCounter([
            ...widgetCounter,
            { key: data.key, count: counter, teethCount }
        ]));
    }
};


export function getWidgetCounter(data) {
    return async () => {
        try {
            const { type } = data ?? {};
            const isTreatmentV2Enabled = type === "TREATMENT_STARTED";
            let query = getQuery(data);
            if (isTreatmentV2Enabled) {
                query = query.replaceAll("lead_treatments__", "");
                query = `${query}&healthcare_provider=PRIVATE`
            }

            let teethCount = 0;

            const response = isTreatmentV2Enabled ?
                await callApi(`/api/v1/practices/${getPracticeId()}/lead-treatments-flat/${query}&fields={leadtreatment_specification}`) :
                await callApi(`/api/v1/practices/${getPracticeId()}/leads/${query}`);
            if (isTreatmentV2Enabled) {
                teethCount = (response?.data?.results ?? [])?.reduce((sum, item) => sum + (item?.leadtreatment_specification?.teeth_count || 0), 0) ?? 0;
            }
            if (response.status === 200) {
                const result = response.data;
                setCounter(data, data.isTreatmentNotPresent ? 0 : result?.count, teethCount);

            } else {
                dispatch(updateError({ success: false, message: response.data || 'Something went wrong' }));
            }

        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}
export function getTreatmentWidgetPrice(data) {
    return async () => {
        try {

            const response = await callApi(`/api/v1/practices/${getPracticeId()}/reports/leads/${getQuery(data)}`);

            if (response.status === 200) {
                const result = response.data;
                setCounter(data, result?.data?.sum_lead_treatments__custom_price);

            } else {
                dispatch(updateError({ success: false, message: response.data || 'Something went wrong' }));
            }
        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}
export function getTaskWidgetCounter(data) {

    return async () => {
        try {

            const response = await callApi(`/api/v1/practices/${getPracticeId()}/reminders/${data?.taskFilter ? getQueryTask(data) : getQuery(data)}`);

            if (response.status === 200) {
                const result = response.data;
                setCounter(data, result?.count);

            } else {
                dispatch(updateError({ success: false, message: response.data || 'Something went wrong' }));
            }

        } catch (error) {
            dispatch(updateError({ success: false, message: error.message || 'Something went wrong' }));
        }
    };
}

