import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@mui/material';
import { Iconify, MultiDatePicker } from '..';
import { widgetButtonStyle, widgetButtonWrapperStyle } from '../../styles/widgetCounterStyle';

const WidgetButtons = ({ toggle, dateRangeProps, handleOpen, handleClose, type, tableTitle }) =>
    <Box sx={widgetButtonWrapperStyle} className={tableTitle === 'Aligner Approve Leads' ? 'widgetButtonWrapper' : ''}>
        {toggle ? (
            <Button
                sx={widgetButtonStyle}
                variant='outlined'
                onClick={handleClose}
                endIcon={<Iconify icon={'solar:alt-arrow-up-line-duotone'} width={24} height={24} />}
                className='widgetButton'
            >
                Collapse
            </Button>
        ) : (
            <Button
                sx={widgetButtonStyle}
                variant='outlined'
                onClick={handleOpen}
                endIcon={<Iconify icon={'solar:alt-arrow-down-outline'} width={24} height={24} />}
                className='widgetButton'
            >
                Expand stats
            </Button>
        )}
        {dateRangeProps && <MultiDatePicker
            value={dateRangeProps.date}
            endDate={new Date()}
            dateChange={dateRangeProps.dateChange}
            setValue={dateRangeProps.setDate}
            startDateValue={dateRangeProps.startDate}
            endDateValue={dateRangeProps.endDate}
            inputFieldType='button'
            type={type}
        />}
    </Box>;

export default WidgetButtons;

WidgetButtons.propTypes = {
    toggle: PropTypes.bool,
    handleOpen: PropTypes.func,
    handleClose: PropTypes.func,
    dateRangeProps: PropTypes.object,
    type: PropTypes.string,
    tableTitle: PropTypes.string,
};