/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// @mui
import { Box, Button, Card, Typography, Tabs, Tab, useTheme } from '@mui/material';
// other libraries
import { isArray, isEmpty } from 'lodash';
// Components
import { Iconify } from '../../../components';
import { TreatmentAccordion } from '../../../components/leadTreatment';
import TreatmentEditPopover from '../../../pages/dashboard/tables/AllLeadTable/TreatmentEditPopover';
// hook
import { useAddTreatment, useTabs } from '../../../hooks';
// redux
import { getActivities } from '../../../redux/slices/activity';
import { useSelector, dispatch } from '../../../redux/store';
import { getAppointment, followUpSlice, clearLead, getLeadReminderData } from '../../../redux/slices/lead';
import { getLeadTreatmentsList, setCurrentTreatmentDataGlobal } from '../../../redux/slices/multiTreatment';
// style
import { communicationTabStyle } from '../../../styles/Chat';
import { taskCard } from '../../../styles/leadTaskReminder';
import { treatmentDetailCardStyle, treatmentTabNewTreatmentBtnStyle, treatmentDetailTabStyle, tabsWrapper, tabsStyle } from '../../../styles/treatmentTabStyle';

const TreatmentsDetails = ({
    leadDetail,
    reminder,
    reloadTaskCounter,
    counter,
    handleLoading,
    selectTreatmentData,
}) => {

    const theme = useTheme();
    const { currentTab, onChangeTab } = useTabs('');
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { followLead } = useSelector((state) => state.lead);
    const { treatmentsList } = useSelector((state) => state.multiTreatment);

    const [leadAppointment, setLeadAppointment] = useState('');
    const [loading, setLoading] = useState(false);
    const [anchorEditTreatment, setAnchorEditTreatment] = useState(null);

    useEffect(() => {
        dispatch(getAppointment(practiceId, leadDetail.id));
        dispatch(getLeadTreatmentsList({ leadId: leadDetail.id }));
        reloadTaskCounter({ userId: '', id: leadDetail.id, additionalFilter: '', CompletedTask: false });
        setLoading(true);
        setTimeout(() => setLoading(false), 2000);
    }, []);

    useEffect(() => {
        if (followLead) {
            setLeadAppointment(followLead?.lead_appointment);
        }
    }, [followLead]);

    useEffect(() => {
        if (!isEmpty(treatmentsList)) {
            if (treatmentsList && treatmentsList?.find((ele) => ele.id === currentTab)?.id) {
                onChangeTab('', currentTab);
                return;
            }
            onChangeTab('', treatmentsList?.[0]?.id);
        }
    }, [treatmentsList]);

    useEffect(() => {
        if (selectTreatmentData?.id) {
            onChangeTab('', selectTreatmentData?.id);
        }
    }, [selectTreatmentData?.id]);

    const { toggle: EditTreatment, onOpen: onEditTreatment, onClose: onCloseEditTreatment } = useAddTreatment();

    const handleAddTreatment = (event) => {
        onEditTreatment();
        setAnchorEditTreatment(event.currentTarget);
    };

    const handleClickAwayLister = (e, popup1Ref) => {
        if (popup1Ref && popup1Ref.current) {
            if (!popup1Ref.current?.contains(e.target)) {
                onCloseEditTreatment();
                dispatch(followUpSlice(leadDetail.id, practiceId, 'LeadDetail'));
                dispatch(getLeadTreatmentsList({ leadId: leadDetail.id }));
                return;
            }
            return;
        }
        onCloseEditTreatment();
        dispatch(followUpSlice(leadDetail.id, practiceId, 'LeadDetail'));
        dispatch(getLeadTreatmentsList({ leadId: leadDetail.id }));
    };

    const handleReloadAPI = (status) => {
        document.body.classList.remove('points-event');
        if (status?.toLowerCase?.() === 'finish') {
            handleClickAwayLister();
            dispatch(clearLead());
            dispatch(setCurrentTreatmentDataGlobal(null));
            if (leadDetail && leadDetail.id) {
                dispatch(getLeadReminderData(practiceId, 'LeadDetail', 10, 0, '', leadDetail.id, ''));
                dispatch(getLeadReminderData(practiceId, 'LeadDetail', 1, 0, '', leadDetail?.id, '', '', 'Graterthan'));
                dispatch(followUpSlice(leadDetail.id, practiceId, 'LeadDetail'));
                dispatch(getActivities(practiceId, leadDetail.id, 10, 0));
            }
        }
    };

    return (
        <Card sx={{ ...taskCard('LeadDetail'), padding: '24px' }}>
            <Box
                sx={tabsWrapper}
            >
                <Typography
                    sx={{
                        fontSize: '26px',
                        fontWeight: 700,
                        lineHeight: '30.55px',
                    }}
                >
                    Treatments
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                    <Button
                        sx={treatmentTabNewTreatmentBtnStyle}
                        variant='outlined'
                        onClick={(event) => handleAddTreatment(event)}
                    >
                        <Iconify sx={{ mr: '8px' }} icon={'eva:plus-fill'} width={20} height={20} /> New treatment
                    </Button>
                </Box>
            </Box>
            <Card sx={treatmentDetailCardStyle}>
                <Tabs
                    orientation='vertical'
                    variant='scrollable'
                    value={currentTab}
                    onChange={(e, newVal) => onChangeTab('', newVal)}
                    sx={{ ...tabsStyle(theme) }}
                >
                    {leadDetail &&
                        leadDetail.lead_treatments &&
                        isArray(treatmentsList) &&
                        treatmentsList.length > 0 &&
                        treatmentsList.map((tab, index) => <Tab disableRipple key={index} label={tab.treatment} value={tab.id} sx={{
                            ...communicationTabStyle,
                            ...treatmentDetailTabStyle
                        }} />)}
                </Tabs>
                {leadDetail &&
                    leadDetail.lead_treatments &&
                    isArray(treatmentsList) &&
                    treatmentsList.length > 0 &&
                    treatmentsList.map((data, index) => {
                        const isMatched = data?.id === currentTab;
                        return (
                            isMatched && (
                                <TreatmentAccordion
                                    key={data.id}
                                    data={data}
                                    index={index}
                                    leadDetail={leadDetail}
                                    leadAppointment={leadAppointment}
                                    reminder={reminder}
                                    reloadTaskCounter={reloadTaskCounter}
                                    counter={counter}
                                    handleLoading={handleLoading}
                                    loading={loading}
                                    selectTreatmentData={selectTreatmentData}
                                />
                            )
                        );
                    })}
            </Card>
            {EditTreatment && (
                <TreatmentEditPopover
                    handleReloadAPI={handleReloadAPI}
                    open={EditTreatment}
                    anchorEl={anchorEditTreatment}
                    handleClickAwayLister={handleClickAwayLister}
                    leadId={leadDetail.id}
                    isAddTreatment
                />
            )}
        </Card>
    );
};

TreatmentsDetails.propTypes = {
    leadDetail: PropTypes.any,
    reminder: PropTypes.any,
    reloadTaskCounter: PropTypes.func,
    counter: PropTypes.array,
    handleLoading: PropTypes.func,
    selectTreatmentData: PropTypes.object,
};

export default TreatmentsDetails;