/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// libraries
import { isEmpty } from 'lodash';
import { Box, Typography, TableContainer, Table, TableBody, Button, Dialog } from '@mui/material';
import { useNavigate } from 'react-router';
// components
import DuplicateTableRow from './DuplicateTableRow';
import ArchiveConfirmationPopup from './ArchiveConfirmationPopup';
import { TableEmptyRows, TableHeadCustomV2, TableNoData } from '../table';
import Iconify from '../Iconify';
import Scrollbar from '../Scrollbar';
import { ArchivedLeadPopUp } from '../dialogPopUp';
import BulkArchivePopup from './BulkArchivePopup';
// constants/hooks
import { SIMILAR_TABLE_HEAD as similarTableHead } from '../../constants/duplicateLeadsConst';
import { emptyRows } from '../../hooks/useTable';
import { useBoolean, useToggle } from '../../hooks';
// Redux
import { dispatch, useSelector } from '../../redux/store';
import { bulkArchivedOrRestore, bulkUnMarkAsDuplicate, setDuplicateArchiveLoading, unMarkAsDuplicate, updateSelectedSimilarLeads } from '../../redux/slices/duplicateLeads';
// Styles
import { common, primary } from '../../theme/palette';
import { tableStyle } from '../../styles/NewLead';
import { selectedLeadBoxStyle, whiteBGStyle, confirmationMainBoxStyle } from '../../styles/DuplicateLeadStyle';

const SimilarLeadsTable = ({ handleClose, dense, rowsPerPage, page, handleCloseSimilarLeads, overviewTab }) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { similarLeads: { results }, selectedSimilarLeads } = useSelector((state) => state.duplicate);
    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedDuplicates, setSelectedDuplicates] = useState([]);
    const [leadDetail, setLeadDetail] = useState(null);
    const open = useBoolean();
    const bulkUnmark = useBoolean();
    const [confirmation, setConfirmation] = useState(false);
    const isNotFound = (tableData?.length === 0 && results?.length === 0);
    const [lastLead, setLastLead] = useState(false);

    useEffect(() => {
        if (isEmpty(results)) {
            setTableData(results);
            return;
        }
        const tempTableData = results?.map((el) => ({ ...el, lead_id: el?.id, checked: false, is_disabled: false }));
        setTableData(tempTableData);
    }, [results]);

    const handleCloseEvent = (notCallSimilar) => {
        setSelectedRow(null);
        const tempTableData = results?.map((el) => ({ ...el, lead_id: el?.id, checked: false, is_disabled: false }));
        setTableData(tempTableData);
        dispatch(updateSelectedSimilarLeads({ leadIds: [] }));
        handleClose(notCallSimilar);
    };

    const handleCheckbox = (e, id) => {
        const updatedData = tableData.map(item => {
            if (item.lead_id === id) {
                return { ...item, checked: e.target.checked };
            }
            return item;
        });
        const tempData = updatedData?.filter((ele) => !ele?.checked);
        if (tempData?.length === 1 && updatedData.length !== 1) {
            setTableData(
                updatedData?.map((item) => {
                    if (item.lead_id === tempData?.[0]?.lead_id) {
                        return { ...item, is_disabled: true };
                    }
                    return item;
                })
            );
        } else {
            setTableData(
                updatedData?.map((item) => ({ ...item, is_disabled: false }))
            );
        }
        if (e.target.checked) {
            dispatch(updateSelectedSimilarLeads({ leadIds: [...selectedSimilarLeads.leadIds, id] }));
            setSelectedDuplicates((state) => [...state, id]);
            return;
        }
        dispatch(updateSelectedSimilarLeads({ leadIds: selectedSimilarLeads?.leadIds?.filter(e => e !== id) }));
        setSelectedDuplicates((state) => state?.filter(e => e !== id));
    };

    const checkAllLeadIdsExist = (data, leadIds) => {
        if (data?.length === 0) return 0;
        const matchedItems = data?.filter(item => leadIds?.includes(item.lead_id));
        return data?.length === matchedItems?.length;
    };

    const disabledCheckbox = (data, leadIds) => {
        const matchedItems = data?.filter(item => leadIds?.includes(item.lead_id) && item.is_archive_pending);
        const disabled = data?.filter(item => item.is_archive_pending);
        return data?.length === (disabled?.length || matchedItems?.length);
    };

    const handleArchiveSuccess = () => {
        open.onFalse();
        const payload = {
            object_ids: selectedSimilarLeads?.leadIds,
            request_type: 'ARCHIVE',
            reason: 'DUPLICATE_ENTRY'
        };
        dispatch(bulkArchivedOrRestore(practiceId, payload, handleUpdate));
        dispatch(setDuplicateArchiveLoading(true));
    };

    const handleBulkUnmarkSuccess = () => {
        bulkUnmark.onFalse();
        const lastIndex = selectedSimilarLeads?.leadIds.length - 1;
        selectedSimilarLeads?.leadIds?.forEach((element, index) => {
            dispatch(bulkUnMarkAsDuplicate({ practiceId, leadId: element, isLastIndex: lastIndex === index, handleClose: handleBulkUnmarkUpdate }));
        });
        dispatch(setDuplicateArchiveLoading(true));
    };

    const handleBulkUnmarkUpdate = () => {
        dispatch(setDuplicateArchiveLoading(false));
        handleCloseEvent();
        handleCloseSimilarLeads();
    };

    const handleUpdate = (status, leadIds) => {
        dispatch(setDuplicateArchiveLoading(false));
        if (status?.toLowerCase?.() === 'finish') {
            if (leadIds?.length === tableData?.length - 1) {
                const leadId = tableData?.filter((ele) => !leadIds?.includes(ele?.lead_id))?.pop()?.lead_id;
                setLastLead(true);
                dispatch(unMarkAsDuplicate({ practiceId, leadId, handleClose: () => { handleCloseEvent(true); } }));
            } else {
                handleCloseEvent();
            }
            setLeadDetail(results?.filter((ele) => ele?.id === leadIds?.[0])?.pop());
            setConfirmation(true);
        } else {
            setLeadDetail(null);
            handleCloseEvent();
        }
    };

    const confirmationClosePopup = () => {
        handleCloseSimilarLeads();
        handleCloseEvent();
        setSelectedDuplicates([]);
        setConfirmation(false);
        if (overviewTab === 'leadDetail') {
            navigate('/dashboard/tables/duplicate-lead-table');
        }
    };

    const {
        toggle: openArchiveLead,
        onOpen: onOpenArchiveLead,
        onClose: onCloseArchiveLead
    } = useToggle();

    const handleArchivedClose = () => {
        onCloseArchiveLead();
        handleCloseEvent();
    };
    const handleSelectedRow = (row) => {
        setSelectedRow(row);
        onOpenArchiveLead();
    };

    const handleViewArchiveLeadClick = () => {
        confirmationClosePopup();
        navigate('/dashboard/tables/archive-table/');
        setSelectedDuplicates([]);
    };

    const handleCloseDuplicatesPopup = () => {
        setConfirmation(false);
        setLastLead(false);
    };

    return (
        <Box sx={{ position: 'relative', '&:last-of-type .MuiAccordion-root': { border: 'none' } }}>
            <Scrollbar sx={{ pl: '0', pr: '0' }}>
                {selectedSimilarLeads?.leadIds?.length > 0 &&
                    <Box sx={selectedLeadBoxStyle(dense)}>
                        <Box>
                            <Typography
                                sx={{ flex: '1 1 100%', fontSize: '14px', color: primary.main }}
                                color='inherit'
                                variant='subtitle1'
                                component='div'
                            >
                                {selectedSimilarLeads?.leadIds?.length} selected
                            </Typography>
                        </Box>
                        <Box>
                            <Button sx={{ ml: 1, fontSize: '13px', bgcolor: primary.main }} variant='contained' onClick={bulkUnmark.onTrue}>
                                <Iconify icon={'pepicons-pop:duplicate-off'} width={18} height={18} sx={{ mr: 1 }} /> Unmark as duplicate
                            </Button>
                            <Button sx={{ ml: 1, fontSize: '13px', bgcolor: primary.main }} variant='contained' onClick={open.onTrue}>
                                <Iconify icon={'ri:delete-bin-line'} width={18} height={18} sx={{ mr: 1 }} /> Archive
                            </Button>
                        </Box>
                    </Box>
                }
                <TableContainer sx={{ maxHeight: '457px', border: '1px solid rgba(241, 243, 244, 1)' }}>
                    <Table size={dense ? 'small' : 'medium'} sx={tableStyle} stickyHeader>
                        <TableHeadCustomV2
                            headLabel={similarTableHead}
                            rowCount={tableData?.length}
                            leadTable
                            tableHeadCustom
                            type='similar_leads'
                            showSelected={checkAllLeadIdsExist(tableData, selectedSimilarLeads.leadIds)}
                            disabledCheckbox={disabledCheckbox(tableData, selectedSimilarLeads.leadIds)}
                        />
                        <TableBody>
                            {tableData?.length > 0 && tableData.map((row, index) =>
                                <DuplicateTableRow
                                    row={row}
                                    key={index}
                                    handleCheckbox={handleCheckbox}
                                    handleClose={handleCloseEvent}
                                    handleSelectedRow={handleSelectedRow}
                                    type={'similarLeads'}
                                    similarLeadPopupClose={handleCloseSimilarLeads}
                                    overviewTab={overviewTab}
                                />
                            )}
                            {tableData?.length === 0 && results?.length === 0 &&
                                <>
                                    <TableEmptyRows emptyRows={emptyRows(page, rowsPerPage, tableData.length)} />
                                    {isNotFound && <TableNoData isNotFound={isNotFound} />}
                                </>}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Scrollbar>
            {open.value &&
                <ArchiveConfirmationPopup
                    open={open.value}
                    onClose={open.onFalse}
                    handleArchiveSuccess={handleArchiveSuccess}
                    selectedLeads={selectedSimilarLeads}
                />
            }
            {bulkUnmark.value &&
                <BulkArchivePopup
                    open={bulkUnmark.value}
                    onClose={bulkUnmark.onFalse}
                    handleBulkUnmarkSuccess={handleBulkUnmarkSuccess}
                    selectedLeads={selectedSimilarLeads}
                />
            }
            <Dialog open={confirmation} onClose={handleCloseDuplicatesPopup}>
                <Box sx={confirmationMainBoxStyle}>
                    <Box sx={{ mr: '12px' }}>
                        <Box sx={whiteBGStyle}><Iconify icon={'akar-icons:circle-check-fill'} sx={{ color: common.green }} width={24} height={24} /></Box>
                    </Box>
                    <Box>
                        <Typography sx={{ fontSize: '18px', fontWeight: '700' }}>Duplicates Successfully Archived</Typography>
                        <Typography sx={{ m: '16px 0', fontSize: '14px' }}><b>{selectedDuplicates?.length}</b> of <b>{results?.length}</b> duplicates for <b>{leadDetail ? leadDetail?.first_name : ''}</b> have been successfully archived. {lastLead && <><b>{leadDetail ? leadDetail?.first_name : ''}</b> is now a unique lead in your database and will be removed from the Duplicate Leads page.</>}</Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                            <Button variant='outlined' onClick={handleViewArchiveLeadClick}>View Archived Leads</Button>
                            <Button variant='contained' sx={{ ml: '12px' }} onClick={confirmationClosePopup}>Continue Reviewing Duplicates</Button>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
            {openArchiveLead && <ArchivedLeadPopUp
                open={openArchiveLead}
                onClose={handleArchivedClose}
                user={selectedRow}
                isDuplicate
            />}
        </Box>
    );
};

export default SimilarLeadsTable;

SimilarLeadsTable.propTypes = {
    handleClose: PropTypes.func,
    dense: PropTypes.bool,
    rowsPerPage: PropTypes.any,
    page: PropTypes.any,
    handleCloseSimilarLeads: PropTypes.func,
    overviewTab: PropTypes.string,
};