/* eslint-disable no-unneeded-ternary */
import { forwardRef, useImperativeHandle, useState } from 'react';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import {
    Box,
    Typography,
    Divider,
    Grid,
    MenuItem,
    ToggleButtonGroup,
    ToggleButton
} from '@mui/material';
// components
import { Scrollbar } from '.';
import { FormProvider, RHFTextField, RHFSelect } from './hook-form';
// redux
import { bulkArchivedOrRestore, selectLeads, startArchivedLoading, bulkUpdate } from '../redux/slices/lead';
import { dispatch, useSelector } from '../redux/store';
// validation
import { bulkArchived } from '../validations/commonValidations';
// style
import { grey } from '../theme/palette';
import { addTreatmentGridStyle, toggleBtnStyle } from '../styles/AddTreatment';
import { archiveGridStyle, selectMenuItemStyle, selectStyle } from '../styles/DialogueTabs';
import { textFieldStyle } from '../styles/NewLead';

const ArchivedOrRestored = forwardRef(({ handleClose }, ref) => {
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { multiLead } = useSelector((state) => state.lead);
    const [isCommunication, setCommunication] = useState('No');
    const { reasons } = useSelector((state) => state.schema);

    const defaultValues = {
        notes: '',
        archiveReason: '',
        typed: multiLead?.type
    };

    const methods = useForm({
        resolver: yupResolver(bulkArchived),
        mode: 'onSubmit',
        defaultValues,
    });

    const {
        handleSubmit,
        getValues,
        setValue
    } = methods;

    useImperativeHandle(
        ref,
        () => ({
            async submit() {
                await handleSubmit(onSubmit)();
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const onSubmit = async (data) => {
        const payload = {
            object_ids: multiLead?.leadId,
            note: data.notes,
            request_type: multiLead?.type?.toUpperCase(),
        };
        const communicationPayload = {
            ids: multiLead?.leadId,
            value: getValues('isCommunication') === 'Yes' ? 'true' : 'false',
            field: 'is_open_for_communication'
        };
        handleClose();
        dispatch(selectLeads({ leadId: [], type: '', loading: true }));
        if (multiLead.type === 'Archive') {
            payload.reason = data.archiveReason;
            dispatch(bulkUpdate(practiceId, communicationPayload));
        }
        dispatch(bulkArchivedOrRestore(practiceId, payload, handleUpdate));
    };

    const handleUpdate = (status) => {
        if (status?.toLowerCase?.() === 'finish') {
            dispatch(startArchivedLoading(true));
        }
        handleClose(status);
    };

    const handleChange = (e) => {
        setCommunication(e.target.value);
        setValue('isCommunication', e.target.value);
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ margin: '0 -8px' }}>
                <Scrollbar sx={{ maxHeight: 64 * 10 }}>
                    <Typography variant='subtitle2' sx={{ fontSize: '18px' }}>Bulk {multiLead.type} {`(${multiLead?.leadId?.length} leads)`}</Typography>
                    <Divider sx={{ borderStyle: 'dashed', mt: 1 }} />
                    <Grid container spacing={1} sx={{ ...addTreatmentGridStyle, mt: 2 }}>
                        {multiLead.type === 'Archive' ?
                            <RHFSelect
                                name='archiveReason'
                                label='Select reason'
                                SelectProps={{ native: false, sx: selectStyle }}
                                sx={{ mt: 0 }}
                            >
                                {reasons?.map((options, index) => (
                                    <MenuItem
                                        key={index}
                                        value={options.value}
                                        sx={selectMenuItemStyle}
                                    >
                                        {options.label}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                            : <RHFTextField name='notes' label='Notes (Required)' multiline rows={3} />
                        }
                    </Grid>
                    {multiLead.type === 'Archive' && <Grid container spacing={1} sx={archiveGridStyle}>
                        <RHFTextField
                            sx={{ ...textFieldStyle, marginLeft: 0 }}
                            name='notes'
                            multiline
                            rows={4}
                            label='Archive notes (Required)'
                            placeholder='Add notes here'
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>}
                    {multiLead.type === 'Archive' && <>
                        <Grid container spacing={1} sx={archiveGridStyle}>
                            <Typography variant='subtitle2' >Is the lead open to be contacted in the future ?</Typography>
                        </Grid>
                        <Grid container spacing={1} sx={archiveGridStyle}>
                            <Box sx={{ border: `0px solid ${grey[500]}`, borderRadius: '10px' }}>
                                <ToggleButtonGroup
                                    color='primary'
                                    name='isCommunication'
                                    value={isCommunication}
                                    exclusive
                                    onChange={handleChange}
                                    aria-label='Platform'
                                >
                                    <ToggleButton sx={toggleBtnStyle} value={'Yes'}>Yes</ToggleButton>
                                    <ToggleButton sx={toggleBtnStyle} value={'No'}>No</ToggleButton>
                                </ToggleButtonGroup>
                            </Box>
                        </Grid>
                    </>}
                </Scrollbar>
            </Box>
        </FormProvider>
    );
});

ArchivedOrRestored.propTypes = {
    handleClose: PropTypes.func
};

export default ArchivedOrRestored;
