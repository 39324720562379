/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { Box, Card, Paper, Stack, Typography, Button, Divider } from '@mui/material'
import { useTheme } from '@mui/material/styles';
import React from 'react'
import ReactHtmlParser from 'react-html-parser';
import { Iconify, Label } from 'src/components'
import { TAB_HEAD } from 'src/constants/LeadDetailConst';
import { box3Style } from 'src/styles/AccountSocialLinks'
import { listHeaderBoxStyle } from 'src/styles/ContactLeadPopup';
import { noteIcon, noteTooltipText, statusStyle, timeLineInfo } from 'src/styles/OverView';
import { TimelineDot, TimelineSeparator } from '@mui/lab';
import { setBackgroundColor, setIcon } from 'src/utils/timelineIcon';
import { timelineItemStyle } from 'src/styles/Common';
import capitalizeFirstLetter from 'src/utils/capitalizedFunc';

const TreatmentTabList = ({ treatmentTabData, handleChange }) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';

    const getStatus = (treatment) => TAB_HEAD.map((e1) => {
        let latestData = '';
        if (treatment?.status === e1.value) {
            latestData = e1.label;
        }
        return latestData;
    });

    return (
        <Box>
            <Box sx={{ ...listHeaderBoxStyle, width: "100%" }}>
                <Typography sx={{ fontSize: '20px', fontWeight: 700 }}>Treatment Information</Typography>
                <Iconify icon={'zondicons:close-solid'} onClick={() => handleChange('', '')} sx={{ color: '#637381', mr: 0, cursor: 'pointer' }} width={24} height={24} />
            </Box>
            <Card sx={{ p: 0, padding: "8px 20px 0 20px", boxShadow: "none", mt: 2 }}>
                <Stack spacing={2} alignItems='flex-start' >

                    {treatmentTabData?.map((item, index) => (
                        <Box key={index}
                            sx={{ ...timelineItemStyle, padding: "12px", width: "100%" }}>

                            <Box sx={{ display: 'flex', gap: "16px", alignItems: 'flex-start', width: "100%" }}>
                                <TimelineSeparator>
                                    <TimelineDot color='info' sx={{ ...timeLineInfo, bgcolor: setBackgroundColor() }}>
                                        {setIcon()}
                                    </TimelineDot>
                                </TimelineSeparator>
                                <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: 2, pt: "6px" }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                        <Paper
                                            sx={{
                                                background: isLight ? '#F4F6F8' : 'transparent',
                                                fontWeight: '500',
                                                fontSize: '14px'
                                            }}
                                            name={'leadTitle'}
                                        >
                                            <Label sx={{ color: 'black', backgroundColor: '#DFE3E8', p: '10px', fontSize: "0.9rem", height: "25px", fontWeight: "500" }}>{item?.treatment_data?.treatment_item?.name}</Label>

                                        </Paper>
                                        <Typography variant="h6" sx={{ fontSize: '13px !important' }}>&#163; {item?.treatment_data?.price} </Typography>

                                        <Button sx={statusStyle(item.status)}><Iconify icon={item.status === 'NEW' ? 'mdi:question-mark-circle-outline' : item.status === 'CONSULTATION_BOOKED' ? 'mdi:calendar-clock' : item.status === 'FAILED_TO_ATTEND_CONSULTATION' ? 'bx:calendar-x' : item.status === 'CONSULTATION_ATTENDED' ? 'mdi:calendar-check' : item.status === 'CLOSED' ? 'material-symbols:check-circle-outline' : item.status === 'TREATMENT_STARTED' ? 'codicon:debug-start' : 'fluent:cloud-sync-complete-24-filled'} width={20} height={20} /> {getStatus(item)}</Button>

                                    </Box>

                                    {item?.treatment_data?.description && <Box sx={{ '& p, & h1, & h2, & h3, & h4, & h5, & h6, & blockquote': { margin: 0, padding: 0, fontSize: "14px" } }}>{ReactHtmlParser(item?.treatment_data?.descriptiont)}</Box>}


                                    {item?.treatment_data?.additional_infos && item?.treatment_data?.additional_infos?.length > 0 && item?.treatment_data?.additional_infos.some(item => item?.categories && item?.categories?.length > 0 && item?.categories[0] === "FLOW") ? item?.treatment_data?.additional_infos?.filter(item => item?.categories && item?.categories?.length > 0 && item?.categories[0] === "FLOW")?.map((info, index) => <Box key={index} sx={index !== 0 ? { mt: "10px" } : {}}>
                                        <Typography variant='subtitle2' sx={{ ...noteTooltipText, display: "flex", alignItems: "center", gap: "5px", mb: "2px", fontSize: "12px", lineHeight: "inherit", textDecoration: "none" }}>
                                            <Iconify icon={'material-symbols:info'} width={14} height={14} sx={{}} />
                                            {"Additional info"}
                                            <Iconify icon={'bi:caret-down-fill'} width={14} height={14} sx={{ ...noteIcon, mt: "0" }} />
                                        </Typography>
                                        <Box sx={{ '& p, & h1, & h2, & h3, & h4, & h5, & h6, blockquote': { margin: 0, padding: 0, fontSize: "14px", pl:1  } }}>{ReactHtmlParser(info?.text)}</Box>
                                    </Box>) : null
                                    }

                                    <Box sx={box3Style}>
                                        <Box sx={{ width: "100%" }}>
                                            {
                                                item?.treatment_data?.offers && item?.treatment_data?.offers?.length > 0 ? item?.treatment_data?.offers?.filter(offer => !offer.is_expired).map((offer, index) => <Box key={index} sx={index !== 0 ? { mt: "10px" } : {}}>
                                                    <Typography variant='subtitle2' sx={{ ...noteTooltipText, display: "flex", alignItems: "center", gap: "5px", mb: "2px", fontSize: "12px", lineHeight: "inherit", textDecoration: "none" }}>
                                                        <Iconify icon={'bxs:offer'} width={14} height={14} sx={{}} />
                                                        {capitalizeFirstLetter(offer?.name)}
                                                        <Iconify icon={'bi:caret-down-fill'} width={14} height={14} sx={{ ...noteIcon, mt: "0" }} />
                                                    </Typography>
                                                    {offer?.offer && <Box sx={{ '& p, & h1, & h2, & h3, & h4, & h5, & h6, & blockquote': { margin: 0, padding: 0,fontSize: "14px", pl:1 } }}>{ReactHtmlParser(offer.offer)}</Box>}

                                                    {offer.description ? (
                                                        <>
                                                            <Divider sx={{ my: 1 }} />
                                                            <Typography variant='subtitle2' sx={{ ...noteTooltipText, display: "flex", alignItems: "center", gap: "5px", mb: "2px", fontSize: "12px", lineHeight: "inherit", textDecoration: "none" }}>
                                                                <Iconify icon={'material-symbols:info'} width={14} height={14} sx={{}} />
                                                                Description
                                                                <Iconify icon={'bi:caret-down-fill'} width={14} height={14} sx={{ ...noteIcon, mt: "0" }} />
                                                            </Typography>
                                                            <Box sx={{ '& p, & h1, & h2, & h3, & h4, & h5, & h6, & blockquote': { margin: 0, padding: 0, fontSize: "14px", pl:1 } }}>{ReactHtmlParser(offer.description)}</Box>

                                                        </>
                                                    ) : null}

                                                </Box>) : null


                                            }
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                        </Box>

                    ))}
                </Stack>

            </Card>
        </Box>
    )
}

TreatmentTabList.propTypes = {
    treatmentTabData: PropTypes.array,
    handleChange: PropTypes.func
};


export default TreatmentTabList