/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types';
// @mui
import { Autocomplete, TextField } from '@mui/material';
// redux
import React from 'react';
import { useSelector } from '../../redux/store';

// -------------------------------------------------------------------------------------------

const ReferralDropdown = ({ name, value, onChange, type, ...other }) => {
    const { referral, practiceUsers } = useSelector((state) => ({
        referral: state.schema?.referral || [],
        practiceUsers: state.mergeTags?.practiceUsers || []
    }));

    const [defaultValue, setDefaultValue] = React.useState(value);

    React.useEffect(() => {
        if (other?.affiliateUser) {
            const currentClinician = practiceUsers?.filter(obj => obj?.id === other?.affiliateUser?.id)?.[0]?.value;
            setDefaultValue(currentClinician)
        } else {
            setDefaultValue(value);
        }
    }, [value])

    const options = other?.preserveClinician ? [
        ...referral.map((item) => ({ ...item, group: "Default" }))
    ] : [
        ...referral.map((item) => ({ ...item, group: "Default" }))?.filter(item => item?.value !== "CLINICIAN"),
        ...practiceUsers.map((user) => ({ ...user, group: "Clinicians" }))
    ];

    return (
        <Autocomplete
            name={name}
            value={defaultValue || ''}
            onChange={onChange}
            groupBy={(option) => option.group}
            options={options}
            renderInput={(params) => <TextField {...params} label="Referral" />}
            sx={{ '.MuiAutocomplete-endAdornment .MuiAutocomplete-clearIndicator': { display: type === 'search' && 'none' } }}
            {...other}
        />);
};

ReferralDropdown.propTypes = {
    name: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    type: PropTypes.string,
};

export default ReferralDropdown;
