/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// lib
import { uniqBy } from 'lodash';
import {
    Backdrop,
    Box,
    IconButton,
    InputBase,
    InputAdornment,
    Stack,
    Typography,
    useTheme,
    Paper,
    Autocomplete,
    TextField,
    Button,
    Portal,
    Link,
    styled,
} from '@mui/material';
// components
import { fetchMergeTagValueForLeads, fetchMergeTagValueForTreatments, resetTreatmentAndLead } from 'src/redux/slices/mergeTags';
import { Iconify } from '../../../../components';
import { SearchNotFound } from '../../../../components/search-not-found';
import { TableLoading } from '../../../../components/loading';
import { NotFound } from '../../../../components/empty-content';
import { Editor } from './editor';
import SendButton from './sendButton';
import AttachmentFile from './attachment';
import { MessageList } from '../../message';
// redux
import { store, useSelector } from '../../../../redux/store';
import { updateError } from '../../../../redux/slices/user';
import { clearTaskLead, searchLead } from '../../../../redux/slices/lead';
import { getTemplateList, updateSubjectTemplateList } from '../../../../redux/slices/practiceMessage';
import { clearMails, sentMessages } from '../../../../redux/slices/mail';
import { get10TemplateList, getSearchTemplateList } from '../../../../redux/api/practiceMessageApis';
// utils/hooks
import { applyStylesToTags } from '../../../../utils/applyStylesToTags ';
import { useBoolean, useResponsive, useAuth } from '../../../../hooks';
import { useDebounce } from '../../../../hooks/use-debounce';
// style
import { common } from '../../../../theme/palette';
import { leadAutoComplete } from '../../../../styles/AddReminderStyle';
import {
    btnStyle,
    editStyle,
    paperStyle,
    editorBoxBtnStyle,
    toTypoStyle,
    emailBoxStyle,
    emailTypoStyle,
    emailTemplateBoxStyle,
    focusUnableToContactBoxStyle,
    inputBaseStyle,
} from './styles';
import { backDropStyle, RootSetStyle, openTamplateBoxStyle, typographyStyle, iconBtnBoxStyle } from '../../../../styles/leadTemplate';
import { attachmentWrapper } from '../../../../styles/EmailMessageComposeStyle';
import { centerAlignDiv, emailCompoRootStyle } from '../../../../styles/emailStyle';

const ZINDEX = 1998;

const POSITION = 24;

const RootStyle = styled('div')(({ theme }) => (emailCompoRootStyle({ theme })));

const MailCompose = ({
    onCloseCompose,
    leadDetail,
    successDone,
    isFromSideBar,
    isFromDrafts,
    draftSubject,
    draftInfo,
    selectedMailInfo,
    isFromUnableToContact,
    sendUnableToContact,
    setEmailNotification
}) => {
    const textareaRef = useRef(null);
    const { dispatch } = store;
    const smUp = useResponsive('up', 'sm');
    const theme = useTheme();
    const { mergeTags: mergeTagsInfo } = useSelector(state => ({ mergeTags: state.mergeTags }));
    const mergeTags = mergeTagsInfo?.mergeTagKeys;
    const [dataMap, setDataMap] = React.useState({});
    const [message, _setMessage] = useState(isFromDrafts ? selectedMailInfo?.body : '');
    const [subject, _setSubject] = useState(isFromDrafts ? draftSubject : '');

    React.useEffect(() => {
        setDataMap(prv => ({ ...prv, ...(mergeTagsInfo?.mtValPractice ?? {}), ...(mergeTagsInfo?.mtValUser ?? {}) }))
        if (!mergeTagsInfo?.mtValLoadingLeads && !mergeTagsInfo?.mtValLoadingTreatments) {
            setDataMap(prv => ({ ...prv, ...(mergeTagsInfo?.mtValTreatments ?? {}), ...(mergeTagsInfo?.mtValLeads ?? {}) }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mergeTagsInfo?.mtValLoadingLeads, mergeTagsInfo?.mtValLoadingTreatments, mergeTagsInfo?.mtValTreatments, mergeTagsInfo?.mtValLeads]);

    const parseMergeTags = (datalabels, removeUnresolvedValues = false) => (
        // Use regex to find all words starting with '$' followed by alphanumeric characters or underscores
        datalabels?.replace(/\$[a-zA-Z0-9_]+/g, (match) => {
            // Remove the '$' sign to get the actual key
            const key = match.slice(1);

            // Return the value from the dataMap if it exists, otherwise return the original field
            return Object.prototype.hasOwnProperty.call(dataMap, key) ? dataMap[key] : (removeUnresolvedValues ? '' : match);
        }) ?? datalabels);

    React.useEffect(() => {
        _setMessage(prv => parseMergeTags(prv ?? ""));
        _setSubject(prv => parseMergeTags(prv ?? ""));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataMap]);

    const setMessage = payload => {
        _setMessage(parseMergeTags(payload ?? ""));
    }
    const setSubject = payload => {
        _setSubject(parseMergeTags(payload));
    }
    const [subjectUnableContact, setSubjectUnableContact] = useState('');

    const { practiceId } = useSelector((state) => state.practiceTreatmentList);

    const [base, setBase] = useState('');
    const [editForm, setEditForm] = useState(false);
    const fullScreen = useBoolean();
    const [files, setFiles] = useState([]);
    const EditorMessage = useRef();
    const {
        templateDetails,
        userTemplateDetails,
        subjectTemplateList,
        showSubjectList,
        templateDetailsList,
        isLoading: isTempLoad,
    } = useSelector((state) => state.practiceMessage);
    const { taskSearchLead, isLoading } = useSelector((state) => state.lead);
    const { practiceUser: { results } } = useSelector((state) => state.practiceUser);
    const [templateOpen, setTemplateOpen] = useState(false);
    const [template, setTemplateList] = useState([]);
    const type = 'EMAIL';
    const { user } = useAuth();
    const [useTemplate, setUseTemplate] = useState(false);
    const getMergeTagVal = (dt) => {
        if (mergeTagsInfo?.mtValPractice?.[dt] ?? mergeTagsInfo?.mtValUser?.[dt] ?? mergeTagsInfo?.mtValTreatments?.[dt] ?? mergeTagsInfo?.mtValLeads?.[dt])
            return mergeTagsInfo?.mtValPractice?.[dt] ?? mergeTagsInfo?.mtValUser?.[dt] ?? mergeTagsInfo?.mtValTreatments?.[dt] ?? mergeTagsInfo?.mtValLeads?.[dt];
        return null;
    };

    const getNameList = () => [...mergeTags]?.sort()?.map(dt => ({
        value: getMergeTagVal(dt) ?? `$${dt}`,
        category: dt?.startsWith("current_user") || dt?.startsWith("user") ?
            "User" : dt?.includes("appointment") ? "Appointment" : dt?.includes("treatment") ? "Treatments" :
                dt?.includes("lead") ? "Leads" :
                    dt?.includes("practice") ? "Practice" : "Others",
        name: mergeTagsInfo?.mtValPractice?.[dt] ?? mergeTagsInfo?.mtValUser?.[dt] ?? mergeTagsInfo?.mtValTreatments?.[dt] ?? mergeTagsInfo?.mtValLeads?.[dt] ? `$${dt} (${getMergeTagVal(dt)})` : `$${dt}`
    }));
    const [attachment, setAttachment] = useState([]);
    const [uploadAttachment, setUploadAttachment] = useState([]);
    const hiddenFileInput = useRef(null);
    const [fileSize, setFileSize] = useState({});
    const [leadID, setLeadID] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [searchSubjectQuery, setSearchSubjectQuery] = useState('');
    const [searchUnableContactSubjectQuery, setSearchUnableContactSubjectQuery] = useState('');
    const [focusUnableToContact, setFocusUnableToContact] = useState(false);

    const [searchLeads, setSearchLeads] = useState(null);
    const [isCalled, setIsCalled] = useState(false);
    const [activeComposeMail, setActiveComposeMail] = useState(false);
    const debouncedValue = useDebounce(searchQuery, 1000);
    const [cursor, setCursor] = useState(0);
    const debouncedSubjectValue = useDebounce(searchSubjectQuery, 1000);
    const debouncedUnableContactSubjectValue = useDebounce(searchUnableContactSubjectQuery, 1000);

    React.useEffect(() => {
        dispatch(fetchMergeTagValueForLeads(leadDetail?.id));
        dispatch(fetchMergeTagValueForTreatments(leadDetail?.id, leadDetail?.lead_treatments?.[0]?.id ?? null));

        return () => dispatch(resetTreatmentAndLead());
    }, [practiceId, leadDetail, dispatch]);

    useEffect(() => {
        if (templateDetails && templateDetails.results) {
            const data = templateDetails.results.filter(
                (el) => el.channel && el.channel && el.channel.toLowerCase() === type.toLowerCase()
            );
            setTemplateList(data);
        }
    }, [templateDetails, type]);

    useEffect(() => {
        if (practiceId) {
            dispatch(getTemplateList(practiceId, true));
        }
    }, [practiceId]);

    useEffect(() => {
        if (message && subject) {
            setEditForm(true);
        } else {
            setEditForm(false);
        }
    }, [message, subject]);

    useEffect(() => {
        dispatch(updateSubjectTemplateList([]));
    }, []);

    const isAdminUser = () => {
        if (results.length > 0) {
            const admin = results.find((el) => el.user && el.user.id === user?.pk);
            return admin?.role === 'ADMIN';
        }
        return false;
    };

    const fetchData = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.readAsDataURL(blob);
        });
    };

    const handlePromiseData = async (data, base64Array) => {
        await Promise.all(data.map(async (obj) => ({ file: await fetchData(obj.file) }))).then((value) => {
            value.map((data) => base64Array.push(data));
        });
    };

    const handleAttachment = async (event, value, perform) => {
        const base64Array = [];
        const newFiles = perform === 'clear' ? [] : event;
        if (perform === 'clear') {
            const data = attachment.attachments.filter((res) => res.file !== value);
            setAttachment({ ...attachment, attachments: data });
            setUploadAttachment(data?.map((ele) => ele.file));
            handlePromiseData(event, base64Array);
            return;
        }

        const largerFile = newFiles.filter((value) => value.size > 20971520);

        if (largerFile?.length > 0 && newFiles.length > 0) {
            setFileSize({ file: largerFile[0]?.name, size: largerFile[0]?.size });
            setEditForm(false);
        } else {
            setFileSize({});
            if (message && subject) {
                setEditForm(true);
            } else {
                setEditForm(false);
            }
        }

        newFiles.map(async (file) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                base64Array.push({ file: reader.result });
            };
            reader.onerror = (error) => {
                dispatch(
                    updateError({
                        success: false,
                        message: JSON.stringify(error),
                    })
                );
            };
        });

        setBase({ files: base64Array });
        if (message && subject) {
            setEditForm(true);
        } else {
            setEditForm(false);
        }
    };

    const handleInputFiles = (e) => {
        const uniqueFiles = Array.from(new Set([...Array.from(e.target.files)]));
        const data = [...uniqueFiles, ...files];
        const removed = uniqBy(data, 'name');
        handleAttachment(removed);
        setFiles(removed);
    };

    const handleClearUploaded = (value) => {
        const removedData = files.filter((file) => file.name !== value);
        setFiles(removedData);
        handleAttachment(removedData);
        hiddenFileInput.current.value = null;
    };

    const getLastPathSegment = (url) => {
        const splitUrl = url?.split('/');
        return splitUrl[splitUrl?.length - 1];
    };

    const handleChange = (e, val) => {
        const value = val?.value;
        if (value) {
            const data = `${value}`;
            if (EditorMessage.current) {
                const editor = EditorMessage.current.getEditor();
                editor.insertText(cursor, data, 'link');
                editor.setSelection(cursor + data.length);
                editor.focus();
            }
        }
    };

    const handleSetCompose = (compose) => {
        if (isFromUnableToContact === 'true') {
            setActiveComposeMail(true);
        }
        setSubject(compose?.subject);
        setMessage(compose?.body);
        setTimeout(() => {
            document.body.style.overflow = 'auto';
        }, 600);
    };

    useEffect(() => {
        if (useTemplate) {
            const { body, subject, attachments } = userTemplateDetails;
            if (body && subject) {
                setSubject(subject);
                setMessage(body);
                setAttachment({ attachments });
                setUploadAttachment(attachments?.map((ele) => ele.file));
            }
        }
    }, [useTemplate, userTemplateDetails]);

    const searchLeadHandler = (value, reason) => {
        if (reason === 'input') {
            setSearchQuery(value);
        }
        if (value.length === 0) {
            setLeadID('');
            dispatch(clearTaskLead());
        }
    };

    const noOptionsHandler = () => {
        if (searchQuery === '' || (searchLeads && searchLeads?.length === 0)) {
            return <SearchNotFound searchQuery={searchQuery} />;
        }
        return '';
    };

    const handleSearchLead = (value) => {
        if (value) {
            setSearchQuery(`${value?.first_name} ${value?.last_name}`);
            setLeadID(value.lead_contact.lead);
            dispatch(fetchMergeTagValueForLeads(value?.lead_contact?.lead));
            dispatch(fetchMergeTagValueForTreatments(value?.lead_contact?.lead, value?.lead_treatments?.[0]?.id ?? null));
        }
        const leadArr = [];
        value?.lead_treatments?.map((el) => leadArr.push({ treatment: el?.treatment, id: el?.id, price: el?.price }));
    };

    useEffect(() => {
        if (searchQuery.length > 0) {
            let search = searchQuery;
            if (Number(searchQuery) && !searchQuery.includes('+')) {
                search = `+44${searchQuery}`;
            }
            setIsCalled(true);
            dispatch(searchLead(encodeURIComponent(search), practiceId, 'FinancialTracker'));
        }
    }, [debouncedValue]);

    useEffect(() => {
        if (searchSubjectQuery.length > 0) {
            handleSearch(practiceId, encodeURIComponent(searchSubjectQuery));
        }
    }, [debouncedSubjectValue]);

    useEffect(() => {
        if (searchUnableContactSubjectQuery.length > 0) {
            handleSearch(practiceId, encodeURIComponent(searchUnableContactSubjectQuery));
        }
    }, [debouncedUnableContactSubjectValue]);

    useEffect(() => {
        if (isCalled && taskSearchLead) {
            setSearchLeads(taskSearchLead);
            setIsCalled(false);
        }
    }, [taskSearchLead]);

    const handleSubjectSearch = (data) => {
        const search = data;
        setSubject(search);
        setSearchSubjectQuery(search);
    };

    const handleUnableContactSubjectSearch = (data) => {
        const search = data;
        setSubjectUnableContact(search);
        setSearchUnableContactSubjectQuery(search);
    };

    const handleSearch = (id, value) => {
        if (value !== '') {
            dispatch(getSearchTemplateList(id, value));
        }
    };

    const createFile = async (url) => {
        const response = await fetch(url);
        const data = await response.blob();
        const parts = url.split('.');
        const fileType = parts[parts.length - 1];
        const reader = new FileReader();
        reader.readAsDataURL(data);
        let base64 = '';
        reader.onload = () => {
            base64 = reader.result.split(',')[1];
            setUploadAttachment((state) => [...state, `data:application/${fileType};base64,${base64}`]);
        };
    };

    useEffect(() => {
        draftInfo?.attachments.map(async (file) => createFile(file.file));
    }, [draftInfo]);

    useEffect(() => {
        if (isFromUnableToContact === 'true') {
            setLeadID(leadDetail.id);
        }
    }, [practiceId, isFromUnableToContact]);

    const handleComposeUnableToContact = () => {
        setActiveComposeMail(true);
        setFocusUnableToContact(false);
    };

    const handleSendUnableToContact = async (val) => {
        const content = val.body;
        const modifiedContent = applyStylesToTags(content);
        const base64Array = [];
        if (val.attachments?.length > 0) {
            await handlePromiseData(val.attachments, base64Array);
        }
        let data = {
            category: 'COMMUNICATION',
            channel: 'EMAIL',
            subject: val.subject,
            body: modifiedContent,
            lead: leadID,
            attachments: base64Array || [],
        };
        data = {
            ...data,
            subject: parseMergeTags(data?.subject, true),
            body: parseMergeTags(data?.body, true)
        }
        dispatch(sentMessages({ data, title: 'SendEmail', successDone, leadID, sendUnableToContact }));
        setActiveComposeMail(false);
        setFocusUnableToContact(false);
    };

    const editorOnChangeSelection = (range) => {
        if (range?.index) {
            setCursor(range?.index);
        }
    };

    const onClickNewLead = () => {
        if (isFromUnableToContact === 'true') {
            setActiveComposeMail(false);
            setSubject('');
            setMessage('');
        } else {
            onCloseCompose();
        }
    };

    const onChangeComposeMail = (e) => {
        if (EditorMessage.current) {
            const editor = EditorMessage.current.getEditor();
            const selection = editor.getSelection();
            if (selection) {
                const cursorPosition = selection.index + selection.length;
                setCursor(cursorPosition);
            }
        }
        setMessage(e);
    };

    const getRenderOptions = (props, option) => {
        const { first_name: firstName, last_name: lastName, lead_contact: leadContact } = option;
        const { email, phone } = leadContact || {};
        return (
            <Box component='li' {...props} key={option.id}>
                {firstName || ''} {lastName || ''} | {email || ''} | {phone || ''}
            </Box>
        );
    };

    const handleInputFocus = () => {
        if (practiceId) dispatch(get10TemplateList(practiceId, true));
    };

    const handleSuccessDone = () => {
        dispatch(clearMails());
        successDone('Drafts');
        if (isFromUnableToContact === 'true') {
            setFocusUnableToContact(false);
            setActiveComposeMail(false);
            sendUnableToContact();
        }
    };

    const onSendUnableToContact = () => {
        setFocusUnableToContact(false);
        setActiveComposeMail(false);
        sendUnableToContact();
        setSubject('');
        setMessage('');
    };

    const onSuccessDone = () => {
        successDone('Outgoing');
        onCloseCompose();
    };

    const handleSetUSeTemplate = () => {
        setUseTemplate(true);
        setActiveComposeMail(true);
        setFocusUnableToContact(false);
    };

    const commonProps = {
        editForm,
        attachment,
        handlePromiseData,
        leadDetail,
        subject,
        message,
        base,
        practiceId,
        isFromSideBar,
        isFromUnableToContact,
        sendUnableToContact: onSendUnableToContact,
        leadSearch: 'searchTerm'
    };

    const renderComposeMail = () => (
        <Paper sx={paperStyle(ZINDEX, fullScreen, POSITION, isFromDrafts, isFromSideBar, isFromUnableToContact)}>
            {!isFromDrafts && (
                <Stack
                    direction='row'
                    alignItems='center'
                    sx={{
                        p: (theme) => theme.spacing(1.5, 1, 1.5, 2),
                        borderBottom: `1px dotted ${common.blueishGray}`,
                    }}
                >
                    <Typography variant='h6' sx={{ flexGrow: 1 }}>
                        New Email
                    </Typography>
                    <IconButton onClick={onClickNewLead}>
                        <Iconify icon='mingcute:close-circle-line' />
                    </IconButton>
                </Stack>
            )}

            {isFromUnableToContact !== 'true' && isFromSideBar === 'true' && !isFromDrafts ? (
                <Box sx={{ p: '16px 16px 0' }}>
                    <Autocomplete
                        autoFocus
                        name={'leadName'}
                        inputValue={searchQuery}
                        value={searchQuery}
                        clearIcon={false}
                        options={searchLeads && searchLeads?.length > 0 ? searchLeads : []}
                        filterOptions={(option) => option}
                        loading={isCalled && isLoading}
                        sx={{ ...leadAutoComplete, mb: 0 }}
                        onInputChange={(event, value, reason) => searchLeadHandler(value, reason)}
                        noOptionsText={noOptionsHandler()}
                        onChange={(e, value) => handleSearchLead(value)}
                        isOptionEqualToValue={(option, value) => option?.first_name === value}
                        getOptionLabel={(option) => `${option?.first_name} ${option?.last_name}`}
                        renderOption={getRenderOptions}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder='Lead'
                                InputProps={{
                                    ...params.InputProps,
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <Iconify icon='uil:search' />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        )}
                    />
                </Box>
            ) : (
                <Box sx={{ p: '16px 16px 0', position: 'relative' }}>
                    <Box sx={centerAlignDiv}>
                        <Typography sx={toTypoStyle}>To</Typography>
                        <Box sx={emailBoxStyle}>
                            <Typography placeholder='To' disabled sx={emailTypoStyle}>
                                {isFromDrafts ? draftInfo?.lead?.lead_contact?.email : leadDetail?.lead_contact?.email}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            )}
            <Box sx={{ position: 'relative' }}>
                <Box sx={{ p: '0 16px 0' }}>
                    <Box sx={centerAlignDiv}>
                        <Typography sx={toTypoStyle}>Subject</Typography>
                        <Box sx={{ ...emailBoxStyle, p: '8px 24px 0 0px' }}>
                            <InputBase
                                placeholder=''
                                sx={{ ...btnStyle(theme), border: 'none !important', p: 0 }}
                                onChange={(e) => handleSubjectSearch(e.target.value)}
                                onFocus={handleInputFocus}
                                value={subject}
                                onClick={(e) => e.stopPropagation()}
                            />
                        </Box>
                    </Box>
                </Box>
                {showSubjectList && (
                    <Box onClick={(e) => e.stopPropagation()} sx={emailTemplateBoxStyle}>
                        {isTempLoad && <TableLoading tableData={isTempLoad && 'No Found'} pageCount={isTempLoad && 'No Found'} />}
                        {subjectTemplateList && subjectTemplateList.length === 0 && subject !== '' && <NotFound />}
                        {(subject === '' ? true : subjectTemplateList.length > 0) && (
                            <MessageList
                                messageData={template}
                                sequenceName={null}
                                setSequenceName={null}
                                treatmentList={[]}
                                practiceId={practiceId}
                                sequenceId={null}
                                isAdmin={isAdminUser()}
                                messageDetail={userTemplateDetails || {}}
                                type={'LeadTemplate'}
                                useOrSend={'use'}
                                setTemplate={handleSetCompose}
                                setTemplateOpen={setTemplateOpen}
                                composeType={type}
                                OpenTemplate={() => setTemplateOpen(true)}
                                screen={''}
                                fullScreen={false}
                                setFullScreen
                                setUseTemplate={setUseTemplate}
                                isFromSearch
                                list={subject === '' ? templateDetailsList : subjectTemplateList}
                            />
                        )}
                    </Box>
                )}
            </Box>
            {
                <Box sx={{ p: '16px 16px 0' }}>
                    <Box sx={attachmentWrapper} alignItems='center'>
                        <Stack direction='row' alignItems='center'>
                            <IconButton onClick={() => hiddenFileInput?.current?.click()}>
                                <Iconify icon='eva:attach-2-fill' />
                            </IconButton>
                        </Stack>
                        <AttachmentFile
                            files={files}
                            handleClearUploaded={handleClearUploaded}
                            fileSize={fileSize}
                            attachment={attachment}
                            getLastPathSegment={getLastPathSegment}
                            handleAttachment={handleAttachment}
                        />
                    </Box>
                </Box>
            }
            {draftInfo?.attachments.map((attachment, index) => (
                <Link
                    key={index}
                    href={attachment.file}
                    target='_blank'
                    rel='noopener'
                    variant='body2'
                    sx={{ p: 2, wordBreak: 'break-all' }}
                >
                    {attachment.file}
                </Link>
            ))}
            <Stack spacing={2} flexGrow={1} sx={{ p: 2 }}>
                <Editor
                    simple
                    id='compose-mail2'
                    value={message}
                    onChanged={onChangeComposeMail}
                    onChangeSelection={(range) => editorOnChangeSelection(range)}
                    ref={textareaRef}
                    placeholder='Type a message'
                    sx={editStyle(fullScreen)}
                    EditorMessage={EditorMessage}
                />
                <Stack direction='row' alignItems='center' sx={{ justifyContent: 'space-between', flexWrap: 'wrap', gap: 1 }}>
                    <Box sx={centerAlignDiv}>
                        <Button onClick={() => setTemplateOpen(true)} sx={{ ...editorBoxBtnStyle, mr: 2 }}>
                            <Iconify
                                sx={{ color: 'grey.700', marginRight: '0' }}
                                icon={'material-symbols:calendar-view-month'}
                                width={20}
                                height={20}
                            />
                        </Button>
                        <Box sx={{ display: 'flex', width: '20rem' }}>
                            <Autocomplete
                                name={'more'}
                                size='small'
                                autoHighlight
                                options={getNameList()}
                                groupBy={(option) => option.category}
                                getOptionLabel={(option) => option?.name}
                                onChange={handleChange}
                                sx={{ width: '100%' }}
                                renderInput={(params) => <TextField {...params} label='Tags' />}
                            />
                        </Box>
                    </Box>
                    <Box sx={{ ml: 'auto' }}>
                        {!isFromDrafts && (
                            <SendButton
                                {...commonProps}
                                title='Save as drafts'
                                successDone={handleSuccessDone}
                                leadID={leadID}
                                selectedFileEmpty={false}
                                uploadAttachment={[]}
                            />
                        )}
                        <SendButton
                            {...commonProps}
                            title='Send'
                            successDone={onSuccessDone}
                            leadID={isFromDrafts ? draftInfo.lead.lead_contact?.lead : leadID}
                            onCloseCompose={() => successDone('Outgoing')}
                            selectedFileEmpty={draftInfo?.attachments.length === 0}
                            uploadAttachment={uploadAttachment}
                        />
                    </Box>
                </Stack>
            </Stack>
        </Paper>
    );

    const handleComposeMAil = () => {
        handleComposeUnableToContact();
        setEmailNotification(false);
    };

    const onFalseTemplate = () => setTemplateOpen(false);

    const onFocus = () => {
        if (practiceId) {
            setFocusUnableToContact(true);
            dispatch(get10TemplateList(practiceId, true));
            setActiveComposeMail(false);
            setEmailNotification(false);
        }
    };

    const commonMessageListProps = {
        messageData: template,
        sequenceName: null,
        setSequenceName: null,
        treatmentList: [],
        practiceId,
        sequenceId: null,
        isAdmin: isAdminUser(),
        messageDetail: userTemplateDetails || {},
        type: 'LeadTemplate',
        useOrSend: 'use',
        setTemplate: handleSetCompose,
        setTemplateOpen,
        composeType: type,
        OpenTemplate: () => setTemplateOpen(true),
        screen: '',
        fullScreen: false,
    };

    return (
        <>
            <input
                type='file'
                ref={hiddenFileInput}
                accept='.pdf,.csv,.docx,.xls,.xlsx,.doc'
                style={{ display: 'none' }}
                onChange={handleInputFiles}
                multiple
            />
            {(fullScreen.value || !smUp) && <Backdrop open sx={{ zIndex: ZINDEX }} />}
            {isFromUnableToContact && (
                <>
                    <Box sx={{ ...emailBoxStyle, border: 'none', display: 'flex', p: '16px 0 16px' }}>
                        <InputBase
                            placeholder='Search templates'
                            startAdornment={<Iconify icon='eva:search-outline' color='grey.500' width={24} height={24} sx={{ mr: 1 }} />}
                            sx={inputBaseStyle}
                            onChange={(e) => handleUnableContactSubjectSearch(e.target.value)}
                            onFocus={onFocus}
                            value={subjectUnableContact}
                        />
                        <Button
                            variant='contained'
                            color='primary'
                            title='Compose mail'
                            onClick={handleComposeMAil}
                            sx={{ minWidth: '130px', ml: 2 }}
                        >
                            Compose mail
                        </Button>
                    </Box>
                    {focusUnableToContact && (
                        <Box onClick={(e) => e.stopPropagation()} sx={focusUnableToContactBoxStyle}>
                            {isTempLoad ? (
                                <TableLoading tableData={isTempLoad && 'No Found'} pageCount={isTempLoad && 'No Found'} />
                            ) : (
                                <>
                                    {subjectUnableContact === '' ? (
                                        <>
                                            {templateDetailsList && templateDetailsList.length === 0 ? (
                                                <><NotFound /></>
                                            ) : (
                                                <MessageList
                                                    {...commonMessageListProps}
                                                    setFullScreen
                                                    setUseTemplate={handleSetUSeTemplate}
                                                    isFromSearch
                                                    list={subjectUnableContact === '' ? templateDetailsList : subjectTemplateList}
                                                    isFromUnableToContact={isFromUnableToContact}
                                                    handleSendUnableToContact={handleSendUnableToContact}
                                                />
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {subjectTemplateList && subjectTemplateList.length === 0 ? (
                                                <><NotFound /></>
                                            ) : (
                                                <MessageList
                                                    {...commonMessageListProps}
                                                    setFullScreen
                                                    setUseTemplate={handleSetUSeTemplate}
                                                    isFromSearch
                                                    list={subjectUnableContact === '' ? templateDetailsList : subjectTemplateList}
                                                    isFromUnableToContact={isFromUnableToContact}
                                                    handleSendUnableToContact={handleSendUnableToContact}
                                                />
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </Box>
                    )}
                </>
            )}
            {isFromUnableToContact === 'true' && activeComposeMail && <>{renderComposeMail()}</>}
            {!isFromUnableToContact && <>{renderComposeMail()}</>}
            {templateOpen && (
                <Portal>
                    <Backdrop open sx={backDropStyle('')} />
                    <RootStyle sx={RootSetStyle('', false)}>
                        <Box>
                            <Box sx={openTamplateBoxStyle}>
                                <Typography onClick={onFalseTemplate} sx={typographyStyle}>
                                    <IconButton onClick={onFalseTemplate}>
                                        <Iconify icon={'eva:arrow-ios-back-fill'} width={20} height={20} />
                                    </IconButton>{' '}
                                    Templates
                                </Typography>
                                <Box sx={iconBtnBoxStyle}>
                                    <IconButton onClick={onFalseTemplate} sx={{ p: 0 }}>
                                        <Iconify icon={'eva:close-fill'} width={24} height={24} />
                                    </IconButton>
                                </Box>
                            </Box>
                            <MessageList {...commonMessageListProps} setFullScreen setUseTemplate={setUseTemplate} />
                        </Box>
                    </RootStyle>
                </Portal>
            )}
        </>
    );
};

MailCompose.propTypes = {
    onCloseCompose: PropTypes.string,
    leadDetail: PropTypes.object,
    successDone: PropTypes.func,
    isFromSideBar: PropTypes.bool,
    isFromDrafts: PropTypes.bool,
    draftInfo: PropTypes.object,
    draftSubject: PropTypes.string,
    selectedMailInfo: PropTypes.object,
    isFromUnableToContact: PropTypes.bool,
    sendUnableToContact: PropTypes.func,
    setEmailNotification: PropTypes.func,
};

export default MailCompose;