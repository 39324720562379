import React from 'react';
import PropTypes from 'prop-types';
import { Box, MenuItem } from '@mui/material';
import { RHFSelect } from '../hook-form';
import { ReferralDropdown } from '../Dropdowns';
import { useSelector } from '../../redux/store';
import { menuItemStyle, selectBoxStyle, selectStyle } from '../../styles/PersonalDetailsStyle';

const CustomLeadInquiryFields = ({ data, values, handleChange, referralValue, handleReferral, affiliateUser }) => {
    const { sourceType } = useSelector((state) => state.schema);

    if (data?.field_type?.attribute !== 'lead_source_type') return null;

    return <Box sx={{ ...selectBoxStyle, mt: '0', mb: '0' }}>
        <RHFSelect
            name='leadType'
            label='Type'
            value={values.leadType}
            SelectProps={{ native: false, sx: selectStyle }}
            sx={{ '.MuiInputBase-root': { mb: '0' } }}
        >
            {sourceType?.map((options, index) => (
                <MenuItem
                    key={index}
                    value={options.value}
                    onChange={(e) => handleChange(e, 'leadType')}
                    sx={menuItemStyle}
                >
                    {options.label}
                </MenuItem>
            ))}
        </RHFSelect>
        <ReferralDropdown
            name={'referral'}
            value={referralValue('value', values)[0]?.label || ''}
            onChange={handleReferral}
            affiliateUser={affiliateUser}
        />
    </Box>;
};

export default CustomLeadInquiryFields;

CustomLeadInquiryFields.propTypes = {
    data: PropTypes.any,
    values: PropTypes.any,
    handleChange: PropTypes.func,
    referralValue: PropTypes.func,
    handleReferral: PropTypes.func,
    affiliateUser: PropTypes.object
};