/* eslint-disable no-restricted-syntax */
import moment from 'moment';
import PropTypes from 'prop-types';
import { useImperativeHandle, forwardRef } from 'react';
// Google map autocomplete
import Autocomplete from 'react-google-autocomplete';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTheme } from '@mui/material/styles';
// @mui
import { Box, Grid, Card, Typography, Checkbox, MenuItem, Divider, FormControl, FormHelperText, FormGroup, FormControlLabel, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
// components
import { Iconify } from '../../../../components';
import { ReferralDropdown } from '../../../../components/Dropdowns';
import { FormProvider, RHFTextField, RHFSelect } from '../../../../components/hook-form';
// @store
import { dispatch, useSelector } from '../../../../redux/store';
import { saveNewLeadDetail, updateCompleteStatus } from '../../../../redux/slices/lead';
// validation
import { PersonalDetailSchema } from '../../../../validations/validations';
// constant
import { inputKeys } from '../../../../constants/LeadDetailConst';
import { toggleButtonStyle } from '../../../../styles/Common';
import { controllerBoxStyle } from '../../../../styles/PersonalDetailsStyle';

const PersonalDetails = forwardRef(({ handleNext, type }, ref) => {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const { newLeadDetail: { personalDetail } } = useSelector((state) => state.lead);
    const { preferredContactMethodData, referral, sourceType, salutationOptions } = useSelector((state) => state.schema);

    const referralValue = (value, data) => referral?.length > 0 && referral?.filter((res) => res[value] === data.referral);

    const defaultValues = {
        firstName: personalDetail && personalDetail?.first_name || '',
        lastName: personalDetail && personalDetail?.last_name || '',
        title: personalDetail && personalDetail?.salutation || '',
        email: personalDetail && personalDetail?.lead_contact && personalDetail?.lead_contact.email || '',
        notes: '',
        // eslint-disable-next-line no-nested-ternary
        phoneNumber: personalDetail && personalDetail?.lead_contact && personalDetail?.lead_contact?.phone || '',
        dob: personalDetail && personalDetail?.dob && new Date(moment(personalDetail?.dob, 'DD-MM-YYYY')) || null,
        preferredContactMethod: personalDetail && personalDetail?.lead_contact && personalDetail?.lead_contact?.preferred_contact_method && personalDetail?.lead_contact?.preferred_contact_method || '',
        address_line_1: personalDetail && personalDetail?.lead_address && personalDetail?.lead_address?.address_line_1 || '',
        county: personalDetail && personalDetail?.lead_address && personalDetail?.lead_address?.county || '',
        city: personalDetail && personalDetail?.lead_address && personalDetail?.lead_address?.city || '',
        postcode: personalDetail && personalDetail?.lead_address && personalDetail?.lead_address?.postcode || '',
        address_line_2: personalDetail && personalDetail?.lead_address && personalDetail?.lead_address?.address_line_2 || '',
        address: '',
        type: personalDetail && personalDetail?.lead_source && personalDetail?.lead_source?.type || '',
        referral: personalDetail && personalDetail?.lead_source && personalDetail?.lead_source?.referral || '',
        isRegister: personalDetail && personalDetail?.is_registered_patient || false
    };
    const methods = useForm({
        resolver: yupResolver(PersonalDetailSchema),
        defaultValues,
    });

    const {
        setValue,
        handleSubmit,
        watch,
        control
    } = methods;

    const values = watch();

    const onSubmit = async (data, status) => {
        try {
            const detail = {
                salutation: data.title && data.title.toUpperCase() || '',
                first_name: data.firstName,
                dob: data.dob ? moment(data.dob, 'DD/MM/YYYY').format('DD-MM-YYYY') : null,
                is_registered_patient: data.isRegister,
                lead_contact: {
                    phone: data.phoneNumber
                },
                lead_source: {}
            };
            if (data.email) {
                detail.lead_contact.email = data.email;
            }
            if (data.lastName) {
                detail.last_name = data.lastName && data.lastName;
            }
            if (data.notes) {
                detail.lead_contact.note = data.notes;
            }
            if (data.address_line_1) {
                detail.lead_address = {
                    ...detail.lead_address,
                    address_line_1: data.address_line_1
                };
            }
            if (data.postcode) {
                detail.lead_address = {
                    ...detail.lead_address,
                    postcode: data.postcode
                };
            }
            if (data.address_line_2) {
                detail.lead_address = {
                    ...detail.lead_address,
                    address_line_2: data.address_line_2
                };
            }
            if (data.city) {
                detail.lead_address = {
                    ...detail.lead_address,
                    city: data.city,
                };
            }
            if (data.county) {
                detail.lead_address = {
                    ...detail.lead_address,
                    county: data.county
                };
            }
            if (data.preferredContactMethod) {
                detail.lead_contact.preferred_contact_method = data.preferredContactMethod.toUpperCase();
            }
            if (data.type) {
                detail.lead_source = {
                    ...detail.lead_source,
                    type: data.type
                };
            }
            if (data.referral) {
                detail.lead_source = {
                    ...detail.lead_source,
                    referral: data.referral
                };
            }
            if (data.affiliate_user) {
                detail.lead_source = {
                    ...detail.lead_source,
                    affiliate_user: data.affiliate_user,
                    type: "CLINICIAN"
                };
            }
            dispatch(saveNewLeadDetail({ personalDetail: detail, personalDetailEdited: true }));
            if (status?.toLowerCase?.() === 'finish') {
                dispatch(updateCompleteStatus(true));
                return;
            }
            handleNext(true);
        }
        catch (err) {
            console.error("Error in personal details", err);
        }
    };

    const setAddressData = (addresses, fulladdress) => {
        ClearAddressData();
        if (addresses && addresses.length > 0) {
            const address1 = fulladdress.split(',')[0];
            setValue('address_line_1', address1);
            addresses.forEach(address => {
                if (address.types.indexOf('postal_code') > -1) {
                    setValue('postcode', address.long_name);
                }
                if (address.types.indexOf('country') > -1) {
                    if (address1 === address.long_name) {
                        setValue('address_line_1', '');
                    }
                    setValue('county', address.long_name);
                }
                if (address.types.indexOf('locality') > -1 || address.types.indexOf('postal_town') > -1) {
                    if (address1.includes(address.long_name)) {
                        setValue('address_line_1', '');
                    }
                    setValue('city', address.long_name);
                }
                if (address.types.indexOf('route') > -1) {
                    if (!address1.includes(address.long_name)) {
                        setValue('address_line_2', address.long_name);
                    } else {
                        setValue('address_line_2', '');
                    }
                }
            });
        } else {
            ClearAddressData();
        }
    };

    const ClearAddressData = () => {
        setValue('postcode', '');
        setValue('address_line_2', '');
        setValue('county', '');
        setValue('city', '');
        setValue('address_line_1', '');
    };
    useImperativeHandle(
        ref, () => ({
            async submit(status) {
                await handleSubmit((data) => onSubmit(data, status))();
                // await handleSubmit(onSubmit)();
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const handleChange = (event, data) => {
        const {
            target: { value },
        } = event;

        setValue(data, value);
    };

    const handleReferral = (event, data) => {
        if (data) {
            setValue('referral', data?.id ? "CLINICIAN" : data.value);
            if (data?.id) {
                setValue('affiliate_user', data?.id);
                setValue('type', "CLINICIAN");
            }
        }
    };

    const getAddressField = () => {
        if (values.address_line_1 || values.address_line_2 || values.city || values.county || values.postcode) {
            return true;
        }
        return false;
    };

    const numberKeyDownFunc = (evt) => {
        const isControlC = (evt.ctrlKey && evt.key === 'c') || (evt.metaKey && evt.key === 'c');
        const isControlV = (evt.ctrlKey && evt.key === 'v') || (evt.metaKey && evt.key === 'v');
        const isAllowedKey = inputKeys.includes(evt.key) || (/^[0-9]*$/).test(evt.key);
        if (!(isControlC || isControlV || isAllowedKey)) {
            evt.preventDefault();
        }
    };

    return (
        <FormProvider methods={methods}>
            <Grid container spacing={1}>
                <Grid item xs={12} md={12}>
                    <Card sx={{ p: 3, boxShadow: 'none' }}>
                        <Typography variant="h6" sx={{ marginBottom: '15px' }}>
                            {type === 'followUp' ? 'Patient Details' : 'Personal Details'}
                        </Typography>
                        <Divider sx={{ marginBottom: '30px' }} />
                        <Box
                            sx={{
                                display: 'grid',
                                columnGap: 2,
                                rowGap: 3,
                                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)', md: 'repeat(4, 1fr)', },
                                mb: 2,
                            }}
                        >
                            <RHFSelect
                                name={'title'}
                                label="Title"
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{ native: false, sx: { textTransform: 'capitalize' } }}
                            >
                                {salutationOptions?.map((option, index) => (
                                    <MenuItem
                                        key={index}
                                        value={option.value}
                                        sx={{
                                            mx: 1,
                                            my: 0.5,
                                            borderRadius: 0.75,
                                            typography: 'body2',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {option.label}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                            <RHFTextField name="firstName" label="First name (Required)" />
                            <RHFTextField name="lastName" label="Last name" />
                            <Box>
                                <Controller
                                    name="dob"
                                    control={control}
                                    render={({ field, fieldState: { error } }) => (
                                        <DesktopDatePicker
                                            orientation="landscape"
                                            label="Date of birth"
                                            value={field.value || null}
                                            format="dd/MM/yyyy"
                                            maxDate={new Date()}
                                            onChange={(newValue) => {
                                                field.onChange(newValue);
                                            }}
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    error: !!error,
                                                    helperText: error?.message,
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: 'grid',
                                columnGap: 2,
                                rowGap: 3,
                                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                                mb: 2,
                            }}
                        >
                            <RHFTextField name='email' label='Email' />
                            <RHFTextField
                                name='phoneNumber'
                                type='string'
                                inputProps={{ maxLength: 15 }}
                                label='Phone number (Required)'
                                onKeyDown={(evt) => numberKeyDownFunc(evt)}
                            />
                        </Box>
                        <Box sx={controllerBoxStyle}>
                            <Controller
                                name="preferredContactMethod"
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                    <FormControl>
                                        <Typography
                                            variant="subtitle2"
                                            sx={{ m: '0 0 10px' }}
                                        >
                                            Preferred Contact Method
                                        </Typography>
                                        <ToggleButtonGroup
                                            color="primary"
                                            exclusive
                                            value={field.value}
                                            onChange={(e, value) => {
                                                if (value === null) {
                                                    field.onChange(field.value);
                                                    return;
                                                }
                                                field.onChange(value);
                                            }}
                                            aria-label="Platform"
                                            sx={{ borderRadius: 0, border: 'none', flexWrap: 'wrap' }}
                                            error={error && !field.value?.length}
                                        >
                                            {preferredContactMethodData?.map((options, index) => (
                                                <ToggleButton
                                                    key={index}
                                                    value={options.value}
                                                    sx={toggleButtonStyle(isLight)}
                                                >
                                                    <Iconify icon={field.value === options.value ? 'bi:check-circle-fill' : 'material-symbols:circle'} width={20} height={20} sx={{ mr: 1, color: field.value === options.value ? '#00A76F' : '#C4CDD5' }} />{options.label}
                                                </ToggleButton>
                                            ))}
                                        </ToggleButtonGroup>
                                        {error && !field.value?.length &&
                                            <FormHelperText error sx={{ px: 2, textTransform: 'capitalize' }}>
                                                {error?.message}
                                            </FormHelperText>}
                                    </FormControl>
                                )}
                            />
                        </Box>
                        <FormGroup sx={{ gap: 2 }}>
                            <FormControlLabel
                                key={1}
                                control={
                                    <Controller
                                        control={control}
                                        name={'isRegister'}
                                        render={({ field }) => (
                                            <Checkbox
                                                areaLabel='register-checkbox'
                                                onChange={e => {
                                                    field.onChange(e.target.checked);
                                                }}
                                                checked={field.value}
                                            />
                                        )}
                                    />
                                }
                                label={'Is registered patient'}
                                sx={{ width: 'fit-content' }}
                            />
                        </FormGroup>
                        <Typography variant="h6" sx={{ mt: 10, marginBottom: '15px' }}>
                            Address
                        </Typography>
                        <Divider sx={{ marginBottom: '30px' }} />
                        <Box
                            sx={{
                                display: 'grid',
                                columnGap: 2,
                                rowGap: 3,
                                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(1, 1fr)' },
                                mb: 2,
                                position: 'relative'
                            }}
                        >
                            <Controller
                                name="address"
                                control={control}
                                render={({ field }) => (
                                    <>
                                        <Iconify icon={'eva:search-outline'} sx={{
                                            position: 'absolute',
                                            left: '15px',
                                            top: '16px'

                                        }} color="grey.500" width={24} height={24} />
                                        <Autocomplete
                                            apiKey='AIzaSyD_7FXxCWgBUUd6922n33WKqrfRDsXXxj8'
                                            onPlaceSelected={(place, inputRef) => {
                                                const addresses = place.address_components;
                                                setAddressData(addresses, inputRef.value);
                                                field.onChange(inputRef.value);
                                            }}
                                            style={{
                                                paddingLeft: '45px',
                                                fontSize: '0.875rem'
                                            }}
                                            options={{
                                                types: ['establishment', 'geocode'],
                                                fields: ['address_components', 'formatted_address'],
                                                componentRestrictions: { country: 'uk' }
                                            }}
                                            defaultValue={field.value}
                                            className='address-input'
                                            placeholder='Search address'
                                        />
                                    </>
                                )}
                            />
                        </Box>
                        {getAddressField() && <Box
                            sx={{
                                display: 'grid',
                                columnGap: 2,
                                rowGap: 3,
                                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                                mb: 2,
                            }}
                        >
                            <RHFTextField name="address_line_1" label="Address line 1" />
                            <RHFTextField name="address_line_2" label="Address line 2" />
                            <RHFTextField name="city" label="City" />
                            <RHFTextField name="county" label="County" />
                            <RHFTextField name="postcode" label="Postcode" />
                        </Box>}
                        <Typography variant="h6" sx={{ mt: 10, marginBottom: '15px' }}>
                            Lead Source
                        </Typography>
                        <Divider sx={{ marginBottom: '30px' }} />
                        <Box
                            sx={{
                                display: 'grid',
                                columnGap: 2,
                                rowGap: 3,
                                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                                mb: 2,
                                alignItems: 'flex-start'
                            }}
                        >
                            <RHFSelect
                                name='type'
                                label='Type'
                                areaLabel='leadType'
                                value={values.type}
                                SelectProps={{ native: false, sx: { textTransform: 'capitalize', mb: { sm: 3, xs: 3 } } }}
                            >
                                {sourceType?.map((options, index) => (
                                    <MenuItem
                                        key={index}
                                        value={options.value}
                                        onChange={(e) => handleChange(e, 'type')}
                                        sx={{
                                            mx: 1,
                                            my: 0.5,
                                            borderRadius: 0.75,
                                            typography: 'body2',
                                            textTransform: 'capitalize',
                                        }}
                                    >
                                        {options.label}
                                    </MenuItem>
                                ))}
                            </RHFSelect>
                            <ReferralDropdown
                                name={'referral'}
                                areaLabel='leadReferral'
                                value={referralValue('value', values)[0]?.label || ''}
                                onChange={handleReferral}
                                affiliateUser={values?.affiliate_user ? { id: values?.affiliate_user } : personalDetail?.lead_source?.affiliate_user}
                            />
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </FormProvider>
    );
});

PersonalDetails.propTypes = {
    handleNext: PropTypes.func,
    type: PropTypes.string,
};

export default PersonalDetails;