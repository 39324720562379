import jwtDecode from 'jwt-decode';
// routes
import { updateError } from 'src/redux/slices/user';
//
import axios from './axios';
import { dispatch } from '../redux/store';
// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false;
    }
    const decoded = jwtDecode(accessToken);

    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
};

const handleTokenExpired = (exp) => {
    const currentTime = Date.now() / 1000;
    const timeLeft = (exp - currentTime) * 1000; // Convert seconds to milliseconds
    let expiredTimer;
    clearTimeout(expiredTimer);

    expiredTimer = setTimeout(() => {
        dispatch(updateError({
            message: 'Session has timed out. Trying to extend session!',
            success: "warning"
        }));
    }, timeLeft);
};

const setSession = (accessToken, refreshToken) => {
    if (accessToken) {
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem('accessToken', accessToken);
        axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

        // This function below will handle when token is expired
        const { exp } = jwtDecode(accessToken); // ~3 days by minimals server
        handleTokenExpired(exp);
    } else {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

export { isValidToken, setSession };
