/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
// libraries
import { useParams } from 'react-router';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDebounce } from 'use-lodash-debounce';
import { createFilterOptions } from '@mui/material/Autocomplete';
import {
    Box,
    Button,
    Chip,
    DialogTitle,
    DialogContent,
    TextField,
    Dialog,
    DialogContentText,
    DialogActions,
    Autocomplete,
    useTheme
} from '@mui/material';
// components
import { FormProvider, RHFTextField } from '../hook-form';
// redux
import { useSelector, dispatch } from '../../redux/store';
import { followUpSlice, updateCounter } from '../../redux/slices/lead';
import {
    createTagItem,
    createCustomTagItem,
    getTagItem,
    getAllTagItem,
    deleteTagItem,
    startLoading
} from '../../redux/slices/tagsCreate';
// util/validation
import { AutocompleteTagsSchema } from '../../validations/validations';
import {
    TabCount,
    TablePageNumber,
    TablePerPage,
    TableSearch,
    TableTreatment,
    TableFilterDate,
    TableStatus,
    TableFilterTags,
    TableOrder,
    TableOrderBy,
    TableReferral,
    TableCategory,
    TableBookedWith
} from '../../utils/allTableTab';
// style
import {
    tagsBoxStyle,
    tagsStyle,
    selectedTagStyle,
    InputTags,
    searchTagsStyle,
    leadDetailsChipStyle
} from '../../styles/AutocompleteTags';

const AutocompleteTags = ({ type, data, selectData, handleSelect, tableType, reloadCounter, updateLeadPage, setTableData, handleArchivedNavigate }) => {

    const filter = createFilterOptions();

    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';

    const { id } = useParams();

    const [position, setPosition] = useState(0);
    const [open, toggleOpen] = useState(false);

    const listElem = useRef();
    const mounted = useRef();

    const contactCount = TabCount(tableType);
    const pageNumber = TablePageNumber(tableType);
    const perPage = TablePerPage(tableType);
    const searchValue = TableSearch(tableType);
    const treatmentValue = TableTreatment(tableType);
    const searchFilterDate = TableFilterDate(tableType);
    const status = TableStatus(tableType);
    const searchTagsValue = TableFilterTags(tableType)?.toString();
    const tableOrder = TableOrder(tableType);
    const tableOrderBy = TableOrderBy(tableType);
    const referral = TableReferral(tableType);
    const category = TableCategory(tableType);
    const bookedWith = TableBookedWith(tableType);
    const [inputValue, setInputValue] = useState('');
    const inputValueDebounce = useDebounce(inputValue, 800);

    const { getAllTag: { results = [], count = 0 } = {}, isLoading = false } = useSelector((state) => state.tagsCreate || []);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { getTag } = useSelector((state) => state.tagsCreate || []);

    const leadId = type !== 'table' ? id : selectData.lead_id;
    const allData = type !== 'table' ? getTag : data.tags;
    const startDate = searchFilterDate ? searchFilterDate[0]?.startDate : null;
    const endDate = searchFilterDate ? searchFilterDate[0]?.endDate : null;

    const treatmentData = [];
    treatmentValue?.map((value) => treatmentData.push(value.id));

    const defaultValues = {
        tags: []
    };

    const notArchType = tableType !== 'ArchiveTable';

    const allTags = notArchType ? results : [];

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(AutocompleteTagsSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        setValue,
        getValues,
        control,
    } = methods;

    useEffect(() => {
        if (leadId && type !== 'table') {
            dispatch(getTagItem(practiceId, leadId));
        }
    }, [leadId, type]);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else if (position && listElem.current) {
            listElem.current.scrollTop = position - listElem?.current?.offsetHeight;
        }
    }, [position]);

    const handleTags = (event, newValue) => {
        const data = newValue.filter(value => typeof value === 'string');
        if (event.target.value) {
            toggleOpen(true);
            setValue('tagName', event.target.value);
            return;
        }
        if (data?.length === 0 && (event.key !== 'Backspace')) {
            if (notArchType) {
                if (newValue && newValue?.length > 0 && (newValue[newValue?.length - 1]?.inputValue === '' || newValue[newValue?.length - 1]?.inputValue)) {
                    toggleOpen(true);
                    setValue('tagName', newValue[newValue?.length - 1]?.inputValue);
                } else {
                    setValue('tags', newValue[newValue?.length - 1]?.name);
                    const tagData = {
                        name: newValue[newValue.length - 1]?.name,
                        colour: newValue[newValue.length - 1]?.colour
                    };    
                    dispatch(createTagItem(tagData, practiceId, leadId, handleSuccess));
                }
            }
        }
    };

    const filterOptions = (options, params) => {
        const filtered = filter(options, params);
        if (filtered.length <= 0 && notArchType && !isLoading) {
            filtered.push({
                    inputValue: params?.inputValue,
                    name: `Add "${params?.inputValue}"`,
            });
        }
        return filtered;
    };

    const getOptionLabel = (option) => {
        if (typeof option === 'string') {
            return option?.name;
        }
        if (option.inputValue) {
            return option?.inputValue;
        }
        return option?.name;
    };

    const onSubmit = (tagData) => {
        const data = {
            name: tagData.tagName
        };
        setValue('tags', [
            ...getValues('tags'),
            {
                title: tagData.tagName,
                tagId: tagData?.tagId
            },
        ]);
        dispatch(createCustomTagItem(data, practiceId, leadId, handleSuccess));
    };

    const handleDelete = (chipToDelete) => () => {
        if (tableType === 'ArchiveTable') {
            handleArchivedNavigate(data);
            return;
        };
        if (notArchType) {
            if (type === 'table') {
                const data = handleSelect();
                if (data) {
                    dispatch(deleteTagItem(chipToDelete, practiceId, data.lead_id, handleSuccess));
                }
                return;
            }
            dispatch(deleteTagItem(chipToDelete, practiceId, leadId, handleSuccess));
        }
    };

    const handleSuccess = (data, leadId) => {

        if (type === 'table') {
            const dataLead = handleSelect();
            dispatch(followUpSlice(leadId || dataLead.lead_id, practiceId, 'LeadDetail', handleClose));
            if(data === 'custom'){
                dispatch(getAllTagItem(practiceId, 30, 0));
            }
            return;
        }
        handleReload(data);
    };

    const handleClose = (data) => {
        setTableData((state) => state.map((ele) => {
            if (ele.id === data?.id) {
                return { ...ele, tags: data?.tags };
            }
            return ele;
        }));
        toggleOpen(false);
    };

    const handleReload = (data) => {
        if (type === 'table') {
            dispatch(updateCounter([]));
            dispatch(getAllTagItem(practiceId, 30, 0));
            const reloadPayLoad = {
                limit: perPage,
                offset: pageNumber,
                filterStatus: status,
                order: tableOrder,
                orderId: tableOrderBy,
                searchQuery: searchValue,
                treatment: treatmentData?.toString(),
                startDate,
                endDate,
                contactedCount: contactCount,
                tags: searchTagsValue,
                referral,
                category,
                bookedWith
            };
            updateLeadPage(reloadPayLoad);
            reloadCounter(reloadPayLoad);
            toggleOpen(false);
            return;
        }
        if (data === 'tag') {
            dispatch(getTagItem(practiceId, leadId));
            return;
        }
        if (data === 'custom') {
            toggleOpen(false);
            dispatch(getAllTagItem(practiceId, 30, 0));
            dispatch(getTagItem(practiceId, leadId));
        }
    };

    const leadMoreData = () => {
        if (count > results?.length) {
            dispatch(getAllTagItem(practiceId, 30, results?.length));
        }
    };

    const leadMorePosition = (event) => {
        const listboxNode = event?.target?.scrollHeight <= event?.target?.scrollTop + event?.target?.clientHeight;
        if (listboxNode) {
            setPosition(event?.target?.scrollTop + event?.target?.clientHeight);
            leadMoreData();
        }
    };

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return; // Skip first mount
        }
        if (practiceId) {
            if (inputValueDebounce) {
                const data = {
                    tagged__items__tag__name__icontains: inputValueDebounce,
                };
                dispatch(getAllTagItem(practiceId, 30, 0, data));
            }
            else {
                dispatch(getAllTagItem(practiceId, 30, 0));
            }
        }
    }, [inputValueDebounce]);

    const inputChangeHandler = (value) => {
        dispatch(startLoading());
        setInputValue(value);
        if (value === '') {
            dispatch(getAllTagItem(practiceId, 30, 0));
        }
    };

    const handleSpaceKeyDown = (event) => {
        if (event.key === ' ') {
            event.stopPropagation();
        }
    };

    return (
        <Box sx={tagsBoxStyle(type)}>
            <Controller
                name="tags"
                control={control}
                render={() => (
                    <Box sx={{ width: '100%', height: type === 'table' ? '100%' : 'auto' }}>
                        <Autocomplete
                            multiple
                            id="free-solo-dialog-demo"
                            value={allData || []}
                            selectOnFocus
                            handleHomeEndKeys
                            options={allTags || []}
                            noOptionsText={isLoading ? 'Loading...' : 'No options'}
                            onInputChange={(e, newValue) => inputChangeHandler(newValue)}
                            sx={type === 'archived' ? searchTagsStyle(isLight) : tagsStyle(type, isLight, tableType, allData?.length)}
                            onKeyPress={(e) => (!notArchType && e.preventDefault())}
                            onKeyDown={(e) => handleSpaceKeyDown(e)}
                            limitTags={type === 'table' ? 2 : allData?.length}
                            onChange={(event, newValue) => handleTags(event, newValue)}
                            filterOptions={(options, params) => filterOptions(options, params)}
                            getOptionLabel={(option) => getOptionLabel(option)}
                            ListboxProps={{
                                ref: listElem,
                                onScroll: leadMorePosition
                            }}
                            renderTags={(value) =>
                                value.map((option) => (
                                    <Chip
                                        sx={type === 'leadDetail' ? leadDetailsChipStyle : InputTags(option?.colour)}
                                        key={option?.id}
                                        label={option?.name}
                                        onDelete={handleDelete(option)}
                                        size="small"
                                        color="info"
                                        variant="soft"
                                        title={option?.name}
                                    />
                                ))
                            }
                            renderOption={(props, option,) => (
                                <li {...props} key={option.id} style={{ padding: '0' }}>
                                    <Box sx={selectedTagStyle(allData, option)}>{option?.name}</Box>
                                </li>
                            )}
                            renderInput={(params) => <TextField {...params} placeholder={((allData?.length <= 0 && (type !== 'table' || notArchType))) ? '+' : ''} />}
                        />
                        <Dialog open={open} onClose={() => toggleOpen(false)}>
                            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                <DialogTitle>Add a new tag</DialogTitle>
                                <DialogContent>
                                    <DialogContentText sx={{ mb: 3 }}>
                                        Did you miss any tag in our list? Please, add it!
                                    </DialogContentText>
                                    <RHFTextField
                                        autoFocus
                                        margin="dense"
                                        name="tagName"
                                        label="Name"
                                        placeholder="Tag name"
                                        onChange={(event) => {
                                            setValue('tagName', event.target.value);
                                        }}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => toggleOpen(false)}>Cancel</Button>
                                    <Button type="submit">Add</Button>
                                </DialogActions>
                            </FormProvider>
                        </Dialog>
                    </Box>
                )}
            />
        </Box>
    );
};

export default AutocompleteTags;

AutocompleteTags.propTypes = {
    type: PropTypes.string,
    selectData: PropTypes.object,
    data: PropTypes.object,
    handleSelect: PropTypes.func,
    tableType: PropTypes.string,
    reloadCounter: PropTypes.func,
    updateLeadPage: PropTypes.func,
    setTableData: PropTypes.func,
    handleArchivedNavigate: PropTypes.func,
};