/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useRef, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';
import useSnackbar from 'src/hooks/useCustomSnackbar';
// @mui
import {
    Card,
    Typography,
    Dialog,
    Box,
    Switch,
    Paper,
    Button,
    Popper,
    TablePagination,
    ButtonGroup,
    MenuList,
    MenuItem,
    Grow,
    Tabs,
    Tab,
    DialogTitle,
    DialogActions,
    Radio,
    Zoom,
    Tooltip,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Select,
    InputLabel,
    ClickAwayListener,
    useTheme,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import { widgetArrayTask } from 'src/constants/widgetConstants';
import MultiAssigneesDropdown from 'src/components/Dropdowns/SelectMultiAssignees';
import TaskReportWidget from 'src/components/reportsWidget/TaskReportWidget';
import { treatmentBoxStyle } from 'src/styles/NewLead';
import { useForm } from 'react-hook-form';
import { FormProvider } from 'src/components/hook-form';
import Activities from './Activities';
import { FollowUp, AppointmentPage } from '../lead';
import { ReusableSidebar } from '../calendar';
import { Iconify, Label } from '../../../components';
import { NotFound } from '../../../components/empty-content';
import { UnableToContact } from '../../../components/failedToAttendTabs';
import { AssignTaskPopup, AddReminderPopup, EditPersonalDetailsPopup, AppointmentAssignTaskPopup, ArchivedLeadPopUp, AddNotePopup } from '../../../components/dialogPopUp';
import { AssignUserAvatar } from '../../../components/custom-avatar';
import { NormalLoading } from '../../../components/loading';
import {
    OpenAttendedPopup,
    OpenUnAttendedPopup,
    OpenConsultationPopup,
    OpenContactLeadPopup
} from '../../../components/fullScreenPopUps';
import LeadTemplate from '../../../pages/dashboard/LeadTemplate';
// general
import {
    AttendedAppointment,
    MessageAndReminder,
    PaymentOption,
    PersonalDetails,
    TreatmentAndAppointment
} from '../general/app';
// redux
import { setCurrentTreatmentDataGlobal } from '../../../redux/slices/multiTreatment';
import {
    getLeadReminderData,
    followUpSlice,
    getAppointment,
    tableLead,
    clearLead,
    updateSteps,
    updateLeadDetail,
    ConsultationAttended,
    RecordAttended,
    recordUnAttended,
    getNewLead,
    updateFollowUpLead,
    CompleteTreatment,
    updateReminder,
    addTaskTableData,
    getLeadNotes,
    getLeadNotesWithFilter,
    setAssigned,
    addLeadTaskTab,
    leadTaskListCounter,
    updateTaskCounter,
    updateSeparateTaskCounter,
    updateLeadTaskCounter,
    setDashboardTaskPageFilter,
    setLeadDetailTaskPageFilter,
    setTaskPageFilter,
    getReminderData,
    deleteReminderData,
    taskCompletedUser,
    setAction,
    taskUnassignedUser,
    ArchivedLeadDashBoard
} from '../../../redux/slices/lead';
import { getPracticeItem } from '../../../redux/slices/practice';
import { clearPracticeUserData } from '../../../redux/slices/practiceUser';
import { getActivities } from '../../../redux/slices/activity';
import { useSelector, dispatch } from '../../../redux/store';
import { updatePracticeId } from '../../../redux/slices/practiceTreatmentList';
import { setLoader } from '../../../redux/slices/loader';
// constants
import { CreateLeadHeaderData, TASK_TAB_HEAD, TASK_ADDITIONAL_FILTER } from '../../../constants/LeadDetailConst';
// hooks
import {
    useTable,
    useTabs,
    useResponsive,
    useRecordAttendToggle,
    useUnRecordAttendToggle,
    useReminderToggle,
    useArchivedToggle,
    useAssignTaskToggle,
    useToggle,
    useAuth,
    useConsultationAttendToggle,
    useSwitchUserToggle,
    useReloadWidgetCounter
} from '../../../hooks';
// utils
import { getIconAndColor } from '../../../utils/taskPriority';
import capitalizeFirstLetter from '../../../utils/capitalizedFunc';
import { getStatusColour, getMessage } from '../../../utils/getTasksData';
import { getAssignees } from '../../../utils/multiAsignees';
import { setRowLeadData } from '../../../utils/getRowLeadData';
import { localStorageRemoveItem } from '../../../utils/storage-available';
// style
import { common } from '../../../theme/palette';
import {
    tabHeadStyle,
    taskCard,
    taskList,
    taskButton,
    leadListCard,
    leadListBox,
    leadTaskPopover,
    leadTaskNameStyle,
    taskMessage,
    taskUser,
    taskUserAvatar,
    addTaskHideBtn,
    addTaskPop,
    plusMenu,
    plusButton,
    plusMenuIcon,
    taskConsultBtn,
    openTask,
    openTaskPop,
    openTaskText,
    completeTreatmentCancel,
    completeTreatmentConfirm,
    profileLabel,
    filterOutBox,
    typographyUi,
    recordBtn,
    taskTopHeaderBoxStyle,
    taskListCardStyle,
    assignUserAvatarStyle,
    assignLengthStyle,
    viewProfileBoxStyle,
    methodButtonStyle,
    formControlLabelStyle,
    filterCountBoxStyle,
    taskItemDetailBoxStyle
} from '../../../styles/leadTaskReminder';
import { yesBtnStyleForLoader } from '../../../styles/Common';

const TaskReminder = ({ type, reminder, leadDetail, reloadTaskCounter, handleLoading, reset, selectedDateRange, isDashboard, shouldRunTaskEffect }) => {


    const navigate = useNavigate();
    const theme = useTheme();
    const leadId = useParams();
    const inputRef = useRef();
    const personalDetailPopupRef = useRef();
    const reminderDataId = useRef();
    const isLight = theme.palette.mode === 'light';
    const { user } = useAuth();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const isDesktop = useResponsive('down', 'sm');
    const { state } = useLocation();

    const { practiceUser } = useSelector((state) => state.practiceUser);
    const { activityList } = useSelector((state) => state.activity);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const [anchorElAssign, setAnchorElAssign] = useState(null);
    const {
        isAppointmentBooked, attendance,
        contactLead, BookingAttendance,
        markAsComplete, followUpLead,
        isAssigned, leadTaskTab,
        taskTableData: {
            limit,
            offset,
            filter,
            assignUser,
            tasks: {
                limit: taskLimit,
                offset: taskOffset,
                filter: taskFilter,
                assignUser: taskAssingUser,
            }
        },
        taskCounter, taskLeadCounter, separateTaskCounter,
        dashContact: {
            pageNumber,
            perPage,
            order,
            orderValue,
            search,
            treatment,
            date,
            status: updateStatus,
            filterTags,
        },
        dashboardTaskPageFilter,
        leadDetailTaskPageFilter,
        taskPageFilter,
        taskDetail,
        isTasksCompleted,
        action,
        isLoadingFinish,
        loadingApis,
        unassignedUser
    } = useSelector((state) => state.lead);

    const { isLoader } = useSelector((state) => state.loaderReducer);

    const startDate = date ? date[0]?.startDate : null;
    const endDate = date ? date[0]?.endDate : null;

    const { currentTab, onChangeTab, setCurrentTab } = useTabs(type === 'LeadDetail' ? leadTaskTab : type === 'Tasks' ? taskFilter : filter);

    const { pathname } = useLocation();
    const location = useLocation();
    const routeData = new URLSearchParams(location.search);
    const searchPractice = routeData.get('practiceId');
    const taskId = routeData.get('taskId');
    const tab = routeData.get('tab');

    const [validStep, setValidStep] = useState(false);
    const [assignedUser, setAssignedUser] = useState(isAssigned);
    const [tasksCompletedUser, setTasksCompletedUser] = useState(isTasksCompleted);
    const [Lead, setLead] = useState(0);
    const [userID, setUserID] = useState('');
    const [open, setOpen] = useState(false);
    const [markComplete, setMarkComplete] = useState(false);
    const [confirmation, setConfirmation] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [reminderList, setReminderList] = useState([]);
    const [addPopupType, setAddPopupType] = useState('editTask');
    const [leadData, setLeadData] = useState('');
    const [assigneeData, setAssigneeData] = useState([]);
    const [reminderData, setReminderID] = useState(0);
    const [openSidebar, setOpenSidebar] = useState(false);
    const [additionalTabState, setAdditionalTabState] = useState('Today');
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [deleteData, setDeleteData] = useState(null);
    const [assignees, setAssignees] = useState([]);
    const leadTreatment = leadDetail?.lead_treatments[0];
    const addTaskHide = type === 'LeadDetail' ? leadTreatment?.status !== undefined && leadTreatment?.status !== 'CLOSED' : type === 'dashboard' || type === 'Tasks';
    const { currentTab: additionalTab, onChangeTab: onAdditionalTab } = useTabs(additionalTabState);
    const { currentTreatmentDataGlobal } = useSelector((state) => state.multiTreatment);
    const [options, setOptions] = useState('');
    const [templateOpen, setTemplateOpen] = useState('');
    const [selectedValue, setSelectedValue] = useState('');
    const [selectedAssigneeFilter, setSelectedAssigneeFilter] = useState([]);
    const [taskWidgetTreatmentValue, setTaskWidgetTreatmentValue] = useState(null);
    const [reloadCounters, setReloadCounters] = useState(null);

    const { reloadWidgetCounter } = useReloadWidgetCounter();

    useEffect(() => {
        if (type === 'Tasks' ? Boolean(taskAssingUser) : Boolean(assignUser)) {
            setSelectedValue('My tasks');
        } else if (unassignedUser) {
            setSelectedValue('Unassigned tasks');
        } else {
            setSelectedValue('All');
        }

    }, [taskAssingUser, unassignedUser, assignUser, taskWidgetTreatmentValue]);

    const assigneeDataFilter = [];
    selectedAssigneeFilter.forEach(els => assigneeDataFilter.push(els.id));

    useEffect(() => {
        const assignees = assigneeDataFilter.length ? assigneeDataFilter.toString() : taskAssingUser || '';
        const taskFilter = { assigneeUser: assignees, isTasksCompleted, unassignedUser, filter: currentTab }
        reloadWidgetCounter({ array: widgetArrayTask, taskFilter });

    }, [selectedAssigneeFilter, assignedUser, isTasksCompleted, unassignedUser, currentTab, taskAssingUser, markComplete])

    useEffect(() => {
        if (reloadCounters) {
            sidebarCloser();
            const assignees = assigneeDataFilter.length ? assigneeDataFilter.toString() : taskAssingUser || '';

            if (type === "Tasks") {
                const taskFilter = { assigneeUser: assignees, isTasksCompleted, unassignedUser, filter: currentTab }
                reloadWidgetCounter({ array: widgetArrayTask, taskFilter });
            }

            reloadTaskCounter({ userId: assignees, id: type === 'LeadDetail' ? leadId.id : '', additionalFilter: additionalTabState, CompletedTask: isTasksCompleted, unassignedUser, treatment: taskWidgetTreatmentValue });

        }

    }, [reloadCounters])

    useEffect(() => {
        if (selectedAssigneeFilter && selectedAssigneeFilter.length > 0) {
            setSelectedValue('All');
        }
    }, [selectedAssigneeFilter]);

    const [rowLead, setRowLead] = useState({
        lead_id: '',
        treatment_id: '',
        price: '',
        first_name: '',
        last_name: '',
        status: '',
        treatment_name: '',
        created_at_human: '',
        lead_treatments__lead_contacted_counts__count: 0
    });

    const {
        page,
        rowsPerPage,
        onChangePage,
        onChangeRowsPerPage,
        setPage,
        setRowsPerPage
    } = useTable();


    const defaultValues = {
        multiSelectAssignee: assigneeDataFilter || [],
    };
    const methods = useForm({
        defaultValues
    });
    const { setValue, handleSubmit } = methods;

    const onSubmit = (data) => {
        setValidStep(!!data);
    };

    useEffect(() => {
        if (practiceId) {
            dispatch(getPracticeItem(practiceId));
        }
    }, [practiceId]);

    useEffect(() => {
        if (filter && type !== 'LeadDetail' && type !== 'Tasks') {
            handleFilterTask('', filter);
        }
    }, [filter]);

    useEffect(() => {
        if (!isEmpty(reminderList) && state?.openSideBar) {
            const data = reminderList?.find((ele) => ele.id === state?.taskId);
            if (!isEmpty(data)) {
                sidebarHandler(data);
            }
        }
    }, [state, reminderList]);

    const handleClose = (status, archive) => {
        dispatch(setLoader(false));
        setOpenSidebar(false);
        if (type === 'LeadDetail' && archive === true) {
            navigate('/dashboard/app');
        };
        if (status?.toLowerCase?.() === 'finish') {
            const data = {
                is_completed: true
            };
            dispatch(updateReminder(data, reminderDataId.current, practiceId, handleReminder, 'markComplete'));
            setConfirmation(false);
        };
        setTimeout(() => {
            const htmlElement = document.querySelector('body');
            htmlElement.classList.remove('overflow-hidden');
        }, 200);
        dispatch(clearLead());
        dispatch(setCurrentTreatmentDataGlobal(null));
        dispatch(updateSteps(null));
        dispatch(updateFollowUpLead(null));
        onCloseConsultationAttend();
        onCloseRecordAttend();
        onCloseUnRecordAttend();
        onCloseContacts();
        onCloseReminder();
        setLead(null);
    };

    const STEPS = [
        {
            label: 'Contact',
            component: <FollowUp ref={inputRef} handleNext={onSubmit} handleClose={handleClose} />
        }
    ];

    const [leadStep, setLeadStep] = useState(STEPS);

    useEffect(() => {
        if (!isEmpty(currentTreatmentDataGlobal)) {
            dispatch(setCurrentTreatmentDataGlobal(null));
        }
        if (action) {
            dispatch(setAction(null));
        }
    }, []);

    useEffect(() => {
        const assignees = assigneeDataFilter.length ? assigneeDataFilter.toString() : assignedUser ? userID : '';
        if (isDashboard && shouldRunTaskEffect?.current) {
            reloadTaskCounter({ userId: assignees, id: type === 'LeadDetail' ? leadId.id : '', additionalFilter: additionalTabState, CompletedTask: isTasksCompleted, unassignedUser, treatment: taskWidgetTreatmentValue });
        }
    }, [selectedDateRange]);

    useEffect(() => {
        if (type) {
            switch (type) {
                case 'dashboard':
                    setAdditionalTabState(dashboardTaskPageFilter);
                    break;
                case 'LeadDetail':
                    setAdditionalTabState(leadDetailTaskPageFilter);
                    break;
                case 'Tasks':
                    setAdditionalTabState(taskPageFilter);
                    break;
                default:
                    setAdditionalTabState('');
                    break;
            }
        }
    }, [type]);

    useEffect(() => () => cleanUp(), []);

    useEffect(() => {
        if (reset) {
            setCurrentTab('');
            dispatch(setLeadDetailTaskPageFilter('Today'));
            onAdditionalTab('', 'Today');
            setAdditionalTabState('Today');
            dispatch(taskCompletedUser(false));
            dispatch(taskUnassignedUser(false));
            dispatch(setAssigned(false));
            dispatch(getLeadReminderData(practiceId, 'LeadDetail', 10, 0, '', leadDetail.id, '', '', '', '', additionalTabState));
            setPage(0);
            setRowsPerPage(10);
        }
    }, [reset]);

    // useEffect(() => {
    //     if (!reminder) {
    //         setReminderList([]);
    //     }
    //     if (reminder && reminder?.results) {
    //         setReminderList(reminder?.results);
    //     }
    // }, [reminder]);
    useLayoutEffect(() => {
        if (!reminder) {
            setReminderList([]);
        }
        if (reminder && reminder?.results) {
            setReminderList(reminder?.results);
        }
    }, [reminder]);

    useEffect(() => {
        if (taskDetail) {
            setLeadData(taskDetail);
        }
    }, [taskDetail]);

    useEffect(() => {
        if (searchPractice && taskId) {
            dispatch(updatePracticeId(searchPractice));
            dispatch(getReminderData(searchPractice, taskId));
            setOpenSidebar(true);
        }
    }, [searchPractice, taskId]);

    useEffect(() => {
        if (leadData && leadData?.lead?.id) {
            dispatch(getLeadNotes(leadData?.lead?.id, practiceId));
        }
    }, [leadData]);

    useEffect(() => {
        if (isAssigned || !isAssigned) {
            setAssignedUser(isAssigned);
        }
    }, [isAssigned]);

    useEffect(() => {
        if (isTasksCompleted || !isTasksCompleted) {
            setTasksCompletedUser(isTasksCompleted);
        }
    }, [isTasksCompleted]);

    const cleanUp = () => {
        if (type === 'LeadDetail') {
            addTableData(10, 0, '', '');

        }
        if (type === 'dashboard') {
            setReminderList([]);
        }
    };

    const addTableData = (tableLimit, tableOffset, tableFilter, tableAssignUser) => {
        const data = type === 'Tasks' ? {
            limit,
            offset,
            filter,
            assignUser,
            tasks: {
                limit: tableLimit,
                offset: tableOffset,
                filter: tableFilter,
                assignUser: tableAssignUser
            },
        } : {
            limit: tableLimit,
            offset: tableOffset,
            filter: tableFilter,
            assignUser: tableAssignUser,
            tasks: {
                limit: taskLimit,
                offset: taskOffset,
                filter: taskFilter,
                assignUser: taskAssingUser
            },
        };
        dispatch(addTaskTableData(data));
    };

    const handleChangePage = (event, newPage) => {
        const assignees = assigneeDataFilter.length ? assigneeDataFilter.toString() : assignedUser ? userID : '';
        dispatch(getLeadReminderData(practiceId, type, rowsPerPage, rowsPerPage * newPage, currentTab === 'All' ? '' : currentTab, type === 'LeadDetail' ? leadId.id : '', assignees, '', '', '', additionalTabState, isTasksCompleted, false, selectedDateRange, unassignedUser, taskWidgetTreatmentValue));
        onChangePage(event, newPage);
        addTableData(rowsPerPage, rowsPerPage * newPage, currentTab === 'All' ? '' : currentTab, assignees);
    };

    const handleChangePerPage = (event) => {
        const assignees = assigneeDataFilter.length ? assigneeDataFilter.toString() : assignedUser ? userID : '';
        dispatch(getLeadReminderData(practiceId, type, event.target.value, 0, currentTab === 'All' ? '' : currentTab, type === 'LeadDetail' ? leadId.id : '', assignees, '', '', '', additionalTabState, isTasksCompleted, false, selectedDateRange, unassignedUser, taskWidgetTreatmentValue));
        onChangeRowsPerPage(event);
        addTableData(event.target.value, 0, currentTab === 'All' ? '' : currentTab, assignees);
    };

    const handleFilterTask = (event, newValue) => {
        const assignees = assigneeDataFilter.length ? assigneeDataFilter.toString() : assignedUser ? userID : '';
        dispatch(getLeadReminderData(practiceId, type, 10, 0, newValue === 'All' ? '' : newValue, type === 'LeadDetail' ? leadId.id : '', assignees, '', '', '', additionalTabState, isTasksCompleted, false, selectedDateRange, unassignedUser, taskWidgetTreatmentValue));
        onChangeTab(event, newValue);
        onChangeRowsPerPage({ target: { value: 10 } });
        addTableData(10, 0, newValue === 'All' ? '' : newValue, assignees);
        if (type === 'LeadDetail') {
            dispatch(addLeadTaskTab(newValue));
        }
    };

    const handleAttendFailedClick = (id, reminder, lead) => {
        localStorageRemoveItem('localTreatmentData');
        if (reminder && reminder.id && reminder?.lead_treatment?.lead) {
            setRowLead(setRowLeadData(reminder, leadData));
        }
        if (id && reminder) {
            leadDataHandler(reminder);
            dispatch(followUpSlice(id, practiceId));
            setTimeout(() => {
                dispatch(getAppointment(practiceId, id));
            }, 300);
        }
        else {
            dispatch(followUpSlice(lead || Lead, practiceId));
            setTimeout(() => {
                dispatch(getAppointment(practiceId, lead || Lead));
            }, 300);
        }
        dispatch(updateFollowUpLead('UnAttended'));
        setTimeout(() => {
            onOpenUnRecordAttend();
        }, 800);
    };

    const handleReminderPopup = (notes, data) => {
        if (type === 'LeadDetail') {
            setLead(leadDetail?.id);
        }
        if (data) {
            setLeadData(data);
        }
        setAddPopupType(notes);
        onOpenReminder();
    };

    const handleAttendClick = (lead, data) => {
        leadDataHandler(data.id);
        dispatch(updateFollowUpLead('Attended'));
        dispatch(followUpSlice((lead || Lead), practiceId));
        dispatch(setCurrentTreatmentDataGlobal({
            lead_id: leadDetail?.id || '',
            first_name: leadDetail?.first_name || '',
            last_name: leadDetail?.last_name || '',
            treatmentData: data?.lead_treatment
        }));
        setTimeout(() => {
            dispatch(getAppointment(practiceId, (lead || Lead)));
            onOpenRecordAttend(lead || Lead);
        }, 800);
    };

    const buttonHandleClose = (event) => {
        if (anchorEl.current && anchorEl.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        if (practiceUser && practiceUser.results) {
            const userData = practiceUser?.results.filter((el) => el?.user?.id === user?.pk);
            if (userData.length > 0) {
                setUserID(userData[0]?.user?.id);
            }
        }
    }, [practiceUser]);

    useEffect(() => {
        if (contactLead) {
            const STEPS = [
                {
                    label: 'Contact',
                    component: <FollowUp ref={inputRef} handleNext={onSubmit} handleClose={handleClose} />
                }
            ];
            setLeadStep(STEPS);
        } else {
            const STEPS = [
                {
                    label: 'Contact',
                    component: <Activities activityList={activityList.results} notAllow />
                }
            ];
            setLeadStep(STEPS);
        }
    }, [contactLead]);

    useEffect(() => {
        if (BookingAttendance) {
            const steps = [...STEPS];
            steps.push({
                label: isAppointmentBooked === 'Booking Details' ? 'Booking outcome' : isAppointmentBooked === 'Unable to contact' ? 'Unable to Contact' : 'Booking outcome',
                component: isAppointmentBooked === 'Booking Details'
                    ? <TreatmentAndAppointment ref={inputRef} type={'followUp'} handleNext={onSubmit} />
                    : isAppointmentBooked === 'Unable to contact' ?
                        <UnableToContact ref={inputRef} handleNext={onSubmit} completeFlow={handleClose} />
                        : <AppointmentPage ref={inputRef} handleNext={onSubmit} />
            });
            setLeadStep(steps);
            return;
        }
        setLeadStep(STEPS);
    }, [BookingAttendance]);

    useEffect(() => {
        if (attendance && isAppointmentBooked !== 'Appointment Not Booked') {
            const steps = [...STEPS];
            if (attendance === 'attendance') {
                steps.push({
                    label: 'Booking outcome',
                    component: isAppointmentBooked === 'Booking Details'
                        ? <TreatmentAndAppointment ref={inputRef} type={'followUp'} handleNext={onSubmit} />
                        : isAppointmentBooked === 'Unable to contact' ?
                            <UnableToContact ref={inputRef} handleNext={onSubmit} completeFlow={handleClose} />
                            : <AppointmentPage ref={inputRef} handleNext={onSubmit} />
                });
                steps.push({
                    label: 'Appointment Outcome',
                    component: <AttendedAppointment ref={inputRef} handleNext={onSubmit} isOverview />
                });
            } else {
                steps.push({
                    label: 'Booking outcome',
                    component: isAppointmentBooked === 'Booking Details'
                        ? <TreatmentAndAppointment ref={inputRef} type={'followUp'} handleNext={onSubmit} />
                        : isAppointmentBooked === 'Unable to contact' ?
                            <UnableToContact ref={inputRef} handleNext={onSubmit} completeFlow={handleClose} />
                            : <AppointmentPage ref={inputRef} handleNext={onSubmit} />
                });
                steps.push({
                    label: 'Patient Detail',
                    component: <PersonalDetails ref={inputRef} handleNext={onSubmit} />
                });
                steps.push({
                    label: 'Payment options',
                    component: <PaymentOption ref={inputRef} handleNext={onSubmit} />
                });
                steps.push({
                    label: 'Message review & reminders',
                    component: <MessageAndReminder ref={inputRef} handleNext={onSubmit} type={'CreatLead'} />
                });
            }
            setLeadStep(steps);
        } else {
            setLeadStep(STEPS);
        }
    }, [attendance]);

    useEffect(() => {

        if (markAsComplete && followUpLead === 'contact') {
            dispatch(updateLeadDetail(isAppointmentBooked, handleClose));
        }
        if (markAsComplete && followUpLead === 'consultation') {
            dispatch(ConsultationAttended(handleClose));
        }
        if (markAsComplete && followUpLead === 'Attended') {
            dispatch(RecordAttended(handleClose));
        }
        if (markAsComplete && followUpLead === 'UnAttended') {
            dispatch(recordUnAttended(handleClose));
        }
        if (markAsComplete && followUpLead === 'archivedLead') {
            dispatch(ArchivedLeadDashBoard(handleClose));
            onCloseArchiveLead()
        }

    }, [markAsComplete, followUpLead]);

    const handleReminder = (value) => {
        dispatch(updateTaskCounter([]));
        dispatch(updateSeparateTaskCounter([]));
        const assignees = assigneeDataFilter.length ? assigneeDataFilter.toString() : assignedUser ? userID : '';
        if (value === 'markComplete') {
            if (type === 'LeadDetail') {
                dispatch(updateLeadTaskCounter([]));
                dispatch(getLeadReminderData(practiceId, type, rowsPerPage, rowsPerPage * page, currentTab === 'All' ? '' : currentTab, type === 'LeadDetail' ? leadId.id : '', assignees, '', 'leadNotes', 'counter', additionalTabState, isTasksCompleted, false, selectedDateRange, unassignedUser, taskWidgetTreatmentValue));
                dispatch(leadTaskListCounter(practiceId, 'LeadDetail', leadDetail?.lead_treatments[0]?.status, leadDetail?.id));
                dispatch(leadTaskListCounter(practiceId, 'LeadDetail', 'TODO', leadDetail?.id));
                dispatch(leadTaskListCounter(practiceId, 'LeadDetail', '', leadDetail?.id));
                dispatch(followUpSlice(leadId.id, practiceId, 'LeadDetail'));
                return;
            }
            dispatch(getLeadReminderData(practiceId, type, rowsPerPage, rowsPerPage * page, currentTab === 'All' ? '' : currentTab, type === 'LeadDetail' ? leadId.id : '', assignees, '', 'leadNotes', '', additionalTabState, isTasksCompleted, false, selectedDateRange, unassignedUser, taskWidgetTreatmentValue));
            reloadTaskCounter({ userId: assignees, id: type === 'LeadDetail' ? leadId.id : '', additionalFilter: additionalTabState, CompletedTask: isTasksCompleted, unassignedUser, treatment: taskWidgetTreatmentValue });
            return;
        }
        if (type === 'LeadDetail') {
            dispatch(followUpSlice(leadId.id, practiceId, 'LeadDetail'));
            dispatch(getLeadNotes(leadId.id, practiceId, handleSuccess));
            dispatch(getLeadNotesWithFilter(leadId?.id, practiceId, handleSuccess));
            dispatch(getActivities(practiceId, leadId.id, 10, 0));
            dispatch(getLeadReminderData(practiceId, 'LeadDetail', 1, 0, '', leadDetail?.id, '', '', 'Graterthan', '', additionalTabState, isTasksCompleted, false, selectedDateRange, unassignedUser, taskWidgetTreatmentValue));
        }
        if (type === 'dashboard') {
            dispatch(getNewLead({ id: practiceId, limit: perPage, offset: pageNumber, filterStatus: updateStatus, order, orderId: orderValue, searchQuery: search, treatment: treatment?.toString(), startDate, endDate, contactedCount: null, tags: filterTags.toString(), referral: updateStatus }));
        }
        dispatch(updateLeadTaskCounter([]));
        dispatch(getLeadReminderData(practiceId, type, rowsPerPage, rowsPerPage * page, currentTab === 'All' ? '' : currentTab, type === 'LeadDetail' ? leadId.id : '', assignees, '', '', 'counter', additionalTabState, isTasksCompleted, false, selectedDateRange, unassignedUser, taskWidgetTreatmentValue));
        reloadTaskCounter({ userId: assignees, id: type === 'LeadDetail' ? leadId.id : '', additionalFilter: additionalTabState, CompletedTask: isTasksCompleted, unassignedUser, treatment: taskWidgetTreatmentValue });
        addTableData(rowsPerPage, rowsPerPage * page, currentTab === 'All' ? '' : currentTab, assignees);
    };

    const handleSuccess = (data) => {
        if (data.message === 'Task completed !') {
            setMarkComplete(false);
            const key = enqueueSnackbar(data.message || '', {
                variant: data.success ? 'success' : 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                autoHideDuration: 5000,
                action:
                    <>
                        <Button onClick={() => undoReminder()} variant='success' size="small" sx={{ color: '#00AB55', '&:hover': { bgcolor: 'rgba(84, 214, 44, 0.16)' } }}>
                            Undo
                        </Button>
                        <Iconify onClick={() => closeSnackbar(key)} icon={'radix-icons:cross-2'} height={15} width={15} sx={{ margin: '0px 8px', cursor: 'pointer' }} />
                    </>
            });
            return;
        }
        if (data.message === 'Task not completed !') {
            setMarkComplete(false);
            enqueueSnackbar(data.message || '', {
                variant: data.success ? 'success' : 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
            });
            return;
        }
        enqueueSnackbar(data.message || '', {
            variant: data.success ? 'success' : 'error',
            anchorOrigin: {
                vertical: 'top',
                horizontal: 'center'
            },
        });
    };

    const undoReminder = () => {
        setMarkComplete(false);
        const data = {
            is_completed: false
        };
        dispatch(updateReminder(data, reminderDataId.current, practiceId, handleReminder, 'markComplete'));
        closeSnackbar();
    };

    const handleDeleteReminder = (data) => {
        setDeleteData(data);
        setDeleteConfirm(true);
    };

    const deleteConfirmAPI = () => {
        dispatch(deleteReminderData(practiceId, deleteData?.id, handleSuccess, handleReminder));
        sidebarCloser();
    };

    const {
        toggle: openContacts,
        onOpen: onOpenContacts,
        onClose: onCloseContacts
    } = useToggle();

    const {
        toggle: leadDetailModal,
        onOpen: onLeadDetail,
        onClose: onCloseLeadDetail
    } = useToggle();

    const {
        toggle: openRecordAttend,
        onOpen: onOpenRecordAttend,
        onClose: onCloseRecordAttend
    } = useRecordAttendToggle();

    const {
        toggle: openUnRecordAttend,
        onOpen: onOpenUnRecordAttend,
        onClose: onCloseUnRecordAttend
    } = useUnRecordAttendToggle();

    const {
        toggle: openArchiveLead,
        onOpen: onOpenArchiveLead,
        onClose: onCloseArchiveLead
    } = useArchivedToggle();

    const {
        toggle: openReminder,
        onOpen: onOpenReminder,
        onClose: onCloseReminder
    } = useReminderToggle();

    const {
        toggle: openAssignTask,
        onOpen: onOpenAssignTask,
        onClose: onCloseAssignTask
    } = useAssignTaskToggle();

    const {
        toggle: openConsultationAttend,
        onOpen: onOpenConsultationAttend,
        onClose: onCloseConsultationAttend
    } = useConsultationAttendToggle();

    const {
        toggle: openAppointmentAssignTask,
        onOpen: onOpenAppointmentAssignTask,
        onClose: onCloseAppointmentAssignTask
    } = useAssignTaskToggle();

    const openAppointmentAssignTaskFuc = (value, assignees, event) => {
        setAnchorElAssign(anchorElAssign ? null : event.currentTarget);
        onOpenAppointmentAssignTask();
        setReminderID(value?.lead_id);
        setAssignees(assignees);
    };

    const onCloseAppointmentAssignTaskPopup = () => {
        onCloseAppointmentAssignTask();
    };

    const editDetailHandler = () => {
        onLeadDetail();
    };

    const closeEditDetails = () => {
        onCloseLeadDetail();
    };

    const handlePopup = (id, reminder, status) => {
        localStorageRemoveItem('localTreatmentData');
        if (status === 'NEW') {
            dispatch(getActivities(practiceId, id, 10, 0));
        }
        dispatch(setCurrentTreatmentDataGlobal(null));
        dispatch(updateFollowUpLead('contact'));
        leadDataHandler(reminder);
        setLead(id);
        dispatch(followUpSlice(id, practiceId));
        setTimeout(() => {
            const htmlElement = document.querySelector('body');
            htmlElement.classList.add('overflow-hidden');
            onOpenContacts();
        }, 800);
    };

    const handleAttendedPopup = async (id, reminder) => {
        localStorageRemoveItem('localTreatmentData');
        dispatch(updateFollowUpLead('consultation'));
        leadDataHandler(reminder);
        setLead(id);
        dispatch(followUpSlice(id, practiceId));
        dispatch(setCurrentTreatmentDataGlobal({
            lead_id: leadDetail?.id || '',
            first_name: leadDetail?.first_name || '',
            last_name: leadDetail?.last_name || '',
            treatmentData: leadData?.lead_treatment
        }));
        setTimeout(() => {
            dispatch(getAppointment(practiceId, id));
            onOpenConsultationAttend(id);
        }, 800);
    };

    const leadDataHandler = (id) => {
        reminderDataId.current = id;
        const leadData = reminderList?.filter((el) => el.id === id);
        setRowLead({
            lead_id: leadData[0]?.lead_treatment?.lead || '',
            treatment_id: leadData[0]?.lead_treatment?.id || '',
            price: leadData[0]?.lead_treatment?.price || '',
            first_name: leadData[0]?.lead?.first_name || '',
            last_name: leadData[0]?.lead?.last_name || '',
            status: leadData[0]?.lead_treatment?.status || '',
            treatment_name: leadData[0]?.lead_treatment?.treatment || '',
            created_at_human: leadData[0]?.lead?.created_at_human,
            lead_treatments__lead_contacted_counts__count: leadData[0]?.lead_treatment?.lead_contacted_counts?.find(item => item.status === leadData[0]?.lead_treatment?.status)?.count || 0,
            clinCheckData: leadData[0]?.lead_treatment?.leadtreatment_clincheck,
            leadTreatmentSpecification: leadData[0]?.lead_treatment?.leadtreatment_specification,
            treatmentData: leadData[0]
        });
    };

    useEffect(() => {
        if (rowLead) {
            dispatch(tableLead(rowLead));
        }
    }, [rowLead]);

    const handleLeadData = (id, event, lead) => {
        leadDataHandler(id);
        setLead(lead);
        setAnchorEl(event.currentTarget);
        setOpen(!open);
    };

    const switchHandler = (value) => {
        dispatch(taskUnassignedUser(false));
        dispatch(setAssigned(value));
        dispatch(getLeadReminderData(practiceId, type, 10, 0, currentTab === 'All' ? '' : currentTab, type === 'LeadDetail' ? leadId.id : '', value ? userID : '', '', '', '', additionalTabState, isTasksCompleted, false, selectedDateRange, false, taskWidgetTreatmentValue));
        addTableData(10, 0, currentTab === 'All' ? '' : currentTab, value ? userID : '');
        onChangeRowsPerPage({ target: { value: 10 } });

        if (type === 'LeadDetail') {
            dispatch(updateLeadTaskCounter([]));
        }
        else if (type === 'dashboard') {
            dispatch(updateTaskCounter([]));
        }
        else if (type === 'Tasks') {
            dispatch(updateSeparateTaskCounter([]));
        }

        reloadTaskCounter({ userId: value ? userID : '', id: type === 'LeadDetail' ? leadId.id : '', additionalFilter: additionalTabState, CompletedTask: isTasksCompleted, unassignedUser: false, treatment: taskWidgetTreatmentValue });
    };

    const switchCompletedTasksHandler = (value) => {
        const assignees = assigneeDataFilter.length ? assigneeDataFilter.toString() : assignedUser ? userID : '';

        dispatch(taskCompletedUser(value));
        dispatch(getLeadReminderData(practiceId, type, 10, 0, currentTab === 'All' ? '' : currentTab, type === 'LeadDetail' ? leadId.id : '', assignees, '', '', '', additionalTabState, value, false, selectedDateRange, unassignedUser, taskWidgetTreatmentValue));
        addTableData(10, 0, currentTab === 'All' ? '' : currentTab, assignees);
        onChangeRowsPerPage({ target: { value: 10 } });

        if (type === 'LeadDetail') {
            dispatch(updateLeadTaskCounter([]));
        }
        else if (type === 'dashboard') {
            dispatch(updateTaskCounter([]));
        }
        else if (type === 'Tasks') {
            dispatch(updateSeparateTaskCounter([]));
        }
        reloadTaskCounter({ userId: assignees, id: type === 'LeadDetail' ? leadId.id : '', additionalFilter: additionalTabState, CompletedTask: value, unassignedUser, treatment: taskWidgetTreatmentValue });
    };

    const switchUnassignedTasksHandler = (value) => {
        dispatch(setAssigned(false));
        dispatch(taskUnassignedUser(value));
        dispatch(getLeadReminderData(practiceId, type, 10, 0, currentTab === 'All' ? '' : currentTab, type === 'LeadDetail' ? leadId.id : '', '', '', '', '', additionalTabState, isTasksCompleted, false, selectedDateRange, value, taskWidgetTreatmentValue));
        addTableData(10, 0, currentTab === 'All' ? '' : currentTab, '');
        onChangeRowsPerPage({ target: { value: 10 } });

        if (type === 'LeadDetail') {
            dispatch(updateLeadTaskCounter([]));
        }
        else if (type === 'dashboard') {
            dispatch(updateTaskCounter([]));
        }
        else if (type === 'Tasks') {
            dispatch(updateSeparateTaskCounter([]));
        }
        reloadTaskCounter({ userId: '', id: type === 'LeadDetail' ? leadId.id : '', additionalFilter: additionalTabState, CompletedTask: isTasksCompleted, unassignedUser: value, treatment: taskWidgetTreatmentValue });
    };

    const switchHandleAllTasks = () => {
        dispatch(setAssigned(false));
        dispatch(taskUnassignedUser(false));
        dispatch(getLeadReminderData(practiceId, type, 10, 0, currentTab === 'All' ? '' : currentTab, type === 'LeadDetail' ? leadId.id : '', '', '', '', '', additionalTabState, isTasksCompleted, false, selectedDateRange, false, taskWidgetTreatmentValue));
        addTableData(10, 0, currentTab === 'All' ? '' : currentTab, '');  // No userID for 'All'
        onChangeRowsPerPage({ target: { value: 10 } });

        if (type === 'LeadDetail') {
            dispatch(updateLeadTaskCounter([]));
        }
        else if (type === 'dashboard') {
            dispatch(updateTaskCounter([]));
        }
        else if (type === 'Tasks') {
            dispatch(updateSeparateTaskCounter([]));
        }
        reloadTaskCounter({ userId: '', id: type === 'LeadDetail' ? leadId.id : '', additionalFilter: additionalTabState, CompletedTask: isTasksCompleted, unassignedUser: false, treatment: taskWidgetTreatmentValue });
    };

    const allPopupCloseHandler = (value) => {
        dispatch(setLoader(false));
        const assignees = assigneeDataFilter.length ? assigneeDataFilter.toString() : assignedUser ? userID : '';
        switch (value) {
            case 'Reminder':
                dispatch(updateTaskCounter([]));
                dispatch(addLeadTaskTab(''));
                onCloseReminder();
                if (type === 'LeadDetail') {
                    onChangeTab('', 'All');
                    dispatch(setAssigned(false));
                    dispatch(getLeadReminderData(practiceId, 'LeadDetail', 10, 0, '', leadId.id, assignees, '', 'leadNotes', 'counter', additionalTabState, isTasksCompleted, '', '', '', taskWidgetTreatmentValue));
                    dispatch(leadTaskListCounter(practiceId, 'LeadDetail', leadDetail?.lead_treatments[0]?.status, leadId.id, assignees, additionalTabState));
                    dispatch(leadTaskListCounter(practiceId, 'LeadDetail', 'TODO', leadId.id, assignees, additionalTabState));
                    dispatch(leadTaskListCounter(practiceId, 'LeadDetail', '', leadId.id, assignees, additionalTabState));
                    setLeadData({});
                    return;
                }
                dispatch(updateTaskCounter([]));
                dispatch(getLeadReminderData(practiceId, type, rowsPerPage, rowsPerPage * page, currentTab === 'All' ? '' : currentTab, type === 'LeadDetail' ? leadId.id : '', assignees, '', 'leadNotes', 'counter', additionalTabState, isTasksCompleted, false, selectedDateRange, unassignedUser, taskWidgetTreatmentValue));
                reloadTaskCounter({ userId: assignees, additionalFilter: additionalTabState, CompletedTask: isTasksCompleted, unassignedUser, treatment: taskWidgetTreatmentValue });
                return;
            case 'Attended':
                onCloseRecordAttend();
                setAnchorElAssign(null);
                break;
            case 'Dialog':
                dispatch(clearLead());
                dispatch(setCurrentTreatmentDataGlobal(null));
                dispatch(updateSteps(null));
                onCloseContacts();
                break;
            case 'UnAttended':
                onCloseUnRecordAttend();
                break;
            case 'Consultation':
                onCloseConsultationAttend();
                setAnchorElAssign(null);
                break;
            case 'Assigned':
                onCloseAssignTask();
                setAnchorElAssign(null);
                break;
            default:
                break;
        }
        dispatch(getLeadReminderData(practiceId, type, rowsPerPage, rowsPerPage * page, currentTab === 'All' ? '' : currentTab, type === 'LeadDetail' ? leadId.id : '', assignees, '', '', '', additionalTabState, isTasksCompleted, false, selectedDateRange, unassignedUser, taskWidgetTreatmentValue));
        addTableData(rowsPerPage, rowsPerPage * page, currentTab === 'All' ? '' : currentTab, assignees);
    };

    const getReminderBtn = (status, id, reminder, leadTreatment, archive) => {
        if (archive) {
            return <Button
                variant="contained"
                color='primary'
                disabled
                sx={{ mb: '4px' }}
            >
                Archived
            </Button>;
        }
        if (!leadTreatment) {
            return '';
        }
        if (status === 'CLOSED' || status === 'TREATMENT_STARTED') {
            if (type === 'LeadDetail' && status === 'CLOSED') {
                return '';
            }
            return <Button
                variant="contained"
                color='primary'
                onClick={() => viewDetailBtn(id, status, reminder)}
                sx={taskButton}
            >
                {status === 'CLOSED' ? 'View details' : 'Complete treatment'}
            </Button>;
        }
        if (status === 'CONSULTATION_ATTENDED') {
            return <Button
                variant="contained"
                color='primary'
                startIcon={<Iconify icon={'fluent:call-32-filled'} height={15} width={15} />}
                onClick={() => handleAttendedPopup(id, reminder)}
                sx={taskButton}
            >
                Follow Up
            </Button>;
        }
        if (status === 'FAILED_TO_ATTEND_CONSULTATION') {
            return <Button
                variant="contained"
                color='primary'
                startIcon={<Iconify icon={'fluent:call-32-filled'} height={15} width={15} />}
                onClick={() => handleAttendFailedClick(id, reminder)}
                sx={taskButton}
            >
                Follow Up
            </Button>;
        }
        return <Button
            variant="contained"
            color='primary'
            startIcon={<Iconify icon={'fluent:call-32-filled'} height={15} width={15} />}
            onClick={() => handlePopup(id, reminder, status)}
            sx={taskButton}
        >
            Follow Up
        </Button>;
    };

    const viewDetailBtn = (id, status, reminder) => {
        localStorageRemoveItem('localTreatmentData');
        if (status === 'CLOSED') {
            navigate(`/dashboard/practice/${practiceId}/lead-detail/${id}`);
            return;
        }
        if (status === 'TREATMENT_STARTED') {
            setConfirmation(true);
            reminderDataId.current = reminder;
        }
    };

    const completeTreatmentAPI = () => {
        const reminderId = reminderList.filter((el) => el.id === reminderDataId.current);
        const data = {
            treatment: reminderId[0]?.lead_treatment?.treatment || leadData?.lead_treatment?.treatment || '',
            custom_price: reminderId[0]?.lead_treatment?.price || leadData?.lead_treatment?.price || ''
        };
        dispatch(setLoader(true));
        dispatch(CompleteTreatment(data, reminderId[0]?.lead_treatment?.lead || leadData?.lead_treatment?.lead, reminderId[0]?.lead_treatment?.id || leadData?.lead_treatment?.id, practiceId, handleClose));
    };

    const assignTaskHandler = (value, event) => {
        setAnchorElAssign(anchorElAssign ? null : event.currentTarget);
        setOpenSidebar(false);
        setReminderID(value);
        onOpenAssignTask();
        setAssigneeData(value?.assignees?.filter((el) => el?.user?.first_name && !el?.user?.is_superuser) || []);
    };

    const markCompleteBtn = (value, markTasks) => {
        reminderDataId.current = value;
        setMarkComplete(value);
        const data = {
            // eslint-disable-next-line no-unneeded-ternary
            is_completed: markTasks ? false : true,
        };
        dispatch(updateReminder(data, value, practiceId, handleReminder, 'markComplete', handleSuccess, markTasks && 'isCompleted'));
    };

    const tabCounter = (data) => {
        if (type === 'LeadDetail') {
            return taskLeadCounter?.filter(value => value.filter === data ? value.count : 0)[0]?.count;
        }
        if (type === 'dashboard') {
            return taskCounter?.filter(value => value.filter === data ? value.count : 0)[0]?.count;
        }
        if (type === 'Tasks') {
            return separateTaskCounter?.filter(value => value.filter === data ? value.count : 0)[0]?.count;
        }
        return 0;
    };

    const filterCount = (data) => {
        const response = tabCounter(data.value);

        return <Box sx={filterCountBoxStyle}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Iconify icon={data?.icon} width={22} height={22} sx={{ mr: 1, color: 'common.grey' }} /> {data?.label}
            </Box>
            <Label sx={{ m: '5px 8px', color: isLight ? 'common.grey' : 'common.white', background: isLight ? `${common.boxShadowColor}` : 'rgba(145, 158, 171, 0.16)' }}> {response && response || 0} </Label>
        </Box>;
    };

    const additionalFilterHandler = (e, value) => {
        onAdditionalTab(e, value);
        setAdditionalTabState(value);
        onChangePage(e, 0);
        const assignees = assigneeDataFilter.length ? assigneeDataFilter.toString() : assignedUser ? userID : '';
        dispatch(getLeadReminderData(practiceId, type, rowsPerPage, 0, currentTab === 'All' ? '' : currentTab, type === 'LeadDetail' ? leadId.id : '', assignees, '', '', '', value, isTasksCompleted, false, selectedDateRange, unassignedUser, taskWidgetTreatmentValue));

        if (type === 'dashboard') {
            dispatch(setDashboardTaskPageFilter(value));
        }
        if (type === 'LeadDetail') {
            dispatch(setLeadDetailTaskPageFilter(value));
        }
        if (type === 'Tasks') {
            dispatch(setTaskPageFilter(value));
        }
    };

    const sidebarHandler = (data) => {
        setLeadData(data);
        setOpenSidebar(true);
        const tabQuery = tab?.length > 0 ? `&tab=${tab}` : '';
        navigate(`?practiceId=${practiceId}&taskId=${data.id}${tabQuery}`, { state: null });
    };

    const sidebarCloser = () => {
        setDeleteConfirm(false);
        setOpenSidebar(false);
        const tabQuery = tab?.length > 0 ? `?tab=${tab}` : '';
        navigate(`${pathname}${tabQuery}`, { replace: true });
        setDeleteData(null);
        setLeadData(null);
    };

    const handleArchivedClose = () => {
        onCloseArchiveLead();
        dispatch(updateFollowUpLead('contact'));
    };

    const { toggle: openNotePopup, onOpen: onOpenNotePopup, onClose: onCloseNotePopup } = useSwitchUserToggle();

    const handleCloseNotePopup = () => {
        onCloseNotePopup();
        setLeadData({});
    };

    const goToViewProfile = (event, data) => {
        setAnchorElAssign(anchorElAssign ? null : event.currentTarget);
        setOpenSidebar(false);
        if (!data?.lead?.is_archived) {
            navigate(`/dashboard/practice/${practiceId}/lead-detail/${data?.lead?.id}`);
        }
    };

    const handleDropdownChange = (event) => {
        const values = event.target.value;
        setSelectedAssigneeFilter([]);
        setValue('multiSelectAssignee', []);
        if (values === 'My tasks') {
            setSelectedValue('My tasks');
            switchHandler(true);
        } else if (values === 'Unassigned tasks') {
            setSelectedValue('Unassigned tasks');
            switchUnassignedTasksHandler(true);
        } else {
            setSelectedValue('All');
            switchHandleAllTasks();
        }
    };

    const onClickAwayEvent = () => {
        const selectMenu = document.querySelector('.MuiModal-root');
        if (selectMenu) {
            return;
        }
        onCloseAssignTask();
        setAnchorElAssign(null);
        dispatch(clearPracticeUserData());
    };

    const appointmentClickAwayEvent = () => {
        const selectMenu = document.querySelector('.MuiModal-root');
        if (selectMenu) {
            return;
        }
        onCloseAppointmentAssignTaskPopup();
        setAnchorElAssign(null);
        dispatch(clearPracticeUserData());
    };

    const handleAssigneeFilter = (e, value) => {

        if (value) {
            const ids = [];
            value.forEach((data) => ids.push(data.id));
            setValue('multiSelectAssignee', ids);
            setSelectedAssigneeFilter(value);
            setSelectedValue('All');
            dispatch(setAssigned(false));
            dispatch(taskUnassignedUser(false));
            dispatch(getLeadReminderData(practiceId, type, 10, 0, currentTab === 'All' ? '' : currentTab, type === 'LeadDetail' ? leadId.id : '', ids?.toString() || '', '', '', '', additionalTabState, isTasksCompleted, false, selectedDateRange, unassignedUser, taskWidgetTreatmentValue));
            addTableData(10, 0, currentTab === 'All' ? '' : currentTab, ids?.toString() || '');
            onChangeRowsPerPage({ target: { value: 10 } });

            if (type === 'LeadDetail') {
                dispatch(updateLeadTaskCounter([]));
            }
            else if (type === 'dashboard') {
                dispatch(updateTaskCounter([]));
            }
            else if (type === 'Tasks') {
                dispatch(updateSeparateTaskCounter([]));
            }
            reloadTaskCounter({ userId: ids?.toString(), id: type === 'LeadDetail' ? leadId.id : '', additionalFilter: additionalTabState, CompletedTask: isTasksCompleted, unassignedUser, treatment: taskWidgetTreatmentValue });
        }
    }

    const handleTaskWidgetClick = (treatment) => {
        setTaskWidgetTreatmentValue(treatment)
        const assignees = assigneeDataFilter.length ? assigneeDataFilter.toString() : assignedUser ? userID : '';
        dispatch(getLeadReminderData(practiceId, type, 10, 0, currentTab === 'All' ? '' : currentTab, type === 'LeadDetail' ? leadId.id : '', assignees, '', '', '', additionalTabState, isTasksCompleted, false, selectedDateRange, unassignedUser, treatment));
        // addTableData(10, 0, currentTab === 'All' ? '' : currentTab, assignees);
        onChangeRowsPerPage({ target: { value: 10 } });

        if (type === 'LeadDetail') {
            dispatch(updateLeadTaskCounter([]));
        }
        else if (type === 'dashboard') {
            dispatch(updateTaskCounter([]));
        }
        else if (type === 'Tasks') {
            dispatch(updateSeparateTaskCounter([]));
        }
        reloadTaskCounter({ userId: assignees, id: type === 'LeadDetail' ? leadId.id : '', additionalFilter: additionalTabState, CompletedTask: isTasksCompleted, unassignedUser, treatment });
    }

    return (
        <>
            <Card sx={taskCard(type)}>
                <Box sx={taskTopHeaderBoxStyle(type)}>
                    {type === 'dashboard' && <Typography variant='h5' sx={typographyUi}>
                        Tasks
                    </Typography>}
                    {type !== 'dashboard' && <TaskReportWidget onWidgetClick={handleTaskWidgetClick} />}
                    <ClickAwayListener onClickAway={() => { }}>
                        <Box>
                            <Box sx={filterOutBox}>
                                <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                    <Box sx={{ display: 'flex', justifyContent: type === 'Tasks' ? 'flex-end' : 'flex-end', mr: 3 }}>
                                        <MultiAssigneesDropdown
                                            name={'multiSelectAssignee'}
                                            label='Assignee users'
                                            sx={{ ...treatmentBoxStyle, maxWidth: "500px" }}
                                            onChange={handleAssigneeFilter}
                                            value={selectedAssigneeFilter}
                                            type={type}
                                        />
                                    </Box>
                                </FormProvider>
                                <Box sx={{ display: 'flex', justifyContent: type === 'Tasks' ? 'flex-end' : 'space-between', mr: 3 }}>
                                    <FormControl variant="outlined" sx={{ minWidth: 160 }}>
                                        <InputLabel>Assignees</InputLabel>
                                        <Select
                                            value={selectedValue}
                                            onChange={handleDropdownChange}
                                            label="Assignees"
                                        >
                                            <MenuItem value="All">All</MenuItem>
                                            <MenuItem value="My tasks">My tasks</MenuItem>
                                            <MenuItem value="Unassigned tasks">Unassigned tasks</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Box sx={{ display: 'flex', justifyContent: type === 'Tasks' ? 'flex-end' : 'space-between' }}>
                                    <Box sx={{ fontSize: '14px !important' }}>
                                        Completed tasks
                                        <Switch
                                            checked={Boolean(tasksCompletedUser)}
                                            onClick={(e) => switchCompletedTasksHandler(e.target.checked)}
                                            color="primary"
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </ClickAwayListener>
                </Box>
                <Box sx={{ display: 'flex', width: '100%', mt: 2 }}>
                    <Box sx={{ width: '244px', bgcolor: 'common.white', borderRadius: 2 }}>
                        <Box sx={{ my: '16px', px: '12px' }}>
                            <FormControl sx={{ width: '100%' }}>
                                <RadioGroup
                                    aria-labelledby="demo-radio-buttons-group-label"
                                    name="radio-buttons-group"
                                    value={currentTab}
                                    onChange={(event, newValue) => handleFilterTask(event, newValue)}
                                    sx={{ width: '100%', gap: 1 }}
                                >
                                    {TASK_TAB_HEAD.map((item, index) => (
                                        <FormControlLabel key={index} value={item.value} control={<Radio />} label={filterCount(item)} sx={formControlLabelStyle} />
                                    ))}
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Box>
                    <Box sx={{ width: 'calc(100% - 244px)' }}>
                        <Box sx={{ ml: '0' }} >
                            <Tabs
                                allowScrollButtonsMobile
                                variant="scrollable"
                                scrollButtons="auto"
                                value={additionalTabState || additionalTab || 'Today'}
                                onChange={additionalFilterHandler}
                                sx={{ px: 3, bgcolor: 'background.neutral' }}
                            >
                                {TASK_ADDITIONAL_FILTER.map((item) => (
                                    <Tab
                                        sx={tabHeadStyle}
                                        disableRipple
                                        key={item.value}
                                        label={item?.label}
                                        value={item.value} />
                                ))}
                            </Tabs>
                        </Box>
                        <Box sx={{ position: 'relative', m: '16px 16px 0', borderRadius: 2 }}>
                            <NormalLoading tableData={(!isEmpty(loadingApis) || isLoadingFinish) && 'No Found'} pageCount={(!isEmpty(loadingApis) || isLoadingFinish) && 'No Found'} type={'tasks'} />
                            <Box sx={taskList(type)}>
                                {reminderList && reminderList?.map((data) => {
                                    const assigneeData = data?.assignees?.filter((el) => el?.user?.first_name && !el?.user?.is_superuser);
                                    const { icon, iconColor } = getIconAndColor(data.priority);
                                    return <Paper
                                        key={data.id}
                                        sx={taskListCardStyle(data?.lead?.is_archived, data?.lead?.is_archive_pending)}
                                    >
                                        <Box sx={{ minWidth: '48px', p: '16px 0 16px 8px' }}>
                                            <Tooltip title={data.id === markComplete || tasksCompletedUser ? 'Mark as incomplete' : 'Mark as complete'} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                                                <Radio
                                                    checked={data.id === markComplete || tasksCompletedUser}
                                                    onClick={() => markCompleteBtn(data.id, tasksCompletedUser)}
                                                    name='radioButtons'
                                                    sx={{ p: 1, '.MuiSvgIcon-root': { width: '24px', height: '24px' } }}
                                                />
                                            </Tooltip>
                                        </Box>
                                        <Paper onClick={() => sidebarHandler(data)} sx={leadListCard(isLight, data?.lead?.is_archived, data?.is_system_generated, data?.lead?.is_archive_pending)}>
                                            <Box sx={{ display: 'none', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                                <Box sx={leadListBox(getStatusColour, data)}>
                                                    {data?.lead_treatment?.status !== 'CLOSED' ? (data?.lead_treatment?.status.charAt(0) + data?.lead_treatment?.status.toLowerCase().replace(/_/g, ' ').slice(1) || '') : 'Completed'}
                                                </Box>
                                            </Box>
                                            <Box sx={taskMessage}>
                                                <Box>
                                                    <Box sx={{ p: '0 24px 0 0', mb: 1 }}>
                                                        {data.title !== '' ? <Box sx={{ fontSize: '16px', lineHeight: '19px' }} ><b>{capitalizeFirstLetter(data?.title)}</b></Box> : getMessage(data?.lead_treatment?.status, data?.lead?.first_name, data?.lead?.last_name, data?.lead_treatment?.treatment, data?.group, data?.method)
                                                        }
                                                    </Box>
                                                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '4px' }}>
                                                        <Box sx={taskItemDetailBoxStyle} >
                                                            <Box sx={leadTaskPopover(data)}>
                                                                <Iconify icon={'charm:clock-alarm'} width={16} height={16} sx={{ mr: '2px' }} />
                                                                {data.datetime_human && data.datetime_human || ''}
                                                            </Box>
                                                        </Box>
                                                        <Box sx={taskItemDetailBoxStyle} onClick={(e) => e.stopPropagation()}>
                                                            <Box sx={leadTaskNameStyle} onClick={() => (type !== 'LeadDetail' && !data?.lead?.is_archived) && navigate(`/dashboard/practice/${practiceId}/lead-detail/${data?.lead?.id}`)}>
                                                                <Iconify icon={'mdi:user'} width={16} height={16} sx={{ mr: '4px', color: 'common.greyTransparent' }} />
                                                                {capitalizeFirstLetter(data?.lead?.first_name || '')} {capitalizeFirstLetter(data?.lead?.last_name || '')}
                                                            </Box>
                                                        </Box>
                                                        <Box sx={taskItemDetailBoxStyle} onClick={(e) => e.stopPropagation()}>
                                                            <Box sx={leadTaskNameStyle} onClick={() => (type !== 'LeadDetail' && !data?.lead?.is_archived) && navigate(`/dashboard/practice/${practiceId}/lead-detail/${data?.lead?.id}`)}>
                                                                <Iconify icon={'mdi:tooth'} width={16} height={16} sx={{ mr: '4px', color: 'common.greyTransparent' }} />
                                                                {data?.lead_treatment?.treatment || ''}
                                                            </Box>
                                                        </Box>
                                                        <Box sx={taskItemDetailBoxStyle}>
                                                            <Box sx={leadTaskNameStyle}>
                                                                <Iconify icon={'mdi:user-plus'} width={16} height={16} sx={{ mr: '4px', color: 'common.greyTransparent' }} />
                                                                {data?.lead_treatment?.status !== 'CLOSED' ? (data?.lead_treatment?.status.charAt(0) + data?.lead_treatment?.status.toLowerCase().replace(/_/g, ' ').slice(1) || '') : 'Completed'}
                                                            </Box>
                                                        </Box>
                                                        <Box sx={taskItemDetailBoxStyle}>
                                                            <Box sx={leadTaskNameStyle}>
                                                                <Iconify icon={'solar:chat-round-dots-bold'} width={16} height={16} sx={{ mr: '2px', color: 'common.greyTransparent' }} />
                                                                {data?.lead_treatment?.lead_contacted_counts?.find(item => item.status === data?.lead_treatment?.status)?.count || 0}
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box sx={{ display: 'none' }}>
                                                    <Tooltip title={`${capitalizeFirstLetter(data.priority)} priority`}>
                                                        <Iconify width={24} height={24} icon={icon} sx={{ color: iconColor }} />
                                                    </Tooltip>
                                                </Box>
                                                <Box sx={{ display: 'flex', gap: 2 }} onClick={(e) => e.stopPropagation()}>
                                                    {assigneeData?.length > 0 ?
                                                        <Box
                                                            sx={taskUserAvatar}
                                                            onClick={(e) => e.stopPropagation()}
                                                        >
                                                            <Tooltip title={getAssignees(data)} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                                                                <Box sx={{ display: 'flex' }}>
                                                                    {assigneeData?.slice(0, 2)?.map((assignee, index) => (
                                                                        <AssignUserAvatar
                                                                            key={index}
                                                                            sx={assignUserAvatarStyle}
                                                                            src={assignee.user?.avatar || ''}
                                                                            name={`${assignee.user?.first_name || ''} ${assignee.user?.last_name || ''}`}
                                                                            role={assignee.role}
                                                                            onClick={(e) => assignTaskHandler(data, e)}
                                                                        />
                                                                    ))}
                                                                    {assigneeData?.length > 2 && (
                                                                        <Box sx={assignLengthStyle} onClick={(e) => assignTaskHandler(data, e)}>
                                                                            + {assigneeData?.length - 2}
                                                                        </Box>
                                                                    )}
                                                                </Box>
                                                            </Tooltip>
                                                        </Box> :
                                                        <Box
                                                            sx={openTask}
                                                            onClick={(e) => e.stopPropagation()}
                                                        >
                                                            <Box
                                                                onClick={(e) => assignTaskHandler(data, e)}
                                                                sx={openTaskPop}>
                                                                <Iconify
                                                                    width={16}
                                                                    height={16}
                                                                    icon={'eva:plus-fill'}
                                                                />
                                                            </Box>
                                                            <Typography
                                                                component={'p'}
                                                                onClick={(e) => assignTaskHandler(data, e)}
                                                                sx={openTaskText}
                                                            >
                                                                Unassigned
                                                            </Typography>
                                                        </Box>
                                                    }
                                                    {type !== 'LeadDetail' && <Tooltip title="View profile">
                                                        <Box
                                                            sx={viewProfileBoxStyle}
                                                            onClick={(event) => goToViewProfile(event, data)}
                                                        >
                                                            <Iconify
                                                                style={profileLabel}
                                                                width={22}
                                                                height={22}
                                                                icon={'mdi:user-outline'}
                                                            />
                                                        </Box>
                                                    </Tooltip>}
                                                </Box>
                                            </Box>
                                            <Box sx={{ ...taskUser, alignItems: 'flex-start', p: 0 }}>
                                                <Box sx={{ display: 'none' }}>
                                                    {data?.lead_treatment?.status === 'CONSULTATION_BOOKED' && !data?.lead?.is_archived ?
                                                        <Box onClick={(e) => e.stopPropagation()} sx={{ mb: '4px' }}>
                                                            <ButtonGroup
                                                                onClick={(e) => handleLeadData(data.id, e, data?.lead_treatment?.lead)}
                                                                variant="contained"
                                                                aria-label="split button"
                                                                sx={taskConsultBtn}
                                                            >
                                                                <Button
                                                                    sx={recordBtn}
                                                                >
                                                                    Record Attendance
                                                                </Button>
                                                                <Button
                                                                    size="small"
                                                                    aria-controls={open ? 'split-button-menu' : undefined}
                                                                    aria-expanded={open ? 'true' : undefined}
                                                                    aria-label="select merge strategy"
                                                                    aria-haspopup="menu"
                                                                    sx={{
                                                                        backgroundColor: `${common.blue} !important`,
                                                                        padding: '0 15px 0 0 !important',
                                                                        minWidth: '10px !important'
                                                                    }}
                                                                >
                                                                    <Iconify
                                                                        style={{ color: 'common.white' }}
                                                                        width={10}
                                                                        height={10}
                                                                        icon={'bxs:right-arrow'}
                                                                    />
                                                                </Button>
                                                            </ButtonGroup>
                                                        </Box>
                                                        : <Box sx={{ display: 'flex', alignItems: 'center' }} onClick={(e) => e.stopPropagation()} >
                                                            {data?.lead_treatment && getReminderBtn(data?.lead_treatment?.status, data?.lead_treatment?.lead, data.id, data?.lead_treatment, data?.lead?.is_archived)}
                                                        </Box>
                                                    }
                                                </Box>
                                                <Box onClick={(e) => e.stopPropagation()} sx={{ display: 'flex', gap: 2 }}>
                                                    <Box sx={{ display: 'none' }}>
                                                        {data?.method === 'CALL' ?
                                                            <Button variant="contained" onClick={() => { setTemplateOpen(false); setLeadData(data); setOptions('phoneNumber'); onOpenNotePopup(); }} sx={methodButtonStyle}><Iconify width={24} height={24} icon={'fluent:call-12-regular'} sx={{ mr: 1 }} />Call</Button>
                                                            : data?.method === 'EMAIL' ? <Button variant="contained" onClick={() => { setLeadData(data); setTemplateOpen(true); }} sx={methodButtonStyle}><Iconify width={24} height={24} icon={'mdi:email-outline'} sx={{ mr: 1 }} />Email</Button> : data?.method === 'WHATSAPP' ? <Button variant="contained" onClick={() => { setTemplateOpen(false); setLeadData(data); setOptions('whatsapp'); onOpenNotePopup(); }} sx={methodButtonStyle}><Iconify width={24} height={24} icon={'mdi:whatsapp'} sx={{ mr: 1 }} />WhatsApp</Button> : null
                                                        }
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Paper>
                                    </Paper>;
                                })}
                                {(reminderList.length === 0 && reminder?.count !== undefined) && <NotFound />}
                            </Box>
                        </Box>
                        <Box sx={{ position: 'relative', pr: 2 }}>
                            {reminder?.count > 10 &&
                                <TablePagination
                                    rowsPerPageOptions={[10, 25]}
                                    component="div"
                                    rowsPerPage={rowsPerPage}
                                    count={reminder?.count || 0}
                                    page={page}
                                    onPageChange={(event, newPage) => handleChangePage(event, newPage)}
                                    onRowsPerPageChange={(event) => handleChangePerPage(event)}
                                    nextIconButtonProps={page === Math.ceil(reminder?.count / rowsPerPage) ? { disabled: true } : undefined}
                                />
                            }
                        </Box>
                        {addTaskHide && <Box onClick={() => handleReminderPopup('addTask')} sx={addTaskHideBtn(isLight, theme)}>
                            <Box sx={addTaskPop}>
                                <Iconify
                                    icon={'ic:baseline-plus'}
                                    width={24}
                                    height={24}
                                    sx={{ fontWeight: '700', marginRight: 1 }}
                                />
                                <Typography variant='h6' sx={{ fontSize: '15px !important' }} >Add a task</Typography>
                            </Box>
                        </Box>}
                    </Box>
                </Box>
                <Popper
                    sx={{
                        zIndex: 1,
                        margin: '0px 20px !important',
                    }}
                    open={open}
                    anchorEl={anchorEl}
                    role={undefined}
                    transition
                    placement={isDesktop ? 'left-start' : 'right-start'}
                >
                    {({ TransitionProps }) => (
                        <Grow
                            {...TransitionProps}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={buttonHandleClose} >
                                    <MenuList id="split-button-menu" autoFocusItem sx={plusButton}>
                                        <MenuItem
                                            key="Attended"
                                            onClick={() => handleAttendClick()}
                                            sx={plusMenu}
                                        >
                                            <Iconify style={plusMenuIcon} width={20} height={20} icon={'akar-icons:check'} /> Attended
                                        </MenuItem>
                                        <MenuItem
                                            key="Failed to attend"
                                            onClick={() => handleAttendFailedClick()}
                                            sx={plusMenu}
                                        >
                                            <Iconify style={plusMenuIcon} width={20} height={20} icon={'ci:close-big'} /> Failed to attend
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
                <AddReminderPopup
                    open={openReminder}
                    onClose={() => allPopupCloseHandler('Reminder')}
                    leadDetail={leadDetail}
                    leadData={leadData}
                    type={addPopupType}
                    page={type}
                    handleLoading={handleLoading}
                />
                {openAssignTask && (
                    <ClickAwayListener onClickAway={onClickAwayEvent}>
                        <Popper open={openAssignTask} anchorEl={anchorElAssign} transition placement="bottom" sx={{ zIndex: '999999' }}>
                            {({ TransitionProps }) => (
                                <Grow {...TransitionProps}>
                                    <Paper sx={{ borderRadius: 2 }}>
                                        <AssignTaskPopup
                                            open={openAssignTask}
                                            onClose={() => allPopupCloseHandler('Assigned')}
                                            leadDetail={leadDetail}
                                            reminder={reminderData}
                                            assignees={assigneeData?.filter((el) => el?.user?.first_name && !el?.user?.is_superuser)?.map((ele) => ele.id)}
                                        />
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </ClickAwayListener>
                )}
                <OpenAttendedPopup
                    open={openRecordAttend}
                    isOverview
                    headerButtons
                    onClose={() => allPopupCloseHandler('Attended')}
                    user={rowLead}
                    type={'recordAttended'}
                    inputRef={inputRef}
                    CreateLeadHeaderData={CreateLeadHeaderData}
                    OpenAssignTask={openAppointmentAssignTaskFuc}
                    editDetailHandler={editDetailHandler}
                />
                {openContacts && <OpenContactLeadPopup
                    open={openContacts}
                    headerButtons
                    onClose={() => allPopupCloseHandler('Dialog')}
                    openArchivePopup={onOpenArchiveLead}
                    user={rowLead}
                    steps={leadStep}
                    BookingAttendance={BookingAttendance}
                    type={'followup'}
                    validStep={validStep}
                    updateStep={() => setValidStep(!validStep)}
                    inputRef={inputRef}
                    CreateLeadHeaderData={CreateLeadHeaderData}
                    ContactedCount={rowLead?.lead_treatments__lead_contacted_counts__count}
                />}
                <OpenUnAttendedPopup
                    open={openUnRecordAttend}
                    headerButtons
                    onClose={() => allPopupCloseHandler('UnAttended')}
                    user={rowLead}
                    type={'recordUnAttended'}
                    inputRef={inputRef}
                    CreateLeadHeaderData={CreateLeadHeaderData}
                />
                {openArchiveLead && <ArchivedLeadPopUp
                    open={openArchiveLead}
                    headerButtons
                    onClose={handleArchivedClose}
                    user={leadData}
                    type={'archiveLead'}
                    inputRef={inputRef}
                    CreateLeadHeaderData={CreateLeadHeaderData}
                />}
                <OpenConsultationPopup
                    open={openConsultationAttend}
                    headerButtons
                    onClose={() => allPopupCloseHandler('Consultation')}
                    user={rowLead}
                    type={'consultationAttended'}
                    inputRef={inputRef}
                    CreateLeadHeaderData={CreateLeadHeaderData}
                    OpenAssignTask={openAppointmentAssignTaskFuc}
                    editDetailHandler={editDetailHandler}
                    selectedTreatmentData={currentTreatmentDataGlobal}
                />
                {openSidebar && <ReusableSidebar
                    type='Task'
                    getReminderBtn={getReminderBtn}
                    data={leadData}
                    open={openSidebar}
                    reminderDataId={reminderDataId}
                    handleAttendClick={handleAttendClick}
                    handleAttendFailedClick={handleAttendFailedClick}
                    onClose={sidebarCloser}
                    handleReminderPopup={handleReminderPopup}
                    deleteTask={handleDeleteReminder}
                    setReloadCounters={setReloadCounters}

                />}
                {openAppointmentAssignTask && (
                    <ClickAwayListener onClickAway={() => appointmentClickAwayEvent()}>
                        <Popper open={openAppointmentAssignTask} anchorEl={anchorElAssign} transition placement="bottom" sx={{ zIndex: '999999' }}>
                            {({ TransitionProps }) => (
                                <Grow {...TransitionProps}>
                                    <Paper sx={{ borderRadius: 2 }}>
                                        <AppointmentAssignTaskPopup
                                            open={openAppointmentAssignTask}
                                            onClose={() => {
                                                onCloseAppointmentAssignTaskPopup();
                                                setAnchorElAssign(null);
                                            }}
                                            user={leadData}
                                            leadId={leadData || reminderData}
                                            type={'leadAssign'}
                                            leadDetail={leadDetail}
                                            assignees={assignees}
                                            selectedTreatmentData={currentTreatmentDataGlobal}
                                        />
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </ClickAwayListener>
                )}
                {leadDetailModal &&
                    <EditPersonalDetailsPopup
                        open={leadDetailModal}
                        handleClose={closeEditDetails}
                        selectedRow={leadData}
                        type={'editLead'}
                        inputRef={personalDetailPopupRef}
                        title='Edit Details'
                        headerButtons
                    />
                }
                {openNotePopup && (
                    <AddNotePopup
                        open={openNotePopup}
                        onClose={handleCloseNotePopup}
                        Users={leadData}
                        type={'TaskSidebar'}
                        options={options}
                        setTemplateOpen={setTemplateOpen}
                        templateOpen={templateOpen}
                    />
                )}
                {templateOpen && (
                    <LeadTemplate
                        openEmailCompose={Boolean(templateOpen)}
                        onCloseEmailCompose={() => {
                            setTemplateOpen(false);
                            setLeadData({});
                            document.body.style.overflowY = 'auto';
                        }}
                        practiceId={practiceId}
                        type={'EMAIL'}
                        leadDetail={leadData}
                    />
                )}
                <Dialog open={confirmation} onClose={() => setConfirmation(false)}>
                    <DialogTitle
                        sx={{
                            padding: '40px 40px 0px',
                            textAlign: 'center'
                        }}
                    >
                        Are you sure you want to complete the treatment ?
                    </DialogTitle>
                    <DialogActions
                        sx={{
                            padding: '40px !important',
                            justifyContent: 'center'
                        }}
                    >
                        <Button
                            sx={completeTreatmentCancel}
                            onClick={() => setConfirmation(false)}
                        >
                            Cancel
                        </Button>
                        <LoadingButton
                            type='submit'
                            variant='contained'
                            loading={isLoader}
                            loadingPosition='start'
                            onClick={() => completeTreatmentAPI()}
                            sx={{ ...yesBtnStyleForLoader(isLoader) }}
                            disabled={isLoader}
                        >
                            Yes
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
                <Dialog open={deleteConfirm} onClose={() => setDeleteConfirm(false)}>
                    <DialogTitle
                        sx={{
                            padding: '40px 40px 0px',
                            textAlign: 'center'
                        }}
                    >
                        Are you sure you want to delete ?
                    </DialogTitle>
                    <DialogActions
                        sx={{
                            padding: '40px !important',
                            justifyContent: 'center'
                        }}
                    >
                        <Button
                            sx={completeTreatmentCancel}
                            onClick={() => setDeleteConfirm(false)}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            sx={completeTreatmentConfirm}
                            onClick={() => deleteConfirmAPI()}
                        >
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>
            </Card>
        </>
    );
};
TaskReminder.propTypes = {
    reminder: PropTypes.object,
    type: PropTypes.string,
    leadDetail: PropTypes.object,
    leadDetailTab: PropTypes.string,
    reloadTaskCounter: PropTypes.func,
    handleLoading: PropTypes.func,
    reset: PropTypes.bool,
    selectedDateRange: PropTypes.string,
    isDashboard: PropTypes.bool,
    shouldRunTaskEffect: PropTypes.object
};

export default TaskReminder;
