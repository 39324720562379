/* eslint-disable no-nested-ternary */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import useSnackbar from 'src/hooks/useCustomSnackbar';
// @mui
import {
    Card,
    Typography,
    Stack,
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogActions,
    Popper,
    Paper,
    Grow,
    MenuList,
    MenuItem,
    Radio,
    Zoom,
    Tooltip,
    ListItem,
    List,
    ListItemAvatar,
    ListItemText,
    useTheme,
    ClickAwayListener
} from '@mui/material';
import {
    Timeline,
    TimelineDot,
    TimelineItem,
    TimelineContent,
    TimelineSeparator,
    LoadingButton,
} from '@mui/lab';
// Components
import { fetchMergeTagValueForLeads, fetchMergeTagValueForTreatments } from 'src/redux/slices/mergeTags';
import Activities from './Activities';
import TreatmentEditPopover from '../../../pages/dashboard/tables/AllLeadTable/TreatmentEditPopover';
import ClinCheckOverViewTab from '../../../components/clinCheck/ClinCheckOverViewTab';
import { Iconify } from '../../../components';
import { AttendedAppointment } from '../../../components/Attended';
import { CustomAvatar, AssignUserAvatar } from '../../../components/custom-avatar';
import { UnableToContact } from '../../../components/failedToAttendTabs';
import UpcomingAppointmentsOverview from '../../../components/UpcomingAppointmentsOverview';
import RecentNotes from '../../../components/RecentNotes';
import SimilarLeadWarningPopup from './SimilarLeadWarningPopup';
import {
    OpenConsultationPopup,
    OpenAttendedPopup,
    OpenUnAttendedPopup,
    OpenContactLeadPopup,
} from '../../../components/fullScreenPopUps';
import {
    AssignTaskPopup,
    ArchivedLeadPopUp,
    EditPersonalDetailsPopup,
    AppointmentAssignTaskPopup
} from '../../../components/dialogPopUp';
import { NormalLoading } from '../../../components/loading';
import LeadTemplate from '../../../pages/dashboard/LeadTemplate';
import { FollowUp, AppointmentPage } from '../lead';
import {
    TreatmentAndAppointment,
    PersonalDetails,
    PaymentOption,
    MessageAndReminder
} from '../general/app';
import { ReusableSidebar } from '../calendar';
import AttendedAppointmentsOverview from '../../../components/AttendedAppointmentsOverview';
// redux
import { setCurrentTreatmentDataGlobal } from '../../../redux/slices/multiTreatment';
import { getActivities } from '../../../redux/slices/activity';
import { updateSelectedSimilarLeads } from '../../../redux/slices/duplicateLeads';
import { dispatch, useSelector } from '../../../redux/store';
import {
    clearLead,
    updateSteps,
    updateFollowUpLead,
    updateLeadDetail,
    ConsultationAttended,
    RecordAttended,
    recordUnAttended,
    getLeadReminderData,
    CompleteTreatment,
    followUpSlice,
    deleteTreatment,
    tableLead,
    getAppointment,
    startLoadingData,
    getLeadNotes,
    updateReminder,
    getReminderData,
    getLeadNotesWithFilter
} from '../../../redux/slices/lead';
import { clearPracticeUserData } from '../../../redux/slices/practiceUser';
import { updatePracticeId } from '../../../redux/slices/practiceTreatmentList';
import { setLoader } from '../../../redux/slices/loader';
// utils/constant/hooks
import { getTreatmentDetails, handleSetTreatmentDataGlobal } from '../../../utils/multiTreatmentFlow';
import capitalizeFirstLetter from '../../../utils/capitalizedFunc';
import { setIcon, setLeadSourceIcon, setBackgroundColor } from '../../../utils/timelineIcon';
import { getMessage } from '../../../utils/getTasksData';
import { GetPracticeAvatar } from '../../../utils/createAvatar';
import { setRowLeadData } from '../../../utils/getRowLeadData';
import { getAssignees } from '../../../utils/multiAsignees';
import { TAB_HEAD, statuses } from '../../../constants/LeadDetailConst';
import {
    useArchivedToggle,
    useConsultationAttendToggle,
    useRecordAttendToggle,
    useUnRecordAttendToggle,
    useToggle,
    useResponsive,
    useAssignTaskToggle,
    useAddTreatment,
    useLeadTableHelper,
} from '../../../hooks';
import { localStorageRemoveItem } from '../../../utils/storage-available';
// styles
import { common, grey } from '../../../theme/palette';
import {
    infoIconBox,
    overViewCard,
    overViewDynamicOuterBox,
    paperStyle,
    timeLabel,
    avatarBox,
    avatarInnerBox,
    plusIconBox,
    unassignTypo,
    timeLineInfo,
    menuListStyle,
    cancelBtnStyle,
    unassignedBoxStyle,
    messageBox,
    noteTooltipText,
    noteIcon,
    takeDepositButton,
    overviewMainBoxStyle,
    overviewLeftLayoutBoxStyle,
    overviewRightLayoutBoxStyle,
    leadAppointmentCardStyle,
    lockIconMainBoxStyle,
    lockIconInnerBoxStyle,
    priceValueTextStyle,
    timeLineTypoV2,
    timeLineMessage,
    flowButtonContentWrapper,
    statusStyle,
    treatmentTestStyle,
    finalTreatmentBoxStyle,
    dotsIconBoxStyle
} from '../../../styles/OverView';
import {
    paperMenuItem,
    editDetailMenuItem,
    menuItemIcon,
    overViewMenuItemStyle,
    typoSpanStyle,
    timeLineStyle,
    appointmentCardStyle
} from '../../../styles/LeadDetailView';
import { taskButton, archivedButton, textStyle, assignUserAvatarStyle, assignLengthStyle } from '../../../styles/leadTaskReminder';
import { yesBtnStyleForLoader } from '../../../styles/Common';
import 'swiper/css';
import 'swiper/css/navigation';

const Overview = ({ leadDetail, activityList, onChangeTab, reloadTaskCounter, LeadNotes, handleMenuItems, setSelectTreatmentData, setOpenContactFromCall, openContactFromCall }) => {

    const theme = useTheme();

    const inputRef = useRef();
    const personalDetailPopupRef = useRef();
    const updateReminderRef = useRef();

    const navigate = useNavigate();

    const { nearestPastData: nearestPastDataV2, nearestData: nearestDataV2 } = useLeadTableHelper('ConsultantBooked');

    const isLight = theme.palette.mode === 'light';

    const isDesktop = useResponsive('down', 'sm');

    const { practiceId } = useSelector((state) => state.practiceTreatmentList);

    const { isLoader } = useSelector((state) => state.loaderReducer);

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { followLead } = useSelector((state) => state.lead);
    const [anchorRef, setAnchorRef] = useState(null);
    const [active, setActive] = useState(0);
    const [reminderId, setReminderID] = useState(0);
    const [reminderData, setReminderData] = useState('');
    const [openSidebar, setOpenSidebar] = useState(false);
    const [validStep, setValidStep] = useState(false);
    const [open, setOpen] = useState(false);
    const [treatmentCon, setTreatmentCon] = useState(false);
    const [cancelTreatment, setCancelTreatment] = useState(false);
    const [markComplete, setMarkComplete] = useState(false);
    const { pathname } = useLocation();
    const location = useLocation();
    const routeData = new URLSearchParams(location.search);
    const searchPractice = routeData.get('practiceId');
    const taskId = routeData.get('taskId');
    const tab = routeData.get('tab');
    const metaData = leadDetail && leadDetail?.lead_metadata;
    const leadSourceMetaData = leadDetail && leadDetail?.lead_source && leadDetail?.lead_source?.metadata;
    const [leadAppointment, setLeadAppointment] = useState('');
    const [bookedLeadAppointment, setBookedLeadAppointment] = useState('');
    const [pastAppointment, setPastAppointment] = useState('');
    const [treatmentData, setTreatmentData] = useState('');
    const [assignees, setAssignees] = useState([]);
    const [leadData, setLeadData] = useState({
        lead_id: '',
        treatment_id: '',
        price: '',
        first_name: '',
        last_name: '',
        status: '',
        treatment_name: '',
        created_at_human: ''
    });
    const [currentTreatmentData, setCurrentTreatmentData] = useState({});
    const [treatmentOpen, setTreatmentOpen] = useState(false);
    const treatmentStatus = leadDetail?.lead_treatments?.[0]?.status;
    const isInvisalignTreatment = () => leadDetail?.lead_treatments?.[0]?.treatment === 'Invisalign';
    const shouldRenderClinCheck = (treatmentStatus === 'CONSULTATION_ATTENDED' || treatmentStatus === 'CONSULTATION_BOOKED') && isInvisalignTreatment();
    const finalTreatmentArray = leadDetail?.lead_treatments?.filter((ele) => ele.status !== 'CLOSED' || leadDetail.lead_treatments.length <= 3);
    const {
        isAppointmentBooked,
        attendance,
        followUpLead,
        leadReminder,
        contactLead,
        BookingAttendance,
        markAsComplete,
        upComingData,
        taskTableData: {
            limit,
            offset,
            filter,
            assignUser
        },
    } = useSelector((state) => state.lead);
    const {
        referral: referralData,
    } = useSelector((state) => state.schema);
    const { practiceTreatment } = useSelector((state) => state.practiceTreatmentList);
    const anchorTreatmentRef = useRef(null);
    const [anchorElAssign, setAnchorElAssign] = useState(null);
    const [anchorEditTreatment, setAnchorEditTreatment] = useState(null);
    const assigneeData = upComingData?.assignees?.filter((el) => el?.user?.first_name && !el?.user?.is_superuser);
    const [templateOpen, setTemplateOpen] = useState('');

    useEffect(() => {
        if (followLead) {
            const treatmentFilter = followLead?.lead_appointment;
            const upcomingAppointment = treatmentFilter?.filter((el) => (
                moment(el.datetime, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD HH:mm:ss') > moment().format('YYYY-MM-DD HH:mm:ss')
            ));
            const upcomingAppointmentSorting = upcomingAppointment?.sort((a, b) => new Date(moment(a.datetime, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD hh:mm:ss')) - new Date(moment(b.datetime, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD hh:mm:ss')));
            const bookedStatusFilter = upcomingAppointmentSorting?.filter((el) => el.status === 'BOOKED');
            const PastAppointment = treatmentFilter?.filter((el) => el.status === 'ATTENDED');
            const PastAppointmentSorting = PastAppointment?.sort((a, b) => new Date(moment(a.datetime, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD hh:mm:ss')) - new Date(moment(b.datetime, 'DD-MM-YYYY HH:mm:ss').format('YYYY-MM-DD hh:mm:ss')));
            setLeadAppointment(bookedStatusFilter);
            setBookedLeadAppointment(bookedStatusFilter);
            setPastAppointment(PastAppointmentSorting);
        }
    }, [followLead]);

    const getBtnName = (treatment) => {
        if ((treatment?.lead_contacted_counts?.find(item => item.status === treatment?.status)?.count === 0 || treatment?.lead_contacted_counts?.length === 0) && treatment.status === 'NEW') {
            return 'Contact Lead';
        }
        if (statuses.includes(leadDetail?.lead_treatments.length > 0 && treatment.status)) {
            return 'Follow up';
        }
        switch (treatment.status) {
            case 'CONSULTATION_BOOKED':
                return 'Record Attendance';
            case 'TREATMENT_STARTED':
                return 'Complete Treatment';
            case 'CLOSED':
                return 'Completed';
            default:
                return '';
        }
    };

    const getHtmlElement = () => document.querySelector('body');

    const removeClass = () => {
        const htmlElement = getHtmlElement();
        htmlElement.classList.remove('overflow-hidden');
    };

    const getStatus = (treatment) => TAB_HEAD.map((e1) => {
        let latestData = '';
        if (treatment?.status === e1.value) {
            latestData = e1.label;
        }
        return latestData;
    });

    useEffect(() => {
        dispatch(getAppointment(practiceId, leadDetail?.id));
        dispatch(updateSelectedSimilarLeads({ leadIds: [] }));
    }, []);

    useEffect(() => {
        if (searchPractice && taskId) {
            dispatch(updatePracticeId(searchPractice));
            dispatch(getReminderData(searchPractice, taskId));
            setOpenSidebar(true);
        }
    }, [searchPractice, taskId]);

    useEffect(() => {
        if (leadDetail?.lead_treatments.length > 0) {
            setTreatmentData(leadDetail?.lead_treatments[0]);
            dispatch(getLeadReminderData(practiceId, 'LeadDetail', 1, 0, '', leadDetail?.id, '', '', 'Graterthan'));
            dispatch(getLeadReminderData(practiceId, 'LeadDetail', 10, 0, 'leadNotes', leadDetail?.id, '', '', '', 'counter'));
        }
    }, [leadDetail, practiceId]);

    useEffect(() => {
        if (upComingData) {
            setReminderData(upComingData);
        }
    }, [upComingData]);

    const {
        toggle: openFollowLead,
        onOpen: onOpenFollowLead,
        onClose: onCloseFollowLead
    } = useToggle();

    const {
        toggle: leadDetailModal,
        onOpen: onLeadDetail,
        onClose: onCloseLeadDetail
    } = useToggle();

    const {
        toggle: openConsultationAttend,
        onOpen: onOpenConsultationAttend,
        onClose: onCloseConsultationAttend
    } = useConsultationAttendToggle();

    const {
        toggle: openRecordAttend,
        onOpen: onOpenRecordAttend,
        onClose: onCloseRecordAttend
    } = useRecordAttendToggle();

    const {
        toggle: openUnRecordAttend,
        onOpen: onOpenUnRecordAttend,
        onClose: onCloseUnRecordAttend
    } = useUnRecordAttendToggle();

    const {
        toggle: openArchiveLead,
        onOpen: onOpenArchiveLead,
        onClose: onCloseArchiveLead
    } = useArchivedToggle();

    const {
        toggle: openAppointmentAssignTask,
        onOpen: onOpenAppointmentAssignTask,
        onClose: onCloseAppointmentAssignTask
    } = useAssignTaskToggle();

    const CreateLeadHeaderData = {
        title: 'Create Lead',
        button1: 'Save & Continue Later',
        button2: 'Close',
    };

    const editDetailHandler = () => onLeadDetail();

    const closeEditDetails = () => onCloseLeadDetail();

    const closeHandler = (value) => {
        setOpenSidebar(false);
        if (value === 'finish') {
            setTreatmentCon(false);
            dispatch(setLoader(false));
            setCancelTreatment(false);
            dispatch(followUpSlice(leadDetail?.id, practiceId, 'LeadDetail'));
            dispatch(getLeadReminderData(practiceId, 'LeadDetail', limit, offset, filter, leadDetail?.id, assignUser));
            reloadTaskCounter({ userId: assignUser, id: leadDetail?.id });
            dispatch(getLeadReminderData(practiceId, 'LeadDetail', 1, 0, '', leadDetail?.id, '', '', 'Graterthan'));
        }
    };

    const onSubmit = (data) => {
        setValidStep(!!data);
    };

    useEffect(() => {
        if (markAsComplete) {
            switch (followUpLead) {
                case 'contactLead':
                    dispatch(updateLeadDetail(isAppointmentBooked, handleClose));
                    break;
                case 'consultationAttended':
                    dispatch(ConsultationAttended(handleClose));
                    break;
                case 'recordAttended':
                    dispatch(RecordAttended(handleClose));
                    break;
                case 'recordUnAttended':
                    dispatch(recordUnAttended(handleClose));
                    break;
                default:
                    break;
            }
        }
    }, [markAsComplete, followUpLead]);

    const openAppointmentAssignTaskFuc = (value, assignees, event) => {
        setAnchorElAssign(anchorElAssign ? null : event.currentTarget);
        onOpenAppointmentAssignTask();
        setReminderID(value?.lead_id);
        setAssignees(assignees);
    };

    const onCloseAppointmentAssignTaskPopup = () => onCloseAppointmentAssignTask();

    const handleClose = (status, Archived) => {
        dispatch(setLoader(false));
        if (status === 'markComplete') {
            dispatch(getLeadReminderData(practiceId, 'LeadDetail', limit, offset, filter, leadDetail?.id, assignUser, '', 'leadNotes', 'counter'));
            reloadTaskCounter({ userID: assignUser, id: leadDetail?.id });
            dispatch(getLeadReminderData(practiceId, 'LeadDetail', 1, 0, '', leadDetail?.id, '', '', 'Graterthan'));
            setOpenSidebar(false);
        };
        if (status?.toLowerCase?.() === 'finish' && Archived === true) {
            navigate('/dashboard/app');
            setOpenSidebar(false);
            dispatch(clearLead());
            dispatch(setCurrentTreatmentDataGlobal(null));
            dispatch(updateSteps(null));
            dispatch(updateFollowUpLead(null));
            setAnchorElAssign(null);
            removeClass();
            return;
        };
        if (status?.toLowerCase?.() === 'finish' && updateReminderRef.current) {
            const data = {
                datetime: `${moment().format('DD-MM-YYYY HH:mm:ss')}.0Z`,
                is_completed: true
            };
            dispatch(updateReminder(data, upComingData?.id, practiceId, handleReminder));
            setOpenSidebar(false);
        } else if (status?.toLowerCase?.() === 'finish') {
            handleReminder();
            setOpenSidebar(false);
        };
        dispatch(startLoadingData(false));
        setAnchorRef(null);
        setAnchorElAssign(null);
        setOpen(false);
        dispatch(clearLead());
        dispatch(setCurrentTreatmentDataGlobal(null));
        dispatch(updateSteps(null));
        dispatch(updateFollowUpLead(null));
        onCloseFollowLead();
        onCloseConsultationAttend();
        onCloseRecordAttend();
        onCloseUnRecordAttend();
        onCloseArchiveLead();
        removeClass();
    };

    const STEPS = [
        {
            label: 'Contact',
            component: <FollowUp ref={inputRef} handleNext={onSubmit} handleClose={handleClose} followLeadForScript={followLead} />
        }
    ];

    const [leadStep, setLeadStep] = useState(STEPS);

    useEffect(() => {
        if (contactLead) {
            const STEPS = [
                {
                    label: 'Contact',
                    component: <FollowUp ref={inputRef} handleNext={onSubmit} handleClose={handleClose} />
                }
            ];
            setLeadStep(STEPS);
        } else {
            const STEPS = [
                {
                    label: 'Contact',
                    component: <Activities activityList={activityList} notAllow />
                }
            ];
            setLeadStep(STEPS);
        }
    }, [contactLead]);

    useEffect(() => {
        if (BookingAttendance) {
            const steps = [...STEPS];
            steps.push({
                label: isAppointmentBooked,
                component: isAppointmentBooked === 'Booking Details'
                    ? <TreatmentAndAppointment ref={inputRef} type={'followUp'} handleNext={onSubmit} isOverview />
                    : isAppointmentBooked === 'Unable to contact' ?
                        <UnableToContact ref={inputRef} handleNext={onSubmit} isOverview completeFlow={handleClose} />
                        : <AppointmentPage ref={inputRef} handleNext={onSubmit} />
            });
            setLeadStep(steps);
            return;
        }
        setLeadStep(STEPS);
    }, [BookingAttendance]);

    useEffect(() => {
        if (attendance && isAppointmentBooked !== 'Appointment Not Booked') {
            const steps = [...STEPS];
            if (attendance === 'attendance') {
                steps.push({
                    label: isAppointmentBooked,
                    component: isAppointmentBooked === 'Booking Details'
                        ? <TreatmentAndAppointment ref={inputRef} type={'followUp'} handleNext={onSubmit} isOverview />
                        : isAppointmentBooked === 'Unable to contact' ?
                            <UnableToContact ref={inputRef} handleNext={onSubmit} isOverview completeFlow={handleClose} />
                            : <AppointmentPage ref={inputRef} handleNext={onSubmit} />
                });
                steps.push({
                    label: 'Appointment Outcome',
                    component: <AttendedAppointment ref={inputRef} handleNext={onSubmit} isOverview />
                });
            } else {
                steps.push({
                    label: isAppointmentBooked,
                    component: isAppointmentBooked === 'Booking Details'
                        ? <TreatmentAndAppointment ref={inputRef} type={'followUp'} handleNext={onSubmit} isOverview />
                        : isAppointmentBooked === 'Unable to contact' ?
                            <UnableToContact ref={inputRef} handleNext={onSubmit} isOverview completeFlow={handleClose} />
                            : <AppointmentPage ref={inputRef} handleNext={onSubmit} />
                });
                steps.push({
                    label: 'Personal Details',
                    component: <PersonalDetails ref={inputRef} handleNext={onSubmit} />
                });
                steps.push({
                    label: 'Payment options',
                    component: <PaymentOption ref={inputRef} handleNext={onSubmit} />
                });
                steps.push({
                    label: 'Message review & reminders',
                    component: <MessageAndReminder ref={inputRef} handleNext={onSubmit} type={'CreatLead'} />
                });
            }
            setLeadStep(steps);
        } else {
            setLeadStep(STEPS);
        }
    }, [attendance]);

    useEffect(() => {
        if (!followUpLead) {
            handleClose();
            dispatch(updateSteps(null));
        }
    }, [followUpLead]);

    const getLeadData = () => {
        dispatch(followUpSlice(leadDetail?.id, practiceId));
        setTimeout(() => {
            dispatch(getAppointment(practiceId, leadDetail?.id));
        }, 800);
    };

    const btnHandler = (event, value, treatment, isCallScript) => {
        localStorageRemoveItem('localTreatmentData');
        if (event === 'called') {
            updateReminderRef.current = true;
        }
        tableData();
        dispatch(fetchMergeTagValueForLeads(leadDetail?.id));
        dispatch(fetchMergeTagValueForTreatments(leadDetail?.id, getTreatmentDetails('id', treatment, leadDetail) || null));
        setLeadData({
            lead_id: leadDetail?.id || '',
            treatment_id: getTreatmentDetails('id', treatment, leadDetail),
            price: getTreatmentDetails('price', treatment, leadDetail),
            first_name: leadDetail.first_name || '',
            last_name: leadDetail.last_name || '',
            status: getTreatmentDetails('status', treatment, leadDetail),
            treatment_name: getTreatmentDetails('treatment', treatment, leadDetail),
            lead_treatments__lead_contacted_counts__count: getTreatmentDetails('count', treatment, leadDetail) === 0 && isCallScript ? null : getTreatmentDetails('count', treatment, leadDetail),
            clinCheckData: getTreatmentDetails('clinCheck', treatment, leadDetail),
            treatmentData: leadDetail?.lead_treatments
        });
        let STATUS = getTreatmentDetails('status', treatment, leadDetail);
        if (treatment) {
            const payload = {
                lead_id: leadDetail?.id || '',
                first_name: leadDetail.first_name || '',
                last_name: leadDetail.last_name || '',
                treatmentData: treatment
            };
            setCurrentTreatmentData(payload);
            dispatch(setCurrentTreatmentDataGlobal(payload));
        } else {
            setCurrentTreatmentData({});
            dispatch(setCurrentTreatmentDataGlobal(null));
        }
        if (value === 'Archive') {
            dispatch(updateFollowUpLead('archivedLead'));
            onOpenArchiveLead(leadDetail?.id);
            return;
        }
        if (isCallScript) {
            STATUS = 'NEW'; // Setting to new as default beaviour of outbound for call script
        }
        switch (STATUS) {
            case 'NEW': {
                dispatch(updateFollowUpLead('contactLead'));
                dispatch(followUpSlice(leadDetail?.id, practiceId));
                const htmlElement = getHtmlElement();
                htmlElement.classList.add('overflow-hidden');
                onOpenFollowLead();
                return;
            }
            case 'CONSULTATION_ATTENDED':
            case 'ATTENDED':
                dispatch(updateFollowUpLead('consultationAttended'));
                getLeadData();
                onOpenConsultationAttend();
                return;
            case 'CONSULTATION_BOOKED':
            case 'BOOKED':
                setAnchorRef(event.currentTarget);
                if (value === 'called') {
                    updateReminderRef.current = true;
                }
                setOpen(true);
                return;
            case 'FAILED_TO_ATTEND_CONSULTATION':
            case 'FAILED_TO_ATTEND':
                getLeadData();
                handleAttenedFailedClick();
                return;
            case 'TREATMENT_STARTED':
                setTreatmentCon(true);
                break;
            default:
                break;
        }
    };

    const handleReminder = () => {
        dispatch(followUpSlice(leadDetail?.id, practiceId, 'LeadDetail'));
        dispatch(getLeadNotes(leadDetail?.id, practiceId, handleSuccess));
        dispatch(getLeadNotesWithFilter(leadDetail?.id, practiceId, handleSuccess));
        dispatch(getActivities(practiceId, leadDetail.id, 10, 0));
        dispatch(getLeadReminderData(practiceId, 'LeadDetail', limit, offset, filter, leadDetail?.id, assignUser, '', '', 'counter'));
        reloadTaskCounter({ id: leadDetail?.id });
        dispatch(getLeadReminderData(practiceId, 'LeadDetail', 1, 0, '', leadDetail?.id, '', '', 'Graterthan'));
    };

    const getCurrentTreatmentData = () => {
        if (!isEmpty(currentTreatmentData?.treatmentData)) {
            return currentTreatmentData?.treatmentData;
        }
        return leadDetail?.lead_treatments[0];
    };

    const apiHandler = () => {
        if (treatmentCon) {
            const data = {
                treatment: getCurrentTreatmentData()?.treatment || '',
                custom_price: getCurrentTreatmentData()?.price || ''
            };
            dispatch(setLoader(true));
            dispatch(CompleteTreatment(data, getCurrentTreatmentData()?.lead, getCurrentTreatmentData()?.id, practiceId, closeHandler));
        }
        if (cancelTreatment && leadDetail?.lead_treatments.length > 0) {
            dispatch(deleteTreatment(practiceId, leadDetail.id, getCurrentTreatmentData()?.id, handleSuccess));
        }
    };

    const handleSuccess = (data) => {
        if (data?.cancelTreatment) {
            enqueueSnackbar(data.message || '', {
                variant: data.success ? 'success' : 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            });
            closeHandler();
            dispatch(getLeadReminderData(practiceId, 'LeadDetail', 10, 0, '', leadDetail?.id, ''));
            reloadTaskCounter({ id: leadDetail?.id });
            dispatch(getLeadReminderData(practiceId, 'LeadDetail', 1, 0, '', leadDetail?.id, '', '', 'Graterthan'));
            dispatch(getActivities(practiceId, leadDetail?.id, 10, 0));
            setCancelTreatment(false);
        }
        if (data.message === 'Task completed !') {
            setMarkComplete(false);
            const key = enqueueSnackbar(data.message || '', {
                variant: data.success ? 'success' : 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                },
                autoHideDuration: 5000,
                action:
                    <>
                        <Button onClick={() => undoReminder()} variant='success' size='small' sx={{ color: common.mintyZest, '&:hover': { bgcolor: 'rgba(84, 214, 44, 0.16)' } }}>
                            Undo
                        </Button>
                        <Iconify onClick={() => closeSnackbar(key)} icon={'radix-icons:cross-2'} height={15} width={15} sx={{ margin: '0px 8px', cursor: 'pointer' }} />
                    </>
            });
        }
    };

    const undoReminder = () => {
        setMarkComplete(false);
        const data = {
            is_completed: false
        };
        dispatch(updateReminder(data, upComingData?.id, practiceId, handleClose, 'markComplete'));
        closeSnackbar();
    };

    const buttonHandleClose = (event) => {
        if (anchorRef?.current && anchorRef?.current?.contains(event?.target)) {
            return;
        }
        setOpen(false);
    };

    const tableData = () => {
        dispatch(tableLead({
            lead_id: leadDetail?.id || '',
            treatment_id: leadDetail?.lead_treatments[0]?.id || '',
            price: leadDetail?.lead_treatments[0]?.price || '',
            first_name: leadDetail.first_name || '',
            last_name: leadDetail.last_name || '',
            status: leadDetail?.lead_treatments[0]?.status || '',
            treatment_name: leadDetail?.lead_treatments[0]?.treatment || '',
            lead_treatments__lead_contacted_counts__count: leadDetail?.lead_treatments[0]?.lead_contacted_counts?.find(item => item.status === leadDetail?.lead_treatments[0]?.status)?.count || 0,
            treatmentData: leadDetail?.lead_treatments
        }));
    };

    const handleAttenedFailedClick = (id, reminder) => {
        if (reminder) {
            setLeadData({ ...setRowLeadData(reminder, leadData), lead_id: reminder?.lead_treatment?.lead || id || '' });
        }
        handleSetTreatmentDataGlobal({ leadDetail, treatment: reminder, setCurrentTreatmentData });
        getLeadData();
        dispatch(updateFollowUpLead('recordUnAttended'));
        onOpenUnRecordAttend();
    };

    const handleAttenedClick = (data, treatment) => {
        setLeadData({
            lead_id: leadDetail?.id || '',
            treatment_id: treatment?.lead_treatment?.id,
            price: treatment?.lead_treatment?.price,
            first_name: treatment?.lead?.first_name || '',
            last_name: treatment?.lead?.last_name || '',
            status: treatment?.lead_treatment?.status,
            treatment_name: treatment?.lead_treatment?.treatment,
            lead_treatments__lead_contacted_counts__count: treatment?.lead_treatment?.lead_contacted_counts?.find(item => item.status === treatment?.lead_treatment?.status)?.count || 0,
            clinCheckData: treatment?.lead_treatment?.leadtreatment_clincheck,
            treatmentData: leadDetail?.lead_treatments
        });
        handleSetTreatmentDataGlobal({ leadDetail, treatment, setCurrentTreatmentData });
        getLeadData();
        dispatch(updateFollowUpLead('recordAttended'));
        onOpenRecordAttend();
    };

    const popupClose = (status) => {
        setTreatmentCon(false);
        setCancelTreatment(false);
        onCloseAssignTask();
        setAnchorElAssign(null);
        if (status) {
            dispatch(getLeadReminderData(practiceId, 'LeadDetail', 1, 0, '', leadDetail?.id, '', '', 'Graterthan'));
            dispatch(getLeadReminderData(practiceId, 'LeadDetail', 10, 0, 'leadNotes', leadDetail?.id, ''));
        }
    };

    const {
        toggle: openAssignTask,
        onOpen: onOpenAssignTask,
        onClose: onCloseAssignTask
    } = useAssignTaskToggle();

    const OpenAssignTask = (value, event) => {
        setAnchorElAssign(anchorElAssign ? null : event.currentTarget);
        setReminderID(value);
        onOpenAssignTask();
    };

    const markCompleteBtn = (value) => {
        setMarkComplete(value);
        const data = {
            is_completed: true
        };
        dispatch(updateReminder(data, value, practiceId, handleClose, 'markComplete', handleSuccess));
    };

    const getReminderBtn = (status, leadTreatment, archive) => {
        if (archive) {
            return <Button
                variant='contained'
                disabled
                sx={archivedButton}
            >
                Archived
            </Button>;
        }
        if (!leadTreatment || status === 'CLOSED') {
            return '';
        }
        switch (status) {
            case 'TREATMENT_STARTED':
                return <Button
                    variant='contained'
                    color='primary'
                    onClick={() => btnHandler('called', '', leadTreatment)}
                    sx={taskButton}
                >
                    Complete treatment
                </Button>;
            default:
                return <Button
                    variant='contained'
                    color='primary'
                    startIcon={<Iconify icon={'fluent:call-32-filled'} height={15} width={15} />}
                    onClick={() => btnHandler('called', '', leadTreatment)}
                    sx={taskButton}
                >
                    Follow Up
                </Button>;
        }
    };

    const sidebarHandler = (data) => {
        setReminderData(data);
        setOpenSidebar(true);
        const tabQuery = tab?.length > 0 ? `&tab=${tab}` : '';
        navigate(`?practiceId=${practiceId}&taskId=${data.id}${tabQuery}`);
    };

    const sidebarCloser = () => {
        setOpenSidebar(false);
        const tabQuery = tab?.length > 0 ? `?tab=${tab}` : '';
        navigate(`${pathname}${tabQuery}`, { replace: true });
        setReminderData('');
    };

    const handleArchivedClose = () => {
        onCloseArchiveLead();
        if (openFollowLead) {
            dispatch(updateFollowUpLead('contactLead'));
        }
    };

    const {
        toggle: EditTreatment,
        onOpen: onEditTreatment,
        onClose: onCloseEditTreatment
    } = useAddTreatment();

    const handleAddTreatment = (event) => {
        onEditTreatment();
        setAnchorEditTreatment(event.currentTarget);
    };

    const handleTogglePopper = (id, ele) => {
        anchorTreatmentRef.current = document.getElementById(`button-${ele.id}`);
        setTreatmentOpen((prevStates) => ({
            ...prevStates,
            [id]: !prevStates[id],
        }));
    };

    const isPopperOpen = (id) => Boolean(treatmentOpen[id]);

    const handleClickEditTreatment = (ele) => { onChangeTab('', 'Treatments'); setSelectTreatmentData(ele); };

    useEffect(() => {
        if (openContactFromCall) {
            btnHandler('', '', finalTreatmentArray[0], true);
        }
    }, [openContactFromCall])

    const renderTreatment = (ele, index, length) => {
        const appointment = nearestDataV2(ele?.appointments) || nearestPastDataV2(ele?.appointments);
        return <Box key={index} sx={flowButtonContentWrapper(length)}>
            <Typography sx={{ fontSize: 16, fontWeight: 600, display: 'flex' }}>{`${ele?.treatment}`} <span style={treatmentTestStyle}>{`£${ele?.price}`}</span> <Box sx={dotsIconBoxStyle} onClick={() => handleTogglePopper(ele.id, ele)} ref={anchorTreatmentRef} id={`button-${ele.id}`}><Iconify icon={'mdi:dots-horizontal'} sx={{ color: common.grey }} width={24} height={24} /></Box></Typography>
            <Button sx={statusStyle(ele.status)}><Iconify icon={ele.status === 'NEW' ? 'mdi:question-mark-circle-outline' : ele.status === 'CONSULTATION_BOOKED' ? 'mdi:calendar-clock' : ele.status === 'FAILED_TO_ATTEND_CONSULTATION' ? 'bx:calendar-x' : ele.status === 'CONSULTATION_ATTENDED' ? 'mdi:calendar-check' : ele.status === 'CLOSED' ? 'material-symbols:check-circle-outline' : ele.status === 'TREATMENT_STARTED' ? 'codicon:debug-start' : 'fluent:cloud-sync-complete-24-filled'} width={24} height={24} /> {getStatus(ele)}</Button>
            <Button variant='outlined' disabled={ele.status === 'CLOSED' || (ele.status === 'CONSULTATION_BOOKED' && appointment?.[0]?.status === 'CANCELLED')} onClick={(e) => btnHandler(e, '', ele)}>
                {getBtnName(ele)}
            </Button>
            <Popper
                sx={{ zIndex: 1, marginLeft: '10px !important' }}
                open={isPopperOpen(ele.id)}
                anchorEl={anchorTreatmentRef.current}
                role={undefined}
                placement='bottom-start'
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom',
                            width: '180px',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={() => setTreatmentOpen((open) => !open)}>
                                <MenuList
                                    autoFocusItem
                                    id='composition-menu'
                                    aria-labelledby='composition-button'
                                    sx={paperMenuItem}
                                >
                                    <MenuItem onClick={() => handleClickEditTreatment(ele)} sx={editDetailMenuItem}>
                                        <Iconify icon={'solar:pen-bold'} sx={{ mr: 1 }} />
                                        Edit Treatment
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box >;
    };

    const handleClickAwayLister = (e, popup1Ref) => {
        if (popup1Ref && popup1Ref.current) {
            if (!popup1Ref.current?.contains(e.target)) {
                onCloseEditTreatment();
                dispatch(followUpSlice(leadDetail.id, practiceId, 'LeadDetail'));
                return;
            }
            return;
        }
        onCloseEditTreatment();
        dispatch(followUpSlice(leadDetail.id, practiceId, 'LeadDetail'));
    };

    const handleReloadAPI = (status) => {
        document.body.classList.remove('points-event');
        if (status?.toLowerCase?.() === 'finish') {
            handleClickAwayLister();
            dispatch(clearLead());
            dispatch(setCurrentTreatmentDataGlobal(null));
            if (leadDetail && leadDetail.id) {
                dispatch(getLeadReminderData(practiceId, 'LeadDetail', 10, 0, '', leadDetail.id, ''));
                dispatch(getLeadReminderData(practiceId, 'LeadDetail', 1, 0, '', leadDetail?.id, '', '', 'Graterthan'));
                dispatch(followUpSlice(leadDetail.id, practiceId, 'LeadDetail'));
                dispatch(getActivities(practiceId, leadDetail.id, 10, 0));
            }
        }
    };

    const onClickAwayEvent = () => {
        const selectMenu = document.querySelector('.MuiModal-root');
        if (selectMenu) {
            return;
        }
        onCloseAssignTask();
        setAnchorElAssign(null);
        dispatch(clearPracticeUserData());
    };

    const appointmentClickAwayEvent = () => {
        const selectMenu = document.querySelector('.MuiModal-root');
        if (selectMenu) {
            return;
        }
        onCloseAppointmentAssignTaskPopup();
        setAnchorElAssign(null);
        dispatch(clearPracticeUserData());
    };

    const handleCloseEmailCompose = () => {
        setTemplateOpen(false);
        setLeadData({});
        document.body.style.overflowY = 'auto';
    };

    const handleCloseAppointmentAssignee = () => {
        onCloseAppointmentAssignTaskPopup();
        setAnchorElAssign(null);
    };

    return (
        <Stack>
            <SimilarLeadWarningPopup />
            <Card sx={overViewCard}>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                    <Box
                        sx={{ ...infoIconBox }}
                    >
                        <Tooltip title={`Resource referral : ${setLeadSourceIcon(leadDetail?.lead_source?.referral, referralData)?.label || 'not found'}`} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                            <Iconify icon={setLeadSourceIcon(leadDetail?.lead_source?.referral, referralData)?.icon} sx={{ color: common.mintBlue }} width={30} height={30} />
                        </Tooltip>
                    </Box>
                    <Box >
                        <Typography variant='h6' sx={{ marginBottom: '4px', fontSize: '16px !important' }}>
                            <span style={{ textTransform: 'capitalize' }}>{leadDetail?.first_name || ''}</span> enquired about {finalTreatmentArray?.length > 0 && finalTreatmentArray[0]?.treatment || ''}
                        </Typography>
                        <Typography variant='body2' sx={{ marginBottom: '0px', color: 'grey.600', }}>
                            <b style={{ marginBottom: '4px', display: 'block', fontWeight: 400 }}> {leadDetail?.lead_contact?.note || ''} </b> <span style={{ fontSize: '12px' }}>{leadDetail?.created_at_human || ''}.</span>
                        </Typography>
                    </Box>
                </Box>
                {((metaData && metaData.length !== 0 && metaData.some(el => el.key && el.key.endsWith('?'))) || (leadSourceMetaData && Object.entries(leadSourceMetaData)?.length !== 0 && Object.entries(leadSourceMetaData).some(([key]) => key && key.endsWith('?')))) && <List sx={{ maxWidth: '100%', m: '8px 0 -24px' }}>
                    {metaData && metaData?.map((el, index) => {
                        const { key, value } = el;
                        if (key.endsWith('?')) {
                            return (
                                <ListItem alignItems='flex-start' key={index} sx={{ p: '8px 0' }}>
                                    <ListItemAvatar sx={{ mt: '1px' }}>
                                        <Iconify icon={'charm:info'} color={common.mintBlue} width={24} height={24} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={key}
                                        secondary={`— ${value}`}
                                        sx={textStyle}
                                    />
                                </ListItem>
                            );
                        }
                        return null;
                    })}
                    {leadSourceMetaData && Object.entries(leadSourceMetaData).map(([key, value], index) => {
                        if (key.endsWith('?')) {
                            return (
                                <ListItem alignItems='flex-start' key={index} sx={{ p: '8px 0' }}>
                                    <ListItemAvatar sx={{ mt: '1px' }}>
                                        <Iconify icon={'charm:info'} color={common.blue} width={24} height={24} />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={key}
                                        secondary={`— ${value}`}
                                        sx={textStyle}
                                    />
                                </ListItem>
                            );
                        }
                        return null;
                    })}
                </List>}
                <Box sx={{ width: '100%', marginTop: 2 }}>
                    <Box sx={overViewDynamicOuterBox(finalTreatmentArray)}>
                        {finalTreatmentArray.length > 2 ?
                            <Box sx={finalTreatmentBoxStyle(finalTreatmentArray)}>
                                <Swiper
                                    modules={[Navigation]}
                                    spaceBetween={16}
                                    slidesPerView={2}
                                    navigation={{
                                        nextEl: '.swiper-button-next',
                                        prevEl: '.swiper-button-prev',
                                    }}
                                    pagination={{ clickable: true }}
                                    scrollbar={{ draggable: true }}
                                    breakpoints={{
                                        1560: {
                                            slidesPerView: 3,
                                        },
                                        768: {
                                            slidesPerView: 2,
                                        },
                                        0: {
                                            slidesPerView: 1,
                                        },
                                    }}
                                >
                                    {finalTreatmentArray?.map((ele, index) => <SwiperSlide key={index}>
                                        {renderTreatment(ele, index, finalTreatmentArray?.length)}
                                    </SwiperSlide>)}
                                </Swiper>
                                <div className='swiper-button-next' />
                                <div className='swiper-button-prev' />
                            </Box>
                            :
                            finalTreatmentArray?.map((ele, index) => <>
                                {renderTreatment(ele, index, finalTreatmentArray?.length)}
                            </>)
                        }
                        {leadDetail && leadDetail.lead_treatments && leadDetail.lead_treatments?.length === 0 && <Box sx={{ display: 'flex' }}>
                            <Button sx={{ display: 'flex', justifyContent: 'flex-start' }} variant='contained' onClick={(event) => handleAddTreatment(event)}>
                                <Iconify sx={{ mr: '8px' }} icon={'eva:plus-fill'} width={20} height={20} /> Add treatment
                            </Button>
                        </Box>}
                    </Box>
                </Box>
            </Card>
            <Box sx={overviewMainBoxStyle}>
                {(upComingData || leadDetail?.lead_treatments?.length > 0) && <Box sx={overviewLeftLayoutBoxStyle}>
                    {upComingData && <Card sx={{ p: 2, mb: 3 }} >
                        <Box
                            key={upComingData?.id}
                            sx={{ display: 'flex', width: '100%' }}
                        >
                            <Box sx={{ minWidth: '20px', mt: '0' }}>
                                <Tooltip title={markComplete ? 'Mark as incomplete' : 'Mark as complete'} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                                    <Radio
                                        checked={markComplete}
                                        onClick={() => markCompleteBtn(upComingData?.id)}
                                        name='radioButtons'
                                        sx={{ p: 1, '.MuiSvgIcon-root': { width: '24px', height: '24px' } }}
                                    />
                                </Tooltip>
                            </Box>
                            <Paper
                                onClick={() => sidebarHandler(upComingData)}
                                sx={{
                                    ...paperStyle,
                                    pointerEvents: upComingData?.lead?.is_archive_pending || upComingData?.lead?.archived_at ? 'none' : null
                                }}
                            >
                                <Box sx={messageBox}>
                                    <Box>
                                        <Box sx={{ p: '0 24px 0 0' }}>
                                            {upComingData.title !== '' ? <Box sx={{ fontSize: '16px', lineHeight: '24px' }} ><b>{capitalizeFirstLetter(upComingData?.title)}</b></Box> : getMessage(upComingData?.lead_treatment?.status, upComingData?.lead?.first_name, upComingData?.lead?.last_name, upComingData?.lead_treatment?.treatment, upComingData?.group, upComingData?.method)}
                                        </Box>
                                        <Box
                                            sx={{ ...avatarBox, mb: 0 }}
                                        >
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', mt: '6px' }}>
                                                <Box sx={{ display: 'flex' }} >
                                                    <Box
                                                        sx={{
                                                            color: upComingData?.datetime_human.includes('now' || 'NOW') ? common.lightGreen : common.red,
                                                            ...timeLabel
                                                        }}
                                                    >
                                                        <Iconify icon={'charm:clock-alarm'} width={14} height={14} sx={{ mr: '4px' }} />
                                                        {upComingData?.datetime_human && upComingData?.datetime_human || ''}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box>
                                        {assigneeData?.length > 0 ?
                                            <Box
                                                sx={avatarInnerBox}
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                <Tooltip title={getAssignees(upComingData)} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                                                    <Box sx={{ display: 'flex' }}>
                                                        {assigneeData?.slice(0, 2)?.map((assignee, index) => (
                                                            <AssignUserAvatar
                                                                key={index}
                                                                sx={assignUserAvatarStyle}
                                                                src={assignee.user?.avatar || ''}
                                                                name={`${assignee.user?.first_name || ''} ${assignee.user?.last_name || ''}`}
                                                                role={assignee.role}
                                                                onClick={(e) => OpenAssignTask(upComingData, e)}
                                                            />
                                                        ))}
                                                        {assigneeData?.length > 2 && (
                                                            <Box sx={assignLengthStyle} onClick={(e) => OpenAssignTask(upComingData, e)}>
                                                                + {assigneeData?.length - 2}
                                                            </Box>
                                                        )}
                                                    </Box>
                                                </Tooltip>
                                            </Box> :
                                            <Box
                                                sx={unassignedBoxStyle}
                                                onClick={(e) => e.stopPropagation()}
                                            >
                                                <Box onClick={(e) => OpenAssignTask(upComingData, e)} sx={plusIconBox}>
                                                    <Iconify width={16} height={16} icon={'eva:plus-fill'} />
                                                </Box>
                                                <Typography
                                                    component={'p'}
                                                    onClick={(e) => OpenAssignTask(upComingData, e)}
                                                    sx={{ ...unassignTypo, color: isLight ? grey[700] : grey[500] }}
                                                >Unassigned</Typography>
                                            </Box>}
                                    </Box>
                                </Box>
                            </Paper>
                        </Box>
                        {leadReminder?.count > 1 && <Button sx={{ mt: '5px' }} onClick={() => onChangeTab('', 'Tasks')}>
                            ...See more
                        </Button>}
                    </Card>}
                    {leadDetail?.lead_treatments?.length > 0 && <Card sx={appointmentCardStyle}>
                        <UpcomingAppointmentsOverview leadAppointment={leadAppointment} treatmentData={treatmentData} leadDetail={leadDetail} treatments={leadDetail?.lead_treatments} handleReloadAPI={handleReloadAPI} />
                    </Card>}
                    {leadDetail?.lead_treatments?.length > 0 && <Card sx={appointmentCardStyle}>
                        <AttendedAppointmentsOverview leadAppointment={pastAppointment} treatmentData={treatmentData} leadDetail={leadDetail} treatments={leadDetail?.lead_treatments} />
                    </Card>}
                    {shouldRenderClinCheck && <Card sx={appointmentCardStyle}>
                        <ClinCheckOverViewTab leadDetail={leadDetail} onChangeTab={onChangeTab} />
                    </Card>}
                </Box>}
                <Box sx={(upComingData || leadDetail?.lead_treatments?.length > 0) ? overviewRightLayoutBoxStyle : { width: '100%' }}>
                    {bookedLeadAppointment?.length > 0 && <Card sx={leadAppointmentCardStyle}>
                        <Box sx={lockIconMainBoxStyle}>
                            <Box sx={lockIconInnerBoxStyle}>
                                <Iconify icon={'ic:baseline-lock'} sx={{ color: common.deepGreen }} width={30} height={30} />
                            </Box>
                        </Box>
                        <Typography sx={{ fontSize: '14px', color: common.mintyGreen, fontWeight: '600' }}>Appointment booked</Typography>
                        <Typography sx={priceValueTextStyle}>£{treatmentData && treatmentData.price || '0'} Potential value.</Typography>
                        <Typography sx={{ fontSize: '14px', color: common.darkGreenContrast, fontWeight: '600' }}><span style={typoSpanStyle}>{leadDetail?.lead_treatments.length > 0 && leadDetail?.lead_treatments[0].treatment || ''}</span> booked for <span style={typoSpanStyle}>{moment(bookedLeadAppointment && bookedLeadAppointment[0]?.datetime, 'DD/MM/YYYY').format('DD MMM YYYY')}</span> at <span style={typoSpanStyle}>{moment(bookedLeadAppointment && bookedLeadAppointment[0]?.datetime, 'DD/MM/YYYY hh:mm:ss').format('hh:mm a')}</span></Typography>
                        <Button variant='contained' sx={takeDepositButton} onClick={() => handleMenuItems('Take a Payment')}>
                            <Iconify icon={'codicon:arrow-down'} sx={{ mr: 1 }} width={18} height={18} /> Take deposit
                        </Button>
                    </Card>}
                    <Card sx={{ p: '24px', mb: 3 }}>
                        <RecentNotes leadDetail={leadDetail} LeadNotes={LeadNotes} />
                    </Card>
                    <Card sx={{ p: '24px', display: 'flex', mb: 3 }}>
                        <Box sx={{ p: 0, bgcolor: 'transparent', width: '100%' }}>
                            <NormalLoading tableData={!activityList && 'No Found'} pageCount={!activityList && 'No Found'} />
                            <Typography variant='h6' sx={{ my: 0, mb: '16px' }}>
                                Timeline
                            </Typography>
                            <Box sx={{ maxHeight: '215px', overflow: 'auto', width: '100%' }}>
                                <Timeline sx={{ p: 0, width: '100%', m: 0 }}>
                                    {activityList && activityList.slice(0, 3).map((value, index) => (
                                        <TimelineItem sx={timeLineStyle} key={index}>
                                            <TimelineSeparator>
                                                <TimelineDot color='info' sx={{ ...timeLineInfo, bgcolor: setBackgroundColor(value) }}>
                                                    {setIcon(value)}
                                                </TimelineDot>
                                            </TimelineSeparator>
                                            <TimelineContent sx={{ padding: '0px 16px 0' }} >
                                                <Typography variant='subtitle2' sx={timeLineMessage}>
                                                    {capitalizeFirstLetter(value?.data?.data?.message)}
                                                </Typography>
                                                <Typography variant='body2' sx={timeLineTypoV2}>{moment(value.timestamp, 'DD/MM/YYYY').format('DD MMM YYYY')} at {moment(value.timestamp, 'DD/MM/YYYY hh:mm:ss').format('hh:mm a')} </Typography>
                                                {value?.action_object?.additional_notes &&
                                                    <Tooltip title={capitalizeFirstLetter(value?.action_object?.additional_notes)} placement='bottom' TransitionComponent={Zoom} TransitionProps={{ timeout: 300 }}>
                                                        <Typography variant='subtitle2' sx={noteTooltipText}>
                                                            Notes
                                                            <Iconify icon={'bi:caret-left-fill'} width={18} height={17} sx={noteIcon} />
                                                        </Typography>
                                                    </Tooltip>}
                                                <Box sx={{ padding: 0, display: 'flex' }}>
                                                    <CustomAvatar src={value?.actor?.avatar || GetPracticeAvatar(practiceTreatment, practiceId)[0]?.logo} sx={{ height: '35px', width: '35px', backgroundColor: 'transparent' }} name={value?.actor?.first_name} />
                                                    <Typography variant='subtitle2' sx={{ margin: '7px 0 0 7px' }}>
                                                        {value?.actor?.first_name || ''} {value?.actor?.last_name || ''}
                                                    </Typography>
                                                </Box>
                                            </TimelineContent>
                                        </TimelineItem>
                                    ))}
                                </Timeline>
                                {activityList?.length > 3 && <Button sx={{ mt: '5px' }} onClick={() => onChangeTab('', 'Timeline')}>
                                    ...See more
                                </Button>}
                            </Box>
                        </Box>
                    </Card>
                </Box>
            </Box>
            <Popper
                sx={{
                    zIndex: 1,
                    margin: '0px 20px !important',
                }}
                open={open}
                anchorEl={anchorRef}
                role={undefined}
                transition
                placement={isDesktop ? 'top' : 'top'}
            >
                {({ TransitionProps }) => (
                    <Grow
                        {...TransitionProps}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={buttonHandleClose} >
                                <MenuList id='split-button-menu' autoFocusItem sx={menuListStyle}>
                                    <MenuItem
                                        key='Attended'
                                        onClick={handleAttenedClick}
                                        sx={overViewMenuItemStyle}
                                    >
                                        <Iconify style={menuItemIcon} width={20} height={20} icon={'akar-icons:check'} /> Attended
                                    </MenuItem>
                                    <MenuItem
                                        key='Failed to attend'
                                        onClick={handleAttenedFailedClick}
                                        sx={overViewMenuItemStyle}
                                    >
                                        <Iconify style={menuItemIcon} width={20} height={20} icon={'ci:close-big'} /> Failed to attend
                                    </MenuItem>
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
            <Dialog open={treatmentCon || cancelTreatment} onClose={popupClose}>
                <DialogTitle sx={{ padding: '40px 40px 0px', textAlign: 'center' }}>Are you sure you want to {treatmentCon ? 'complete the treatment' : 'cancel the treatment'} ?</DialogTitle>
                <DialogActions sx={{ padding: '40px !important', justifyContent: 'center' }}>
                    <Button sx={cancelBtnStyle} onClick={popupClose} >Cancel</Button>
                    <LoadingButton
                        type='submit'
                        variant='contained'
                        loading={isLoader}
                        loadingPosition='start'
                        onClick={apiHandler}
                        sx={{ ...yesBtnStyleForLoader(isLoader) }}
                        disabled={isLoader}
                    >
                        Yes
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            {openAssignTask && (
                <ClickAwayListener onClickAway={onClickAwayEvent}>
                    <Popper open={openAssignTask} anchorEl={anchorElAssign} transition placement='bottom' sx={{ zIndex: '999999' }}>
                        {({ TransitionProps }) => (
                            <Grow {...TransitionProps}>
                                <Paper sx={{ borderRadius: 2 }}>
                                    <AssignTaskPopup
                                        open={openAssignTask}
                                        onClose={popupClose}
                                        leadDetail={leadDetail}
                                        reminder={reminderId}
                                        assignees={assigneeData?.map((ele) => ele.id)}
                                    />
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </ClickAwayListener>
            )}
            {openFollowLead && <OpenContactLeadPopup
                open={openFollowLead}
                headerButtons
                onClose={handleClose}
                openArchivePopup={onOpenArchiveLead}
                user={leadData}
                steps={leadStep}
                type={'followup'}
                validStep={validStep}
                BookingAttendance={BookingAttendance}
                updateStep={() => setValidStep(!validStep)}
                inputRef={inputRef}
                active={active}
                CreateLeadHeaderData={CreateLeadHeaderData}
                setActive={setActive}
                ContactedCount={leadDetail?.lead_treatments[0]?.lead_contacted_counts?.find(item => item.status === leadDetail?.lead_treatments[0]?.status)?.count || 0}
                setOpenContactFromCall={setOpenContactFromCall}
            />
            }
            {openConsultationAttend && <OpenConsultationPopup
                open={openConsultationAttend}
                headerButtons
                onClose={handleClose}
                user={leadData}
                type={'consultationAttended'}
                inputRef={inputRef}
                CreateLeadHeaderData={CreateLeadHeaderData}
                OpenAssignTask={openAppointmentAssignTaskFuc}
                editDetailHandler={editDetailHandler}
                selectedTreatmentData={currentTreatmentData}
            />
            }
            {openRecordAttend && <OpenAttendedPopup
                open={openRecordAttend}
                headerButtons
                onClose={handleClose}
                user={leadData}
                type={'recordAttended'}
                inputRef={inputRef}
                CreateLeadHeaderData={CreateLeadHeaderData}
                OpenAssignTask={openAppointmentAssignTaskFuc}
                editDetailHandler={editDetailHandler}
                isOverview
            />
            }
            {openUnRecordAttend && <OpenUnAttendedPopup
                open={openUnRecordAttend}
                headerButtons
                onClose={handleClose}
                user={leadData}
                type={'recordUnAttended'}
                inputRef={inputRef}
                CreateLeadHeaderData={CreateLeadHeaderData}
            />
            }
            {openArchiveLead && <ArchivedLeadPopUp
                open={openArchiveLead}
                headerButtons
                onClose={handleArchivedClose}
                user={leadData}
                type={'archiveLead'}
                inputRef={inputRef}
                CreateLeadHeaderData={CreateLeadHeaderData}
            />
            }
            {openAppointmentAssignTask && (
                <ClickAwayListener onClickAway={() => appointmentClickAwayEvent()}>
                    <Popper open={openAppointmentAssignTask} anchorEl={anchorElAssign} transition placement='bottom' sx={{ zIndex: '999999' }}>
                        {({ TransitionProps }) => (
                            <Grow {...TransitionProps}>
                                <Paper sx={{ borderRadius: 2 }}>
                                    <AppointmentAssignTaskPopup
                                        open={openAppointmentAssignTask}
                                        onClose={handleCloseAppointmentAssignee}
                                        user={leadData}
                                        leadId={leadDetail?.id}
                                        type={'leadAssign'}
                                        leadDetail={leadDetail}
                                        reminder={reminderId}
                                        selectedTreatmentData={currentTreatmentData}
                                        assignees={assignees}
                                    />
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </ClickAwayListener>
            )}
            {leadDetailModal &&
                <EditPersonalDetailsPopup
                    open={leadDetailModal}
                    handleClose={closeEditDetails}
                    selectedRow={leadData}
                    type={'editLead'}
                    inputRef={personalDetailPopupRef}
                    title='Edit Details'
                    headerButtons
                />
            }
            {EditTreatment && (
                <TreatmentEditPopover
                    handleReloadAPI={handleReloadAPI}
                    open={EditTreatment}
                    anchorEl={anchorEditTreatment}
                    handleClickAwayLister={handleClickAwayLister}
                    leadId={leadDetail.id}
                    isAddTreatment
                />
            )}
            {templateOpen && (
                <LeadTemplate
                    openEmailCompose={Boolean(templateOpen)}
                    onCloseEmailCompose={handleCloseEmailCompose}
                    practiceId={practiceId}
                    type={'EMAIL'}
                    leadDetail={leadData}
                />
            )}
            <ReusableSidebar
                type='Task-overView'
                getReminderBtn={getReminderBtn}
                overViewbtns={getReminderBtn}
                handleAttendClick={handleAttenedClick}
                handleAttendFailedClick={handleAttenedFailedClick}
                onClose={sidebarCloser}
                data={reminderData}
                open={openSidebar}
            />
        </Stack >
    );
};

Overview.propTypes = {
    leadDetail: PropTypes.object,
    activityList: PropTypes.array,
    onChangeTab: PropTypes.func,
    reloadTaskCounter: PropTypes.func,
    handleLoading: PropTypes.func,
    LeadNotes: PropTypes.array,
    handleMenuItems: PropTypes.func,
    setSelectTreatmentData: PropTypes.object,
    openContactFromCall: PropTypes.bool,
    setOpenContactFromCall: PropTypes.func,
};

export default Overview;