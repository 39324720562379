import PropTypes from 'prop-types';
import React, { useEffect, useState, useRef } from 'react';
// use @mui
import {
    Dialog,
    Box,
    Button,
    Typography,
    Tooltip,
    Divider
} from '@mui/material';

import moment from 'moment';
// use form
import { isEqual } from 'lodash'
import { useForm, Controller } from 'react-hook-form';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
// use components
import { openTaskPop } from 'src/styles/leadTaskReminder';
import { grey, common } from 'src/theme/palette';
import { Iconify } from 'src/components';
import { formats } from 'src/components/editor/EditorToolbar';
import ReactQuill from 'react-quill';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, RHFTextField } from '../../../components/hook-form';


export default function MultipleEditOffersDialog({
    openEditOffer,
    handleCloseEditDialog,
    inputValue,
    setInputValue
}) {

    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
    const [showFormFields, setShowFormFields] = useState(false);
    const [isAddingOffer, setIsAddingOffer] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isOfferChanged, setIsOfferChanged] = useState(false);

    const originalInputValue = useRef(null);

    const EditorMessage = useRef();
    const commonModules = {
        toolbar: [
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline'],
            [
                {
                    color: [common.red, common.green, common.blue, common.orange, common.violet, common.skyBlue],
                },
            ],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            ['link'],
            ['clean']
        ],
        history: {
            delay: 500,
            maxStack: 100,
            userOnly: true,
        },
        syntax: true,
        clipboard: {
            matchVisual: false,
        },
    };

    useEffect(() => {
        if (openEditOffer) {
            const input = inputValue.offers.map((offer) => {
                return {
                    ...offer,
                    offer: offer.offer.replace(/<\/?[^>]+(>|$)/g, '\n')
                }
            });
            originalInputValue.current = JSON.parse(JSON.stringify(input));
            setIsOfferChanged(false);

        }
    }, [openEditOffer]);

    const checkForChanges = () => {
        const isChanged = !isEqual(originalInputValue.current, inputValue.offers);
        setIsOfferChanged(isChanged);
    };

    useEffect(() => {
        if (openEditOffer) {
            checkForChanges();
        }
    }, [inputValue]);

    useEffect(() => {
        setShowFormFields(false);
        setIsAddingOffer(false);
        setIsDatePickerOpen(false);
        setActiveIndex(0);
        setIsOfferChanged(false);
    }, [openEditOffer]);


    const getDate = (date) => {
        if (!date) return null;
        const parsedDate = moment(date, 'DD-MM-YYYY HH:mm:ss.SSSSSSZ');
        return parsedDate.isValid() ? parsedDate.toDate() : null;
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Offer name is required'),
        offer: Yup.string().required('Offer detail is required')
    });

    const methodsEditOffer = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationSchema),
        defaultValues: {
            name: '',
            offer: '',
            description: '',
            expires_at: null,
        },
    });
    const {
        control: controlEditOffer,
        handleSubmit: handleSaveEditOffer,
        reset
    } = methodsEditOffer;

    useEffect(() => {
        if (openEditOffer) {
            if (isAddingOffer) {
                reset({
                    name: '',
                    offer: '',
                    description: '',
                    expires_at: null,
                });
            } else {
                const currentOffer = inputValue?.offers?.[activeIndex];
                reset({
                    name: currentOffer?.name || '',
                    offer: currentOffer?.offer || '',
                    description: currentOffer?.description || '',
                    expires_at: getDate(currentOffer?.expires_at) || null,
                });
            }
        }
    }, [openEditOffer, activeIndex, inputValue, isAddingOffer, reset, showFormFields]);
    const onSubmitEditOffer = (data) => {
        if (isAddingOffer) {
            setInputValue((prevState) => ({
                ...prevState,
                offers: [
                    ...prevState.offers,
                    {
                        name: data.name,
                        offer: data.offer,
                        expires_at: data.expires_at
                            ? `${moment(data.expires_at).format('DD-MM-YYYY HH:mm:ss.SSSSSS')}Z`
                            : null,
                        description: data.description
                    },
                ],
            }));
        } else {
            setInputValue((prevState) => {
                const updatedOffers = [...prevState.offers];
                updatedOffers[activeIndex] = {
                    ...updatedOffers[activeIndex],
                    name: data.name,
                    offer: data.offer,
                    expires_at: data.expires_at
                        ? `${moment(data.expires_at).format('DD-MM-YYYY HH:mm:ss.SSSSSS')}Z`
                        : null,
                    description: data.description
                };
                return { ...prevState, offers: updatedOffers };
            });
        }

        setIsAddingOffer(false);
        setShowFormFields(false);
    };

    const handleAddOffer = () => {
        setIsAddingOffer(true);
        setShowFormFields(true);
    }

    const handleEditOffer = (index) => {
        setIsAddingOffer(false);
        setActiveIndex(index);
        setShowFormFields(true)
    }

    const handleDeleteOffer = (index) => {
        setInputValue((state) => {
            const updatedOffers = [...state.offers];
            updatedOffers.splice(index, 1);
            return { ...state, offers: updatedOffers };
        });
    }

    const handleSaveOffers = () => {
        setShowFormFields(false);
        setIsAddingOffer(false);
        setIsDatePickerOpen(false);
        setActiveIndex(0);
        setIsOfferChanged(false);
        handleCloseEditDialog(isOfferChanged);
    }

    const handleCloseForm = () => {
        setShowFormFields(false);
        setIsAddingOffer(false);
        setActiveIndex(0);
    }

    const editorOnChange = (value, field) => {
        field.onChange(value);
    };

    return (
        <Dialog fullWidth maxWidth={`${(isAddingOffer || showFormFields) ? "lg" : "sm"}`} open={openEditOffer} onClose={handleCloseEditDialog} sx={{ height: isDatePickerOpen ? '900px' : 'hidden' }}>
            <Box sx={{ width: '100%', p: 2, mt: 2 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, alignItems: 'center' }}>
                    <Typography variant='h5' sx={{ color: '#212B36', fontSize: '16px', display: "inline-block" }}>Existing Offers</Typography>
                    <Tooltip title="Add offer">
                        <Box sx={{ ...openTaskPop, width: '36px', height: '36px', bgcolor: grey[200], mr: 3 }} onClick={handleAddOffer} >
                            <Iconify sx={{ color: 'common.grey' }} width={20} height={20} icon={'eva:plus-fill'} />
                        </Box>
                    </Tooltip>
                </Box>
                <Divider sx={{ mb: 2 }} />
                <FormProvider methods={methodsEditOffer} onSubmit={handleSaveEditOffer(onSubmitEditOffer)}>
                    <Box>
                        {

                            !inputValue?.offers?.length ? <Typography sx={{ color: 'grey.500', fontSize: '14px' }}>No common offers available for selected treatment</Typography> : (
                                <>
                                    {
                                        inputValue?.offers?.map((offer, index) => {
                                            return <Box key={index} sx={index !== 0 ? { mt: "10px" } : {}}>
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, gap: 2 }}>
                                                    <Box>
                                                        <Typography sx={{ fontWeight: "500", fontSize: "18px" }}>{index + 1}. {offer?.name}</Typography>

                                                    </Box>


                                                    {showFormFields && activeIndex === index && !isAddingOffer ? <></> : <Box>
                                                        <Tooltip title="Edit">
                                                            <Iconify width={18} height={18} icon={'solar:pen-bold'} sx={{ mr: 1 }} onClick={() => handleEditOffer(index)} /></Tooltip>

                                                        <Tooltip title="Delete"><Iconify width={18} height={18} icon={'solar:trash-bin-trash-bold'} sx={{ mr: 1, color: "rgb(255, 86, 48)" }} onClick={() => handleDeleteOffer(index)} /></Tooltip>

                                                    </Box>}
                                                </Box>
                                                {showFormFields && activeIndex === index && !isAddingOffer && (
                                                    <Box sx={{ p: 2, mb: 1, display: "flex", flexDirection: "column", justifyContent: "center", mt: 1 }} >
                                                        <RHFTextField
                                                            sx={{ width: '100%' }}
                                                            name="name"
                                                            label="Offer Name"
                                                            control={controlEditOffer}
                                                            rules={{
                                                                required: "Offer name is required",
                                                            }}
                                                        />
                                                        <RHFTextField
                                                            sx={{ width: '100%', mt: 2 }}
                                                            name="description"
                                                            label="Offer Description"
                                                            multiline
                                                            minRows={4}
                                                            control={controlEditOffer}
                                                        />
                                                        <Box sx={{ mt: 2, '.ql-container': { borderRadius: "0 0 10px 10px !important", borderColor: "rgba(145, 158, 171, 0.2) !important" }, '.ql-toolbar.ql-snow': { borderRadius: "10px 10px 0 0 !important", borderColor: "rgba(145, 158, 171, 0.2) !important" }, '.ql-container.ql-snow': { minHeight: "250px !important" } }}>
                                                            <Controller
                                                                name={"offer"}
                                                                control={controlEditOffer}
                                                                render={({ field }) => <ReactQuill
                                                                    ref={EditorMessage}
                                                                    modules={commonModules}
                                                                    formats={formats}
                                                                    placeholder='Write offer detail...'
                                                                    value={field.value}
                                                                    onChange={(newValue) => editorOnChange(newValue, field)}
                                                                />
                                                                }
                                                            />
                                                        </Box>
                                                        <Controller
                                                            name="expires_at"
                                                            control={controlEditOffer}
                                                            defaultValue={getDate(inputValue?.offers[activeIndex]?.expires_at) || null}
                                                            render={({ field }) => {
                                                                const parsedDate = getDate(field.value);
                                                                return (
                                                                    <DateTimePicker
                                                                        {...field}
                                                                        sx={{ mt: 2 }}
                                                                        label="Expiry Date"
                                                                        value={parsedDate}
                                                                        onChange={(newValue) => {
                                                                            field.onChange(newValue);
                                                                        }
                                                                        }
                                                                        format={'dd/MM/yyyy HH:mm'}
                                                                        ampm={false}
                                                                        // minDateTime={new Date()}
                                                                        onOpen={() => setIsDatePickerOpen(true)}
                                                                        onClose={() => setIsDatePickerOpen(false)}
                                                                        slotProps={{
                                                                            textField: {
                                                                                fullWidth: true,
                                                                                error: !!field.error,
                                                                                helperText: field.error?.message,
                                                                                onKeyDown: (e) => e.preventDefault()
                                                                            },
                                                                        }}
                                                                    />
                                                                )
                                                            }
                                                            }
                                                        />
                                                    </Box>)}
                                            </Box>
                                        })
                                    }
                                </>
                            )
                        }
                    </Box>


                    {isAddingOffer && <Box sx={{ p: 2, mt: 2, display: "flex", flexDirection: "column", justifyContent: "center" }} >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                            <Typography sx={{ fontWeight: "500", fontSize: "18px" }}>New offer</Typography>
                        </Box>

                        <RHFTextField
                            sx={{ width: '100%' }}
                            name="name"
                            label="Offer Name"
                            control={controlEditOffer}
                            rules={{
                                required: "Offer name is required",
                            }}
                        />
                        <RHFTextField
                            sx={{ width: '100%', mt: 2 }}
                            name="description"
                            label="Offer Description"
                            multiline
                            minRows={4}
                            control={controlEditOffer}
                        />
                        <Box sx={{ mt: 2, '.ql-container': { borderRadius: "0 0 10px 10px !important", borderColor: "rgba(145, 158, 171, 0.2) !important" }, '.ql-toolbar.ql-snow': { borderRadius: "10px 10px 0 0 !important", borderColor: "rgba(145, 158, 171, 0.2) !important" }, '.ql-container.ql-snow': { minHeight: "250px !important" } }}>
                            <Controller
                                name={"offer"}
                                control={controlEditOffer}
                                render={({ field }) => <ReactQuill
                                    ref={EditorMessage}
                                    modules={commonModules}
                                    formats={formats}
                                    placeholder='Write offer detail...'
                                    value={field.value}
                                    onChange={(newValue) => editorOnChange(newValue, field)}
                                />
                                }
                            />
                        </Box>
                        <Controller
                            name="expires_at"
                            control={controlEditOffer}
                            defaultValue={getDate(inputValue?.offers[activeIndex]?.expires_at) || null}
                            render={({ field }) => {
                                const parsedDate = getDate(field.value);
                                return (
                                    <DateTimePicker
                                        {...field}
                                        sx={{ mt: 2 }}
                                        label="Expiry Date"
                                        value={parsedDate}
                                        onChange={(newValue) => {
                                            field.onChange(newValue);
                                        }
                                        }
                                        format={'dd/MM/yyyy HH:mm'}
                                        ampm={false}
                                        // minDateTime={new Date()}
                                        onOpen={() => setIsDatePickerOpen(true)}
                                        onClose={() => setIsDatePickerOpen(false)}
                                        slotProps={{
                                            textField: {
                                                fullWidth: true,
                                                error: !!field.error,
                                                helperText: field.error?.message,
                                                onKeyDown: (e) => e.preventDefault()
                                            },
                                        }}
                                    />
                                )
                            }
                            }
                        />

                    </Box>}



                    {(!showFormFields && !isAddingOffer) ? <Box sx={{ display: 'flex', p: 2, width: '100%', justifyContent: "center", mt: 2 }}>
                        <Button
                            variant="outlined"
                            color="inherit"
                            onClick={handleCloseEditDialog}
                        >
                            cancel
                        </Button>
                        <Button
                            variant="contained"
                            sx={{ ml: 2 }}
                            // disabled={!isOfferChanged}
                            onClick={handleSaveOffers}
                        >
                            Save
                        </Button>
                    </Box> : (
                        <Box sx={{ display: 'flex', p: 2, width: '100%', justifyContent: "center", mt: 2 }}>
                            <Button
                                variant="outlined"
                                color="inherit"
                                onClick={handleCloseForm}
                            >
                                cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{ ml: 2 }}
                            // disabled={!isOfferChanged}
                            // onClick={handleSaveOffers}
                            >
                                Confirm
                            </Button>
                        </Box>
                    )}
                </FormProvider>

            </Box>

        </Dialog>
    );
}

MultipleEditOffersDialog.propTypes = {
    openEditOffer: PropTypes.bool,
    handleCloseEditDialog: PropTypes.func,
    inputValue: PropTypes.object,
    setInputValue: PropTypes.func,
};