
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import CurrencyInput from 'react-currency-input-field';
// use @mui
import {
    Stack,
    Dialog,
    Typography,
    Box,
    Button,
    FormControlLabel,
    Checkbox,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Tooltip,
    Card,
    List,
    ListItem,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { openTaskPop } from 'src/styles/leadTaskReminder';
import { grey } from 'src/theme/palette';
// other libraries
import { isEmpty } from 'lodash';
// use form
import { useForm } from 'react-hook-form';
// use components
import CustomColorPicker from 'src/components/CustomColorPicker';
import { useToggle } from 'src/hooks';
import { COLOR_CODES } from 'src/constants/newLead';
import { Iconify } from 'src/components';
import ReactHtmlParser from 'react-html-parser';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
import {
    ErrorTextStyle,
    AmountInputStyle
} from '../../../styles/PaymentRefund';
import EditOffersDialog from './EditOffersDialog';
import EditAdditionalInfoDialog from './EditAdditionalInfoDialog';

export default function PracticeEditDialog({
    row,
    open,
    onClose,
    treatmentName,
    treatmentCategory,
    treatmentStatus = false,
    treatmentPrice,
    treatmentData,
    saveTreatMentData,
    offers = [],
    description,
    additionalInfo = []
}) {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';
    const [amountError, setAmountError] = useState(false);
    const [decimalSeparator] = useState('.');
    const [thousandSeparator] = useState(',');
    const [inputType] = useState('text');
    const [anchorEl, setAnchorEl] = useState(null);
    const [openEditOffer, setOpenEditOffer] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [isAddingOffer, setIsAddingOffer] = useState(false);
    const [openEditInfo, setOpenEditInfo] = useState(false)

    const { toggle: openColor, onOpen: onColorOpen, onClose: onColorClose } = useToggle();


    const defaultValues = useMemo(
        () => ({
            price: row?.price || '',
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [row]
    );

    const [amount, setAmount] = useState(treatmentPrice || 0.00);

    const [inputValue, setInputValue] = useState({
        price: amount,
        checked: treatmentStatus,
        color: treatmentData?.colour,
        isStarred: treatmentData?.is_starred || false,
        icon: treatmentData?.icon || '',
        offers: offers.map((offer) => ({
            name: offer.name,
            offer: offer.offer,
            description: offer.description,
            expires_at: offer.expires_at,
        })),
        description: description || '',
        additionalInfo: additionalInfo.map(info => ({
            text: info.text,
            categories: info.categories,
            custom_identifier: info.custom_identifier,
            is_enabled: info.is_enabled
        }))
    });


    const handleChangeColor = (color) => {
        setInputValue((state) => ({ ...state, color }));
        onColorClose();
    };

    const handleChangeIcon = (e) => {
        setInputValue((state) => ({ ...state, icon: e.target.value }));
    };

    const handleOpenColorPicker = (event) => {
        onColorOpen();
        setAnchorEl(event.currentTarget);
    };

    const handleCloseColorPicker = () => {
        onColorClose();
        setAnchorEl(null);
    };

    const handleChange = (value) => {
        setAmount(value);
        setInputValue({
            ...inputValue,
            price: value
        });
        if (value > 0) {
            setAmountError(false);
            return;
        }
        setAmountError(true);
    };

    const handleFavoriteChange = (e) => {
        setInputValue({
            ...inputValue,
            isStarred: e.target.checked
        });
    };

    const handleDescriptionChange = (e) => {
        setInputValue({
            ...inputValue,
            description: e.target.value
        })
    }

    const methods = useForm({
        defaultValues,
    });

    const {
        handleSubmit,
        // control
    } = methods;


    const onSubmit = async () => {
        if ((amount === 0 || amount === '0.00' || amount === undefined)) {
            setAmountError(true);
            return;
        }

        saveTreatMentData(inputValue);
        onClose();
    };

    const ICONS = ['mdi:tooth-outline', 'mdi:tooth'];

    const handleEditOffer = (index) => {
        setActiveIndex(index);
        setOpenEditOffer(true);
    }

    const handleDeleteOffer = (index) => {
        setInputValue((state) => {
            const updatedOffers = [...state.offers];
            updatedOffers.splice(index, 1);
            return { ...state, offers: updatedOffers };
        });
    }

    const handleCloseEditDialog = () => {
        setOpenEditOffer(false);
        setActiveIndex(0);
        setIsAddingOffer(false);
    }

    const handleAddOffer = () => {
        setIsAddingOffer(true);
        setOpenEditOffer(true);
    }

    const handleEditInfo = (index) => {
        setActiveIndex(index);
        setOpenEditInfo(true);
    }

    const handleAddInfo = () => {
        setIsAddingOffer(true);
        setOpenEditInfo(true);
    }

    const handleDeleteInfo = (index) => {
        setInputValue((state) => {
            const updatedInfo = [...state.additionalInfo];
            updatedInfo.splice(index, 1);
            return { ...state, additionalInfo: updatedInfo };
        });
    }
    const handleCloseInfoDialog = () => {
        setOpenEditInfo(false);
        setActiveIndex(0);
        setIsAddingOffer(false);
    }

    return (
        <>
            <Dialog fullWidth maxWidth="lg" open={open} onClose={onClose}>
                <Stack spacing={1} sx={{ px: 2, background: "#F4F6F8", py: 3 }}>
                    <Typography variant="h6" sx={{ px: 2 }}>
                        Edit Treatment
                    </Typography>
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={2} >
                            <Box sx={{ display: "flex", gap: 1, width: "100%", px: 2 }}>
                                <Card sx={{ display: "flex", flexDirection: "column", gap: 2, width: "35%", borderRadius: "16px", background: "#ffff", p: 2 }}>
                                    <RHFTextField
                                        value={treatmentName}
                                        name="Treatment name"
                                        label="Treatment name"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <RHFTextField
                                        value={treatmentCategory}
                                        name="Category"
                                        label="Category"
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <Box sx={{}}>
                                        <CurrencyInput
                                            name="treatmentValue"
                                            value={amount}
                                            decimalSeparator={decimalSeparator}
                                            thousandSeparator={thousandSeparator}
                                            onValueChange={(value, name) => handleChange(value, name)}
                                            className="currency-input"
                                            decimalsLimit={2}
                                            decimalScale={2}
                                            inputType={inputType}
                                            prefix={'£ '}
                                            style={AmountInputStyle(amountError, 'appointment', isLight)}
                                            step={1}
                                        />
                                        {amountError && <Typography variant='p' sx={ErrorTextStyle}>This field is required</Typography>}
                                    </Box>
                                    <Box sx={{}}>
                                        <FormControl sx={{ width: '100%' }}>
                                            <InputLabel
                                                size='normal'
                                                name="icon"
                                            >
                                                Select icon
                                            </InputLabel>
                                            <Select
                                                name="icon"
                                                value={inputValue?.icon}
                                                label="Select icon"
                                                onChange={handleChangeIcon}
                                            >
                                                {ICONS.map((icon) => (
                                                    <MenuItem
                                                        key={icon}
                                                        value={icon}
                                                    >
                                                        <Iconify
                                                            icon={icon}
                                                            width={24}
                                                            height={24}
                                                        />
                                                    </MenuItem>
                                                ))}
                                                {!isEmpty(inputValue?.icon) && <MenuItem
                                                    value={''}
                                                >
                                                    Remove Icon
                                                </MenuItem>}
                                            </Select>
                                        </FormControl>
                                    </Box>
                                    <Box sx={{ display: 'flex' }}>
                                        <Typography variant='p' sx={{ color: '#212B36', fontSize: '16px' }}>Select color: </Typography>
                                        <CustomColorPicker open={openColor}
                                            openColorPicker={handleOpenColorPicker}
                                            handleClose={handleCloseColorPicker}
                                            colorList={COLOR_CODES}
                                            value={inputValue?.color}
                                            anchorEl={anchorEl}
                                            onChange={handleChangeColor}
                                            style={{ marginLeft: '15px' }}
                                        />
                                    </Box>
                                    <Box sx={{ px: 1 }}>
                                        <FormControlLabel control={<Checkbox checked={inputValue.isStarred}
                                            onChange={handleFavoriteChange}
                                            inputProps={{ 'aria-label': 'controlled' }} />} label="Is Starred" />
                                    </Box>
                                    <RHFTextField
                                        value={inputValue.description}
                                        name="description"
                                        label="Description"
                                        multiline
                                        minRows={5}
                                        onChange={handleDescriptionChange}
                                    />
                                </Card>

                                <Card sx={{ display: "flex", flexDirection: "column", gap: 3, width: "65%", borderRadius: "16px", background: "#ffff", p: 2 }}>
                                    <Box sx={{ width: '100%' }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, alignItems: 'center' }}>
                                            <Typography variant='h6' sx={{ color: '#212B36', fontSize: '16px', display: "inline-block" }}>Offers</Typography>
                                            <Tooltip title="Add offer">
                                                <Box sx={{ ...openTaskPop, width: '36px', height: '36px', bgcolor: grey[200], mr: 3 }} onClick={handleAddOffer} >
                                                    <Iconify sx={{ color: 'common.grey' }} width={15} height={15} icon={'eva:plus-fill'} />
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                        {inputValue?.offers.length > 0 ? (
                                            <List sx={{ padding: 0 }}>
                                                {inputValue.offers.map((offer, index) => (
                                                    <ListItem key={index} sx={{ display: "list-item", pt: 0 }}>
                                                        <Box sx={{ justifyContent: 'space-between', alignItems: 'center', display: "flex" }}>
                                                            <Typography> {index + 1}. {offer?.name}</Typography>
                                                            <Box>
                                                                <Tooltip title="Edit">
                                                                    <Iconify width={18} height={18} icon={'solar:pen-bold'} sx={{ mr: 1 }} onClick={() => handleEditOffer(index)} /></Tooltip>

                                                                <Tooltip title="Delete"><Iconify width={18} height={18} icon={'solar:trash-bin-trash-bold'} sx={{ mr: 1, color: "rgb(255, 86, 48)" }} onClick={() => handleDeleteOffer(index)} /></Tooltip>

                                                            </Box>
                                                        </Box>
                                                        {offer?.description && <Typography sx={{ color: "common.grey", pl:1}}>&#9658; {offer?.description}</Typography>}
                                                        {offer?.offer && <Box sx={{'& p, & h1, & h2, & h3, & h4, & h5, & h6, & blockquote': { margin: 0, padding: 0 }}}>{ReactHtmlParser(offer.offer)}</Box>}

                                                    </ListItem>
                                                ))}
                                            </List>
                                        ) : <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, color: "common.grey" }}>No offers added</Box>}
                                    </Box>

                                    <Box sx={{ width: "100%" }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1, alignItems: 'center' }}>
                                            <Typography variant='h6' sx={{ color: '#212B36', fontSize: '16px', display: "inline-block" }}>Additional Info</Typography>
                                            <Tooltip title="Add info">
                                                <Box sx={{ ...openTaskPop, width: '36px', height: '36px', bgcolor: grey[200], mr: 3 }} onClick={handleAddInfo} >
                                                    <Iconify sx={{ color: 'common.grey' }} width={15} height={15} icon={'eva:plus-fill'} />
                                                </Box>
                                            </Tooltip>
                                        </Box>
                                        {inputValue?.additionalInfo.length > 0 ? (
                                            <List sx={{ padding: 0 }}>
                                                {inputValue.additionalInfo.map((info, index) => (
                                                    <ListItem key={index} sx={{ display: "list-item", pt: 0 }}>
                                                        <Box sx={{ justifyContent: 'space-between', alignItems: 'center', display: "flex" }}>
                                                            <Typography>
                                                                {index + 1}. Category - 
                                                                <Typography sx={{ fontWeight: "600", display: "inline-block", mx: "5px" }}>{info?.categories?.join(', ')}</Typography>
                                                            </Typography>
                                                            <Box>
                                                                <Tooltip title="Edit">
                                                                    <Iconify width={18} height={18} icon={'solar:pen-bold'} sx={{ mr: 1 }} onClick={() => handleEditInfo(index)} /></Tooltip>

                                                                <Tooltip title="Delete"><Iconify width={18} height={18} icon={'solar:trash-bin-trash-bold'} sx={{ mr: 1, color: "rgb(255, 86, 48)" }} onClick={() => handleDeleteInfo(index)} /></Tooltip>
                                                            </Box>
                                                        </Box>
                                                        <Box sx={{'& p, & h1, & h2, & h3, & h4, & h5, & h6, & blockquote': { margin: 0, padding: 0 }}}>{ReactHtmlParser(info?.text)}</Box>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        ) : <Box sx={{ display: 'flex', justifyContent: 'space-between', px: 2, color: "common.grey" }}>No additional offers added</Box>}

                                    </Box>
                                </Card>
                            </Box>
                            <Stack width="160px" marginTop={0} justifyContent="space-between" flexDirection="row" alignItems="flex-start" alignSelf="flex-end">
                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    onClick={onClose}
                                >
                                    cancel
                                </Button>
                                <Button
                                    variant="contained"
                                    type="submit"
                                >
                                    save
                                </Button>
                            </Stack>
                        </Stack>
                    </FormProvider>
                </Stack>
            </Dialog>
            {openEditOffer && <EditOffersDialog openEditOffer={openEditOffer} handleCloseEditDialog={handleCloseEditDialog} isAddingOffer={isAddingOffer} setIsAddingOffer={setIsAddingOffer} activeIndex={activeIndex} inputValue={inputValue} setInputValue={setInputValue} />}

            <EditAdditionalInfoDialog openEditInfo={openEditInfo} handleCloseInfoDialog={handleCloseInfoDialog} isAddingOffer={isAddingOffer} setIsAddingOffer={setIsAddingOffer} activeIndex={activeIndex} inputValue={inputValue} setInputValue={setInputValue} />
        </>
    );
}

PracticeEditDialog.propTypes = {
    row: PropTypes.object,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    treatmentName: PropTypes.string,
    treatmentCategory: PropTypes.string,
    treatmentStatus: PropTypes.bool,
    treatmentPrice: PropTypes.number,
    treatmentData: PropTypes.any,
    saveTreatMentData: PropTypes.func,
    offers: PropTypes.any,
    description: PropTypes.string,
    additionalInfo: PropTypes.array,
};