/* eslint-disable no-nested-ternary */
import { createSlice } from '@reduxjs/toolkit';
import { uniqBy } from 'lodash';
//
import { dispatch } from '../store';
import { updateError } from './user';
// utils
import { callApi } from '../../utils/apiCall';
// constants
import { LEAD_UPDATE_SUCCESS, SOMETHING_WENT_WRONG } from '../../constants/apiMessages';

const initialState = {
    isLoading: false,
    tag: {},
    deletetag: '',
    getTag: [],
    getAllTag: [],
    error: '',
    message: '',
    otherTag: {}
};

const slice = createSlice({
    name: 'tagsCreate',
    initialState,
    reducers: {
        reset(state) {
            Object.assign(state, initialState);
        },
        startLoading(state) {
            state.isLoading = true;
            state.message = '';
        },
        createTagData(state, action) {
            state.isLoading = false;
            state.tag = action.payload;
        },
        deleteTagData(state) {
            state.isLoading = false;
            state.deletetag = 'Tag deleted successfully';
        },
        getTagData(state, action) {
            state.isLoading = false;
            state.getTag = action.payload;
        },
        getAllTagData(state, action) {
            state.isLoading = false;
            state.getAllTag = action.payload;
        },
        addTagsList(state, action) {
            state.isLoading = false;
            state.getAllTag = action.payload;
        },
        addMoreTagsList(state, action) {
            state.isLoading = false;
            state.getAllTag.results = uniqBy([
                ...state.getAllTag.results,
                ...action.payload
            ], 'id');
        },
        hasError(state, action) {
            state.isLoading = false;
            state.error = action.payload;
        },
        updateLoader(state, action) {
            state.isLoading = action.payload;
        },
        createOtherTagData(state, action) {
            state.isLoading = false;
            state.otherTag = action.payload;
        },
    }
});

export const { reset, startLoading } = slice.actions;

// Reducer
export default slice.reducer;

export function createCustomTagItem(data, practiceId, id, handleNext, tagObject) {
    const category = tagObject ? tagObject === 'campaigns' ? 'CAMPAIGN' : 'ENGAGEMENT_TEMPLATE' : 'LEAD';
    const updatedData = {...data, categories: [category]}
    return async () => {
        try {
            dispatch(slice.actions.updateLoader(true));
            const response = await callApi(`/api/v1/practices/${practiceId}/tags/`, 'post', updatedData);
            if (response.status === 201) {
                const NewTag = {
                    name: response.data.name,
                    colour: response.data.colour
                };
                if(tagObject){
                    dispatch(createOtherTagItem(tagObject, NewTag, practiceId, id, handleNext, 'custom'));
                }
                else {
                    dispatch(createTagItem(NewTag, practiceId, id, handleNext, 'custom'));
                }
                
                dispatch(updateError({ success: true, message: 'Tag created successfully' }));
            } else {
                dispatch(updateError({ message: JSON.stringify(response?.data), success: false }));
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}


export function createTagItem(data, practiceId, id, handleReload, type) {
    return async () => {
        try {
            dispatch(slice.actions.updateLoader(true));
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${id}/tag/`, 'post', data);
            if (response.status === 201) {
                dispatch(slice.actions.createTagData(response.data));
                dispatch(updateError({ message: LEAD_UPDATE_SUCCESS, success: true }));
                handleReload(type || 'tag');
            } else {
                dispatch(updateError({ success: false, message: response.data.message || SOMETHING_WENT_WRONG }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
        }
    };
}

export function getAllTagItem(practiceId, limit, offset, data, category) {
    return async () => {
        dispatch(slice.actions.startLoading());
        try {
            dispatch(slice.actions.updateLoader(true));
            const response = await callApi(`/api/v1/practices/${practiceId}/tags/?limit=${limit}&offset=${offset}&fields={id,colour,name}&categories=${category || `LEAD`}${data && data?.tagged__items__tag__name__icontains ? `&tagged__items__tag__name__icontains=${data.tagged__items__tag__name__icontains}` : ''}`, 'get');
            if (limit) {
                if (offset === 0) {
                    dispatch(slice.actions.addTagsList(response?.data));
                } else {
                    dispatch(slice.actions.addMoreTagsList(response?.data?.results));
                }
            }
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getTagItem(practiceId, id) {
    return async () => {
        try {
            dispatch(slice.actions.updateLoader(true));
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${id}/tags/?fields={colour,id,name}`);
            dispatch(slice.actions.getTagData(response.data.results));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function deleteTagItem(data, practiceId, id, handleClose) {
    return async () => {
        try {
            dispatch(slice.actions.updateLoader(true));
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${id}/untag/`, 'post', data);
            if (response.status === 200) {
                dispatch(slice.actions.deleteTagData());
                dispatch(updateError({ message: LEAD_UPDATE_SUCCESS, success: true }));
                handleClose('tag', id);
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
        }
    };
}

export function createOtherTagItem(tagObject, data, practiceId, id, handleReload, type) {
    return async () => {
        try {
            dispatch(slice.actions.updateLoader(true));
            const response = await callApi(`/api/v1/practices/${practiceId}/${tagObject}/${id}/tag/`, 'post', data);
            if (response.status === 201) {
                dispatch(slice.actions.createOtherTagData(response.data));
                dispatch(updateError({ message: `${tagObject === "campaigns" ? "Campaign" : "Engagement-template"} updated successfully`, success: true }));
                handleReload(type || {success: true});
            } else {
                dispatch(updateError({ success: false, message: response.data.message || SOMETHING_WENT_WRONG }));
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
        }
    };
}

export function deleteOtherTagItem(tagObject, data, practiceId, id, handleClose) {
    return async () => {
        try {
            dispatch(slice.actions.updateLoader(true));
            const response = await callApi(`/api/v1/practices/${practiceId}/${tagObject}/${id}/untag/`, 'post', data);
            if (response.status === 200) {
                dispatch(slice.actions.deleteTagData());
                dispatch(updateError({ message: `${tagObject === "campaigns" ? "Campaign" : "Engagement-template"} updated successfully`, success: true }));
                handleClose({success: true, key: "deleted"});
            }
        } catch (error) {
            dispatch(updateError({ message: JSON.stringify(error.message), success: false }));
        }
    };
}