import { createSlice } from "@reduxjs/toolkit";
import { callApi } from "src/utils/apiCall";
import { dispatch, store } from "../store";
import { updateError } from "./user";

const INITIAL_STATE = {
    mergeTagKeys: [],
    mtValPractice: {},
    mtValTreatments: {},
    mtValLeads: {},
    mtValUser: {},
    mergeTagKeysLoading: false,
    mtValLoadingPractice: false,
    mtValLoadingLeads: false,
    mtValLoadingTreatments: false,
    mtValLoadingUser: false,
    settingsMenuActive: false,
    selectedPracticeForMenu: "practice_details",
    practiceUsers: [],
}

const slice = createSlice({
    name: "mergeTags",
    initialState: INITIAL_STATE,
    reducers: {
        reset(state) {
            Object.assign(state, INITIAL_STATE);
        },
        resetTreatmentAndLead(state) {
            state.mtValLoadingLeads = false;
            state.mtValLeads = {};
            state.mtValLoadingTreatments = false;
            state.mtValTreatments = {};
        },
        fetchMergeTagKeys(state) {
            state.mergeTagKeysLoading = true;
        },
        storeMergeTagKey(state, action) {
            state.mergeTagKeysLoading = false;
            state.mergeTagKeys = action?.payload;
        },
        fetchMergeTagValueForPractice(state) {
            state.mtValLoadingPractice = true;
            state.mtValPractice = {};
        },
        fetchMergeTagValueForLead(state) {
            state.mtValLoadingLeads = true;
            state.mtValLeads = {};
        },
        fetchMergeTagValueForTreatment(state) {
            state.mtValLoadingTreatments = true;
            state.mtValTreatments = {};
        },
        fetchMergeTagValueForUser(state) {
            state.mtValLoadingUser = true;
            state.mtValUser = {};
        },
        storeMergeTagValueForPractice(state, action) {
            state.mtValLoadingPractice = false;
            state.mtValPractice = action?.payload;
        },
        storeMergeTagValueForLead(state, action) {
            state.mtValLoadingLeads = false;
            state.mtValLeads = action?.payload;
        },
        storeMergeTagValueForTreatment(state, action) {
            state.mtValLoadingTreatments = false;
            state.mtValTreatments = action?.payload;
        },
        storeMergeTagValueForUser(state, action) {
            state.mtValLoadingUser = false;
            state.mtValUser = action?.payload;
        },
        updateSettingsMenuActive(state, action) {
            state.settingsMenuActive = action.payload;
        },
        updateSelectedPracticeForMenu(state, action) {
            state.selectedPracticeForMenu = action.payload
        },
        updatePracticeUsers(state, action) {
            state.practiceUsers = action.payload
        },
    }
});

export default slice.reducer;
export const { reset, resetTreatmentAndLead, updateSettingsMenuActive, updateSelectedPracticeForMenu } = slice.actions;

const getPracticeId = () => store.getState()?.practiceTreatmentList?.practiceId;

export function fetchMergeTagKeys() {
    return async () => {
        try {
            dispatch(slice?.actions?.fetchMergeTagKeys());
            const response = await callApi(`/api/v1/utils/mergetags/`);
            if (response?.status === 200) {
                const massagedData = response?.data?.map(el => el?.tag)?.sort();
                dispatch(slice?.actions?.storeMergeTagKey(massagedData));
            } else {
                throw new Error("Response Status not success");
            }
        } catch (e) {
            console.error("Error while fetching form Data", e);
            dispatch(slice?.actions?.storeMergeTagKey([]));
            dispatch(updateError({ success: false, message: 'Failed to fetch data, please try again after sometime' }));
        }
    }
}

export function fetchMergeTagValueForPractice() {
    return async () => {
        try {
            dispatch(slice?.actions?.fetchMergeTagValueForPractice());
            const practiceId = getPracticeId();
            const response = await callApi(`/api/v1/practices/${practiceId}/get_context/`);
            if (response?.status === 200) {
                dispatch(slice?.actions?.storeMergeTagValueForPractice(response?.data ?? {}));
            } else {
                throw new Error("Response Status not success");
            }
        } catch (e) {
            console.error("Error while fetching form Data", e);
            dispatch(slice?.actions?.storeMergeTagValueForPractice({}));
            dispatch(updateError({ success: false, message: 'Failed to fetch data, please try again after sometime' }));
        }
    }
}

export function fetchPracticeUsers() {
    return async () => {
        try {
            dispatch(slice?.actions?.updatePracticeUsers([]));
            const practiceId = getPracticeId();
            const response = await callApi(`/api/v1/practices/${practiceId}/reports/practice-user`);
            if (response?.status === 200) {
                const data = response?.data?.
                    filter(obj => obj?.role === "Practitioner")?.
                    map(obj => {
                        const practicionerName = `${obj?.user?.first_name ?? ""} ${obj?.user?.last_name ?? ""}`;
                        return ({
                            label: practicionerName?.trim(),
                            value: practicionerName?.toUpperCase()?.trim(),
                            icon: "fluent-emoji-high-contrast:health-worker",
                            id: obj?.id
                        })
                    })
                dispatch(slice?.actions?.updatePracticeUsers(data ?? []));
            } else {
                throw new Error("Response Status not success");
            }
        } catch (e) {
            console.error("Error while fetching practice user Data", e);
            dispatch(slice?.actions?.updatePracticeUsers([]));
            dispatch(updateError({ success: false, message: 'Failed to Fetch Practice Users, please try again after sometime' }));
        }
    }
}

export function fetchMergeTagValueForUser() {
    return async () => {
        try {
            dispatch(slice?.actions?.fetchMergeTagValueForUser());
            const response = await callApi(`/api/v1/auth/user/get_context/`);
            if (response?.status === 200) {
                dispatch(slice?.actions?.storeMergeTagValueForUser(response?.data ?? {}));
            } else {
                throw new Error("Response Status not success");
            }
        } catch (e) {
            console.error("Error while fetching form Data", e);
            dispatch(slice?.actions?.storeMergeTagValueForUser({}));
            dispatch(updateError({ success: false, message: 'Failed to fetch data, please try again after sometime' }));
        }
    }
}

export function fetchMergeTagValueForLeads(leadId) {
    return async () => {
        try {
            dispatch(slice?.actions?.fetchMergeTagValueForLead());
            const practiceId = getPracticeId();
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${leadId}/get_context/`);
            if (response?.status === 200) {
                dispatch(slice?.actions?.storeMergeTagValueForLead(response?.data ?? {}));
            } else {
                throw new Error("Response Status not success");
            }
        } catch (e) {
            console.error("Error while fetching form Data", e);
            dispatch(slice?.actions?.storeMergeTagValueForLead({}));
            dispatch(updateError({ success: false, message: 'Failed to fetch data, please try again after sometime' }));
        }
    }
}

export function fetchMergeTagValueForTreatments(leadId, treatmentId) {
    return async () => {
        try {
            if (!(leadId && treatmentId)) {
                dispatch(slice?.actions?.storeMergeTagValueForTreatment({}));
                return;
            }
            dispatch(slice?.actions?.fetchMergeTagValueForTreatment());
            const practiceId = getPracticeId();
            const response = await callApi(`/api/v1/practices/${practiceId}/leads/${leadId}/treatments/${treatmentId}/get_context/`);
            if (response?.status === 200) {
                dispatch(slice?.actions?.storeMergeTagValueForTreatment(response?.data ?? {}));
            } else {
                throw new Error("Response Status not success");
            }
        } catch (e) {
            console.error("Error while fetching form Data", e);
            dispatch(slice?.actions?.storeMergeTagValueForTreatment({}));
            dispatch(updateError({ success: false, message: 'Failed to fetch data, please try again after sometime' }));
        }
    }
}