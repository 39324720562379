import PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import useSnackbar from 'src/hooks/useCustomSnackbar';
// @mui
import {
    Box, Typography, Button
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
// hook
// components
import { Scrollbar } from '../../../components';
import { FormProvider, RHFTextField } from '../../../components/hook-form';
// hooks
import { useAuth } from '../../../hooks';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// redux
import { getPracticeTreatmentListData } from '../../../redux/slices/practiceTreatmentList';
import { getPracticeUserList } from '../../../redux/slices/practiceUser';
import { dispatch, useSelector } from '../../../redux/store';
import { addNotes } from '../../../redux/slices/lead';
// validation
import { UserPasswordSchema } from '../../../validations/validations';
// style
import { textFieldStyle, numberStyle } from '../../../styles/SwitchUserPopupStyle';

// ----------------------------------------------------------------------------------------------

const SwitchUserlogin = forwardRef(({ Users, handleNext, type, options, setTemplateOpen }, ref) => {
    const navigate = useNavigate();
    const { login, isAuthenticated } = useAuth();
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { enqueueSnackbar } = useSnackbar();
    const { leadDetail } = useSelector((state) => state.lead);

    const defaultValues = {
        password: ''
    };

    const methods = useForm({
        resolver: yupResolver(UserPasswordSchema),
        defaultValues
    });

    const {
        handleSubmit,
        setValue
    } = methods;

    useImperativeHandle(
        ref,
        () => ({
            async submit() {
                await handleSubmit(onSubmit)();
            },
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const onSubmit = async (data) => {
        if (type === 'LeadDetail' || type === 'TaskSidebar') {
            const Details = {
                lead: type === 'TaskSidebar' ? Users?.lead?.id : Users.id,
                additional_notes: data.password,
                note: 'FOLLOW_UP'
            };
            if (leadDetail?.lead_treatments?.length > 0) {
                Details.treatment = leadDetail?.lead_treatments[0]?.id;
            }
            if (options === 'whatsapp') {
                Details.contacted_via = 'WHATSAPP';
            }
            if (options === 'phoneNumber') {
                Details.contacted_via = 'PHONE';
            }
            if (options === 'email') {
                Details.contacted_via = 'EMAIL';
            }
            dispatch(addNotes(Details, practiceId, handleClose, 'leadNotePopup'));
            return;
        }
        try {
            await login(Users?.user?.email, data.password);
            if (isAuthenticated) {
                handleNext(true);
                await dispatch(getPracticeTreatmentListData());
                dispatch(getPracticeUserList(practiceId));
                navigate(PATH_DASHBOARD.root);
            }
            setValue('password', '');
        } catch (error) {
            Object.entries(error).forEach(([, value]) => {
                enqueueSnackbar(value[0], {
                    variant: 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                    }
                });
            });
        }
    };

    const handleClose = (status) => {
        if (status?.toLowerCase?.() === 'finish') {
            handleNext(true);
        }
    };

    const openWhatsApp = (phoneNumber) => {
        const whatsappLink = `https://wa.me/${phoneNumber}`;
        window.open(whatsappLink, '_blank');
    };

    return (
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ margin: '0 -8px' }}>
                <Scrollbar sx={{ maxHeight: 64 * 6 }}>
                    <Box sx={{ textAlign: 'center' }}>
                        {options === 'whatsapp' && <Button color="primary" onClick={() => openWhatsApp(Users?.lead_contact?.phone || Users?.lead?.lead_contact?.phone)} variant='contained' sx={{ mb: 5, padding: '6px 28px' }} >Send WhatsApp Message</Button>}
                        {options === 'phoneNumber' && <Button color="primary" onClick={() => { window.location.href = `tel://${leadDetail?.lead_contact?.phone || Users?.lead?.lead_contact?.phone}`; }} variant='contained' sx={{ mb: 5, padding: '6px 28px' }} >Call</Button>}
                        {options === 'email' && <Button color="primary" onClick={() => { setTemplateOpen(true); }} variant='contained' sx={{ mb: 5, padding: '6px 28px' }} >Send Email</Button>}
                        <Typography sx={{ fontSize: '20px', fontWeight: '700', m: '0 0 16px' }}>Log Communication</Typography>
                        {Users?.lead_contact?.phone && <Box onClick={() => openWhatsApp(Users?.lead_contact?.phone)} sx={{ ...numberStyle, display: 'none' }}>
                            {Users?.lead_contact?.phone}
                        </Box>}
                        <RHFTextField
                            sx={textFieldStyle}
                            name='password'
                            rows={8}
                            multiline={type === 'LeadDetail' || type === 'TaskSidebar'}
                            label={options === 'whatsapp' ? 'Add note / What happend on WhatsApp' : 'Add note'}
                            placeholder={'Leave a note..'}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Box>
                </Scrollbar>
            </Box>
        </FormProvider>
    );
});

SwitchUserlogin.propTypes = {
    Users: PropTypes.any,
    handleNext: PropTypes.func,
    type: PropTypes.string,
    options: PropTypes.string,
    setTemplateOpen: PropTypes.any
};

export default SwitchUserlogin;
