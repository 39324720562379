/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
// libraries
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import { useDebounce } from 'use-lodash-debounce';
import {
    Box,
    TextField,
    Autocomplete,
    useTheme,
    Chip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    Button,
    DialogActions,
} from '@mui/material';
import { createFilterOptions } from '@mui/material/Autocomplete';
// redux
import { useSelector, dispatch } from '../../redux/store';
import { createCustomTagItem, createOtherTagItem, deleteOtherTagItem, getAllTagItem, startLoading } from '../../redux/slices/tagsCreate';
// validation
import { AutocompleteTagsSchema } from '../../validations/validations';
// style
import {
    tagsBoxStyle,
    selectedTagStyle,
    tagsStyle,
    InputTags,
} from '../../styles/AutocompleteTags';
import { FormProvider, RHFTextField } from '../hook-form';

const AutocompleteCampaignTags = ({ row, handleAddTag, handleDeleteTag, tagType }) => {
    const { getAllTag: { results = [], count = 0 } = {}, isLoading = false } = useSelector((state) => state.tagsCreate || []);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const allData = row?.tags || [];
    const [position, setPosition] = useState(0);
    const listElem = useRef();
    const mounted = useRef();
    const [inputValue, setInputValue] = useState('');
    const [open, toggleOpen] = useState(false);
    const inputValueDebounce = useDebounce(inputValue, 800);

    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';

    const filter = createFilterOptions();

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else if (position && listElem.current) {
            listElem.current.scrollTop = position - listElem?.current?.offsetHeight;
        }
    }, [position]);

    const defaultValues = {
        tags: [],
    };

    const allTags = results;

    const methods = useForm({
        mode: 'onChange',
        resolver: yupResolver(AutocompleteTagsSchema),
        defaultValues,
    });

    const {
        handleSubmit,
        setValue,
        getValues,
        control,
    } = methods;

    const getOptionLabel = (option) => {

        if (typeof option === 'string') {
            return option;
        }
        if (option.inputValue) {
            return option?.inputValue;
        }
        return option?.name || '';
    };

    const leadMoreData = () => {
        if (count > results?.length) {
            dispatch(getAllTagItem(practiceId, 30, results?.length,'', tagType === 'campaigns' ? 'CAMPAIGN' : 'ENGAGEMENT_TEMPLATE'));
        }
    };

    const leadMorePosition = (event) => {
        const listboxNode = event?.target?.scrollHeight <= event?.target?.scrollTop + event?.target?.clientHeight;
        if (listboxNode) {
            setPosition(event?.target?.scrollTop + event?.target?.clientHeight);
            leadMoreData();
        }
    };

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return; // Skip first mount
        }

        if (practiceId) {
            if (inputValueDebounce) {
                const data = {
                    tagged__items__tag__name__icontains: inputValueDebounce,
                };
                dispatch(getAllTagItem(practiceId, 30, 0, data, tagType === 'campaigns' ? 'CAMPAIGN' : 'ENGAGEMENT_TEMPLATE'));
            }
            else {
                dispatch(getAllTagItem(practiceId, 30, 0, '', tagType === 'campaigns' ? 'CAMPAIGN' : 'ENGAGEMENT_TEMPLATE'));
            }
        }
    }, [inputValueDebounce]);

    const inputChangeHandler = (value) => {
        dispatch(startLoading());
        setInputValue(value);
    };

    const handleSpaceKeyDown = (event) => {
        if (event.key === ' ') {
            event.stopPropagation();
        }
    };

    const filterOptions = (options, params) => {

        const filtered = filter(options, params);
        if (filtered.length <= 0 && !isLoading) {
            filtered.push({
                inputValue: params?.inputValue,
                name: `Add "${params?.inputValue}"`,
            });
        }
        return filtered;
    };

    const handleDelete = (chipToDelete) => () => {
        dispatch(deleteOtherTagItem(tagType, chipToDelete, practiceId, row.id, handleSuccess));
    };

    const onSubmit = (tagData) => {
        const data = {
            name: tagData.tagName
        };
        setValue('tags', [
            ...getValues('tags'),
            {
                title: tagData.tagName,
                tagId: tagData?.tagId
            },
        ]);
        dispatch(createCustomTagItem(data, practiceId, row.id, handleSuccess, tagType));
    };

    const handleTags = (event, newValue) => {
        const data = newValue.filter(value => typeof value === 'string');
        if (event.target.value) {
            toggleOpen(true);
            setValue('tagName', event.target.value);
            return;
        }
        if (data?.length === 0 && (event.key !== 'Backspace')) {

            if (newValue && newValue?.length > 0 && (newValue[newValue?.length - 1]?.inputValue === '' || newValue[newValue?.length - 1]?.inputValue)) {
                toggleOpen(true);
                setValue('tagName', newValue[newValue?.length - 1]?.inputValue);
            } else {
                setValue('tags', newValue[newValue?.length - 1]?.name);
                const tagData = {
                    name: newValue[newValue.length - 1]?.name,
                    colour: newValue[newValue.length - 1]?.colour
                };
                dispatch(createOtherTagItem(tagType, tagData, practiceId, row?.id, handleSuccess));
            }
        }
    };

    const handleSuccess = (data) => {
        toggleOpen(false);
        if(data?.key === "deleted"){
            handleDeleteTag(data);
            return;
        }
        handleAddTag(data);
    };

    return (
        <Box sx={tagsBoxStyle('table')}>
            <Controller
                name="tags"
                control={control}
                render={() => (
                    <Box sx={{ width: '100%' }}>
                        <Autocomplete
                            multiple
                            id="free-solo-dialog-demo"
                            options={allTags || []}
                            noOptionsText={isLoading ? 'Loading...' : 'No options'}
                            onInputChange={(e, newValue) => inputChangeHandler(newValue)}
                            value={allData}
                            sx={tagsStyle('table', isLight, '', allData?.length)}
                            onKeyDown={(e) => handleSpaceKeyDown(e)}
                            onChange={(event, newValue) => handleTags(event, newValue)}
                            filterOptions={(options, params) => filterOptions(options, params)}
                            getOptionLabel={(option) => getOptionLabel(option)}

                            ListboxProps={{
                                ref: listElem,
                                onScroll: leadMorePosition
                            }}
                            renderTags={(value) =>
                                value.map((option) => (
                                    <Chip
                                        sx={InputTags(option?.colour)}
                                        key={option?.id}
                                        label={option?.name}
                                        onDelete={handleDelete(option)}
                                        size="small"
                                        color="info"
                                        variant="soft"
                                        title={option?.name}
                                    />
                                ))
                            }
                            renderOption={(props, option) => (
                                <li {...props} key={option.id} style={{ padding: '0' }}>
                                    <Box sx={selectedTagStyle()}>{option.name}</Box>
                                </li>
                            )}
                            renderInput={(params) => <TextField {...params} placeholder={allData?.length <= 0 ? '+' : ''} />}
                        />
                        <Dialog open={open} onClose={() => toggleOpen(false)}>
                            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                                <DialogTitle>Add a new tag</DialogTitle>
                                <DialogContent>
                                    <DialogContentText sx={{ mb: 3 }}>
                                        Did you miss any tag in our list? Please, add it!
                                    </DialogContentText>
                                    <RHFTextField
                                        autoFocus
                                        margin="dense"
                                        name="tagName"
                                        label="Name"
                                        placeholder="Tag name"
                                        onChange={(event) => {
                                            setValue('tagName', event.target.value);
                                        }}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => toggleOpen(false)}>Cancel</Button>
                                    <Button type="submit">Add</Button>
                                </DialogActions>
                            </FormProvider>
                        </Dialog>
                    </Box>
                )}
            />
        </Box>
    );
};

export default AutocompleteCampaignTags;

AutocompleteCampaignTags.propTypes = {
    row: PropTypes.object,
    handleAddTag: PropTypes.func,
    handleDeleteTag: PropTypes.func,
    tagType: PropTypes.string
};