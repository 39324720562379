import PropTypes from 'prop-types';
import { SwipeableDrawer, Box, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import { TaskSidebar } from '../../../components/dashboardSidebars';
import { Iconify } from '../../../components';
// style
import { filterBoxStyle, reusableSidebarBoxStyle } from '../../../styles/TaskSidebarStyle';

// ----------------------------------------------------------------------

export default function ReusableSidebar({
    type,
    open,
    onClose,
    data,
    getReminderBtn,
    handleAttendClick,
    handleAttendFailedClick,
    reminderDataId,
    overViewbtns,
    handleReminderPopup,
    deleteTask,
    setReloadCounters
}) {
    const theme = useTheme();
    const isLight = theme.palette.mode === 'light';

    return (
        <SwipeableDrawer
            anchor="right"
            open={open}
            onClose={onClose}
            BackdropProps={{ invisible: true }}
            PaperProps={{ sx: { width: '377px', maxWidth: '80%', backgroundImage: 'none !important' } }}
            sx={{ zIndex: 1116 }}
        >
            <Box>
                <Box sx={reusableSidebarBoxStyle}>
                    {(type === 'Task' || type === 'Task-overView') && <Box sx={{ pr: 2, display: 'none' }} >
                        <Box sx={filterBoxStyle(isLight)} onClick={() => handleReminderPopup('editTask', data, data?.lead?.is_archived)} >
                            <Button
                                variant='contained'
                            >
                                <Iconify icon={'solar:pen-bold'} /> Edit task
                            </Button>
                        </Box>
                    </Box>}
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Box onClick={() => deleteTask(data)} sx={{ lineHeight: 0, cursor: 'pointer' }}>
                            <Iconify icon='ri:delete-bin-line' color={'common.grey'} width={24} height={24} />
                        </Box>
                        <Iconify icon='eva:close-fill' width={28} height={28} color={'common.grey'} sx={{ ml: '12px' ,cursor: 'pointer', ':hover': { bgcolor: 'rgba(99, 115, 129, 0.08)' } }} onClick={onClose} />
                    </Box>
                </Box>
                {(type === 'Task' || type === 'Task-overView') &&
					<TaskSidebar
					    data={data}
					    type={type}
					    overViewbtns={overViewbtns}
					    reminderDataId={reminderDataId}
					    getReminderBtn={getReminderBtn}
					    handleAttendClick={handleAttendClick}
					    handleAttendFailedClick={handleAttendFailedClick}
                        setReloadCounters={setReloadCounters}
                        onClose={onClose}
					/>}
            </Box>
        </SwipeableDrawer>
    );
}

ReusableSidebar.propTypes = {
    data: PropTypes.any,
    type: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    handleReminderPopup: PropTypes.func,
    reminderDataId: PropTypes.any,
    getReminderBtn: PropTypes.func,
    overViewbtns: PropTypes.func,
    handleAttendClick: PropTypes.func,
    handleAttendFailedClick: PropTypes.func,
    deleteTask: PropTypes.func,
    setReloadCounters: PropTypes.func
};