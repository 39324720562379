/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable camelcase */
import { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useDebounce } from 'use-lodash-debounce';
// @mui
import {
    Box,
    Tab,
    Tabs,
    Card,
    Table,
    Switch,
    Divider,
    TableBody,
    Container,
    TableContainer,
    TablePagination,
    FormControlLabel,
} from '@mui/material';
// components
import MultipleInfoEditDialog from 'src/sections/@dashboard/practiceTreatment/MultipleInfoEditDialog';
import MultipleEditOffersDialog from 'src/sections/@dashboard/practiceTreatment/MultipleEditOffersDialog';
import { useLocation } from 'react-router';
import { enqueueSnackbar } from 'notistack';
import { updateSelectedPracticeForMenu } from 'src/redux/slices/mergeTags';
import { useSettingsContext } from 'src/components/settings/context';
import { HeaderBreadcrumbs, Scrollbar } from '../../../components';
import { TableHeadCustom, TableNoData } from '../../../components/table';
import { TableLoading } from '../../../components/loading';
// constants
import { isEditData, noEditData, practiceTabs } from '../../../constants/PracticeTreatmentsCategoryConst';
// hooks
import {
    useTabs,
    useAuth,
    useToggle,
    useTable,
} from '../../../hooks';
// redux
import { dispatch, useSelector } from '../../../redux/store';
import { getPracticeUserList } from '../../../redux/slices/practiceUser';
import { deletePracticeTreatment, getPracticeTreatmentList, getTreatmentCategory, updatePracticeTreatment } from '../../../redux/slices/practiceTreatment';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// sections
import { UserTableToolbar } from '../../../sections/@dashboard/practiceTreatment/list';
import { PracticeTreatmentTableRow, PracticeEditDialog } from '../../../sections/@dashboard/practiceTreatment';
// style
import { tabStyle, tableContainerStyle, formControlStyle } from '../../../styles/PracticeTreatmentListStyle';
import { settingsMainBoxStyle, settingsContentBoxStyle } from '../../../styles/SettingsMenuStyle';

//-------------------------------------------------------------------------

export default function PracticeTreatmentList() {
    const {
        dense,
        page,
        order,
        orderBy,
        rowsPerPage,
        setPage,
        selected,
        setSelected,
        onSelectRow,
        onSelectAllRows,
        onSort,
        onChangeDense,
        onChangePage,
        onChangeRowsPerPage,
    } = useTable();

    const settings = useSettingsContext();
    const { user } = useAuth();
    const listElem = useRef();
    const mounted = useRef();
    const [position, setPosition] = useState(0);
    const [adminUser, setAdminUser] = useState('');
    const [filterName, setFilterName] = useState('');
    const [filterRole, setFilterRole] = useState('All');
    const [treatments, setTreatments] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [openEditOffer, setOpenEditOffer] = useState(false);
    const [openEditInfo, setOpenEditInfo] = useState(false);

    const [inputValue, setInputValue] = useState({
        offers: [{ name: '', offer: '', description: '', expires_at: '' }],
    });

    const debouncedValue = useDebounce(filterName, 1000);
    const practiceUser = useSelector((state) => state.practiceUser);
    const { practiceId } = useSelector((state) => state.practiceTreatmentList);
    const { treatmentsList, categoryList } = useSelector((state) => state.practiceTreatment);
    const isEdit = adminUser && adminUser.role === 'ADMIN';
    const TABLE_HEAD = isEdit ? isEditData : noEditData;
    const {
        currentTab: filterStatus,
        onChangeTab: onChangeFilterStatus
    } = useTabs('Enable');

    const {
        toggle: openContacts,
        onOpen: onOpenContacts,
        onClose: onCloseContacts
    } = useToggle();

    useEffect(() => {
        if (practiceId) {
            const data = {
                limit: 10,
                offset: 0
            };
            dispatch(getPracticeUserList(practiceId));
            dispatch(getTreatmentCategory(data));
        }
    }, [practiceId]);

    useEffect(() => {
        if (practiceId) {
            const data = {
                limit: rowsPerPage,
                offset: page * rowsPerPage,
                category: filterRole !== 'All' && filterRole,
                treatment: debouncedValue,
                type: 'practiceTreatment',
                enable: filterStatus
            };
            dispatch(getPracticeTreatmentList(practiceId, data, true));
        }
    }, [debouncedValue, practiceId]);

    useEffect(() => {
        if (treatmentsList && treatmentsList?.results) {
            setTableData(treatmentsList.results);
            const currentUser = user.email;
            setTreatments(treatmentsList?.results);
            setAdminUser(practiceUser.practiceUser.results.find((users) => users.user.email === currentUser));
        } else {
            setTableData([]);
        }
    }, [treatmentsList]);

    const handleFilterName = (filterName) => {
        setFilterName(filterName);
        setPage(0);
    };

    const handleDeleteRow = (id) => {
        const deleteRow = tableData.filter((row) => row.id !== id);
        setSelected([]);
        setTableData(deleteRow);
        dispatch(deletePracticeTreatment(id, handleSuccess));
    };

    const handleSuccess = () => {
        const data = {
            limit: rowsPerPage,
            offset: page * rowsPerPage,
            category: filterRole !== 'All' && filterRole,
            treatment: filterName,
            type: 'practiceTreatment',
            enable: filterStatus
        };
        dispatch(getPracticeTreatmentList(practiceId, data, true));
    };

    const handleResetFilter = () => {
        setFilterName('');
        setFilterRole('All');
    };

    const handleOnPage = (e, newValue) => {
        onChangePage(e, newValue);
        const data = {
            limit: rowsPerPage,
            offset: newValue * rowsPerPage,
            category: filterRole !== 'All' && filterRole,
            treatment: filterName,
            type: 'practiceTreatment',
            enable: filterStatus
        };
        dispatch(getPracticeTreatmentList(practiceId, data, true));
    };

    const handleOnChangeRowsPerPage = (e) => {
        onChangeRowsPerPage(e);
        const data = {
            limit: e.target.value,
            offset: page,
            category: filterRole !== 'All' && filterRole,
            treatment: filterName,
            type: 'practiceTreatment',
            enable: filterStatus
        };
        dispatch(getPracticeTreatmentList(practiceId, data, true));
    };

    const handleFilter = (e) => {
        setPage(0);
        setFilterRole(e.target.value);
        const data = {
            limit: rowsPerPage,
            offset: 0,
            category: e.target.value !== 'All' && e.target.value,
            treatment: filterName,
            type: 'practiceTreatment',
            enable: filterStatus
        };
        dispatch(getPracticeTreatmentList(practiceId, data, true));
        setSelected([]);
    };

    const leadMoreData = () => {
        if ((categoryList?.count + 1) > categoryList?.results?.length) {
            const data = {
                limit: 10,
                offset: categoryList?.results?.length - 1
            };
            dispatch(getTreatmentCategory(data));
        }
    };

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
        } else if (position && listElem.current) {
            listElem.current.scrollTop = position - listElem?.current?.offsetHeight;
        }
    }, [position]);

    const handleTabs = (e, value) => {
        const data = {
            limit: rowsPerPage,
            offset: 0,
            category: filterRole !== 'All' && filterRole,
            treatment: filterName,
            type: 'practiceTreatment',
            enable: value
        };
        dispatch(getPracticeTreatmentList(practiceId, data, true));
        onChangeFilterStatus(e, value);
    };

    const location = useLocation()

    useEffect(() => {
        dispatch(updateSelectedPracticeForMenu("practice_treatments"))
    }, [location.pathname])

    const handleSelectAll = (isAllSelected) => {
        onSelectAllRows(isAllSelected, tableData.map((row) => row.id));

    }
    const handleCheckTreatment = (checked, id) => {
        if (checked) {
            setSelected([...selected, id])
        } else {
            setSelected(selected.filter((row) => row !== id))
        }
    }

    const handleOfferButton = () => {
        setOpenEditOffer(true);
        const filteredData = tableData?.filter((row) => selected.includes(row.id));
        const allOffersAreSame = filteredData.length > 0 && 
        filteredData.every((item, _, array) => {
            const firstOffers = array[0].offers.map(({ name, offer, expires_at }) => ({name, offer,expires_at}));
            const currentOffers = item.offers.map(({ name, offer, expires_at }) => ({ name, offer,expires_at}));

            return JSON.stringify(currentOffers) === JSON.stringify(firstOffers);
        });
        if(allOffersAreSame) {
            if (selected.length === tableData.length){
                setInputValue({
                    offers: filteredData[0].offers.map((offer) => ({
                       ...offer,
                        name: offer.name,
                        offer: offer.offer,
                        description: offer.description,
                        expires_at: offer.expires_at,
                    })),
                });
            }
            else {
                setInputValue({
                    offers: filteredData[0].offers.map((offer) => ({
                        name: offer.name,
                        offer: offer.offer,
                        description: offer.description,
                        expires_at: offer.expires_at,
                    })),
                });
            }
            
        }
        else {
            setInputValue({
                offers: [],
            });
        }
    }

    const handleSuccessOffers = (data) => {
            enqueueSnackbar("Offer updated successfully" || 'Something went wrong',
                {
                    variant: data.success ? 'success' : 'error',
                    anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center'
                    }
                }
            );
        
    }
    const handleCloseEditDialog = async (isChanged) => {
      
        if (isChanged === true) {
            /* eslint-disable no-await-in-loop */
            for (let i = 0; i < selected.length; i += 1) {
                const detail = {
                    practice: Number(practiceId),
                    offers: inputValue.offers,
                };
    
                await dispatch(updatePracticeTreatment(detail, selected[i], handleSuccessOffers));
            }
    
            setSelected([]);
            setInputValue({ offers: [] });
            handleSuccess(); 
        }
    
        setOpenEditOffer(false);
    };
    
    const handleSuccessInfo = (data) => {
        enqueueSnackbar("Additional infos updated successfully" || 'Something went wrong',
            {
                variant: data.success ? 'success' : 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'center'
                }
            }
        );
    
}
    const handleAdditonalInfoButton = () => {
        setOpenEditInfo(true);
        const filteredData = tableData?.filter((row) => selected.includes(row.id));
        const allInfosAreSame = filteredData.length > 0 && 
        filteredData.every((item, _, array) => {
            const firstInfo = array[0].additional_infos.map(({ text, is_enabled, custom_identifier, categories }) => ({text, is_enabled,custom_identifier, categories}));
            const currentInfo = item.additional_infos.map(({ text, is_enabled, custom_identifier, categories }) => ({ text, is_enabled, custom_identifier, categories}));

            return JSON.stringify(currentInfo) === JSON.stringify(firstInfo);
        });
        if(allInfosAreSame) {
            if (selected.length === tableData.length){
                setInputValue({
                    additional_infos: filteredData[0].additional_infos.map((info) => ({
                       ...info,
                       text: info.text, 
                       is_enabled: info.is_enabled, 
                       custom_identifier: info.custom_identifier, 
                       categories: info.categories
                    })),
                });
            }
            else {
                setInputValue({
                    additional_infos: filteredData[0].additional_infos.map((info) => ({
                        text: info.text, 
                        is_enabled: info.is_enabled, 
                        custom_identifier: info.custom_identifier, 
                        categories: info.categories
                     })),
                });
            }
            
        }
        else {
            setInputValue({
                additional_infos: [],
            });
        }
    }

    const handleCloseEditInfoDialog = async (isChanged) => {
      
        if (isChanged === true) {
            /* eslint-disable no-await-in-loop */
            for (let i = 0; i < selected.length; i += 1) {
                const detail = {
                    practice: Number(practiceId),
                    additional_infos: inputValue.additional_infos,
                };
    
                await dispatch(updatePracticeTreatment(detail, selected[i], handleSuccessInfo));
            }
    
            setSelected([]);
            setInputValue({ additional_infos: [] });
            handleSuccess(); 
        }
    
        setOpenEditInfo(false);
    };
    

    return (
        <>
            <Helmet>
                <title> Practice Treatments | Dental SEM CRM </title>
            </Helmet>
            <Container maxWidth={settings.themeStretch ? false : 'xl'}>
                <Box sx={settingsMainBoxStyle}>
                    <Box sx={settingsContentBoxStyle}>
                        <HeaderBreadcrumbs
                            heading="Practice Treatments"
                            links={[
                                { name: 'Dashboard', href: PATH_DASHBOARD.root },
                                { name: 'Practice Treatments' }
                            ]}
                        />
                        <Card>
                            <Tabs
                                allowScrollButtonsMobile
                                variant="scrollable"
                                scrollButtons="auto"
                                value={filterStatus}
                                onChange={handleTabs}
                                sx={{ px: 2, bgcolor: 'background.neutral' }}
                            >
                                {practiceTabs.map((el) => <Tab
                                    sx={tabStyle}
                                    disableRipple
                                    key={el.value}
                                    label={el.label}
                                    value={el.value}
                                />)}
                            </Tabs>
                            <Divider />
                            <UserTableToolbar
                                filterName={filterName}
                                filterRole={filterRole}
                                onFilterName={handleFilterName}
                                onFilterRole={handleFilter}
                                optionsRole={tableData}
                                onResetFilter={handleResetFilter}
                                categoryList={categoryList}
                                leadMoreData={leadMoreData}
                                setPosition={setPosition}
                                showOfferButton={isEdit}
                                disabledOfferButton={selected.length === 0}
                                handleOfferButton={handleOfferButton}
                                handleAdditonalInfoButton={handleAdditonalInfoButton}
                            />
                            <Scrollbar>
                                <TableContainer sx={tableContainerStyle}>
                                    <Table size={dense ? 'small' : 'medium'} sx={{ '.MuiTableCell-root': { boxShadow: 'none !important', borderRadius: 0 } }}>
                                        <TableHeadCustom
                                            order={order}
                                            orderBy={orderBy}
                                            headLabel={TABLE_HEAD}
                                            rowCount={tableData.length}
                                            numSelected={selected.length}
                                            onSort={onSort}
                                            onSelectAllRows={(checked) =>
                                                onSelectAllRows(
                                                    checked,
                                                    tableData.map((row) => row.id)
                                                )
                                            }
                                            showSelected={selected.length === tableData.length}
                                            handleSelectAll={handleSelectAll}
                                        />
                                        <TableBody>
                                            <TableLoading pageCount={treatmentsList?.count === undefined && 'No Found'} tableData={treatmentsList?.results?.length === 0 && 'No Found'} />
                                            {treatmentsList !== undefined && treatments.map((row) => (
                                                <PracticeTreatmentTableRow
                                                    key={row.id}
                                                    row={row}
                                                    selected={selected.includes(row.id)}
                                                    onSelectRow={() => onSelectRow(row.id)}
                                                    onDeleteRow={() => handleDeleteRow(row.id)}
                                                    onClick={() => onOpenContacts(row.id)}
                                                    isEdit={isEdit}
                                                    reloadData={handleSuccess}
                                                    onCheckTreatment={(checked) => handleCheckTreatment(checked, row.id)}
                                                />
                                            ))}
                                            {treatmentsList.count !== undefined && treatmentsList.results.length === 0 &&
                                                <TableNoData isNotFound={treatments?.length === 0} />
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Scrollbar>
                            <Box sx={{ position: 'relative' }}>
                                {treatmentsList?.count > 10 && <TablePagination
                                    rowsPerPageOptions={[10, 25]}
                                    component="div"
                                    count={treatmentsList?.count}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={(e, newValue) => handleOnPage(e, newValue)}
                                    onRowsPerPageChange={handleOnChangeRowsPerPage}
                                />}

                                <FormControlLabel
                                    control={<Switch checked={dense} onChange={onChangeDense} />}
                                    label="Dense"
                                    sx={formControlStyle}
                                />
                            </Box>
                        </Card>
                    </Box>
                </Box>
            </Container>
            <PracticeEditDialog open={openContacts} onClose={onCloseContacts} />
            <MultipleEditOffersDialog openEditOffer={openEditOffer} handleCloseEditDialog={handleCloseEditDialog} inputValue={inputValue} setInputValue={setInputValue} />
            <MultipleInfoEditDialog openEditInfo={openEditInfo} inputValue={inputValue} setInputValue={setInputValue} handleCloseEditInfoDialog={handleCloseEditInfoDialog}/>
        </>
    );
}
