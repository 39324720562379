// ----------------------------------------------------------------------

function path(root, sublink) {
    return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_STRIPE = '/stripe';
const ROOTS_FACEBOOK = '/facebook';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
    root: ROOTS_AUTH,
    login: path(ROOTS_AUTH, '/login'),
    register: path(ROOTS_AUTH, '/register'),
    loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
    registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
    confirmEmail: path(ROOTS_AUTH, '/register/account-confirm-email'),
    verify: path(ROOTS_AUTH, '/verify'),
    resetPassword: path(ROOTS_AUTH, '/reset-password'),
};

export const PATH_PAGE = {
    maintenance: '/maintenance',
    page403: '/403',
    page404: '/404',
    page500: '/500',
    comingSoon: '/coming-soon',
    components: '/components',
    privacyPolicy: '/privacy-policy',
    newPassword: path(ROOTS_AUTH, '/reset/:uid/:token'),
    appointmentRating: '/appointments/feedback',
};

export const PATH_DASHBOARD = {
    root: ROOTS_DASHBOARD,
    general: {
        app: path(ROOTS_DASHBOARD, '/app'),
        leadDetail: path(ROOTS_DASHBOARD, '/practice/:practiceId/lead-detail/:id/:tab?'),
        archiveDetail: path(ROOTS_DASHBOARD, '/practice/:practiceId/archived/:id/'),
        StripeAuthorized: path(ROOTS_STRIPE, '/authorize/callback'),
        FacebookAuthorized: path(ROOTS_FACEBOOK, '/authorize/callback'),
    },
    task: {
        root: path(ROOTS_DASHBOARD, '/tasks')
    },
    whatsapp: {
        root: path(ROOTS_DASHBOARD, '/whatsapp')
    },
    sms: {
        root: path(ROOTS_DASHBOARD, '/sms')
    },
    emails: {
        root: path(ROOTS_DASHBOARD, '/emails')
    },
    createStripePayment: {
        root: path(ROOTS_DASHBOARD, '/payment-links/create')
    },
    analytics: {
        root: path(ROOTS_DASHBOARD, '/analytics'),
        reports: path(ROOTS_DASHBOARD, '/analytics/reports'),
        treatmentSummary: path(ROOTS_DASHBOARD, '/analytics/treatment-summary'),
        categorySummary: path(ROOTS_DASHBOARD, '/analytics/category-summary'),
        contactSummary: path(ROOTS_DASHBOARD, '/analytics/contact-summary'),
        contactLogs: path(ROOTS_DASHBOARD, '/analytics/contact-logs'),
        sourceSummary: path(ROOTS_DASHBOARD, '/analytics/source-summary'),
        practitionerSummary: path(ROOTS_DASHBOARD, '/analytics/practitioner-summary'),
        reportsSetting: path(ROOTS_DASHBOARD, '/analytics/reports-setting'),
        archiveSummary: path(ROOTS_DASHBOARD, '/analytics/archived-summary'),
    },
    patientReview: path(ROOTS_DASHBOARD, '/patient-review'),
    tables: {
        root: path(ROOTS_DASHBOARD, '/tables'),
        AllLeadTable: path(ROOTS_DASHBOARD, '/tables/all-lead-table'),
        NoTaskLeadsTable: path(ROOTS_DASHBOARD, '/tables/no-task-assigned-table'),
        newLeadTable: path(ROOTS_DASHBOARD, '/tables/new-lead-table'),
        nhsLeadTable: path(ROOTS_DASHBOARD, '/tables/nhs-lead-table'),
        archiveLeadTable: path(ROOTS_DASHBOARD, '/tables/archive-table'),
        ConsultantAttendedTable: path(ROOTS_DASHBOARD, '/tables/consultant-attended-table'),
        ConsultantBookedTable: path(ROOTS_DASHBOARD, '/tables/consultant-booked-table'),
        treatmentStartedTable: path(ROOTS_DASHBOARD, '/tables/treatment-started-table'),
        alignerApproveTable: path(ROOTS_DASHBOARD, '/tables/aligner-approve-table'),
        failedToAttendTable: path(ROOTS_DASHBOARD, '/tables/failed-to-attend-table'),
        closedTable: path(ROOTS_DASHBOARD, '/tables/completed-table'),
        duplicateLeadTable: path(ROOTS_DASHBOARD, '/tables/duplicate-lead-table'),
    },
    calendar: path(ROOTS_DASHBOARD, '/calendar'),
    kanban: path(ROOTS_DASHBOARD, '/kanban'),
    financialTracker: path(ROOTS_DASHBOARD, '/financial-tracker'),
    qrCodeGenerator: path(ROOTS_DASHBOARD, '/qr-code'),
    campaign: {
        root: path(ROOTS_DASHBOARD, '/campaigns-manager'),
        campaign: path(ROOTS_DASHBOARD, '/campaigns-manager/campaigns'),
        audienceManager: path(ROOTS_DASHBOARD, '/campaigns-manager/audience_manager'),
        createAudience: path(ROOTS_DASHBOARD, '/campaigns-manager/create_audience'),
        editAudience: (id) => path(ROOTS_DASHBOARD, `/campaigns-manager/${id}/edit_audience`),
        templates: path(ROOTS_DASHBOARD, '/campaigns-manager/templates'),
    },
    permissionDenied: path(ROOTS_DASHBOARD, '/permission-denied'),
    user: {
        root: path(ROOTS_DASHBOARD, '/user'),
        new: path(ROOTS_DASHBOARD, '/user/new'),
        list: path(ROOTS_DASHBOARD, '/user/list'),
        profile: path(ROOTS_DASHBOARD, '/user/profile'),
        account: path(ROOTS_DASHBOARD, '/user/account'),
        message: path(ROOTS_DASHBOARD, '/user/message'),
        integration: path(ROOTS_DASHBOARD, '/user/integration'),
        practice: path(ROOTS_DASHBOARD, '/user/practice'),
        practiceId: (id) => path(ROOTS_DASHBOARD, `/user/practice/${id}`),
        practiceTreatment: path(ROOTS_DASHBOARD, '/user/practice-treatment'),
        practiceUser: path(ROOTS_DASHBOARD, '/user/practice-user'),
        edit: (name) => path(ROOTS_DASHBOARD, `/user/${name}/edit`),
        consent: path(ROOTS_DASHBOARD, '/user/practice-consent'),
        loan: path(ROOTS_DASHBOARD, '/user/practice-loan'),
        notification: path(ROOTS_DASHBOARD, '/user/notification-settings'),
        notificationPage: path(ROOTS_DASHBOARD, '/user/notification-table'),
        practicePreferences: path(ROOTS_DASHBOARD, '/user/practice-preferences'),
        demoEdit: path(ROOTS_DASHBOARD, '/user/reece-chung/edit'),
        autoReminders: path(ROOTS_DASHBOARD, '/user/auto-reminders'),
        createReminder: path(ROOTS_DASHBOARD, '/user/create-reminder'),
        editAutoReminder: (id) => path(ROOTS_DASHBOARD, `/user/${id}/edit-reminder`),
        facebookComment: path(ROOTS_DASHBOARD, '/user/facebook'),
        exportToCsv: path(ROOTS_DASHBOARD, '/user/export-csv'),
        practiceChatbot: path(ROOTS_DASHBOARD, '/user/practice-chatbot'),
        callScripts: path(ROOTS_DASHBOARD, '/user/call-scripts'),
        callScriptsV2: path(ROOTS_DASHBOARD, '/user/call-scripts-v2'),
        createScript: path(ROOTS_DASHBOARD, '/user/create-script'),
        createChatbot: path(ROOTS_DASHBOARD, '/user/create-chatbot'),
        editCallScript: (id) => path(ROOTS_DASHBOARD, `/user/${id}/edit-script`),
        editChatbot: (id) => path(ROOTS_DASHBOARD, `/user/edit-chatbot/${id}`),
        consentBuilder: path(ROOTS_DASHBOARD, '/user/consent'),
        createConsent: path(ROOTS_DASHBOARD, '/user/create-consent'),
        editConsent: (id) => path(ROOTS_DASHBOARD, `/user/${id}/edit-consent`),
    },
    /* super admin */
    superAdmin: {
        root: path(ROOTS_DASHBOARD, '/super-admin'),
        engagementTemplates: path(ROOTS_DASHBOARD, '/super-admin/engagement_templates'),
        EmailTemplates: path(ROOTS_DASHBOARD, '/super-admin/email_template/:templateId?'),
        SmsTemplates: path(ROOTS_DASHBOARD, '/super-admin/sms_template/:templateId?'),
        BulkAutoReminders: path(ROOTS_DASHBOARD, '/super-admin/auto_reminder'),
        CreateBulkAutoReminder: path(ROOTS_DASHBOARD, '/super-admin/create_reminder'),
        EditBulkAutoReminder: (id) => path(ROOTS_DASHBOARD, `/super-admin/${id}/edit_reminder`),
        ImportLeads: path(ROOTS_DASHBOARD, '/super-admin/import'),
        ImportLeadsCreate: path(ROOTS_DASHBOARD, '/super-admin/create_import_lead'),
    },
    invoice: {
        root: path(ROOTS_DASHBOARD, '/invoice'),
        list: path(ROOTS_DASHBOARD, '/invoice/list'),
        new: path(ROOTS_DASHBOARD, '/invoice/new'),
        view: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}`),
        edit: (id) => path(ROOTS_DASHBOARD, `/invoice/${id}/edit`),
        demoEdit: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b1/edit'),
        demoView: path(ROOTS_DASHBOARD, '/invoice/e99f09a7-dd88-49d5-b1c8-1daf80c2d7b5'),
    },
    websiteSupport: path(ROOTS_DASHBOARD, '/website-support')
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
